/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const buildbuddy = $root.buildbuddy = (() => {

    /**
     * Namespace buildbuddy.
     * @exports buildbuddy
     * @namespace
     */
    const buildbuddy = {};

    buildbuddy.service = (function() {

        /**
         * Namespace service.
         * @memberof buildbuddy
         * @namespace
         */
        const service = {};

        service.BuildBuddyService = (function() {

            /**
             * Constructs a new BuildBuddyService service.
             * @memberof buildbuddy.service
             * @classdesc Represents a BuildBuddyService
             * @extends $protobuf.rpc.Service
             * @constructor
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             */
            function BuildBuddyService(rpcImpl, requestDelimited, responseDelimited) {
                $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
            }

            (BuildBuddyService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = BuildBuddyService;

            /**
             * Creates new BuildBuddyService service using the specified rpc implementation.
             * @function create
             * @memberof buildbuddy.service.BuildBuddyService
             * @static
             * @param {$protobuf.RPCImpl} rpcImpl RPC implementation
             * @param {boolean} [requestDelimited=false] Whether requests are length-delimited
             * @param {boolean} [responseDelimited=false] Whether responses are length-delimited
             * @returns {BuildBuddyService} RPC service. Useful where requests and/or responses are streamed.
             */
            BuildBuddyService.create = function create(rpcImpl, requestDelimited, responseDelimited) {
                return new this(rpcImpl, requestDelimited, responseDelimited);
            };

            /**
             * Callback as used by {@link buildbuddy.service.BuildBuddyService#getInvocation}.
             * @memberof buildbuddy.service.BuildBuddyService
             * @typedef GetInvocationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {invocation.GetInvocationResponse} [response] GetInvocationResponse
             */

            /**
             * Calls GetInvocation.
             * @function getInvocation
             * @memberof buildbuddy.service.BuildBuddyService
             * @instance
             * @param {invocation.IGetInvocationRequest} request GetInvocationRequest message or plain object
             * @param {buildbuddy.service.BuildBuddyService.GetInvocationCallback} callback Node-style callback called with the error, if any, and GetInvocationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BuildBuddyService.prototype.getInvocation = function getInvocation(request, callback) {
                return this.rpcCall(getInvocation, $root.invocation.GetInvocationRequest, $root.invocation.GetInvocationResponse, request, callback);
            }, "name", { value: "GetInvocation" });

            /**
             * Calls GetInvocation.
             * @function getInvocation
             * @memberof buildbuddy.service.BuildBuddyService
             * @instance
             * @param {invocation.IGetInvocationRequest} request GetInvocationRequest message or plain object
             * @returns {Promise<invocation.GetInvocationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link buildbuddy.service.BuildBuddyService#searchInvocation}.
             * @memberof buildbuddy.service.BuildBuddyService
             * @typedef SearchInvocationCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {invocation.SearchInvocationResponse} [response] SearchInvocationResponse
             */

            /**
             * Calls SearchInvocation.
             * @function searchInvocation
             * @memberof buildbuddy.service.BuildBuddyService
             * @instance
             * @param {invocation.ISearchInvocationRequest} request SearchInvocationRequest message or plain object
             * @param {buildbuddy.service.BuildBuddyService.SearchInvocationCallback} callback Node-style callback called with the error, if any, and SearchInvocationResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BuildBuddyService.prototype.searchInvocation = function searchInvocation(request, callback) {
                return this.rpcCall(searchInvocation, $root.invocation.SearchInvocationRequest, $root.invocation.SearchInvocationResponse, request, callback);
            }, "name", { value: "SearchInvocation" });

            /**
             * Calls SearchInvocation.
             * @function searchInvocation
             * @memberof buildbuddy.service.BuildBuddyService
             * @instance
             * @param {invocation.ISearchInvocationRequest} request SearchInvocationRequest message or plain object
             * @returns {Promise<invocation.SearchInvocationResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link buildbuddy.service.BuildBuddyService#createUser}.
             * @memberof buildbuddy.service.BuildBuddyService
             * @typedef CreateUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {user.CreateUserResponse} [response] CreateUserResponse
             */

            /**
             * Calls CreateUser.
             * @function createUser
             * @memberof buildbuddy.service.BuildBuddyService
             * @instance
             * @param {user.ICreateUserRequest} request CreateUserRequest message or plain object
             * @param {buildbuddy.service.BuildBuddyService.CreateUserCallback} callback Node-style callback called with the error, if any, and CreateUserResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BuildBuddyService.prototype.createUser = function createUser(request, callback) {
                return this.rpcCall(createUser, $root.user.CreateUserRequest, $root.user.CreateUserResponse, request, callback);
            }, "name", { value: "CreateUser" });

            /**
             * Calls CreateUser.
             * @function createUser
             * @memberof buildbuddy.service.BuildBuddyService
             * @instance
             * @param {user.ICreateUserRequest} request CreateUserRequest message or plain object
             * @returns {Promise<user.CreateUserResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link buildbuddy.service.BuildBuddyService#getUser}.
             * @memberof buildbuddy.service.BuildBuddyService
             * @typedef GetUserCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {user.GetUserResponse} [response] GetUserResponse
             */

            /**
             * Calls GetUser.
             * @function getUser
             * @memberof buildbuddy.service.BuildBuddyService
             * @instance
             * @param {user.IGetUserRequest} request GetUserRequest message or plain object
             * @param {buildbuddy.service.BuildBuddyService.GetUserCallback} callback Node-style callback called with the error, if any, and GetUserResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BuildBuddyService.prototype.getUser = function getUser(request, callback) {
                return this.rpcCall(getUser, $root.user.GetUserRequest, $root.user.GetUserResponse, request, callback);
            }, "name", { value: "GetUser" });

            /**
             * Calls GetUser.
             * @function getUser
             * @memberof buildbuddy.service.BuildBuddyService
             * @instance
             * @param {user.IGetUserRequest} request GetUserRequest message or plain object
             * @returns {Promise<user.GetUserResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link buildbuddy.service.BuildBuddyService#getBazelConfig}.
             * @memberof buildbuddy.service.BuildBuddyService
             * @typedef GetBazelConfigCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {bazel_config.GetBazelConfigResponse} [response] GetBazelConfigResponse
             */

            /**
             * Calls GetBazelConfig.
             * @function getBazelConfig
             * @memberof buildbuddy.service.BuildBuddyService
             * @instance
             * @param {bazel_config.IGetBazelConfigRequest} request GetBazelConfigRequest message or plain object
             * @param {buildbuddy.service.BuildBuddyService.GetBazelConfigCallback} callback Node-style callback called with the error, if any, and GetBazelConfigResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BuildBuddyService.prototype.getBazelConfig = function getBazelConfig(request, callback) {
                return this.rpcCall(getBazelConfig, $root.bazel_config.GetBazelConfigRequest, $root.bazel_config.GetBazelConfigResponse, request, callback);
            }, "name", { value: "GetBazelConfig" });

            /**
             * Calls GetBazelConfig.
             * @function getBazelConfig
             * @memberof buildbuddy.service.BuildBuddyService
             * @instance
             * @param {bazel_config.IGetBazelConfigRequest} request GetBazelConfigRequest message or plain object
             * @returns {Promise<bazel_config.GetBazelConfigResponse>} Promise
             * @variation 2
             */

            /**
             * Callback as used by {@link buildbuddy.service.BuildBuddyService#getInvocationStat}.
             * @memberof buildbuddy.service.BuildBuddyService
             * @typedef GetInvocationStatCallback
             * @type {function}
             * @param {Error|null} error Error, if any
             * @param {invocation.GetInvocationStatResponse} [response] GetInvocationStatResponse
             */

            /**
             * Calls GetInvocationStat.
             * @function getInvocationStat
             * @memberof buildbuddy.service.BuildBuddyService
             * @instance
             * @param {invocation.IGetInvocationStatRequest} request GetInvocationStatRequest message or plain object
             * @param {buildbuddy.service.BuildBuddyService.GetInvocationStatCallback} callback Node-style callback called with the error, if any, and GetInvocationStatResponse
             * @returns {undefined}
             * @variation 1
             */
            Object.defineProperty(BuildBuddyService.prototype.getInvocationStat = function getInvocationStat(request, callback) {
                return this.rpcCall(getInvocationStat, $root.invocation.GetInvocationStatRequest, $root.invocation.GetInvocationStatResponse, request, callback);
            }, "name", { value: "GetInvocationStat" });

            /**
             * Calls GetInvocationStat.
             * @function getInvocationStat
             * @memberof buildbuddy.service.BuildBuddyService
             * @instance
             * @param {invocation.IGetInvocationStatRequest} request GetInvocationStatRequest message or plain object
             * @returns {Promise<invocation.GetInvocationStatResponse>} Promise
             * @variation 2
             */

            return BuildBuddyService;
        })();

        return service;
    })();

    return buildbuddy;
})();

export const bazel_config = $root.bazel_config = (() => {

    /**
     * Namespace bazel_config.
     * @exports bazel_config
     * @namespace
     */
    const bazel_config = {};

    bazel_config.ConfigOption = (function() {

        /**
         * Properties of a ConfigOption.
         * @memberof bazel_config
         * @interface IConfigOption
         * @property {string|null} [body] ConfigOption body
         * @property {string|null} [optionLifecycle] ConfigOption optionLifecycle
         * @property {string|null} [flagName] ConfigOption flagName
         * @property {string|null} [flagValue] ConfigOption flagValue
         */

        /**
         * Constructs a new ConfigOption.
         * @memberof bazel_config
         * @classdesc Represents a ConfigOption.
         * @implements IConfigOption
         * @constructor
         * @param {bazel_config.IConfigOption=} [properties] Properties to set
         */
        function ConfigOption(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConfigOption body.
         * @member {string} body
         * @memberof bazel_config.ConfigOption
         * @instance
         */
        ConfigOption.prototype.body = "";

        /**
         * ConfigOption optionLifecycle.
         * @member {string} optionLifecycle
         * @memberof bazel_config.ConfigOption
         * @instance
         */
        ConfigOption.prototype.optionLifecycle = "";

        /**
         * ConfigOption flagName.
         * @member {string} flagName
         * @memberof bazel_config.ConfigOption
         * @instance
         */
        ConfigOption.prototype.flagName = "";

        /**
         * ConfigOption flagValue.
         * @member {string} flagValue
         * @memberof bazel_config.ConfigOption
         * @instance
         */
        ConfigOption.prototype.flagValue = "";

        /**
         * Creates a new ConfigOption instance using the specified properties.
         * @function create
         * @memberof bazel_config.ConfigOption
         * @static
         * @param {bazel_config.IConfigOption=} [properties] Properties to set
         * @returns {bazel_config.ConfigOption} ConfigOption instance
         */
        ConfigOption.create = function create(properties) {
            return new ConfigOption(properties);
        };

        /**
         * Encodes the specified ConfigOption message. Does not implicitly {@link bazel_config.ConfigOption.verify|verify} messages.
         * @function encode
         * @memberof bazel_config.ConfigOption
         * @static
         * @param {bazel_config.IConfigOption} message ConfigOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigOption.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.body != null && message.hasOwnProperty("body"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.body);
            if (message.optionLifecycle != null && message.hasOwnProperty("optionLifecycle"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.optionLifecycle);
            if (message.flagName != null && message.hasOwnProperty("flagName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.flagName);
            if (message.flagValue != null && message.hasOwnProperty("flagValue"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.flagValue);
            return writer;
        };

        /**
         * Encodes the specified ConfigOption message, length delimited. Does not implicitly {@link bazel_config.ConfigOption.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bazel_config.ConfigOption
         * @static
         * @param {bazel_config.IConfigOption} message ConfigOption message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConfigOption.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConfigOption message from the specified reader or buffer.
         * @function decode
         * @memberof bazel_config.ConfigOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bazel_config.ConfigOption} ConfigOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigOption.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bazel_config.ConfigOption();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.body = reader.string();
                    break;
                case 2:
                    message.optionLifecycle = reader.string();
                    break;
                case 3:
                    message.flagName = reader.string();
                    break;
                case 4:
                    message.flagValue = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConfigOption message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bazel_config.ConfigOption
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bazel_config.ConfigOption} ConfigOption
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConfigOption.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConfigOption message.
         * @function verify
         * @memberof bazel_config.ConfigOption
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConfigOption.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.body != null && message.hasOwnProperty("body"))
                if (!$util.isString(message.body))
                    return "body: string expected";
            if (message.optionLifecycle != null && message.hasOwnProperty("optionLifecycle"))
                if (!$util.isString(message.optionLifecycle))
                    return "optionLifecycle: string expected";
            if (message.flagName != null && message.hasOwnProperty("flagName"))
                if (!$util.isString(message.flagName))
                    return "flagName: string expected";
            if (message.flagValue != null && message.hasOwnProperty("flagValue"))
                if (!$util.isString(message.flagValue))
                    return "flagValue: string expected";
            return null;
        };

        /**
         * Creates a ConfigOption message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof bazel_config.ConfigOption
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {bazel_config.ConfigOption} ConfigOption
         */
        ConfigOption.fromObject = function fromObject(object) {
            if (object instanceof $root.bazel_config.ConfigOption)
                return object;
            let message = new $root.bazel_config.ConfigOption();
            if (object.body != null)
                message.body = String(object.body);
            if (object.optionLifecycle != null)
                message.optionLifecycle = String(object.optionLifecycle);
            if (object.flagName != null)
                message.flagName = String(object.flagName);
            if (object.flagValue != null)
                message.flagValue = String(object.flagValue);
            return message;
        };

        /**
         * Creates a plain object from a ConfigOption message. Also converts values to other types if specified.
         * @function toObject
         * @memberof bazel_config.ConfigOption
         * @static
         * @param {bazel_config.ConfigOption} message ConfigOption
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConfigOption.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.body = "";
                object.optionLifecycle = "";
                object.flagName = "";
                object.flagValue = "";
            }
            if (message.body != null && message.hasOwnProperty("body"))
                object.body = message.body;
            if (message.optionLifecycle != null && message.hasOwnProperty("optionLifecycle"))
                object.optionLifecycle = message.optionLifecycle;
            if (message.flagName != null && message.hasOwnProperty("flagName"))
                object.flagName = message.flagName;
            if (message.flagValue != null && message.hasOwnProperty("flagValue"))
                object.flagValue = message.flagValue;
            return object;
        };

        /**
         * Converts this ConfigOption to JSON.
         * @function toJSON
         * @memberof bazel_config.ConfigOption
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConfigOption.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ConfigOption;
    })();

    bazel_config.GetBazelConfigRequest = (function() {

        /**
         * Properties of a GetBazelConfigRequest.
         * @memberof bazel_config
         * @interface IGetBazelConfigRequest
         * @property {context.IRequestContext|null} [requestContext] GetBazelConfigRequest requestContext
         * @property {string|null} [host] GetBazelConfigRequest host
         * @property {string|null} [protocol] GetBazelConfigRequest protocol
         */

        /**
         * Constructs a new GetBazelConfigRequest.
         * @memberof bazel_config
         * @classdesc Represents a GetBazelConfigRequest.
         * @implements IGetBazelConfigRequest
         * @constructor
         * @param {bazel_config.IGetBazelConfigRequest=} [properties] Properties to set
         */
        function GetBazelConfigRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetBazelConfigRequest requestContext.
         * @member {context.IRequestContext|null|undefined} requestContext
         * @memberof bazel_config.GetBazelConfigRequest
         * @instance
         */
        GetBazelConfigRequest.prototype.requestContext = null;

        /**
         * GetBazelConfigRequest host.
         * @member {string} host
         * @memberof bazel_config.GetBazelConfigRequest
         * @instance
         */
        GetBazelConfigRequest.prototype.host = "";

        /**
         * GetBazelConfigRequest protocol.
         * @member {string} protocol
         * @memberof bazel_config.GetBazelConfigRequest
         * @instance
         */
        GetBazelConfigRequest.prototype.protocol = "";

        /**
         * Creates a new GetBazelConfigRequest instance using the specified properties.
         * @function create
         * @memberof bazel_config.GetBazelConfigRequest
         * @static
         * @param {bazel_config.IGetBazelConfigRequest=} [properties] Properties to set
         * @returns {bazel_config.GetBazelConfigRequest} GetBazelConfigRequest instance
         */
        GetBazelConfigRequest.create = function create(properties) {
            return new GetBazelConfigRequest(properties);
        };

        /**
         * Encodes the specified GetBazelConfigRequest message. Does not implicitly {@link bazel_config.GetBazelConfigRequest.verify|verify} messages.
         * @function encode
         * @memberof bazel_config.GetBazelConfigRequest
         * @static
         * @param {bazel_config.IGetBazelConfigRequest} message GetBazelConfigRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBazelConfigRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestContext != null && message.hasOwnProperty("requestContext"))
                $root.context.RequestContext.encode(message.requestContext, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.host != null && message.hasOwnProperty("host"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.host);
            if (message.protocol != null && message.hasOwnProperty("protocol"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.protocol);
            return writer;
        };

        /**
         * Encodes the specified GetBazelConfigRequest message, length delimited. Does not implicitly {@link bazel_config.GetBazelConfigRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bazel_config.GetBazelConfigRequest
         * @static
         * @param {bazel_config.IGetBazelConfigRequest} message GetBazelConfigRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBazelConfigRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetBazelConfigRequest message from the specified reader or buffer.
         * @function decode
         * @memberof bazel_config.GetBazelConfigRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bazel_config.GetBazelConfigRequest} GetBazelConfigRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBazelConfigRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bazel_config.GetBazelConfigRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requestContext = $root.context.RequestContext.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.host = reader.string();
                    break;
                case 3:
                    message.protocol = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetBazelConfigRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bazel_config.GetBazelConfigRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bazel_config.GetBazelConfigRequest} GetBazelConfigRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBazelConfigRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetBazelConfigRequest message.
         * @function verify
         * @memberof bazel_config.GetBazelConfigRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetBazelConfigRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.requestContext != null && message.hasOwnProperty("requestContext")) {
                let error = $root.context.RequestContext.verify(message.requestContext);
                if (error)
                    return "requestContext." + error;
            }
            if (message.host != null && message.hasOwnProperty("host"))
                if (!$util.isString(message.host))
                    return "host: string expected";
            if (message.protocol != null && message.hasOwnProperty("protocol"))
                if (!$util.isString(message.protocol))
                    return "protocol: string expected";
            return null;
        };

        /**
         * Creates a GetBazelConfigRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof bazel_config.GetBazelConfigRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {bazel_config.GetBazelConfigRequest} GetBazelConfigRequest
         */
        GetBazelConfigRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.bazel_config.GetBazelConfigRequest)
                return object;
            let message = new $root.bazel_config.GetBazelConfigRequest();
            if (object.requestContext != null) {
                if (typeof object.requestContext !== "object")
                    throw TypeError(".bazel_config.GetBazelConfigRequest.requestContext: object expected");
                message.requestContext = $root.context.RequestContext.fromObject(object.requestContext);
            }
            if (object.host != null)
                message.host = String(object.host);
            if (object.protocol != null)
                message.protocol = String(object.protocol);
            return message;
        };

        /**
         * Creates a plain object from a GetBazelConfigRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof bazel_config.GetBazelConfigRequest
         * @static
         * @param {bazel_config.GetBazelConfigRequest} message GetBazelConfigRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetBazelConfigRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.requestContext = null;
                object.host = "";
                object.protocol = "";
            }
            if (message.requestContext != null && message.hasOwnProperty("requestContext"))
                object.requestContext = $root.context.RequestContext.toObject(message.requestContext, options);
            if (message.host != null && message.hasOwnProperty("host"))
                object.host = message.host;
            if (message.protocol != null && message.hasOwnProperty("protocol"))
                object.protocol = message.protocol;
            return object;
        };

        /**
         * Converts this GetBazelConfigRequest to JSON.
         * @function toJSON
         * @memberof bazel_config.GetBazelConfigRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetBazelConfigRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetBazelConfigRequest;
    })();

    bazel_config.GetBazelConfigResponse = (function() {

        /**
         * Properties of a GetBazelConfigResponse.
         * @memberof bazel_config
         * @interface IGetBazelConfigResponse
         * @property {context.IResponseContext|null} [responseContext] GetBazelConfigResponse responseContext
         * @property {Array.<bazel_config.IConfigOption>|null} [configOption] GetBazelConfigResponse configOption
         */

        /**
         * Constructs a new GetBazelConfigResponse.
         * @memberof bazel_config
         * @classdesc Represents a GetBazelConfigResponse.
         * @implements IGetBazelConfigResponse
         * @constructor
         * @param {bazel_config.IGetBazelConfigResponse=} [properties] Properties to set
         */
        function GetBazelConfigResponse(properties) {
            this.configOption = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetBazelConfigResponse responseContext.
         * @member {context.IResponseContext|null|undefined} responseContext
         * @memberof bazel_config.GetBazelConfigResponse
         * @instance
         */
        GetBazelConfigResponse.prototype.responseContext = null;

        /**
         * GetBazelConfigResponse configOption.
         * @member {Array.<bazel_config.IConfigOption>} configOption
         * @memberof bazel_config.GetBazelConfigResponse
         * @instance
         */
        GetBazelConfigResponse.prototype.configOption = $util.emptyArray;

        /**
         * Creates a new GetBazelConfigResponse instance using the specified properties.
         * @function create
         * @memberof bazel_config.GetBazelConfigResponse
         * @static
         * @param {bazel_config.IGetBazelConfigResponse=} [properties] Properties to set
         * @returns {bazel_config.GetBazelConfigResponse} GetBazelConfigResponse instance
         */
        GetBazelConfigResponse.create = function create(properties) {
            return new GetBazelConfigResponse(properties);
        };

        /**
         * Encodes the specified GetBazelConfigResponse message. Does not implicitly {@link bazel_config.GetBazelConfigResponse.verify|verify} messages.
         * @function encode
         * @memberof bazel_config.GetBazelConfigResponse
         * @static
         * @param {bazel_config.IGetBazelConfigResponse} message GetBazelConfigResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBazelConfigResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.responseContext != null && message.hasOwnProperty("responseContext"))
                $root.context.ResponseContext.encode(message.responseContext, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.configOption != null && message.configOption.length)
                for (let i = 0; i < message.configOption.length; ++i)
                    $root.bazel_config.ConfigOption.encode(message.configOption[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetBazelConfigResponse message, length delimited. Does not implicitly {@link bazel_config.GetBazelConfigResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof bazel_config.GetBazelConfigResponse
         * @static
         * @param {bazel_config.IGetBazelConfigResponse} message GetBazelConfigResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetBazelConfigResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetBazelConfigResponse message from the specified reader or buffer.
         * @function decode
         * @memberof bazel_config.GetBazelConfigResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {bazel_config.GetBazelConfigResponse} GetBazelConfigResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBazelConfigResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.bazel_config.GetBazelConfigResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.responseContext = $root.context.ResponseContext.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.configOption && message.configOption.length))
                        message.configOption = [];
                    message.configOption.push($root.bazel_config.ConfigOption.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetBazelConfigResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof bazel_config.GetBazelConfigResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {bazel_config.GetBazelConfigResponse} GetBazelConfigResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetBazelConfigResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetBazelConfigResponse message.
         * @function verify
         * @memberof bazel_config.GetBazelConfigResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetBazelConfigResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.responseContext != null && message.hasOwnProperty("responseContext")) {
                let error = $root.context.ResponseContext.verify(message.responseContext);
                if (error)
                    return "responseContext." + error;
            }
            if (message.configOption != null && message.hasOwnProperty("configOption")) {
                if (!Array.isArray(message.configOption))
                    return "configOption: array expected";
                for (let i = 0; i < message.configOption.length; ++i) {
                    let error = $root.bazel_config.ConfigOption.verify(message.configOption[i]);
                    if (error)
                        return "configOption." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetBazelConfigResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof bazel_config.GetBazelConfigResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {bazel_config.GetBazelConfigResponse} GetBazelConfigResponse
         */
        GetBazelConfigResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.bazel_config.GetBazelConfigResponse)
                return object;
            let message = new $root.bazel_config.GetBazelConfigResponse();
            if (object.responseContext != null) {
                if (typeof object.responseContext !== "object")
                    throw TypeError(".bazel_config.GetBazelConfigResponse.responseContext: object expected");
                message.responseContext = $root.context.ResponseContext.fromObject(object.responseContext);
            }
            if (object.configOption) {
                if (!Array.isArray(object.configOption))
                    throw TypeError(".bazel_config.GetBazelConfigResponse.configOption: array expected");
                message.configOption = [];
                for (let i = 0; i < object.configOption.length; ++i) {
                    if (typeof object.configOption[i] !== "object")
                        throw TypeError(".bazel_config.GetBazelConfigResponse.configOption: object expected");
                    message.configOption[i] = $root.bazel_config.ConfigOption.fromObject(object.configOption[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetBazelConfigResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof bazel_config.GetBazelConfigResponse
         * @static
         * @param {bazel_config.GetBazelConfigResponse} message GetBazelConfigResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetBazelConfigResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.configOption = [];
            if (options.defaults)
                object.responseContext = null;
            if (message.responseContext != null && message.hasOwnProperty("responseContext"))
                object.responseContext = $root.context.ResponseContext.toObject(message.responseContext, options);
            if (message.configOption && message.configOption.length) {
                object.configOption = [];
                for (let j = 0; j < message.configOption.length; ++j)
                    object.configOption[j] = $root.bazel_config.ConfigOption.toObject(message.configOption[j], options);
            }
            return object;
        };

        /**
         * Converts this GetBazelConfigResponse to JSON.
         * @function toJSON
         * @memberof bazel_config.GetBazelConfigResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetBazelConfigResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetBazelConfigResponse;
    })();

    return bazel_config;
})();

export const context = $root.context = (() => {

    /**
     * Namespace context.
     * @exports context
     * @namespace
     */
    const context = {};

    context.RequestContext = (function() {

        /**
         * Properties of a RequestContext.
         * @memberof context
         * @interface IRequestContext
         * @property {user.IUserId|null} [userId] RequestContext userId
         */

        /**
         * Constructs a new RequestContext.
         * @memberof context
         * @classdesc Represents a RequestContext.
         * @implements IRequestContext
         * @constructor
         * @param {context.IRequestContext=} [properties] Properties to set
         */
        function RequestContext(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestContext userId.
         * @member {user.IUserId|null|undefined} userId
         * @memberof context.RequestContext
         * @instance
         */
        RequestContext.prototype.userId = null;

        /**
         * Creates a new RequestContext instance using the specified properties.
         * @function create
         * @memberof context.RequestContext
         * @static
         * @param {context.IRequestContext=} [properties] Properties to set
         * @returns {context.RequestContext} RequestContext instance
         */
        RequestContext.create = function create(properties) {
            return new RequestContext(properties);
        };

        /**
         * Encodes the specified RequestContext message. Does not implicitly {@link context.RequestContext.verify|verify} messages.
         * @function encode
         * @memberof context.RequestContext
         * @static
         * @param {context.IRequestContext} message RequestContext message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestContext.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && message.hasOwnProperty("userId"))
                $root.user.UserId.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RequestContext message, length delimited. Does not implicitly {@link context.RequestContext.verify|verify} messages.
         * @function encodeDelimited
         * @memberof context.RequestContext
         * @static
         * @param {context.IRequestContext} message RequestContext message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestContext.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestContext message from the specified reader or buffer.
         * @function decode
         * @memberof context.RequestContext
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {context.RequestContext} RequestContext
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestContext.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.context.RequestContext();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.user.UserId.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestContext message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof context.RequestContext
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {context.RequestContext} RequestContext
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestContext.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestContext message.
         * @function verify
         * @memberof context.RequestContext
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestContext.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId")) {
                let error = $root.user.UserId.verify(message.userId);
                if (error)
                    return "userId." + error;
            }
            return null;
        };

        /**
         * Creates a RequestContext message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof context.RequestContext
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {context.RequestContext} RequestContext
         */
        RequestContext.fromObject = function fromObject(object) {
            if (object instanceof $root.context.RequestContext)
                return object;
            let message = new $root.context.RequestContext();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".context.RequestContext.userId: object expected");
                message.userId = $root.user.UserId.fromObject(object.userId);
            }
            return message;
        };

        /**
         * Creates a plain object from a RequestContext message. Also converts values to other types if specified.
         * @function toObject
         * @memberof context.RequestContext
         * @static
         * @param {context.RequestContext} message RequestContext
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestContext.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.userId = null;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.user.UserId.toObject(message.userId, options);
            return object;
        };

        /**
         * Converts this RequestContext to JSON.
         * @function toJSON
         * @memberof context.RequestContext
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestContext.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestContext;
    })();

    context.ResponseContext = (function() {

        /**
         * Properties of a ResponseContext.
         * @memberof context
         * @interface IResponseContext
         */

        /**
         * Constructs a new ResponseContext.
         * @memberof context
         * @classdesc Represents a ResponseContext.
         * @implements IResponseContext
         * @constructor
         * @param {context.IResponseContext=} [properties] Properties to set
         */
        function ResponseContext(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ResponseContext instance using the specified properties.
         * @function create
         * @memberof context.ResponseContext
         * @static
         * @param {context.IResponseContext=} [properties] Properties to set
         * @returns {context.ResponseContext} ResponseContext instance
         */
        ResponseContext.create = function create(properties) {
            return new ResponseContext(properties);
        };

        /**
         * Encodes the specified ResponseContext message. Does not implicitly {@link context.ResponseContext.verify|verify} messages.
         * @function encode
         * @memberof context.ResponseContext
         * @static
         * @param {context.IResponseContext} message ResponseContext message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseContext.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ResponseContext message, length delimited. Does not implicitly {@link context.ResponseContext.verify|verify} messages.
         * @function encodeDelimited
         * @memberof context.ResponseContext
         * @static
         * @param {context.IResponseContext} message ResponseContext message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseContext.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseContext message from the specified reader or buffer.
         * @function decode
         * @memberof context.ResponseContext
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {context.ResponseContext} ResponseContext
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseContext.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.context.ResponseContext();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseContext message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof context.ResponseContext
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {context.ResponseContext} ResponseContext
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseContext.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseContext message.
         * @function verify
         * @memberof context.ResponseContext
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseContext.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ResponseContext message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof context.ResponseContext
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {context.ResponseContext} ResponseContext
         */
        ResponseContext.fromObject = function fromObject(object) {
            if (object instanceof $root.context.ResponseContext)
                return object;
            return new $root.context.ResponseContext();
        };

        /**
         * Creates a plain object from a ResponseContext message. Also converts values to other types if specified.
         * @function toObject
         * @memberof context.ResponseContext
         * @static
         * @param {context.ResponseContext} message ResponseContext
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseContext.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ResponseContext to JSON.
         * @function toJSON
         * @memberof context.ResponseContext
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseContext.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseContext;
    })();

    return context;
})();

export const user = $root.user = (() => {

    /**
     * Namespace user.
     * @exports user
     * @namespace
     */
    const user = {};

    user.GetUserRequest = (function() {

        /**
         * Properties of a GetUserRequest.
         * @memberof user
         * @interface IGetUserRequest
         * @property {user.IUserId|null} [userId] GetUserRequest userId
         */

        /**
         * Constructs a new GetUserRequest.
         * @memberof user
         * @classdesc Represents a GetUserRequest.
         * @implements IGetUserRequest
         * @constructor
         * @param {user.IGetUserRequest=} [properties] Properties to set
         */
        function GetUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetUserRequest userId.
         * @member {user.IUserId|null|undefined} userId
         * @memberof user.GetUserRequest
         * @instance
         */
        GetUserRequest.prototype.userId = null;

        /**
         * Creates a new GetUserRequest instance using the specified properties.
         * @function create
         * @memberof user.GetUserRequest
         * @static
         * @param {user.IGetUserRequest=} [properties] Properties to set
         * @returns {user.GetUserRequest} GetUserRequest instance
         */
        GetUserRequest.create = function create(properties) {
            return new GetUserRequest(properties);
        };

        /**
         * Encodes the specified GetUserRequest message. Does not implicitly {@link user.GetUserRequest.verify|verify} messages.
         * @function encode
         * @memberof user.GetUserRequest
         * @static
         * @param {user.IGetUserRequest} message GetUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && message.hasOwnProperty("userId"))
                $root.user.UserId.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetUserRequest message, length delimited. Does not implicitly {@link user.GetUserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user.GetUserRequest
         * @static
         * @param {user.IGetUserRequest} message GetUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof user.GetUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.GetUserRequest} GetUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.GetUserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.user.UserId.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetUserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user.GetUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user.GetUserRequest} GetUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetUserRequest message.
         * @function verify
         * @memberof user.GetUserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetUserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId")) {
                let error = $root.user.UserId.verify(message.userId);
                if (error)
                    return "userId." + error;
            }
            return null;
        };

        /**
         * Creates a GetUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.GetUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.GetUserRequest} GetUserRequest
         */
        GetUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.user.GetUserRequest)
                return object;
            let message = new $root.user.GetUserRequest();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".user.GetUserRequest.userId: object expected");
                message.userId = $root.user.UserId.fromObject(object.userId);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.GetUserRequest
         * @static
         * @param {user.GetUserRequest} message GetUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.userId = null;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.user.UserId.toObject(message.userId, options);
            return object;
        };

        /**
         * Converts this GetUserRequest to JSON.
         * @function toJSON
         * @memberof user.GetUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetUserRequest;
    })();

    user.GetUserResponse = (function() {

        /**
         * Properties of a GetUserResponse.
         * @memberof user
         * @interface IGetUserResponse
         * @property {user.IDisplayUser|null} [displayUser] GetUserResponse displayUser
         * @property {Array.<grp.IGroup>|null} [userGroup] GetUserResponse userGroup
         */

        /**
         * Constructs a new GetUserResponse.
         * @memberof user
         * @classdesc Represents a GetUserResponse.
         * @implements IGetUserResponse
         * @constructor
         * @param {user.IGetUserResponse=} [properties] Properties to set
         */
        function GetUserResponse(properties) {
            this.userGroup = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetUserResponse displayUser.
         * @member {user.IDisplayUser|null|undefined} displayUser
         * @memberof user.GetUserResponse
         * @instance
         */
        GetUserResponse.prototype.displayUser = null;

        /**
         * GetUserResponse userGroup.
         * @member {Array.<grp.IGroup>} userGroup
         * @memberof user.GetUserResponse
         * @instance
         */
        GetUserResponse.prototype.userGroup = $util.emptyArray;

        /**
         * Creates a new GetUserResponse instance using the specified properties.
         * @function create
         * @memberof user.GetUserResponse
         * @static
         * @param {user.IGetUserResponse=} [properties] Properties to set
         * @returns {user.GetUserResponse} GetUserResponse instance
         */
        GetUserResponse.create = function create(properties) {
            return new GetUserResponse(properties);
        };

        /**
         * Encodes the specified GetUserResponse message. Does not implicitly {@link user.GetUserResponse.verify|verify} messages.
         * @function encode
         * @memberof user.GetUserResponse
         * @static
         * @param {user.IGetUserResponse} message GetUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.displayUser != null && message.hasOwnProperty("displayUser"))
                $root.user.DisplayUser.encode(message.displayUser, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.userGroup != null && message.userGroup.length)
                for (let i = 0; i < message.userGroup.length; ++i)
                    $root.grp.Group.encode(message.userGroup[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetUserResponse message, length delimited. Does not implicitly {@link user.GetUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user.GetUserResponse
         * @static
         * @param {user.IGetUserResponse} message GetUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof user.GetUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.GetUserResponse} GetUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.GetUserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.displayUser = $root.user.DisplayUser.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.userGroup && message.userGroup.length))
                        message.userGroup = [];
                    message.userGroup.push($root.grp.Group.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user.GetUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user.GetUserResponse} GetUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetUserResponse message.
         * @function verify
         * @memberof user.GetUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.displayUser != null && message.hasOwnProperty("displayUser")) {
                let error = $root.user.DisplayUser.verify(message.displayUser);
                if (error)
                    return "displayUser." + error;
            }
            if (message.userGroup != null && message.hasOwnProperty("userGroup")) {
                if (!Array.isArray(message.userGroup))
                    return "userGroup: array expected";
                for (let i = 0; i < message.userGroup.length; ++i) {
                    let error = $root.grp.Group.verify(message.userGroup[i]);
                    if (error)
                        return "userGroup." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.GetUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.GetUserResponse} GetUserResponse
         */
        GetUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.user.GetUserResponse)
                return object;
            let message = new $root.user.GetUserResponse();
            if (object.displayUser != null) {
                if (typeof object.displayUser !== "object")
                    throw TypeError(".user.GetUserResponse.displayUser: object expected");
                message.displayUser = $root.user.DisplayUser.fromObject(object.displayUser);
            }
            if (object.userGroup) {
                if (!Array.isArray(object.userGroup))
                    throw TypeError(".user.GetUserResponse.userGroup: array expected");
                message.userGroup = [];
                for (let i = 0; i < object.userGroup.length; ++i) {
                    if (typeof object.userGroup[i] !== "object")
                        throw TypeError(".user.GetUserResponse.userGroup: object expected");
                    message.userGroup[i] = $root.grp.Group.fromObject(object.userGroup[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.GetUserResponse
         * @static
         * @param {user.GetUserResponse} message GetUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetUserResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.userGroup = [];
            if (options.defaults)
                object.displayUser = null;
            if (message.displayUser != null && message.hasOwnProperty("displayUser"))
                object.displayUser = $root.user.DisplayUser.toObject(message.displayUser, options);
            if (message.userGroup && message.userGroup.length) {
                object.userGroup = [];
                for (let j = 0; j < message.userGroup.length; ++j)
                    object.userGroup[j] = $root.grp.Group.toObject(message.userGroup[j], options);
            }
            return object;
        };

        /**
         * Converts this GetUserResponse to JSON.
         * @function toJSON
         * @memberof user.GetUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetUserResponse;
    })();

    user.CreateUserRequest = (function() {

        /**
         * Properties of a CreateUserRequest.
         * @memberof user
         * @interface ICreateUserRequest
         */

        /**
         * Constructs a new CreateUserRequest.
         * @memberof user
         * @classdesc Represents a CreateUserRequest.
         * @implements ICreateUserRequest
         * @constructor
         * @param {user.ICreateUserRequest=} [properties] Properties to set
         */
        function CreateUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CreateUserRequest instance using the specified properties.
         * @function create
         * @memberof user.CreateUserRequest
         * @static
         * @param {user.ICreateUserRequest=} [properties] Properties to set
         * @returns {user.CreateUserRequest} CreateUserRequest instance
         */
        CreateUserRequest.create = function create(properties) {
            return new CreateUserRequest(properties);
        };

        /**
         * Encodes the specified CreateUserRequest message. Does not implicitly {@link user.CreateUserRequest.verify|verify} messages.
         * @function encode
         * @memberof user.CreateUserRequest
         * @static
         * @param {user.ICreateUserRequest} message CreateUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CreateUserRequest message, length delimited. Does not implicitly {@link user.CreateUserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user.CreateUserRequest
         * @static
         * @param {user.ICreateUserRequest} message CreateUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof user.CreateUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.CreateUserRequest} CreateUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.CreateUserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateUserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user.CreateUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user.CreateUserRequest} CreateUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateUserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateUserRequest message.
         * @function verify
         * @memberof user.CreateUserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateUserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CreateUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.CreateUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.CreateUserRequest} CreateUserRequest
         */
        CreateUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.user.CreateUserRequest)
                return object;
            return new $root.user.CreateUserRequest();
        };

        /**
         * Creates a plain object from a CreateUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.CreateUserRequest
         * @static
         * @param {user.CreateUserRequest} message CreateUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateUserRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CreateUserRequest to JSON.
         * @function toJSON
         * @memberof user.CreateUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateUserRequest;
    })();

    user.CreateUserResponse = (function() {

        /**
         * Properties of a CreateUserResponse.
         * @memberof user
         * @interface ICreateUserResponse
         * @property {user.IDisplayUser|null} [displayUser] CreateUserResponse displayUser
         */

        /**
         * Constructs a new CreateUserResponse.
         * @memberof user
         * @classdesc Represents a CreateUserResponse.
         * @implements ICreateUserResponse
         * @constructor
         * @param {user.ICreateUserResponse=} [properties] Properties to set
         */
        function CreateUserResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateUserResponse displayUser.
         * @member {user.IDisplayUser|null|undefined} displayUser
         * @memberof user.CreateUserResponse
         * @instance
         */
        CreateUserResponse.prototype.displayUser = null;

        /**
         * Creates a new CreateUserResponse instance using the specified properties.
         * @function create
         * @memberof user.CreateUserResponse
         * @static
         * @param {user.ICreateUserResponse=} [properties] Properties to set
         * @returns {user.CreateUserResponse} CreateUserResponse instance
         */
        CreateUserResponse.create = function create(properties) {
            return new CreateUserResponse(properties);
        };

        /**
         * Encodes the specified CreateUserResponse message. Does not implicitly {@link user.CreateUserResponse.verify|verify} messages.
         * @function encode
         * @memberof user.CreateUserResponse
         * @static
         * @param {user.ICreateUserResponse} message CreateUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.displayUser != null && message.hasOwnProperty("displayUser"))
                $root.user.DisplayUser.encode(message.displayUser, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateUserResponse message, length delimited. Does not implicitly {@link user.CreateUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user.CreateUserResponse
         * @static
         * @param {user.ICreateUserResponse} message CreateUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof user.CreateUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.CreateUserResponse} CreateUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.CreateUserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.displayUser = $root.user.DisplayUser.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user.CreateUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user.CreateUserResponse} CreateUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateUserResponse message.
         * @function verify
         * @memberof user.CreateUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.displayUser != null && message.hasOwnProperty("displayUser")) {
                let error = $root.user.DisplayUser.verify(message.displayUser);
                if (error)
                    return "displayUser." + error;
            }
            return null;
        };

        /**
         * Creates a CreateUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.CreateUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.CreateUserResponse} CreateUserResponse
         */
        CreateUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.user.CreateUserResponse)
                return object;
            let message = new $root.user.CreateUserResponse();
            if (object.displayUser != null) {
                if (typeof object.displayUser !== "object")
                    throw TypeError(".user.CreateUserResponse.displayUser: object expected");
                message.displayUser = $root.user.DisplayUser.fromObject(object.displayUser);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.CreateUserResponse
         * @static
         * @param {user.CreateUserResponse} message CreateUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateUserResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.displayUser = null;
            if (message.displayUser != null && message.hasOwnProperty("displayUser"))
                object.displayUser = $root.user.DisplayUser.toObject(message.displayUser, options);
            return object;
        };

        /**
         * Converts this CreateUserResponse to JSON.
         * @function toJSON
         * @memberof user.CreateUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateUserResponse;
    })();

    user.UserId = (function() {

        /**
         * Properties of a UserId.
         * @memberof user
         * @interface IUserId
         * @property {string|null} [id] UserId id
         */

        /**
         * Constructs a new UserId.
         * @memberof user
         * @classdesc Represents a UserId.
         * @implements IUserId
         * @constructor
         * @param {user.IUserId=} [properties] Properties to set
         */
        function UserId(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserId id.
         * @member {string} id
         * @memberof user.UserId
         * @instance
         */
        UserId.prototype.id = "";

        /**
         * Creates a new UserId instance using the specified properties.
         * @function create
         * @memberof user.UserId
         * @static
         * @param {user.IUserId=} [properties] Properties to set
         * @returns {user.UserId} UserId instance
         */
        UserId.create = function create(properties) {
            return new UserId(properties);
        };

        /**
         * Encodes the specified UserId message. Does not implicitly {@link user.UserId.verify|verify} messages.
         * @function encode
         * @memberof user.UserId
         * @static
         * @param {user.IUserId} message UserId message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserId.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && message.hasOwnProperty("id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified UserId message, length delimited. Does not implicitly {@link user.UserId.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user.UserId
         * @static
         * @param {user.IUserId} message UserId message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserId.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserId message from the specified reader or buffer.
         * @function decode
         * @memberof user.UserId
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.UserId} UserId
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserId.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.UserId();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserId message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user.UserId
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user.UserId} UserId
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserId.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserId message.
         * @function verify
         * @memberof user.UserId
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserId.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a UserId message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.UserId
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.UserId} UserId
         */
        UserId.fromObject = function fromObject(object) {
            if (object instanceof $root.user.UserId)
                return object;
            let message = new $root.user.UserId();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a UserId message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.UserId
         * @static
         * @param {user.UserId} message UserId
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserId.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this UserId to JSON.
         * @function toJSON
         * @memberof user.UserId
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserId.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserId;
    })();

    user.Name = (function() {

        /**
         * Properties of a Name.
         * @memberof user
         * @interface IName
         * @property {string|null} [full] Name full
         * @property {string|null} [first] Name first
         * @property {string|null} [last] Name last
         */

        /**
         * Constructs a new Name.
         * @memberof user
         * @classdesc Represents a Name.
         * @implements IName
         * @constructor
         * @param {user.IName=} [properties] Properties to set
         */
        function Name(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Name full.
         * @member {string} full
         * @memberof user.Name
         * @instance
         */
        Name.prototype.full = "";

        /**
         * Name first.
         * @member {string} first
         * @memberof user.Name
         * @instance
         */
        Name.prototype.first = "";

        /**
         * Name last.
         * @member {string} last
         * @memberof user.Name
         * @instance
         */
        Name.prototype.last = "";

        /**
         * Creates a new Name instance using the specified properties.
         * @function create
         * @memberof user.Name
         * @static
         * @param {user.IName=} [properties] Properties to set
         * @returns {user.Name} Name instance
         */
        Name.create = function create(properties) {
            return new Name(properties);
        };

        /**
         * Encodes the specified Name message. Does not implicitly {@link user.Name.verify|verify} messages.
         * @function encode
         * @memberof user.Name
         * @static
         * @param {user.IName} message Name message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Name.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.full != null && message.hasOwnProperty("full"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.full);
            if (message.first != null && message.hasOwnProperty("first"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.first);
            if (message.last != null && message.hasOwnProperty("last"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.last);
            return writer;
        };

        /**
         * Encodes the specified Name message, length delimited. Does not implicitly {@link user.Name.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user.Name
         * @static
         * @param {user.IName} message Name message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Name.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Name message from the specified reader or buffer.
         * @function decode
         * @memberof user.Name
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.Name} Name
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Name.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.Name();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.full = reader.string();
                    break;
                case 2:
                    message.first = reader.string();
                    break;
                case 3:
                    message.last = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Name message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user.Name
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user.Name} Name
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Name.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Name message.
         * @function verify
         * @memberof user.Name
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Name.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.full != null && message.hasOwnProperty("full"))
                if (!$util.isString(message.full))
                    return "full: string expected";
            if (message.first != null && message.hasOwnProperty("first"))
                if (!$util.isString(message.first))
                    return "first: string expected";
            if (message.last != null && message.hasOwnProperty("last"))
                if (!$util.isString(message.last))
                    return "last: string expected";
            return null;
        };

        /**
         * Creates a Name message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.Name
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.Name} Name
         */
        Name.fromObject = function fromObject(object) {
            if (object instanceof $root.user.Name)
                return object;
            let message = new $root.user.Name();
            if (object.full != null)
                message.full = String(object.full);
            if (object.first != null)
                message.first = String(object.first);
            if (object.last != null)
                message.last = String(object.last);
            return message;
        };

        /**
         * Creates a plain object from a Name message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.Name
         * @static
         * @param {user.Name} message Name
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Name.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.full = "";
                object.first = "";
                object.last = "";
            }
            if (message.full != null && message.hasOwnProperty("full"))
                object.full = message.full;
            if (message.first != null && message.hasOwnProperty("first"))
                object.first = message.first;
            if (message.last != null && message.hasOwnProperty("last"))
                object.last = message.last;
            return object;
        };

        /**
         * Converts this Name to JSON.
         * @function toJSON
         * @memberof user.Name
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Name.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Name;
    })();

    user.DisplayUser = (function() {

        /**
         * Properties of a DisplayUser.
         * @memberof user
         * @interface IDisplayUser
         * @property {user.IUserId|null} [userId] DisplayUser userId
         * @property {user.IName|null} [name] DisplayUser name
         * @property {string|null} [profileImageUrl] DisplayUser profileImageUrl
         */

        /**
         * Constructs a new DisplayUser.
         * @memberof user
         * @classdesc Represents a DisplayUser.
         * @implements IDisplayUser
         * @constructor
         * @param {user.IDisplayUser=} [properties] Properties to set
         */
        function DisplayUser(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DisplayUser userId.
         * @member {user.IUserId|null|undefined} userId
         * @memberof user.DisplayUser
         * @instance
         */
        DisplayUser.prototype.userId = null;

        /**
         * DisplayUser name.
         * @member {user.IName|null|undefined} name
         * @memberof user.DisplayUser
         * @instance
         */
        DisplayUser.prototype.name = null;

        /**
         * DisplayUser profileImageUrl.
         * @member {string} profileImageUrl
         * @memberof user.DisplayUser
         * @instance
         */
        DisplayUser.prototype.profileImageUrl = "";

        /**
         * Creates a new DisplayUser instance using the specified properties.
         * @function create
         * @memberof user.DisplayUser
         * @static
         * @param {user.IDisplayUser=} [properties] Properties to set
         * @returns {user.DisplayUser} DisplayUser instance
         */
        DisplayUser.create = function create(properties) {
            return new DisplayUser(properties);
        };

        /**
         * Encodes the specified DisplayUser message. Does not implicitly {@link user.DisplayUser.verify|verify} messages.
         * @function encode
         * @memberof user.DisplayUser
         * @static
         * @param {user.IDisplayUser} message DisplayUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DisplayUser.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && message.hasOwnProperty("userId"))
                $root.user.UserId.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.name != null && message.hasOwnProperty("name"))
                $root.user.Name.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.profileImageUrl != null && message.hasOwnProperty("profileImageUrl"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.profileImageUrl);
            return writer;
        };

        /**
         * Encodes the specified DisplayUser message, length delimited. Does not implicitly {@link user.DisplayUser.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user.DisplayUser
         * @static
         * @param {user.IDisplayUser} message DisplayUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DisplayUser.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DisplayUser message from the specified reader or buffer.
         * @function decode
         * @memberof user.DisplayUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.DisplayUser} DisplayUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DisplayUser.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.DisplayUser();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.user.UserId.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.name = $root.user.Name.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.profileImageUrl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DisplayUser message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user.DisplayUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user.DisplayUser} DisplayUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DisplayUser.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DisplayUser message.
         * @function verify
         * @memberof user.DisplayUser
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DisplayUser.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId")) {
                let error = $root.user.UserId.verify(message.userId);
                if (error)
                    return "userId." + error;
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                let error = $root.user.Name.verify(message.name);
                if (error)
                    return "name." + error;
            }
            if (message.profileImageUrl != null && message.hasOwnProperty("profileImageUrl"))
                if (!$util.isString(message.profileImageUrl))
                    return "profileImageUrl: string expected";
            return null;
        };

        /**
         * Creates a DisplayUser message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.DisplayUser
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.DisplayUser} DisplayUser
         */
        DisplayUser.fromObject = function fromObject(object) {
            if (object instanceof $root.user.DisplayUser)
                return object;
            let message = new $root.user.DisplayUser();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".user.DisplayUser.userId: object expected");
                message.userId = $root.user.UserId.fromObject(object.userId);
            }
            if (object.name != null) {
                if (typeof object.name !== "object")
                    throw TypeError(".user.DisplayUser.name: object expected");
                message.name = $root.user.Name.fromObject(object.name);
            }
            if (object.profileImageUrl != null)
                message.profileImageUrl = String(object.profileImageUrl);
            return message;
        };

        /**
         * Creates a plain object from a DisplayUser message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.DisplayUser
         * @static
         * @param {user.DisplayUser} message DisplayUser
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DisplayUser.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userId = null;
                object.name = null;
                object.profileImageUrl = "";
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.user.UserId.toObject(message.userId, options);
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = $root.user.Name.toObject(message.name, options);
            if (message.profileImageUrl != null && message.hasOwnProperty("profileImageUrl"))
                object.profileImageUrl = message.profileImageUrl;
            return object;
        };

        /**
         * Converts this DisplayUser to JSON.
         * @function toJSON
         * @memberof user.DisplayUser
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DisplayUser.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DisplayUser;
    })();

    return user;
})();

export const grp = $root.grp = (() => {

    /**
     * Namespace grp.
     * @exports grp
     * @namespace
     */
    const grp = {};

    grp.Group = (function() {

        /**
         * Properties of a Group.
         * @memberof grp
         * @interface IGroup
         * @property {string|null} [id] Group id
         * @property {string|null} [name] Group name
         * @property {string|null} [ownedDomain] Group ownedDomain
         */

        /**
         * Constructs a new Group.
         * @memberof grp
         * @classdesc Represents a Group.
         * @implements IGroup
         * @constructor
         * @param {grp.IGroup=} [properties] Properties to set
         */
        function Group(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Group id.
         * @member {string} id
         * @memberof grp.Group
         * @instance
         */
        Group.prototype.id = "";

        /**
         * Group name.
         * @member {string} name
         * @memberof grp.Group
         * @instance
         */
        Group.prototype.name = "";

        /**
         * Group ownedDomain.
         * @member {string} ownedDomain
         * @memberof grp.Group
         * @instance
         */
        Group.prototype.ownedDomain = "";

        /**
         * Creates a new Group instance using the specified properties.
         * @function create
         * @memberof grp.Group
         * @static
         * @param {grp.IGroup=} [properties] Properties to set
         * @returns {grp.Group} Group instance
         */
        Group.create = function create(properties) {
            return new Group(properties);
        };

        /**
         * Encodes the specified Group message. Does not implicitly {@link grp.Group.verify|verify} messages.
         * @function encode
         * @memberof grp.Group
         * @static
         * @param {grp.IGroup} message Group message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Group.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && message.hasOwnProperty("id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && message.hasOwnProperty("name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.ownedDomain != null && message.hasOwnProperty("ownedDomain"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.ownedDomain);
            return writer;
        };

        /**
         * Encodes the specified Group message, length delimited. Does not implicitly {@link grp.Group.verify|verify} messages.
         * @function encodeDelimited
         * @memberof grp.Group
         * @static
         * @param {grp.IGroup} message Group message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Group.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Group message from the specified reader or buffer.
         * @function decode
         * @memberof grp.Group
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {grp.Group} Group
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Group.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.grp.Group();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.ownedDomain = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Group message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof grp.Group
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {grp.Group} Group
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Group.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Group message.
         * @function verify
         * @memberof grp.Group
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Group.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.ownedDomain != null && message.hasOwnProperty("ownedDomain"))
                if (!$util.isString(message.ownedDomain))
                    return "ownedDomain: string expected";
            return null;
        };

        /**
         * Creates a Group message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof grp.Group
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {grp.Group} Group
         */
        Group.fromObject = function fromObject(object) {
            if (object instanceof $root.grp.Group)
                return object;
            let message = new $root.grp.Group();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.ownedDomain != null)
                message.ownedDomain = String(object.ownedDomain);
            return message;
        };

        /**
         * Creates a plain object from a Group message. Also converts values to other types if specified.
         * @function toObject
         * @memberof grp.Group
         * @static
         * @param {grp.Group} message Group
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Group.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.ownedDomain = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.ownedDomain != null && message.hasOwnProperty("ownedDomain"))
                object.ownedDomain = message.ownedDomain;
            return object;
        };

        /**
         * Converts this Group to JSON.
         * @function toJSON
         * @memberof grp.Group
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Group.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Group;
    })();

    return grp;
})();

export const invocation = $root.invocation = (() => {

    /**
     * Namespace invocation.
     * @exports invocation
     * @namespace
     */
    const invocation = {};

    invocation.Invocation = (function() {

        /**
         * Properties of an Invocation.
         * @memberof invocation
         * @interface IInvocation
         * @property {string|null} [invocationId] Invocation invocationId
         * @property {Array.<invocation.IInvocationEvent>|null} [event] Invocation event
         * @property {boolean|null} [success] Invocation success
         * @property {string|null} [user] Invocation user
         * @property {Long|null} [durationUsec] Invocation durationUsec
         * @property {string|null} [host] Invocation host
         * @property {string|null} [command] Invocation command
         * @property {Array.<string>|null} [pattern] Invocation pattern
         * @property {Long|null} [actionCount] Invocation actionCount
         * @property {invocation.Invocation.InvocationStatus|null} [invocationStatus] Invocation invocationStatus
         * @property {string|null} [consoleBuffer] Invocation consoleBuffer
         * @property {Array.<command_line.ICommandLine>|null} [structuredCommandLine] Invocation structuredCommandLine
         * @property {Long|null} [createdAtUsec] Invocation createdAtUsec
         * @property {Long|null} [updatedAtUsec] Invocation updatedAtUsec
         */

        /**
         * Constructs a new Invocation.
         * @memberof invocation
         * @classdesc Represents an Invocation.
         * @implements IInvocation
         * @constructor
         * @param {invocation.IInvocation=} [properties] Properties to set
         */
        function Invocation(properties) {
            this.event = [];
            this.pattern = [];
            this.structuredCommandLine = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Invocation invocationId.
         * @member {string} invocationId
         * @memberof invocation.Invocation
         * @instance
         */
        Invocation.prototype.invocationId = "";

        /**
         * Invocation event.
         * @member {Array.<invocation.IInvocationEvent>} event
         * @memberof invocation.Invocation
         * @instance
         */
        Invocation.prototype.event = $util.emptyArray;

        /**
         * Invocation success.
         * @member {boolean} success
         * @memberof invocation.Invocation
         * @instance
         */
        Invocation.prototype.success = false;

        /**
         * Invocation user.
         * @member {string} user
         * @memberof invocation.Invocation
         * @instance
         */
        Invocation.prototype.user = "";

        /**
         * Invocation durationUsec.
         * @member {Long} durationUsec
         * @memberof invocation.Invocation
         * @instance
         */
        Invocation.prototype.durationUsec = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Invocation host.
         * @member {string} host
         * @memberof invocation.Invocation
         * @instance
         */
        Invocation.prototype.host = "";

        /**
         * Invocation command.
         * @member {string} command
         * @memberof invocation.Invocation
         * @instance
         */
        Invocation.prototype.command = "";

        /**
         * Invocation pattern.
         * @member {Array.<string>} pattern
         * @memberof invocation.Invocation
         * @instance
         */
        Invocation.prototype.pattern = $util.emptyArray;

        /**
         * Invocation actionCount.
         * @member {Long} actionCount
         * @memberof invocation.Invocation
         * @instance
         */
        Invocation.prototype.actionCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Invocation invocationStatus.
         * @member {invocation.Invocation.InvocationStatus} invocationStatus
         * @memberof invocation.Invocation
         * @instance
         */
        Invocation.prototype.invocationStatus = 0;

        /**
         * Invocation consoleBuffer.
         * @member {string} consoleBuffer
         * @memberof invocation.Invocation
         * @instance
         */
        Invocation.prototype.consoleBuffer = "";

        /**
         * Invocation structuredCommandLine.
         * @member {Array.<command_line.ICommandLine>} structuredCommandLine
         * @memberof invocation.Invocation
         * @instance
         */
        Invocation.prototype.structuredCommandLine = $util.emptyArray;

        /**
         * Invocation createdAtUsec.
         * @member {Long} createdAtUsec
         * @memberof invocation.Invocation
         * @instance
         */
        Invocation.prototype.createdAtUsec = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Invocation updatedAtUsec.
         * @member {Long} updatedAtUsec
         * @memberof invocation.Invocation
         * @instance
         */
        Invocation.prototype.updatedAtUsec = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new Invocation instance using the specified properties.
         * @function create
         * @memberof invocation.Invocation
         * @static
         * @param {invocation.IInvocation=} [properties] Properties to set
         * @returns {invocation.Invocation} Invocation instance
         */
        Invocation.create = function create(properties) {
            return new Invocation(properties);
        };

        /**
         * Encodes the specified Invocation message. Does not implicitly {@link invocation.Invocation.verify|verify} messages.
         * @function encode
         * @memberof invocation.Invocation
         * @static
         * @param {invocation.IInvocation} message Invocation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Invocation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.invocationId != null && message.hasOwnProperty("invocationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.invocationId);
            if (message.event != null && message.event.length)
                for (let i = 0; i < message.event.length; ++i)
                    $root.invocation.InvocationEvent.encode(message.event[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.success != null && message.hasOwnProperty("success"))
                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.success);
            if (message.user != null && message.hasOwnProperty("user"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.user);
            if (message.durationUsec != null && message.hasOwnProperty("durationUsec"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.durationUsec);
            if (message.host != null && message.hasOwnProperty("host"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.host);
            if (message.command != null && message.hasOwnProperty("command"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.command);
            if (message.pattern != null && message.pattern.length)
                for (let i = 0; i < message.pattern.length; ++i)
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.pattern[i]);
            if (message.actionCount != null && message.hasOwnProperty("actionCount"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.actionCount);
            if (message.invocationStatus != null && message.hasOwnProperty("invocationStatus"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.invocationStatus);
            if (message.consoleBuffer != null && message.hasOwnProperty("consoleBuffer"))
                writer.uint32(/* id 11, wireType 2 =*/90).string(message.consoleBuffer);
            if (message.structuredCommandLine != null && message.structuredCommandLine.length)
                for (let i = 0; i < message.structuredCommandLine.length; ++i)
                    $root.command_line.CommandLine.encode(message.structuredCommandLine[i], writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.createdAtUsec != null && message.hasOwnProperty("createdAtUsec"))
                writer.uint32(/* id 13, wireType 0 =*/104).int64(message.createdAtUsec);
            if (message.updatedAtUsec != null && message.hasOwnProperty("updatedAtUsec"))
                writer.uint32(/* id 14, wireType 0 =*/112).int64(message.updatedAtUsec);
            return writer;
        };

        /**
         * Encodes the specified Invocation message, length delimited. Does not implicitly {@link invocation.Invocation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invocation.Invocation
         * @static
         * @param {invocation.IInvocation} message Invocation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Invocation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Invocation message from the specified reader or buffer.
         * @function decode
         * @memberof invocation.Invocation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invocation.Invocation} Invocation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Invocation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invocation.Invocation();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.invocationId = reader.string();
                    break;
                case 2:
                    if (!(message.event && message.event.length))
                        message.event = [];
                    message.event.push($root.invocation.InvocationEvent.decode(reader, reader.uint32()));
                    break;
                case 3:
                    message.success = reader.bool();
                    break;
                case 4:
                    message.user = reader.string();
                    break;
                case 5:
                    message.durationUsec = reader.int64();
                    break;
                case 6:
                    message.host = reader.string();
                    break;
                case 7:
                    message.command = reader.string();
                    break;
                case 8:
                    if (!(message.pattern && message.pattern.length))
                        message.pattern = [];
                    message.pattern.push(reader.string());
                    break;
                case 9:
                    message.actionCount = reader.int64();
                    break;
                case 10:
                    message.invocationStatus = reader.int32();
                    break;
                case 11:
                    message.consoleBuffer = reader.string();
                    break;
                case 12:
                    if (!(message.structuredCommandLine && message.structuredCommandLine.length))
                        message.structuredCommandLine = [];
                    message.structuredCommandLine.push($root.command_line.CommandLine.decode(reader, reader.uint32()));
                    break;
                case 13:
                    message.createdAtUsec = reader.int64();
                    break;
                case 14:
                    message.updatedAtUsec = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Invocation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invocation.Invocation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invocation.Invocation} Invocation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Invocation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Invocation message.
         * @function verify
         * @memberof invocation.Invocation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Invocation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.invocationId != null && message.hasOwnProperty("invocationId"))
                if (!$util.isString(message.invocationId))
                    return "invocationId: string expected";
            if (message.event != null && message.hasOwnProperty("event")) {
                if (!Array.isArray(message.event))
                    return "event: array expected";
                for (let i = 0; i < message.event.length; ++i) {
                    let error = $root.invocation.InvocationEvent.verify(message.event[i]);
                    if (error)
                        return "event." + error;
                }
            }
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            if (message.user != null && message.hasOwnProperty("user"))
                if (!$util.isString(message.user))
                    return "user: string expected";
            if (message.durationUsec != null && message.hasOwnProperty("durationUsec"))
                if (!$util.isInteger(message.durationUsec) && !(message.durationUsec && $util.isInteger(message.durationUsec.low) && $util.isInteger(message.durationUsec.high)))
                    return "durationUsec: integer|Long expected";
            if (message.host != null && message.hasOwnProperty("host"))
                if (!$util.isString(message.host))
                    return "host: string expected";
            if (message.command != null && message.hasOwnProperty("command"))
                if (!$util.isString(message.command))
                    return "command: string expected";
            if (message.pattern != null && message.hasOwnProperty("pattern")) {
                if (!Array.isArray(message.pattern))
                    return "pattern: array expected";
                for (let i = 0; i < message.pattern.length; ++i)
                    if (!$util.isString(message.pattern[i]))
                        return "pattern: string[] expected";
            }
            if (message.actionCount != null && message.hasOwnProperty("actionCount"))
                if (!$util.isInteger(message.actionCount) && !(message.actionCount && $util.isInteger(message.actionCount.low) && $util.isInteger(message.actionCount.high)))
                    return "actionCount: integer|Long expected";
            if (message.invocationStatus != null && message.hasOwnProperty("invocationStatus"))
                switch (message.invocationStatus) {
                default:
                    return "invocationStatus: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.consoleBuffer != null && message.hasOwnProperty("consoleBuffer"))
                if (!$util.isString(message.consoleBuffer))
                    return "consoleBuffer: string expected";
            if (message.structuredCommandLine != null && message.hasOwnProperty("structuredCommandLine")) {
                if (!Array.isArray(message.structuredCommandLine))
                    return "structuredCommandLine: array expected";
                for (let i = 0; i < message.structuredCommandLine.length; ++i) {
                    let error = $root.command_line.CommandLine.verify(message.structuredCommandLine[i]);
                    if (error)
                        return "structuredCommandLine." + error;
                }
            }
            if (message.createdAtUsec != null && message.hasOwnProperty("createdAtUsec"))
                if (!$util.isInteger(message.createdAtUsec) && !(message.createdAtUsec && $util.isInteger(message.createdAtUsec.low) && $util.isInteger(message.createdAtUsec.high)))
                    return "createdAtUsec: integer|Long expected";
            if (message.updatedAtUsec != null && message.hasOwnProperty("updatedAtUsec"))
                if (!$util.isInteger(message.updatedAtUsec) && !(message.updatedAtUsec && $util.isInteger(message.updatedAtUsec.low) && $util.isInteger(message.updatedAtUsec.high)))
                    return "updatedAtUsec: integer|Long expected";
            return null;
        };

        /**
         * Creates an Invocation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invocation.Invocation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invocation.Invocation} Invocation
         */
        Invocation.fromObject = function fromObject(object) {
            if (object instanceof $root.invocation.Invocation)
                return object;
            let message = new $root.invocation.Invocation();
            if (object.invocationId != null)
                message.invocationId = String(object.invocationId);
            if (object.event) {
                if (!Array.isArray(object.event))
                    throw TypeError(".invocation.Invocation.event: array expected");
                message.event = [];
                for (let i = 0; i < object.event.length; ++i) {
                    if (typeof object.event[i] !== "object")
                        throw TypeError(".invocation.Invocation.event: object expected");
                    message.event[i] = $root.invocation.InvocationEvent.fromObject(object.event[i]);
                }
            }
            if (object.success != null)
                message.success = Boolean(object.success);
            if (object.user != null)
                message.user = String(object.user);
            if (object.durationUsec != null)
                if ($util.Long)
                    (message.durationUsec = $util.Long.fromValue(object.durationUsec)).unsigned = false;
                else if (typeof object.durationUsec === "string")
                    message.durationUsec = parseInt(object.durationUsec, 10);
                else if (typeof object.durationUsec === "number")
                    message.durationUsec = object.durationUsec;
                else if (typeof object.durationUsec === "object")
                    message.durationUsec = new $util.LongBits(object.durationUsec.low >>> 0, object.durationUsec.high >>> 0).toNumber();
            if (object.host != null)
                message.host = String(object.host);
            if (object.command != null)
                message.command = String(object.command);
            if (object.pattern) {
                if (!Array.isArray(object.pattern))
                    throw TypeError(".invocation.Invocation.pattern: array expected");
                message.pattern = [];
                for (let i = 0; i < object.pattern.length; ++i)
                    message.pattern[i] = String(object.pattern[i]);
            }
            if (object.actionCount != null)
                if ($util.Long)
                    (message.actionCount = $util.Long.fromValue(object.actionCount)).unsigned = false;
                else if (typeof object.actionCount === "string")
                    message.actionCount = parseInt(object.actionCount, 10);
                else if (typeof object.actionCount === "number")
                    message.actionCount = object.actionCount;
                else if (typeof object.actionCount === "object")
                    message.actionCount = new $util.LongBits(object.actionCount.low >>> 0, object.actionCount.high >>> 0).toNumber();
            switch (object.invocationStatus) {
            case "UNKNOWN_INVOCATION_STATUS":
            case 0:
                message.invocationStatus = 0;
                break;
            case "COMPLETE_INVOCATION_STATUS":
            case 1:
                message.invocationStatus = 1;
                break;
            case "PARTIAL_INVOCATION_STATUS":
            case 2:
                message.invocationStatus = 2;
                break;
            }
            if (object.consoleBuffer != null)
                message.consoleBuffer = String(object.consoleBuffer);
            if (object.structuredCommandLine) {
                if (!Array.isArray(object.structuredCommandLine))
                    throw TypeError(".invocation.Invocation.structuredCommandLine: array expected");
                message.structuredCommandLine = [];
                for (let i = 0; i < object.structuredCommandLine.length; ++i) {
                    if (typeof object.structuredCommandLine[i] !== "object")
                        throw TypeError(".invocation.Invocation.structuredCommandLine: object expected");
                    message.structuredCommandLine[i] = $root.command_line.CommandLine.fromObject(object.structuredCommandLine[i]);
                }
            }
            if (object.createdAtUsec != null)
                if ($util.Long)
                    (message.createdAtUsec = $util.Long.fromValue(object.createdAtUsec)).unsigned = false;
                else if (typeof object.createdAtUsec === "string")
                    message.createdAtUsec = parseInt(object.createdAtUsec, 10);
                else if (typeof object.createdAtUsec === "number")
                    message.createdAtUsec = object.createdAtUsec;
                else if (typeof object.createdAtUsec === "object")
                    message.createdAtUsec = new $util.LongBits(object.createdAtUsec.low >>> 0, object.createdAtUsec.high >>> 0).toNumber();
            if (object.updatedAtUsec != null)
                if ($util.Long)
                    (message.updatedAtUsec = $util.Long.fromValue(object.updatedAtUsec)).unsigned = false;
                else if (typeof object.updatedAtUsec === "string")
                    message.updatedAtUsec = parseInt(object.updatedAtUsec, 10);
                else if (typeof object.updatedAtUsec === "number")
                    message.updatedAtUsec = object.updatedAtUsec;
                else if (typeof object.updatedAtUsec === "object")
                    message.updatedAtUsec = new $util.LongBits(object.updatedAtUsec.low >>> 0, object.updatedAtUsec.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an Invocation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invocation.Invocation
         * @static
         * @param {invocation.Invocation} message Invocation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Invocation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.event = [];
                object.pattern = [];
                object.structuredCommandLine = [];
            }
            if (options.defaults) {
                object.invocationId = "";
                object.success = false;
                object.user = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.durationUsec = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.durationUsec = options.longs === String ? "0" : 0;
                object.host = "";
                object.command = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.actionCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.actionCount = options.longs === String ? "0" : 0;
                object.invocationStatus = options.enums === String ? "UNKNOWN_INVOCATION_STATUS" : 0;
                object.consoleBuffer = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.createdAtUsec = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.createdAtUsec = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.updatedAtUsec = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.updatedAtUsec = options.longs === String ? "0" : 0;
            }
            if (message.invocationId != null && message.hasOwnProperty("invocationId"))
                object.invocationId = message.invocationId;
            if (message.event && message.event.length) {
                object.event = [];
                for (let j = 0; j < message.event.length; ++j)
                    object.event[j] = $root.invocation.InvocationEvent.toObject(message.event[j], options);
            }
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = message.user;
            if (message.durationUsec != null && message.hasOwnProperty("durationUsec"))
                if (typeof message.durationUsec === "number")
                    object.durationUsec = options.longs === String ? String(message.durationUsec) : message.durationUsec;
                else
                    object.durationUsec = options.longs === String ? $util.Long.prototype.toString.call(message.durationUsec) : options.longs === Number ? new $util.LongBits(message.durationUsec.low >>> 0, message.durationUsec.high >>> 0).toNumber() : message.durationUsec;
            if (message.host != null && message.hasOwnProperty("host"))
                object.host = message.host;
            if (message.command != null && message.hasOwnProperty("command"))
                object.command = message.command;
            if (message.pattern && message.pattern.length) {
                object.pattern = [];
                for (let j = 0; j < message.pattern.length; ++j)
                    object.pattern[j] = message.pattern[j];
            }
            if (message.actionCount != null && message.hasOwnProperty("actionCount"))
                if (typeof message.actionCount === "number")
                    object.actionCount = options.longs === String ? String(message.actionCount) : message.actionCount;
                else
                    object.actionCount = options.longs === String ? $util.Long.prototype.toString.call(message.actionCount) : options.longs === Number ? new $util.LongBits(message.actionCount.low >>> 0, message.actionCount.high >>> 0).toNumber() : message.actionCount;
            if (message.invocationStatus != null && message.hasOwnProperty("invocationStatus"))
                object.invocationStatus = options.enums === String ? $root.invocation.Invocation.InvocationStatus[message.invocationStatus] : message.invocationStatus;
            if (message.consoleBuffer != null && message.hasOwnProperty("consoleBuffer"))
                object.consoleBuffer = message.consoleBuffer;
            if (message.structuredCommandLine && message.structuredCommandLine.length) {
                object.structuredCommandLine = [];
                for (let j = 0; j < message.structuredCommandLine.length; ++j)
                    object.structuredCommandLine[j] = $root.command_line.CommandLine.toObject(message.structuredCommandLine[j], options);
            }
            if (message.createdAtUsec != null && message.hasOwnProperty("createdAtUsec"))
                if (typeof message.createdAtUsec === "number")
                    object.createdAtUsec = options.longs === String ? String(message.createdAtUsec) : message.createdAtUsec;
                else
                    object.createdAtUsec = options.longs === String ? $util.Long.prototype.toString.call(message.createdAtUsec) : options.longs === Number ? new $util.LongBits(message.createdAtUsec.low >>> 0, message.createdAtUsec.high >>> 0).toNumber() : message.createdAtUsec;
            if (message.updatedAtUsec != null && message.hasOwnProperty("updatedAtUsec"))
                if (typeof message.updatedAtUsec === "number")
                    object.updatedAtUsec = options.longs === String ? String(message.updatedAtUsec) : message.updatedAtUsec;
                else
                    object.updatedAtUsec = options.longs === String ? $util.Long.prototype.toString.call(message.updatedAtUsec) : options.longs === Number ? new $util.LongBits(message.updatedAtUsec.low >>> 0, message.updatedAtUsec.high >>> 0).toNumber() : message.updatedAtUsec;
            return object;
        };

        /**
         * Converts this Invocation to JSON.
         * @function toJSON
         * @memberof invocation.Invocation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Invocation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * InvocationStatus enum.
         * @name invocation.Invocation.InvocationStatus
         * @enum {string}
         * @property {number} UNKNOWN_INVOCATION_STATUS=0 UNKNOWN_INVOCATION_STATUS value
         * @property {number} COMPLETE_INVOCATION_STATUS=1 COMPLETE_INVOCATION_STATUS value
         * @property {number} PARTIAL_INVOCATION_STATUS=2 PARTIAL_INVOCATION_STATUS value
         */
        Invocation.InvocationStatus = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_INVOCATION_STATUS"] = 0;
            values[valuesById[1] = "COMPLETE_INVOCATION_STATUS"] = 1;
            values[valuesById[2] = "PARTIAL_INVOCATION_STATUS"] = 2;
            return values;
        })();

        return Invocation;
    })();

    invocation.InvocationEvent = (function() {

        /**
         * Properties of an InvocationEvent.
         * @memberof invocation
         * @interface IInvocationEvent
         * @property {google.protobuf.ITimestamp|null} [eventTime] InvocationEvent eventTime
         * @property {build_event_stream.IBuildEvent|null} [buildEvent] InvocationEvent buildEvent
         * @property {Long|null} [sequenceNumber] InvocationEvent sequenceNumber
         */

        /**
         * Constructs a new InvocationEvent.
         * @memberof invocation
         * @classdesc Represents an InvocationEvent.
         * @implements IInvocationEvent
         * @constructor
         * @param {invocation.IInvocationEvent=} [properties] Properties to set
         */
        function InvocationEvent(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InvocationEvent eventTime.
         * @member {google.protobuf.ITimestamp|null|undefined} eventTime
         * @memberof invocation.InvocationEvent
         * @instance
         */
        InvocationEvent.prototype.eventTime = null;

        /**
         * InvocationEvent buildEvent.
         * @member {build_event_stream.IBuildEvent|null|undefined} buildEvent
         * @memberof invocation.InvocationEvent
         * @instance
         */
        InvocationEvent.prototype.buildEvent = null;

        /**
         * InvocationEvent sequenceNumber.
         * @member {Long} sequenceNumber
         * @memberof invocation.InvocationEvent
         * @instance
         */
        InvocationEvent.prototype.sequenceNumber = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new InvocationEvent instance using the specified properties.
         * @function create
         * @memberof invocation.InvocationEvent
         * @static
         * @param {invocation.IInvocationEvent=} [properties] Properties to set
         * @returns {invocation.InvocationEvent} InvocationEvent instance
         */
        InvocationEvent.create = function create(properties) {
            return new InvocationEvent(properties);
        };

        /**
         * Encodes the specified InvocationEvent message. Does not implicitly {@link invocation.InvocationEvent.verify|verify} messages.
         * @function encode
         * @memberof invocation.InvocationEvent
         * @static
         * @param {invocation.IInvocationEvent} message InvocationEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InvocationEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.eventTime != null && message.hasOwnProperty("eventTime"))
                $root.google.protobuf.Timestamp.encode(message.eventTime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.buildEvent != null && message.hasOwnProperty("buildEvent"))
                $root.build_event_stream.BuildEvent.encode(message.buildEvent, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.sequenceNumber != null && message.hasOwnProperty("sequenceNumber"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.sequenceNumber);
            return writer;
        };

        /**
         * Encodes the specified InvocationEvent message, length delimited. Does not implicitly {@link invocation.InvocationEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invocation.InvocationEvent
         * @static
         * @param {invocation.IInvocationEvent} message InvocationEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InvocationEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InvocationEvent message from the specified reader or buffer.
         * @function decode
         * @memberof invocation.InvocationEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invocation.InvocationEvent} InvocationEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InvocationEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invocation.InvocationEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.eventTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.buildEvent = $root.build_event_stream.BuildEvent.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.sequenceNumber = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InvocationEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invocation.InvocationEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invocation.InvocationEvent} InvocationEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InvocationEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InvocationEvent message.
         * @function verify
         * @memberof invocation.InvocationEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InvocationEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.eventTime != null && message.hasOwnProperty("eventTime")) {
                let error = $root.google.protobuf.Timestamp.verify(message.eventTime);
                if (error)
                    return "eventTime." + error;
            }
            if (message.buildEvent != null && message.hasOwnProperty("buildEvent")) {
                let error = $root.build_event_stream.BuildEvent.verify(message.buildEvent);
                if (error)
                    return "buildEvent." + error;
            }
            if (message.sequenceNumber != null && message.hasOwnProperty("sequenceNumber"))
                if (!$util.isInteger(message.sequenceNumber) && !(message.sequenceNumber && $util.isInteger(message.sequenceNumber.low) && $util.isInteger(message.sequenceNumber.high)))
                    return "sequenceNumber: integer|Long expected";
            return null;
        };

        /**
         * Creates an InvocationEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invocation.InvocationEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invocation.InvocationEvent} InvocationEvent
         */
        InvocationEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.invocation.InvocationEvent)
                return object;
            let message = new $root.invocation.InvocationEvent();
            if (object.eventTime != null) {
                if (typeof object.eventTime !== "object")
                    throw TypeError(".invocation.InvocationEvent.eventTime: object expected");
                message.eventTime = $root.google.protobuf.Timestamp.fromObject(object.eventTime);
            }
            if (object.buildEvent != null) {
                if (typeof object.buildEvent !== "object")
                    throw TypeError(".invocation.InvocationEvent.buildEvent: object expected");
                message.buildEvent = $root.build_event_stream.BuildEvent.fromObject(object.buildEvent);
            }
            if (object.sequenceNumber != null)
                if ($util.Long)
                    (message.sequenceNumber = $util.Long.fromValue(object.sequenceNumber)).unsigned = false;
                else if (typeof object.sequenceNumber === "string")
                    message.sequenceNumber = parseInt(object.sequenceNumber, 10);
                else if (typeof object.sequenceNumber === "number")
                    message.sequenceNumber = object.sequenceNumber;
                else if (typeof object.sequenceNumber === "object")
                    message.sequenceNumber = new $util.LongBits(object.sequenceNumber.low >>> 0, object.sequenceNumber.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an InvocationEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invocation.InvocationEvent
         * @static
         * @param {invocation.InvocationEvent} message InvocationEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InvocationEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.eventTime = null;
                object.buildEvent = null;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.sequenceNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.sequenceNumber = options.longs === String ? "0" : 0;
            }
            if (message.eventTime != null && message.hasOwnProperty("eventTime"))
                object.eventTime = $root.google.protobuf.Timestamp.toObject(message.eventTime, options);
            if (message.buildEvent != null && message.hasOwnProperty("buildEvent"))
                object.buildEvent = $root.build_event_stream.BuildEvent.toObject(message.buildEvent, options);
            if (message.sequenceNumber != null && message.hasOwnProperty("sequenceNumber"))
                if (typeof message.sequenceNumber === "number")
                    object.sequenceNumber = options.longs === String ? String(message.sequenceNumber) : message.sequenceNumber;
                else
                    object.sequenceNumber = options.longs === String ? $util.Long.prototype.toString.call(message.sequenceNumber) : options.longs === Number ? new $util.LongBits(message.sequenceNumber.low >>> 0, message.sequenceNumber.high >>> 0).toNumber() : message.sequenceNumber;
            return object;
        };

        /**
         * Converts this InvocationEvent to JSON.
         * @function toJSON
         * @memberof invocation.InvocationEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InvocationEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InvocationEvent;
    })();

    invocation.InvocationLookup = (function() {

        /**
         * Properties of an InvocationLookup.
         * @memberof invocation
         * @interface IInvocationLookup
         * @property {string|null} [invocationId] InvocationLookup invocationId
         */

        /**
         * Constructs a new InvocationLookup.
         * @memberof invocation
         * @classdesc Represents an InvocationLookup.
         * @implements IInvocationLookup
         * @constructor
         * @param {invocation.IInvocationLookup=} [properties] Properties to set
         */
        function InvocationLookup(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InvocationLookup invocationId.
         * @member {string} invocationId
         * @memberof invocation.InvocationLookup
         * @instance
         */
        InvocationLookup.prototype.invocationId = "";

        /**
         * Creates a new InvocationLookup instance using the specified properties.
         * @function create
         * @memberof invocation.InvocationLookup
         * @static
         * @param {invocation.IInvocationLookup=} [properties] Properties to set
         * @returns {invocation.InvocationLookup} InvocationLookup instance
         */
        InvocationLookup.create = function create(properties) {
            return new InvocationLookup(properties);
        };

        /**
         * Encodes the specified InvocationLookup message. Does not implicitly {@link invocation.InvocationLookup.verify|verify} messages.
         * @function encode
         * @memberof invocation.InvocationLookup
         * @static
         * @param {invocation.IInvocationLookup} message InvocationLookup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InvocationLookup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.invocationId != null && message.hasOwnProperty("invocationId"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.invocationId);
            return writer;
        };

        /**
         * Encodes the specified InvocationLookup message, length delimited. Does not implicitly {@link invocation.InvocationLookup.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invocation.InvocationLookup
         * @static
         * @param {invocation.IInvocationLookup} message InvocationLookup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InvocationLookup.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InvocationLookup message from the specified reader or buffer.
         * @function decode
         * @memberof invocation.InvocationLookup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invocation.InvocationLookup} InvocationLookup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InvocationLookup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invocation.InvocationLookup();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.invocationId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InvocationLookup message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invocation.InvocationLookup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invocation.InvocationLookup} InvocationLookup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InvocationLookup.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InvocationLookup message.
         * @function verify
         * @memberof invocation.InvocationLookup
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InvocationLookup.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.invocationId != null && message.hasOwnProperty("invocationId"))
                if (!$util.isString(message.invocationId))
                    return "invocationId: string expected";
            return null;
        };

        /**
         * Creates an InvocationLookup message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invocation.InvocationLookup
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invocation.InvocationLookup} InvocationLookup
         */
        InvocationLookup.fromObject = function fromObject(object) {
            if (object instanceof $root.invocation.InvocationLookup)
                return object;
            let message = new $root.invocation.InvocationLookup();
            if (object.invocationId != null)
                message.invocationId = String(object.invocationId);
            return message;
        };

        /**
         * Creates a plain object from an InvocationLookup message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invocation.InvocationLookup
         * @static
         * @param {invocation.InvocationLookup} message InvocationLookup
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InvocationLookup.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.invocationId = "";
            if (message.invocationId != null && message.hasOwnProperty("invocationId"))
                object.invocationId = message.invocationId;
            return object;
        };

        /**
         * Converts this InvocationLookup to JSON.
         * @function toJSON
         * @memberof invocation.InvocationLookup
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InvocationLookup.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InvocationLookup;
    })();

    invocation.GetInvocationRequest = (function() {

        /**
         * Properties of a GetInvocationRequest.
         * @memberof invocation
         * @interface IGetInvocationRequest
         * @property {context.IRequestContext|null} [requestContext] GetInvocationRequest requestContext
         * @property {invocation.IInvocationLookup|null} [lookup] GetInvocationRequest lookup
         */

        /**
         * Constructs a new GetInvocationRequest.
         * @memberof invocation
         * @classdesc Represents a GetInvocationRequest.
         * @implements IGetInvocationRequest
         * @constructor
         * @param {invocation.IGetInvocationRequest=} [properties] Properties to set
         */
        function GetInvocationRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetInvocationRequest requestContext.
         * @member {context.IRequestContext|null|undefined} requestContext
         * @memberof invocation.GetInvocationRequest
         * @instance
         */
        GetInvocationRequest.prototype.requestContext = null;

        /**
         * GetInvocationRequest lookup.
         * @member {invocation.IInvocationLookup|null|undefined} lookup
         * @memberof invocation.GetInvocationRequest
         * @instance
         */
        GetInvocationRequest.prototype.lookup = null;

        /**
         * Creates a new GetInvocationRequest instance using the specified properties.
         * @function create
         * @memberof invocation.GetInvocationRequest
         * @static
         * @param {invocation.IGetInvocationRequest=} [properties] Properties to set
         * @returns {invocation.GetInvocationRequest} GetInvocationRequest instance
         */
        GetInvocationRequest.create = function create(properties) {
            return new GetInvocationRequest(properties);
        };

        /**
         * Encodes the specified GetInvocationRequest message. Does not implicitly {@link invocation.GetInvocationRequest.verify|verify} messages.
         * @function encode
         * @memberof invocation.GetInvocationRequest
         * @static
         * @param {invocation.IGetInvocationRequest} message GetInvocationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInvocationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestContext != null && message.hasOwnProperty("requestContext"))
                $root.context.RequestContext.encode(message.requestContext, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.lookup != null && message.hasOwnProperty("lookup"))
                $root.invocation.InvocationLookup.encode(message.lookup, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetInvocationRequest message, length delimited. Does not implicitly {@link invocation.GetInvocationRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invocation.GetInvocationRequest
         * @static
         * @param {invocation.IGetInvocationRequest} message GetInvocationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInvocationRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetInvocationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof invocation.GetInvocationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invocation.GetInvocationRequest} GetInvocationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInvocationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invocation.GetInvocationRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requestContext = $root.context.RequestContext.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.lookup = $root.invocation.InvocationLookup.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetInvocationRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invocation.GetInvocationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invocation.GetInvocationRequest} GetInvocationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInvocationRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetInvocationRequest message.
         * @function verify
         * @memberof invocation.GetInvocationRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetInvocationRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.requestContext != null && message.hasOwnProperty("requestContext")) {
                let error = $root.context.RequestContext.verify(message.requestContext);
                if (error)
                    return "requestContext." + error;
            }
            if (message.lookup != null && message.hasOwnProperty("lookup")) {
                let error = $root.invocation.InvocationLookup.verify(message.lookup);
                if (error)
                    return "lookup." + error;
            }
            return null;
        };

        /**
         * Creates a GetInvocationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invocation.GetInvocationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invocation.GetInvocationRequest} GetInvocationRequest
         */
        GetInvocationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.invocation.GetInvocationRequest)
                return object;
            let message = new $root.invocation.GetInvocationRequest();
            if (object.requestContext != null) {
                if (typeof object.requestContext !== "object")
                    throw TypeError(".invocation.GetInvocationRequest.requestContext: object expected");
                message.requestContext = $root.context.RequestContext.fromObject(object.requestContext);
            }
            if (object.lookup != null) {
                if (typeof object.lookup !== "object")
                    throw TypeError(".invocation.GetInvocationRequest.lookup: object expected");
                message.lookup = $root.invocation.InvocationLookup.fromObject(object.lookup);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetInvocationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invocation.GetInvocationRequest
         * @static
         * @param {invocation.GetInvocationRequest} message GetInvocationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetInvocationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.requestContext = null;
                object.lookup = null;
            }
            if (message.requestContext != null && message.hasOwnProperty("requestContext"))
                object.requestContext = $root.context.RequestContext.toObject(message.requestContext, options);
            if (message.lookup != null && message.hasOwnProperty("lookup"))
                object.lookup = $root.invocation.InvocationLookup.toObject(message.lookup, options);
            return object;
        };

        /**
         * Converts this GetInvocationRequest to JSON.
         * @function toJSON
         * @memberof invocation.GetInvocationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetInvocationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetInvocationRequest;
    })();

    invocation.GetInvocationResponse = (function() {

        /**
         * Properties of a GetInvocationResponse.
         * @memberof invocation
         * @interface IGetInvocationResponse
         * @property {context.IResponseContext|null} [responseContext] GetInvocationResponse responseContext
         * @property {Array.<invocation.IInvocation>|null} [invocation] GetInvocationResponse invocation
         */

        /**
         * Constructs a new GetInvocationResponse.
         * @memberof invocation
         * @classdesc Represents a GetInvocationResponse.
         * @implements IGetInvocationResponse
         * @constructor
         * @param {invocation.IGetInvocationResponse=} [properties] Properties to set
         */
        function GetInvocationResponse(properties) {
            this.invocation = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetInvocationResponse responseContext.
         * @member {context.IResponseContext|null|undefined} responseContext
         * @memberof invocation.GetInvocationResponse
         * @instance
         */
        GetInvocationResponse.prototype.responseContext = null;

        /**
         * GetInvocationResponse invocation.
         * @member {Array.<invocation.IInvocation>} invocation
         * @memberof invocation.GetInvocationResponse
         * @instance
         */
        GetInvocationResponse.prototype.invocation = $util.emptyArray;

        /**
         * Creates a new GetInvocationResponse instance using the specified properties.
         * @function create
         * @memberof invocation.GetInvocationResponse
         * @static
         * @param {invocation.IGetInvocationResponse=} [properties] Properties to set
         * @returns {invocation.GetInvocationResponse} GetInvocationResponse instance
         */
        GetInvocationResponse.create = function create(properties) {
            return new GetInvocationResponse(properties);
        };

        /**
         * Encodes the specified GetInvocationResponse message. Does not implicitly {@link invocation.GetInvocationResponse.verify|verify} messages.
         * @function encode
         * @memberof invocation.GetInvocationResponse
         * @static
         * @param {invocation.IGetInvocationResponse} message GetInvocationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInvocationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.responseContext != null && message.hasOwnProperty("responseContext"))
                $root.context.ResponseContext.encode(message.responseContext, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.invocation != null && message.invocation.length)
                for (let i = 0; i < message.invocation.length; ++i)
                    $root.invocation.Invocation.encode(message.invocation[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetInvocationResponse message, length delimited. Does not implicitly {@link invocation.GetInvocationResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invocation.GetInvocationResponse
         * @static
         * @param {invocation.IGetInvocationResponse} message GetInvocationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInvocationResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetInvocationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof invocation.GetInvocationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invocation.GetInvocationResponse} GetInvocationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInvocationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invocation.GetInvocationResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.responseContext = $root.context.ResponseContext.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.invocation && message.invocation.length))
                        message.invocation = [];
                    message.invocation.push($root.invocation.Invocation.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetInvocationResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invocation.GetInvocationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invocation.GetInvocationResponse} GetInvocationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInvocationResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetInvocationResponse message.
         * @function verify
         * @memberof invocation.GetInvocationResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetInvocationResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.responseContext != null && message.hasOwnProperty("responseContext")) {
                let error = $root.context.ResponseContext.verify(message.responseContext);
                if (error)
                    return "responseContext." + error;
            }
            if (message.invocation != null && message.hasOwnProperty("invocation")) {
                if (!Array.isArray(message.invocation))
                    return "invocation: array expected";
                for (let i = 0; i < message.invocation.length; ++i) {
                    let error = $root.invocation.Invocation.verify(message.invocation[i]);
                    if (error)
                        return "invocation." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetInvocationResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invocation.GetInvocationResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invocation.GetInvocationResponse} GetInvocationResponse
         */
        GetInvocationResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.invocation.GetInvocationResponse)
                return object;
            let message = new $root.invocation.GetInvocationResponse();
            if (object.responseContext != null) {
                if (typeof object.responseContext !== "object")
                    throw TypeError(".invocation.GetInvocationResponse.responseContext: object expected");
                message.responseContext = $root.context.ResponseContext.fromObject(object.responseContext);
            }
            if (object.invocation) {
                if (!Array.isArray(object.invocation))
                    throw TypeError(".invocation.GetInvocationResponse.invocation: array expected");
                message.invocation = [];
                for (let i = 0; i < object.invocation.length; ++i) {
                    if (typeof object.invocation[i] !== "object")
                        throw TypeError(".invocation.GetInvocationResponse.invocation: object expected");
                    message.invocation[i] = $root.invocation.Invocation.fromObject(object.invocation[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetInvocationResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invocation.GetInvocationResponse
         * @static
         * @param {invocation.GetInvocationResponse} message GetInvocationResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetInvocationResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.invocation = [];
            if (options.defaults)
                object.responseContext = null;
            if (message.responseContext != null && message.hasOwnProperty("responseContext"))
                object.responseContext = $root.context.ResponseContext.toObject(message.responseContext, options);
            if (message.invocation && message.invocation.length) {
                object.invocation = [];
                for (let j = 0; j < message.invocation.length; ++j)
                    object.invocation[j] = $root.invocation.Invocation.toObject(message.invocation[j], options);
            }
            return object;
        };

        /**
         * Converts this GetInvocationResponse to JSON.
         * @function toJSON
         * @memberof invocation.GetInvocationResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetInvocationResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetInvocationResponse;
    })();

    invocation.InvocationQuery = (function() {

        /**
         * Properties of an InvocationQuery.
         * @memberof invocation
         * @interface IInvocationQuery
         * @property {string|null} [user] InvocationQuery user
         * @property {string|null} [host] InvocationQuery host
         * @property {string|null} [groupId] InvocationQuery groupId
         */

        /**
         * Constructs a new InvocationQuery.
         * @memberof invocation
         * @classdesc Represents an InvocationQuery.
         * @implements IInvocationQuery
         * @constructor
         * @param {invocation.IInvocationQuery=} [properties] Properties to set
         */
        function InvocationQuery(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InvocationQuery user.
         * @member {string} user
         * @memberof invocation.InvocationQuery
         * @instance
         */
        InvocationQuery.prototype.user = "";

        /**
         * InvocationQuery host.
         * @member {string} host
         * @memberof invocation.InvocationQuery
         * @instance
         */
        InvocationQuery.prototype.host = "";

        /**
         * InvocationQuery groupId.
         * @member {string} groupId
         * @memberof invocation.InvocationQuery
         * @instance
         */
        InvocationQuery.prototype.groupId = "";

        /**
         * Creates a new InvocationQuery instance using the specified properties.
         * @function create
         * @memberof invocation.InvocationQuery
         * @static
         * @param {invocation.IInvocationQuery=} [properties] Properties to set
         * @returns {invocation.InvocationQuery} InvocationQuery instance
         */
        InvocationQuery.create = function create(properties) {
            return new InvocationQuery(properties);
        };

        /**
         * Encodes the specified InvocationQuery message. Does not implicitly {@link invocation.InvocationQuery.verify|verify} messages.
         * @function encode
         * @memberof invocation.InvocationQuery
         * @static
         * @param {invocation.IInvocationQuery} message InvocationQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InvocationQuery.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.user != null && message.hasOwnProperty("user"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.user);
            if (message.host != null && message.hasOwnProperty("host"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.host);
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.groupId);
            return writer;
        };

        /**
         * Encodes the specified InvocationQuery message, length delimited. Does not implicitly {@link invocation.InvocationQuery.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invocation.InvocationQuery
         * @static
         * @param {invocation.IInvocationQuery} message InvocationQuery message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InvocationQuery.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InvocationQuery message from the specified reader or buffer.
         * @function decode
         * @memberof invocation.InvocationQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invocation.InvocationQuery} InvocationQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InvocationQuery.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invocation.InvocationQuery();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.user = reader.string();
                    break;
                case 2:
                    message.host = reader.string();
                    break;
                case 3:
                    message.groupId = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InvocationQuery message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invocation.InvocationQuery
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invocation.InvocationQuery} InvocationQuery
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InvocationQuery.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InvocationQuery message.
         * @function verify
         * @memberof invocation.InvocationQuery
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InvocationQuery.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.user != null && message.hasOwnProperty("user"))
                if (!$util.isString(message.user))
                    return "user: string expected";
            if (message.host != null && message.hasOwnProperty("host"))
                if (!$util.isString(message.host))
                    return "host: string expected";
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                if (!$util.isString(message.groupId))
                    return "groupId: string expected";
            return null;
        };

        /**
         * Creates an InvocationQuery message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invocation.InvocationQuery
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invocation.InvocationQuery} InvocationQuery
         */
        InvocationQuery.fromObject = function fromObject(object) {
            if (object instanceof $root.invocation.InvocationQuery)
                return object;
            let message = new $root.invocation.InvocationQuery();
            if (object.user != null)
                message.user = String(object.user);
            if (object.host != null)
                message.host = String(object.host);
            if (object.groupId != null)
                message.groupId = String(object.groupId);
            return message;
        };

        /**
         * Creates a plain object from an InvocationQuery message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invocation.InvocationQuery
         * @static
         * @param {invocation.InvocationQuery} message InvocationQuery
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InvocationQuery.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.user = "";
                object.host = "";
                object.groupId = "";
            }
            if (message.user != null && message.hasOwnProperty("user"))
                object.user = message.user;
            if (message.host != null && message.hasOwnProperty("host"))
                object.host = message.host;
            if (message.groupId != null && message.hasOwnProperty("groupId"))
                object.groupId = message.groupId;
            return object;
        };

        /**
         * Converts this InvocationQuery to JSON.
         * @function toJSON
         * @memberof invocation.InvocationQuery
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InvocationQuery.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InvocationQuery;
    })();

    invocation.InvocationSort = (function() {

        /**
         * Properties of an InvocationSort.
         * @memberof invocation
         * @interface IInvocationSort
         * @property {invocation.InvocationSort.SortField|null} [sortField] InvocationSort sortField
         * @property {boolean|null} [ascending] InvocationSort ascending
         */

        /**
         * Constructs a new InvocationSort.
         * @memberof invocation
         * @classdesc Represents an InvocationSort.
         * @implements IInvocationSort
         * @constructor
         * @param {invocation.IInvocationSort=} [properties] Properties to set
         */
        function InvocationSort(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InvocationSort sortField.
         * @member {invocation.InvocationSort.SortField} sortField
         * @memberof invocation.InvocationSort
         * @instance
         */
        InvocationSort.prototype.sortField = 0;

        /**
         * InvocationSort ascending.
         * @member {boolean} ascending
         * @memberof invocation.InvocationSort
         * @instance
         */
        InvocationSort.prototype.ascending = false;

        /**
         * Creates a new InvocationSort instance using the specified properties.
         * @function create
         * @memberof invocation.InvocationSort
         * @static
         * @param {invocation.IInvocationSort=} [properties] Properties to set
         * @returns {invocation.InvocationSort} InvocationSort instance
         */
        InvocationSort.create = function create(properties) {
            return new InvocationSort(properties);
        };

        /**
         * Encodes the specified InvocationSort message. Does not implicitly {@link invocation.InvocationSort.verify|verify} messages.
         * @function encode
         * @memberof invocation.InvocationSort
         * @static
         * @param {invocation.IInvocationSort} message InvocationSort message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InvocationSort.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sortField != null && message.hasOwnProperty("sortField"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.sortField);
            if (message.ascending != null && message.hasOwnProperty("ascending"))
                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.ascending);
            return writer;
        };

        /**
         * Encodes the specified InvocationSort message, length delimited. Does not implicitly {@link invocation.InvocationSort.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invocation.InvocationSort
         * @static
         * @param {invocation.IInvocationSort} message InvocationSort message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InvocationSort.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InvocationSort message from the specified reader or buffer.
         * @function decode
         * @memberof invocation.InvocationSort
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invocation.InvocationSort} InvocationSort
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InvocationSort.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invocation.InvocationSort();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.sortField = reader.int32();
                    break;
                case 2:
                    message.ascending = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InvocationSort message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invocation.InvocationSort
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invocation.InvocationSort} InvocationSort
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InvocationSort.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InvocationSort message.
         * @function verify
         * @memberof invocation.InvocationSort
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InvocationSort.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.sortField != null && message.hasOwnProperty("sortField"))
                switch (message.sortField) {
                default:
                    return "sortField: enum value expected";
                case 0:
                case 1:
                    break;
                }
            if (message.ascending != null && message.hasOwnProperty("ascending"))
                if (typeof message.ascending !== "boolean")
                    return "ascending: boolean expected";
            return null;
        };

        /**
         * Creates an InvocationSort message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invocation.InvocationSort
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invocation.InvocationSort} InvocationSort
         */
        InvocationSort.fromObject = function fromObject(object) {
            if (object instanceof $root.invocation.InvocationSort)
                return object;
            let message = new $root.invocation.InvocationSort();
            switch (object.sortField) {
            case "UNKNOWN_SORT_FIELD":
            case 0:
                message.sortField = 0;
                break;
            case "CREATED_AT_USEC_SORT_FIELD":
            case 1:
                message.sortField = 1;
                break;
            }
            if (object.ascending != null)
                message.ascending = Boolean(object.ascending);
            return message;
        };

        /**
         * Creates a plain object from an InvocationSort message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invocation.InvocationSort
         * @static
         * @param {invocation.InvocationSort} message InvocationSort
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InvocationSort.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.sortField = options.enums === String ? "UNKNOWN_SORT_FIELD" : 0;
                object.ascending = false;
            }
            if (message.sortField != null && message.hasOwnProperty("sortField"))
                object.sortField = options.enums === String ? $root.invocation.InvocationSort.SortField[message.sortField] : message.sortField;
            if (message.ascending != null && message.hasOwnProperty("ascending"))
                object.ascending = message.ascending;
            return object;
        };

        /**
         * Converts this InvocationSort to JSON.
         * @function toJSON
         * @memberof invocation.InvocationSort
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InvocationSort.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * SortField enum.
         * @name invocation.InvocationSort.SortField
         * @enum {string}
         * @property {number} UNKNOWN_SORT_FIELD=0 UNKNOWN_SORT_FIELD value
         * @property {number} CREATED_AT_USEC_SORT_FIELD=1 CREATED_AT_USEC_SORT_FIELD value
         */
        InvocationSort.SortField = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN_SORT_FIELD"] = 0;
            values[valuesById[1] = "CREATED_AT_USEC_SORT_FIELD"] = 1;
            return values;
        })();

        return InvocationSort;
    })();

    invocation.SearchInvocationRequest = (function() {

        /**
         * Properties of a SearchInvocationRequest.
         * @memberof invocation
         * @interface ISearchInvocationRequest
         * @property {context.IRequestContext|null} [requestContext] SearchInvocationRequest requestContext
         * @property {invocation.IInvocationQuery|null} [query] SearchInvocationRequest query
         * @property {invocation.IInvocationSort|null} [sort] SearchInvocationRequest sort
         * @property {number|null} [count] SearchInvocationRequest count
         */

        /**
         * Constructs a new SearchInvocationRequest.
         * @memberof invocation
         * @classdesc Represents a SearchInvocationRequest.
         * @implements ISearchInvocationRequest
         * @constructor
         * @param {invocation.ISearchInvocationRequest=} [properties] Properties to set
         */
        function SearchInvocationRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchInvocationRequest requestContext.
         * @member {context.IRequestContext|null|undefined} requestContext
         * @memberof invocation.SearchInvocationRequest
         * @instance
         */
        SearchInvocationRequest.prototype.requestContext = null;

        /**
         * SearchInvocationRequest query.
         * @member {invocation.IInvocationQuery|null|undefined} query
         * @memberof invocation.SearchInvocationRequest
         * @instance
         */
        SearchInvocationRequest.prototype.query = null;

        /**
         * SearchInvocationRequest sort.
         * @member {invocation.IInvocationSort|null|undefined} sort
         * @memberof invocation.SearchInvocationRequest
         * @instance
         */
        SearchInvocationRequest.prototype.sort = null;

        /**
         * SearchInvocationRequest count.
         * @member {number} count
         * @memberof invocation.SearchInvocationRequest
         * @instance
         */
        SearchInvocationRequest.prototype.count = 0;

        /**
         * Creates a new SearchInvocationRequest instance using the specified properties.
         * @function create
         * @memberof invocation.SearchInvocationRequest
         * @static
         * @param {invocation.ISearchInvocationRequest=} [properties] Properties to set
         * @returns {invocation.SearchInvocationRequest} SearchInvocationRequest instance
         */
        SearchInvocationRequest.create = function create(properties) {
            return new SearchInvocationRequest(properties);
        };

        /**
         * Encodes the specified SearchInvocationRequest message. Does not implicitly {@link invocation.SearchInvocationRequest.verify|verify} messages.
         * @function encode
         * @memberof invocation.SearchInvocationRequest
         * @static
         * @param {invocation.ISearchInvocationRequest} message SearchInvocationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchInvocationRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestContext != null && message.hasOwnProperty("requestContext"))
                $root.context.RequestContext.encode(message.requestContext, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.query != null && message.hasOwnProperty("query"))
                $root.invocation.InvocationQuery.encode(message.query, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.sort != null && message.hasOwnProperty("sort"))
                $root.invocation.InvocationSort.encode(message.sort, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.count != null && message.hasOwnProperty("count"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.count);
            return writer;
        };

        /**
         * Encodes the specified SearchInvocationRequest message, length delimited. Does not implicitly {@link invocation.SearchInvocationRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invocation.SearchInvocationRequest
         * @static
         * @param {invocation.ISearchInvocationRequest} message SearchInvocationRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchInvocationRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SearchInvocationRequest message from the specified reader or buffer.
         * @function decode
         * @memberof invocation.SearchInvocationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invocation.SearchInvocationRequest} SearchInvocationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchInvocationRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invocation.SearchInvocationRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requestContext = $root.context.RequestContext.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.query = $root.invocation.InvocationQuery.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.sort = $root.invocation.InvocationSort.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.count = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SearchInvocationRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invocation.SearchInvocationRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invocation.SearchInvocationRequest} SearchInvocationRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchInvocationRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SearchInvocationRequest message.
         * @function verify
         * @memberof invocation.SearchInvocationRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SearchInvocationRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.requestContext != null && message.hasOwnProperty("requestContext")) {
                let error = $root.context.RequestContext.verify(message.requestContext);
                if (error)
                    return "requestContext." + error;
            }
            if (message.query != null && message.hasOwnProperty("query")) {
                let error = $root.invocation.InvocationQuery.verify(message.query);
                if (error)
                    return "query." + error;
            }
            if (message.sort != null && message.hasOwnProperty("sort")) {
                let error = $root.invocation.InvocationSort.verify(message.sort);
                if (error)
                    return "sort." + error;
            }
            if (message.count != null && message.hasOwnProperty("count"))
                if (!$util.isInteger(message.count))
                    return "count: integer expected";
            return null;
        };

        /**
         * Creates a SearchInvocationRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invocation.SearchInvocationRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invocation.SearchInvocationRequest} SearchInvocationRequest
         */
        SearchInvocationRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.invocation.SearchInvocationRequest)
                return object;
            let message = new $root.invocation.SearchInvocationRequest();
            if (object.requestContext != null) {
                if (typeof object.requestContext !== "object")
                    throw TypeError(".invocation.SearchInvocationRequest.requestContext: object expected");
                message.requestContext = $root.context.RequestContext.fromObject(object.requestContext);
            }
            if (object.query != null) {
                if (typeof object.query !== "object")
                    throw TypeError(".invocation.SearchInvocationRequest.query: object expected");
                message.query = $root.invocation.InvocationQuery.fromObject(object.query);
            }
            if (object.sort != null) {
                if (typeof object.sort !== "object")
                    throw TypeError(".invocation.SearchInvocationRequest.sort: object expected");
                message.sort = $root.invocation.InvocationSort.fromObject(object.sort);
            }
            if (object.count != null)
                message.count = object.count | 0;
            return message;
        };

        /**
         * Creates a plain object from a SearchInvocationRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invocation.SearchInvocationRequest
         * @static
         * @param {invocation.SearchInvocationRequest} message SearchInvocationRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchInvocationRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.requestContext = null;
                object.query = null;
                object.sort = null;
                object.count = 0;
            }
            if (message.requestContext != null && message.hasOwnProperty("requestContext"))
                object.requestContext = $root.context.RequestContext.toObject(message.requestContext, options);
            if (message.query != null && message.hasOwnProperty("query"))
                object.query = $root.invocation.InvocationQuery.toObject(message.query, options);
            if (message.sort != null && message.hasOwnProperty("sort"))
                object.sort = $root.invocation.InvocationSort.toObject(message.sort, options);
            if (message.count != null && message.hasOwnProperty("count"))
                object.count = message.count;
            return object;
        };

        /**
         * Converts this SearchInvocationRequest to JSON.
         * @function toJSON
         * @memberof invocation.SearchInvocationRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchInvocationRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SearchInvocationRequest;
    })();

    invocation.SearchInvocationResponse = (function() {

        /**
         * Properties of a SearchInvocationResponse.
         * @memberof invocation
         * @interface ISearchInvocationResponse
         * @property {context.IResponseContext|null} [responseContext] SearchInvocationResponse responseContext
         * @property {Array.<invocation.IInvocation>|null} [invocation] SearchInvocationResponse invocation
         */

        /**
         * Constructs a new SearchInvocationResponse.
         * @memberof invocation
         * @classdesc Represents a SearchInvocationResponse.
         * @implements ISearchInvocationResponse
         * @constructor
         * @param {invocation.ISearchInvocationResponse=} [properties] Properties to set
         */
        function SearchInvocationResponse(properties) {
            this.invocation = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * SearchInvocationResponse responseContext.
         * @member {context.IResponseContext|null|undefined} responseContext
         * @memberof invocation.SearchInvocationResponse
         * @instance
         */
        SearchInvocationResponse.prototype.responseContext = null;

        /**
         * SearchInvocationResponse invocation.
         * @member {Array.<invocation.IInvocation>} invocation
         * @memberof invocation.SearchInvocationResponse
         * @instance
         */
        SearchInvocationResponse.prototype.invocation = $util.emptyArray;

        /**
         * Creates a new SearchInvocationResponse instance using the specified properties.
         * @function create
         * @memberof invocation.SearchInvocationResponse
         * @static
         * @param {invocation.ISearchInvocationResponse=} [properties] Properties to set
         * @returns {invocation.SearchInvocationResponse} SearchInvocationResponse instance
         */
        SearchInvocationResponse.create = function create(properties) {
            return new SearchInvocationResponse(properties);
        };

        /**
         * Encodes the specified SearchInvocationResponse message. Does not implicitly {@link invocation.SearchInvocationResponse.verify|verify} messages.
         * @function encode
         * @memberof invocation.SearchInvocationResponse
         * @static
         * @param {invocation.ISearchInvocationResponse} message SearchInvocationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchInvocationResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.responseContext != null && message.hasOwnProperty("responseContext"))
                $root.context.ResponseContext.encode(message.responseContext, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.invocation != null && message.invocation.length)
                for (let i = 0; i < message.invocation.length; ++i)
                    $root.invocation.Invocation.encode(message.invocation[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified SearchInvocationResponse message, length delimited. Does not implicitly {@link invocation.SearchInvocationResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invocation.SearchInvocationResponse
         * @static
         * @param {invocation.ISearchInvocationResponse} message SearchInvocationResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchInvocationResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a SearchInvocationResponse message from the specified reader or buffer.
         * @function decode
         * @memberof invocation.SearchInvocationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invocation.SearchInvocationResponse} SearchInvocationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchInvocationResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invocation.SearchInvocationResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.responseContext = $root.context.ResponseContext.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.invocation && message.invocation.length))
                        message.invocation = [];
                    message.invocation.push($root.invocation.Invocation.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a SearchInvocationResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invocation.SearchInvocationResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invocation.SearchInvocationResponse} SearchInvocationResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchInvocationResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a SearchInvocationResponse message.
         * @function verify
         * @memberof invocation.SearchInvocationResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SearchInvocationResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.responseContext != null && message.hasOwnProperty("responseContext")) {
                let error = $root.context.ResponseContext.verify(message.responseContext);
                if (error)
                    return "responseContext." + error;
            }
            if (message.invocation != null && message.hasOwnProperty("invocation")) {
                if (!Array.isArray(message.invocation))
                    return "invocation: array expected";
                for (let i = 0; i < message.invocation.length; ++i) {
                    let error = $root.invocation.Invocation.verify(message.invocation[i]);
                    if (error)
                        return "invocation." + error;
                }
            }
            return null;
        };

        /**
         * Creates a SearchInvocationResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invocation.SearchInvocationResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invocation.SearchInvocationResponse} SearchInvocationResponse
         */
        SearchInvocationResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.invocation.SearchInvocationResponse)
                return object;
            let message = new $root.invocation.SearchInvocationResponse();
            if (object.responseContext != null) {
                if (typeof object.responseContext !== "object")
                    throw TypeError(".invocation.SearchInvocationResponse.responseContext: object expected");
                message.responseContext = $root.context.ResponseContext.fromObject(object.responseContext);
            }
            if (object.invocation) {
                if (!Array.isArray(object.invocation))
                    throw TypeError(".invocation.SearchInvocationResponse.invocation: array expected");
                message.invocation = [];
                for (let i = 0; i < object.invocation.length; ++i) {
                    if (typeof object.invocation[i] !== "object")
                        throw TypeError(".invocation.SearchInvocationResponse.invocation: object expected");
                    message.invocation[i] = $root.invocation.Invocation.fromObject(object.invocation[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a SearchInvocationResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invocation.SearchInvocationResponse
         * @static
         * @param {invocation.SearchInvocationResponse} message SearchInvocationResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchInvocationResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.invocation = [];
            if (options.defaults)
                object.responseContext = null;
            if (message.responseContext != null && message.hasOwnProperty("responseContext"))
                object.responseContext = $root.context.ResponseContext.toObject(message.responseContext, options);
            if (message.invocation && message.invocation.length) {
                object.invocation = [];
                for (let j = 0; j < message.invocation.length; ++j)
                    object.invocation[j] = $root.invocation.Invocation.toObject(message.invocation[j], options);
            }
            return object;
        };

        /**
         * Converts this SearchInvocationResponse to JSON.
         * @function toJSON
         * @memberof invocation.SearchInvocationResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchInvocationResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SearchInvocationResponse;
    })();

    /**
     * AggType enum.
     * @name invocation.AggType
     * @enum {string}
     * @property {number} UNKNOWN_AGGREGATION_TYPE=0 UNKNOWN_AGGREGATION_TYPE value
     * @property {number} USER_AGGREGATION_TYPE=1 USER_AGGREGATION_TYPE value
     * @property {number} HOSTNAME_AGGREGATION_TYPE=2 HOSTNAME_AGGREGATION_TYPE value
     */
    invocation.AggType = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN_AGGREGATION_TYPE"] = 0;
        values[valuesById[1] = "USER_AGGREGATION_TYPE"] = 1;
        values[valuesById[2] = "HOSTNAME_AGGREGATION_TYPE"] = 2;
        return values;
    })();

    invocation.InvocationStat = (function() {

        /**
         * Properties of an InvocationStat.
         * @memberof invocation
         * @interface IInvocationStat
         * @property {invocation.AggType|null} [aggregationType] InvocationStat aggregationType
         * @property {string|null} [name] InvocationStat name
         * @property {Long|null} [totalBuildTimeUsec] InvocationStat totalBuildTimeUsec
         * @property {Long|null} [totalNumBuilds] InvocationStat totalNumBuilds
         * @property {Long|null} [latestBuildTimeUsec] InvocationStat latestBuildTimeUsec
         * @property {Long|null} [totalActions] InvocationStat totalActions
         * @property {Long|null} [lastGreenBuildUsec] InvocationStat lastGreenBuildUsec
         */

        /**
         * Constructs a new InvocationStat.
         * @memberof invocation
         * @classdesc Represents an InvocationStat.
         * @implements IInvocationStat
         * @constructor
         * @param {invocation.IInvocationStat=} [properties] Properties to set
         */
        function InvocationStat(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * InvocationStat aggregationType.
         * @member {invocation.AggType} aggregationType
         * @memberof invocation.InvocationStat
         * @instance
         */
        InvocationStat.prototype.aggregationType = 0;

        /**
         * InvocationStat name.
         * @member {string} name
         * @memberof invocation.InvocationStat
         * @instance
         */
        InvocationStat.prototype.name = "";

        /**
         * InvocationStat totalBuildTimeUsec.
         * @member {Long} totalBuildTimeUsec
         * @memberof invocation.InvocationStat
         * @instance
         */
        InvocationStat.prototype.totalBuildTimeUsec = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * InvocationStat totalNumBuilds.
         * @member {Long} totalNumBuilds
         * @memberof invocation.InvocationStat
         * @instance
         */
        InvocationStat.prototype.totalNumBuilds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * InvocationStat latestBuildTimeUsec.
         * @member {Long} latestBuildTimeUsec
         * @memberof invocation.InvocationStat
         * @instance
         */
        InvocationStat.prototype.latestBuildTimeUsec = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * InvocationStat totalActions.
         * @member {Long} totalActions
         * @memberof invocation.InvocationStat
         * @instance
         */
        InvocationStat.prototype.totalActions = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * InvocationStat lastGreenBuildUsec.
         * @member {Long} lastGreenBuildUsec
         * @memberof invocation.InvocationStat
         * @instance
         */
        InvocationStat.prototype.lastGreenBuildUsec = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new InvocationStat instance using the specified properties.
         * @function create
         * @memberof invocation.InvocationStat
         * @static
         * @param {invocation.IInvocationStat=} [properties] Properties to set
         * @returns {invocation.InvocationStat} InvocationStat instance
         */
        InvocationStat.create = function create(properties) {
            return new InvocationStat(properties);
        };

        /**
         * Encodes the specified InvocationStat message. Does not implicitly {@link invocation.InvocationStat.verify|verify} messages.
         * @function encode
         * @memberof invocation.InvocationStat
         * @static
         * @param {invocation.IInvocationStat} message InvocationStat message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InvocationStat.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.aggregationType != null && message.hasOwnProperty("aggregationType"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.aggregationType);
            if (message.name != null && message.hasOwnProperty("name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.totalBuildTimeUsec != null && message.hasOwnProperty("totalBuildTimeUsec"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalBuildTimeUsec);
            if (message.totalNumBuilds != null && message.hasOwnProperty("totalNumBuilds"))
                writer.uint32(/* id 4, wireType 0 =*/32).int64(message.totalNumBuilds);
            if (message.latestBuildTimeUsec != null && message.hasOwnProperty("latestBuildTimeUsec"))
                writer.uint32(/* id 5, wireType 0 =*/40).int64(message.latestBuildTimeUsec);
            if (message.totalActions != null && message.hasOwnProperty("totalActions"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.totalActions);
            if (message.lastGreenBuildUsec != null && message.hasOwnProperty("lastGreenBuildUsec"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.lastGreenBuildUsec);
            return writer;
        };

        /**
         * Encodes the specified InvocationStat message, length delimited. Does not implicitly {@link invocation.InvocationStat.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invocation.InvocationStat
         * @static
         * @param {invocation.IInvocationStat} message InvocationStat message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InvocationStat.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an InvocationStat message from the specified reader or buffer.
         * @function decode
         * @memberof invocation.InvocationStat
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invocation.InvocationStat} InvocationStat
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InvocationStat.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invocation.InvocationStat();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.aggregationType = reader.int32();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.totalBuildTimeUsec = reader.int64();
                    break;
                case 4:
                    message.totalNumBuilds = reader.int64();
                    break;
                case 5:
                    message.latestBuildTimeUsec = reader.int64();
                    break;
                case 6:
                    message.totalActions = reader.int64();
                    break;
                case 7:
                    message.lastGreenBuildUsec = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an InvocationStat message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invocation.InvocationStat
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invocation.InvocationStat} InvocationStat
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InvocationStat.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an InvocationStat message.
         * @function verify
         * @memberof invocation.InvocationStat
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InvocationStat.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.aggregationType != null && message.hasOwnProperty("aggregationType"))
                switch (message.aggregationType) {
                default:
                    return "aggregationType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.totalBuildTimeUsec != null && message.hasOwnProperty("totalBuildTimeUsec"))
                if (!$util.isInteger(message.totalBuildTimeUsec) && !(message.totalBuildTimeUsec && $util.isInteger(message.totalBuildTimeUsec.low) && $util.isInteger(message.totalBuildTimeUsec.high)))
                    return "totalBuildTimeUsec: integer|Long expected";
            if (message.totalNumBuilds != null && message.hasOwnProperty("totalNumBuilds"))
                if (!$util.isInteger(message.totalNumBuilds) && !(message.totalNumBuilds && $util.isInteger(message.totalNumBuilds.low) && $util.isInteger(message.totalNumBuilds.high)))
                    return "totalNumBuilds: integer|Long expected";
            if (message.latestBuildTimeUsec != null && message.hasOwnProperty("latestBuildTimeUsec"))
                if (!$util.isInteger(message.latestBuildTimeUsec) && !(message.latestBuildTimeUsec && $util.isInteger(message.latestBuildTimeUsec.low) && $util.isInteger(message.latestBuildTimeUsec.high)))
                    return "latestBuildTimeUsec: integer|Long expected";
            if (message.totalActions != null && message.hasOwnProperty("totalActions"))
                if (!$util.isInteger(message.totalActions) && !(message.totalActions && $util.isInteger(message.totalActions.low) && $util.isInteger(message.totalActions.high)))
                    return "totalActions: integer|Long expected";
            if (message.lastGreenBuildUsec != null && message.hasOwnProperty("lastGreenBuildUsec"))
                if (!$util.isInteger(message.lastGreenBuildUsec) && !(message.lastGreenBuildUsec && $util.isInteger(message.lastGreenBuildUsec.low) && $util.isInteger(message.lastGreenBuildUsec.high)))
                    return "lastGreenBuildUsec: integer|Long expected";
            return null;
        };

        /**
         * Creates an InvocationStat message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invocation.InvocationStat
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invocation.InvocationStat} InvocationStat
         */
        InvocationStat.fromObject = function fromObject(object) {
            if (object instanceof $root.invocation.InvocationStat)
                return object;
            let message = new $root.invocation.InvocationStat();
            switch (object.aggregationType) {
            case "UNKNOWN_AGGREGATION_TYPE":
            case 0:
                message.aggregationType = 0;
                break;
            case "USER_AGGREGATION_TYPE":
            case 1:
                message.aggregationType = 1;
                break;
            case "HOSTNAME_AGGREGATION_TYPE":
            case 2:
                message.aggregationType = 2;
                break;
            }
            if (object.name != null)
                message.name = String(object.name);
            if (object.totalBuildTimeUsec != null)
                if ($util.Long)
                    (message.totalBuildTimeUsec = $util.Long.fromValue(object.totalBuildTimeUsec)).unsigned = false;
                else if (typeof object.totalBuildTimeUsec === "string")
                    message.totalBuildTimeUsec = parseInt(object.totalBuildTimeUsec, 10);
                else if (typeof object.totalBuildTimeUsec === "number")
                    message.totalBuildTimeUsec = object.totalBuildTimeUsec;
                else if (typeof object.totalBuildTimeUsec === "object")
                    message.totalBuildTimeUsec = new $util.LongBits(object.totalBuildTimeUsec.low >>> 0, object.totalBuildTimeUsec.high >>> 0).toNumber();
            if (object.totalNumBuilds != null)
                if ($util.Long)
                    (message.totalNumBuilds = $util.Long.fromValue(object.totalNumBuilds)).unsigned = false;
                else if (typeof object.totalNumBuilds === "string")
                    message.totalNumBuilds = parseInt(object.totalNumBuilds, 10);
                else if (typeof object.totalNumBuilds === "number")
                    message.totalNumBuilds = object.totalNumBuilds;
                else if (typeof object.totalNumBuilds === "object")
                    message.totalNumBuilds = new $util.LongBits(object.totalNumBuilds.low >>> 0, object.totalNumBuilds.high >>> 0).toNumber();
            if (object.latestBuildTimeUsec != null)
                if ($util.Long)
                    (message.latestBuildTimeUsec = $util.Long.fromValue(object.latestBuildTimeUsec)).unsigned = false;
                else if (typeof object.latestBuildTimeUsec === "string")
                    message.latestBuildTimeUsec = parseInt(object.latestBuildTimeUsec, 10);
                else if (typeof object.latestBuildTimeUsec === "number")
                    message.latestBuildTimeUsec = object.latestBuildTimeUsec;
                else if (typeof object.latestBuildTimeUsec === "object")
                    message.latestBuildTimeUsec = new $util.LongBits(object.latestBuildTimeUsec.low >>> 0, object.latestBuildTimeUsec.high >>> 0).toNumber();
            if (object.totalActions != null)
                if ($util.Long)
                    (message.totalActions = $util.Long.fromValue(object.totalActions)).unsigned = false;
                else if (typeof object.totalActions === "string")
                    message.totalActions = parseInt(object.totalActions, 10);
                else if (typeof object.totalActions === "number")
                    message.totalActions = object.totalActions;
                else if (typeof object.totalActions === "object")
                    message.totalActions = new $util.LongBits(object.totalActions.low >>> 0, object.totalActions.high >>> 0).toNumber();
            if (object.lastGreenBuildUsec != null)
                if ($util.Long)
                    (message.lastGreenBuildUsec = $util.Long.fromValue(object.lastGreenBuildUsec)).unsigned = false;
                else if (typeof object.lastGreenBuildUsec === "string")
                    message.lastGreenBuildUsec = parseInt(object.lastGreenBuildUsec, 10);
                else if (typeof object.lastGreenBuildUsec === "number")
                    message.lastGreenBuildUsec = object.lastGreenBuildUsec;
                else if (typeof object.lastGreenBuildUsec === "object")
                    message.lastGreenBuildUsec = new $util.LongBits(object.lastGreenBuildUsec.low >>> 0, object.lastGreenBuildUsec.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from an InvocationStat message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invocation.InvocationStat
         * @static
         * @param {invocation.InvocationStat} message InvocationStat
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InvocationStat.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.aggregationType = options.enums === String ? "UNKNOWN_AGGREGATION_TYPE" : 0;
                object.name = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalBuildTimeUsec = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalBuildTimeUsec = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalNumBuilds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalNumBuilds = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.latestBuildTimeUsec = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.latestBuildTimeUsec = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalActions = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalActions = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.lastGreenBuildUsec = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lastGreenBuildUsec = options.longs === String ? "0" : 0;
            }
            if (message.aggregationType != null && message.hasOwnProperty("aggregationType"))
                object.aggregationType = options.enums === String ? $root.invocation.AggType[message.aggregationType] : message.aggregationType;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.totalBuildTimeUsec != null && message.hasOwnProperty("totalBuildTimeUsec"))
                if (typeof message.totalBuildTimeUsec === "number")
                    object.totalBuildTimeUsec = options.longs === String ? String(message.totalBuildTimeUsec) : message.totalBuildTimeUsec;
                else
                    object.totalBuildTimeUsec = options.longs === String ? $util.Long.prototype.toString.call(message.totalBuildTimeUsec) : options.longs === Number ? new $util.LongBits(message.totalBuildTimeUsec.low >>> 0, message.totalBuildTimeUsec.high >>> 0).toNumber() : message.totalBuildTimeUsec;
            if (message.totalNumBuilds != null && message.hasOwnProperty("totalNumBuilds"))
                if (typeof message.totalNumBuilds === "number")
                    object.totalNumBuilds = options.longs === String ? String(message.totalNumBuilds) : message.totalNumBuilds;
                else
                    object.totalNumBuilds = options.longs === String ? $util.Long.prototype.toString.call(message.totalNumBuilds) : options.longs === Number ? new $util.LongBits(message.totalNumBuilds.low >>> 0, message.totalNumBuilds.high >>> 0).toNumber() : message.totalNumBuilds;
            if (message.latestBuildTimeUsec != null && message.hasOwnProperty("latestBuildTimeUsec"))
                if (typeof message.latestBuildTimeUsec === "number")
                    object.latestBuildTimeUsec = options.longs === String ? String(message.latestBuildTimeUsec) : message.latestBuildTimeUsec;
                else
                    object.latestBuildTimeUsec = options.longs === String ? $util.Long.prototype.toString.call(message.latestBuildTimeUsec) : options.longs === Number ? new $util.LongBits(message.latestBuildTimeUsec.low >>> 0, message.latestBuildTimeUsec.high >>> 0).toNumber() : message.latestBuildTimeUsec;
            if (message.totalActions != null && message.hasOwnProperty("totalActions"))
                if (typeof message.totalActions === "number")
                    object.totalActions = options.longs === String ? String(message.totalActions) : message.totalActions;
                else
                    object.totalActions = options.longs === String ? $util.Long.prototype.toString.call(message.totalActions) : options.longs === Number ? new $util.LongBits(message.totalActions.low >>> 0, message.totalActions.high >>> 0).toNumber() : message.totalActions;
            if (message.lastGreenBuildUsec != null && message.hasOwnProperty("lastGreenBuildUsec"))
                if (typeof message.lastGreenBuildUsec === "number")
                    object.lastGreenBuildUsec = options.longs === String ? String(message.lastGreenBuildUsec) : message.lastGreenBuildUsec;
                else
                    object.lastGreenBuildUsec = options.longs === String ? $util.Long.prototype.toString.call(message.lastGreenBuildUsec) : options.longs === Number ? new $util.LongBits(message.lastGreenBuildUsec.low >>> 0, message.lastGreenBuildUsec.high >>> 0).toNumber() : message.lastGreenBuildUsec;
            return object;
        };

        /**
         * Converts this InvocationStat to JSON.
         * @function toJSON
         * @memberof invocation.InvocationStat
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InvocationStat.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InvocationStat;
    })();

    invocation.GetInvocationStatRequest = (function() {

        /**
         * Properties of a GetInvocationStatRequest.
         * @memberof invocation
         * @interface IGetInvocationStatRequest
         * @property {context.IRequestContext|null} [requestContext] GetInvocationStatRequest requestContext
         * @property {invocation.AggType|null} [aggregationType] GetInvocationStatRequest aggregationType
         */

        /**
         * Constructs a new GetInvocationStatRequest.
         * @memberof invocation
         * @classdesc Represents a GetInvocationStatRequest.
         * @implements IGetInvocationStatRequest
         * @constructor
         * @param {invocation.IGetInvocationStatRequest=} [properties] Properties to set
         */
        function GetInvocationStatRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetInvocationStatRequest requestContext.
         * @member {context.IRequestContext|null|undefined} requestContext
         * @memberof invocation.GetInvocationStatRequest
         * @instance
         */
        GetInvocationStatRequest.prototype.requestContext = null;

        /**
         * GetInvocationStatRequest aggregationType.
         * @member {invocation.AggType} aggregationType
         * @memberof invocation.GetInvocationStatRequest
         * @instance
         */
        GetInvocationStatRequest.prototype.aggregationType = 0;

        /**
         * Creates a new GetInvocationStatRequest instance using the specified properties.
         * @function create
         * @memberof invocation.GetInvocationStatRequest
         * @static
         * @param {invocation.IGetInvocationStatRequest=} [properties] Properties to set
         * @returns {invocation.GetInvocationStatRequest} GetInvocationStatRequest instance
         */
        GetInvocationStatRequest.create = function create(properties) {
            return new GetInvocationStatRequest(properties);
        };

        /**
         * Encodes the specified GetInvocationStatRequest message. Does not implicitly {@link invocation.GetInvocationStatRequest.verify|verify} messages.
         * @function encode
         * @memberof invocation.GetInvocationStatRequest
         * @static
         * @param {invocation.IGetInvocationStatRequest} message GetInvocationStatRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInvocationStatRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.requestContext != null && message.hasOwnProperty("requestContext"))
                $root.context.RequestContext.encode(message.requestContext, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.aggregationType != null && message.hasOwnProperty("aggregationType"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.aggregationType);
            return writer;
        };

        /**
         * Encodes the specified GetInvocationStatRequest message, length delimited. Does not implicitly {@link invocation.GetInvocationStatRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invocation.GetInvocationStatRequest
         * @static
         * @param {invocation.IGetInvocationStatRequest} message GetInvocationStatRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInvocationStatRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetInvocationStatRequest message from the specified reader or buffer.
         * @function decode
         * @memberof invocation.GetInvocationStatRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invocation.GetInvocationStatRequest} GetInvocationStatRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInvocationStatRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invocation.GetInvocationStatRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.requestContext = $root.context.RequestContext.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.aggregationType = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetInvocationStatRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invocation.GetInvocationStatRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invocation.GetInvocationStatRequest} GetInvocationStatRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInvocationStatRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetInvocationStatRequest message.
         * @function verify
         * @memberof invocation.GetInvocationStatRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetInvocationStatRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.requestContext != null && message.hasOwnProperty("requestContext")) {
                let error = $root.context.RequestContext.verify(message.requestContext);
                if (error)
                    return "requestContext." + error;
            }
            if (message.aggregationType != null && message.hasOwnProperty("aggregationType"))
                switch (message.aggregationType) {
                default:
                    return "aggregationType: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            return null;
        };

        /**
         * Creates a GetInvocationStatRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invocation.GetInvocationStatRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invocation.GetInvocationStatRequest} GetInvocationStatRequest
         */
        GetInvocationStatRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.invocation.GetInvocationStatRequest)
                return object;
            let message = new $root.invocation.GetInvocationStatRequest();
            if (object.requestContext != null) {
                if (typeof object.requestContext !== "object")
                    throw TypeError(".invocation.GetInvocationStatRequest.requestContext: object expected");
                message.requestContext = $root.context.RequestContext.fromObject(object.requestContext);
            }
            switch (object.aggregationType) {
            case "UNKNOWN_AGGREGATION_TYPE":
            case 0:
                message.aggregationType = 0;
                break;
            case "USER_AGGREGATION_TYPE":
            case 1:
                message.aggregationType = 1;
                break;
            case "HOSTNAME_AGGREGATION_TYPE":
            case 2:
                message.aggregationType = 2;
                break;
            }
            return message;
        };

        /**
         * Creates a plain object from a GetInvocationStatRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invocation.GetInvocationStatRequest
         * @static
         * @param {invocation.GetInvocationStatRequest} message GetInvocationStatRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetInvocationStatRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.requestContext = null;
                object.aggregationType = options.enums === String ? "UNKNOWN_AGGREGATION_TYPE" : 0;
            }
            if (message.requestContext != null && message.hasOwnProperty("requestContext"))
                object.requestContext = $root.context.RequestContext.toObject(message.requestContext, options);
            if (message.aggregationType != null && message.hasOwnProperty("aggregationType"))
                object.aggregationType = options.enums === String ? $root.invocation.AggType[message.aggregationType] : message.aggregationType;
            return object;
        };

        /**
         * Converts this GetInvocationStatRequest to JSON.
         * @function toJSON
         * @memberof invocation.GetInvocationStatRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetInvocationStatRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetInvocationStatRequest;
    })();

    invocation.GetInvocationStatResponse = (function() {

        /**
         * Properties of a GetInvocationStatResponse.
         * @memberof invocation
         * @interface IGetInvocationStatResponse
         * @property {context.IResponseContext|null} [responseContext] GetInvocationStatResponse responseContext
         * @property {Array.<invocation.IInvocationStat>|null} [invocationStat] GetInvocationStatResponse invocationStat
         */

        /**
         * Constructs a new GetInvocationStatResponse.
         * @memberof invocation
         * @classdesc Represents a GetInvocationStatResponse.
         * @implements IGetInvocationStatResponse
         * @constructor
         * @param {invocation.IGetInvocationStatResponse=} [properties] Properties to set
         */
        function GetInvocationStatResponse(properties) {
            this.invocationStat = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetInvocationStatResponse responseContext.
         * @member {context.IResponseContext|null|undefined} responseContext
         * @memberof invocation.GetInvocationStatResponse
         * @instance
         */
        GetInvocationStatResponse.prototype.responseContext = null;

        /**
         * GetInvocationStatResponse invocationStat.
         * @member {Array.<invocation.IInvocationStat>} invocationStat
         * @memberof invocation.GetInvocationStatResponse
         * @instance
         */
        GetInvocationStatResponse.prototype.invocationStat = $util.emptyArray;

        /**
         * Creates a new GetInvocationStatResponse instance using the specified properties.
         * @function create
         * @memberof invocation.GetInvocationStatResponse
         * @static
         * @param {invocation.IGetInvocationStatResponse=} [properties] Properties to set
         * @returns {invocation.GetInvocationStatResponse} GetInvocationStatResponse instance
         */
        GetInvocationStatResponse.create = function create(properties) {
            return new GetInvocationStatResponse(properties);
        };

        /**
         * Encodes the specified GetInvocationStatResponse message. Does not implicitly {@link invocation.GetInvocationStatResponse.verify|verify} messages.
         * @function encode
         * @memberof invocation.GetInvocationStatResponse
         * @static
         * @param {invocation.IGetInvocationStatResponse} message GetInvocationStatResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInvocationStatResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.responseContext != null && message.hasOwnProperty("responseContext"))
                $root.context.ResponseContext.encode(message.responseContext, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.invocationStat != null && message.invocationStat.length)
                for (let i = 0; i < message.invocationStat.length; ++i)
                    $root.invocation.InvocationStat.encode(message.invocationStat[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetInvocationStatResponse message, length delimited. Does not implicitly {@link invocation.GetInvocationStatResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof invocation.GetInvocationStatResponse
         * @static
         * @param {invocation.IGetInvocationStatResponse} message GetInvocationStatResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetInvocationStatResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetInvocationStatResponse message from the specified reader or buffer.
         * @function decode
         * @memberof invocation.GetInvocationStatResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {invocation.GetInvocationStatResponse} GetInvocationStatResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInvocationStatResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.invocation.GetInvocationStatResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.responseContext = $root.context.ResponseContext.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.invocationStat && message.invocationStat.length))
                        message.invocationStat = [];
                    message.invocationStat.push($root.invocation.InvocationStat.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetInvocationStatResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof invocation.GetInvocationStatResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {invocation.GetInvocationStatResponse} GetInvocationStatResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetInvocationStatResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetInvocationStatResponse message.
         * @function verify
         * @memberof invocation.GetInvocationStatResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetInvocationStatResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.responseContext != null && message.hasOwnProperty("responseContext")) {
                let error = $root.context.ResponseContext.verify(message.responseContext);
                if (error)
                    return "responseContext." + error;
            }
            if (message.invocationStat != null && message.hasOwnProperty("invocationStat")) {
                if (!Array.isArray(message.invocationStat))
                    return "invocationStat: array expected";
                for (let i = 0; i < message.invocationStat.length; ++i) {
                    let error = $root.invocation.InvocationStat.verify(message.invocationStat[i]);
                    if (error)
                        return "invocationStat." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetInvocationStatResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof invocation.GetInvocationStatResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {invocation.GetInvocationStatResponse} GetInvocationStatResponse
         */
        GetInvocationStatResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.invocation.GetInvocationStatResponse)
                return object;
            let message = new $root.invocation.GetInvocationStatResponse();
            if (object.responseContext != null) {
                if (typeof object.responseContext !== "object")
                    throw TypeError(".invocation.GetInvocationStatResponse.responseContext: object expected");
                message.responseContext = $root.context.ResponseContext.fromObject(object.responseContext);
            }
            if (object.invocationStat) {
                if (!Array.isArray(object.invocationStat))
                    throw TypeError(".invocation.GetInvocationStatResponse.invocationStat: array expected");
                message.invocationStat = [];
                for (let i = 0; i < object.invocationStat.length; ++i) {
                    if (typeof object.invocationStat[i] !== "object")
                        throw TypeError(".invocation.GetInvocationStatResponse.invocationStat: object expected");
                    message.invocationStat[i] = $root.invocation.InvocationStat.fromObject(object.invocationStat[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetInvocationStatResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof invocation.GetInvocationStatResponse
         * @static
         * @param {invocation.GetInvocationStatResponse} message GetInvocationStatResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetInvocationStatResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.invocationStat = [];
            if (options.defaults)
                object.responseContext = null;
            if (message.responseContext != null && message.hasOwnProperty("responseContext"))
                object.responseContext = $root.context.ResponseContext.toObject(message.responseContext, options);
            if (message.invocationStat && message.invocationStat.length) {
                object.invocationStat = [];
                for (let j = 0; j < message.invocationStat.length; ++j)
                    object.invocationStat[j] = $root.invocation.InvocationStat.toObject(message.invocationStat[j], options);
            }
            return object;
        };

        /**
         * Converts this GetInvocationStatResponse to JSON.
         * @function toJSON
         * @memberof invocation.GetInvocationStatResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetInvocationStatResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetInvocationStatResponse;
    })();

    return invocation;
})();

export const google = $root.google = (() => {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    const google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        const protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {Long|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {Long} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.seconds = reader.int64();
                        break;
                    case 2:
                        message.nanos = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                let message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    return google;
})();

export const build_event_stream = $root.build_event_stream = (() => {

    /**
     * Namespace build_event_stream.
     * @exports build_event_stream
     * @namespace
     */
    const build_event_stream = {};

    build_event_stream.BuildEventId = (function() {

        /**
         * Properties of a BuildEventId.
         * @memberof build_event_stream
         * @interface IBuildEventId
         * @property {build_event_stream.BuildEventId.IUnknownBuildEventId|null} [unknown] BuildEventId unknown
         * @property {build_event_stream.BuildEventId.IProgressId|null} [progress] BuildEventId progress
         * @property {build_event_stream.BuildEventId.IBuildStartedId|null} [started] BuildEventId started
         * @property {build_event_stream.BuildEventId.IUnstructuredCommandLineId|null} [unstructuredCommandLine] BuildEventId unstructuredCommandLine
         * @property {build_event_stream.BuildEventId.IStructuredCommandLineId|null} [structuredCommandLine] BuildEventId structuredCommandLine
         * @property {build_event_stream.BuildEventId.IWorkspaceStatusId|null} [workspaceStatus] BuildEventId workspaceStatus
         * @property {build_event_stream.BuildEventId.IOptionsParsedId|null} [optionsParsed] BuildEventId optionsParsed
         * @property {build_event_stream.BuildEventId.IFetchId|null} [fetch] BuildEventId fetch
         * @property {build_event_stream.BuildEventId.IConfigurationId|null} [configuration] BuildEventId configuration
         * @property {build_event_stream.BuildEventId.ITargetConfiguredId|null} [targetConfigured] BuildEventId targetConfigured
         * @property {build_event_stream.BuildEventId.IPatternExpandedId|null} [pattern] BuildEventId pattern
         * @property {build_event_stream.BuildEventId.IPatternExpandedId|null} [patternSkipped] BuildEventId patternSkipped
         * @property {build_event_stream.BuildEventId.INamedSetOfFilesId|null} [namedSet] BuildEventId namedSet
         * @property {build_event_stream.BuildEventId.ITargetCompletedId|null} [targetCompleted] BuildEventId targetCompleted
         * @property {build_event_stream.BuildEventId.IActionCompletedId|null} [actionCompleted] BuildEventId actionCompleted
         * @property {build_event_stream.BuildEventId.IUnconfiguredLabelId|null} [unconfiguredLabel] BuildEventId unconfiguredLabel
         * @property {build_event_stream.BuildEventId.IConfiguredLabelId|null} [configuredLabel] BuildEventId configuredLabel
         * @property {build_event_stream.BuildEventId.ITestResultId|null} [testResult] BuildEventId testResult
         * @property {build_event_stream.BuildEventId.ITestSummaryId|null} [testSummary] BuildEventId testSummary
         * @property {build_event_stream.BuildEventId.IBuildFinishedId|null} [buildFinished] BuildEventId buildFinished
         * @property {build_event_stream.BuildEventId.IBuildToolLogsId|null} [buildToolLogs] BuildEventId buildToolLogs
         * @property {build_event_stream.BuildEventId.IBuildMetricsId|null} [buildMetrics] BuildEventId buildMetrics
         * @property {build_event_stream.BuildEventId.IWorkspaceConfigId|null} [workspace] BuildEventId workspace
         * @property {build_event_stream.BuildEventId.IBuildMetadataId|null} [buildMetadata] BuildEventId buildMetadata
         * @property {build_event_stream.BuildEventId.IConvenienceSymlinksIdentifiedId|null} [convenienceSymlinksIdentified] BuildEventId convenienceSymlinksIdentified
         */

        /**
         * Constructs a new BuildEventId.
         * @memberof build_event_stream
         * @classdesc Represents a BuildEventId.
         * @implements IBuildEventId
         * @constructor
         * @param {build_event_stream.IBuildEventId=} [properties] Properties to set
         */
        function BuildEventId(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BuildEventId unknown.
         * @member {build_event_stream.BuildEventId.IUnknownBuildEventId|null|undefined} unknown
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.unknown = null;

        /**
         * BuildEventId progress.
         * @member {build_event_stream.BuildEventId.IProgressId|null|undefined} progress
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.progress = null;

        /**
         * BuildEventId started.
         * @member {build_event_stream.BuildEventId.IBuildStartedId|null|undefined} started
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.started = null;

        /**
         * BuildEventId unstructuredCommandLine.
         * @member {build_event_stream.BuildEventId.IUnstructuredCommandLineId|null|undefined} unstructuredCommandLine
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.unstructuredCommandLine = null;

        /**
         * BuildEventId structuredCommandLine.
         * @member {build_event_stream.BuildEventId.IStructuredCommandLineId|null|undefined} structuredCommandLine
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.structuredCommandLine = null;

        /**
         * BuildEventId workspaceStatus.
         * @member {build_event_stream.BuildEventId.IWorkspaceStatusId|null|undefined} workspaceStatus
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.workspaceStatus = null;

        /**
         * BuildEventId optionsParsed.
         * @member {build_event_stream.BuildEventId.IOptionsParsedId|null|undefined} optionsParsed
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.optionsParsed = null;

        /**
         * BuildEventId fetch.
         * @member {build_event_stream.BuildEventId.IFetchId|null|undefined} fetch
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.fetch = null;

        /**
         * BuildEventId configuration.
         * @member {build_event_stream.BuildEventId.IConfigurationId|null|undefined} configuration
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.configuration = null;

        /**
         * BuildEventId targetConfigured.
         * @member {build_event_stream.BuildEventId.ITargetConfiguredId|null|undefined} targetConfigured
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.targetConfigured = null;

        /**
         * BuildEventId pattern.
         * @member {build_event_stream.BuildEventId.IPatternExpandedId|null|undefined} pattern
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.pattern = null;

        /**
         * BuildEventId patternSkipped.
         * @member {build_event_stream.BuildEventId.IPatternExpandedId|null|undefined} patternSkipped
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.patternSkipped = null;

        /**
         * BuildEventId namedSet.
         * @member {build_event_stream.BuildEventId.INamedSetOfFilesId|null|undefined} namedSet
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.namedSet = null;

        /**
         * BuildEventId targetCompleted.
         * @member {build_event_stream.BuildEventId.ITargetCompletedId|null|undefined} targetCompleted
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.targetCompleted = null;

        /**
         * BuildEventId actionCompleted.
         * @member {build_event_stream.BuildEventId.IActionCompletedId|null|undefined} actionCompleted
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.actionCompleted = null;

        /**
         * BuildEventId unconfiguredLabel.
         * @member {build_event_stream.BuildEventId.IUnconfiguredLabelId|null|undefined} unconfiguredLabel
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.unconfiguredLabel = null;

        /**
         * BuildEventId configuredLabel.
         * @member {build_event_stream.BuildEventId.IConfiguredLabelId|null|undefined} configuredLabel
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.configuredLabel = null;

        /**
         * BuildEventId testResult.
         * @member {build_event_stream.BuildEventId.ITestResultId|null|undefined} testResult
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.testResult = null;

        /**
         * BuildEventId testSummary.
         * @member {build_event_stream.BuildEventId.ITestSummaryId|null|undefined} testSummary
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.testSummary = null;

        /**
         * BuildEventId buildFinished.
         * @member {build_event_stream.BuildEventId.IBuildFinishedId|null|undefined} buildFinished
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.buildFinished = null;

        /**
         * BuildEventId buildToolLogs.
         * @member {build_event_stream.BuildEventId.IBuildToolLogsId|null|undefined} buildToolLogs
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.buildToolLogs = null;

        /**
         * BuildEventId buildMetrics.
         * @member {build_event_stream.BuildEventId.IBuildMetricsId|null|undefined} buildMetrics
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.buildMetrics = null;

        /**
         * BuildEventId workspace.
         * @member {build_event_stream.BuildEventId.IWorkspaceConfigId|null|undefined} workspace
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.workspace = null;

        /**
         * BuildEventId buildMetadata.
         * @member {build_event_stream.BuildEventId.IBuildMetadataId|null|undefined} buildMetadata
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.buildMetadata = null;

        /**
         * BuildEventId convenienceSymlinksIdentified.
         * @member {build_event_stream.BuildEventId.IConvenienceSymlinksIdentifiedId|null|undefined} convenienceSymlinksIdentified
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        BuildEventId.prototype.convenienceSymlinksIdentified = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * BuildEventId id.
         * @member {"unknown"|"progress"|"started"|"unstructuredCommandLine"|"structuredCommandLine"|"workspaceStatus"|"optionsParsed"|"fetch"|"configuration"|"targetConfigured"|"pattern"|"patternSkipped"|"namedSet"|"targetCompleted"|"actionCompleted"|"unconfiguredLabel"|"configuredLabel"|"testResult"|"testSummary"|"buildFinished"|"buildToolLogs"|"buildMetrics"|"workspace"|"buildMetadata"|"convenienceSymlinksIdentified"|undefined} id
         * @memberof build_event_stream.BuildEventId
         * @instance
         */
        Object.defineProperty(BuildEventId.prototype, "id", {
            get: $util.oneOfGetter($oneOfFields = ["unknown", "progress", "started", "unstructuredCommandLine", "structuredCommandLine", "workspaceStatus", "optionsParsed", "fetch", "configuration", "targetConfigured", "pattern", "patternSkipped", "namedSet", "targetCompleted", "actionCompleted", "unconfiguredLabel", "configuredLabel", "testResult", "testSummary", "buildFinished", "buildToolLogs", "buildMetrics", "workspace", "buildMetadata", "convenienceSymlinksIdentified"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new BuildEventId instance using the specified properties.
         * @function create
         * @memberof build_event_stream.BuildEventId
         * @static
         * @param {build_event_stream.IBuildEventId=} [properties] Properties to set
         * @returns {build_event_stream.BuildEventId} BuildEventId instance
         */
        BuildEventId.create = function create(properties) {
            return new BuildEventId(properties);
        };

        /**
         * Encodes the specified BuildEventId message. Does not implicitly {@link build_event_stream.BuildEventId.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.BuildEventId
         * @static
         * @param {build_event_stream.IBuildEventId} message BuildEventId message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildEventId.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.unknown != null && message.hasOwnProperty("unknown"))
                $root.build_event_stream.BuildEventId.UnknownBuildEventId.encode(message.unknown, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.progress != null && message.hasOwnProperty("progress"))
                $root.build_event_stream.BuildEventId.ProgressId.encode(message.progress, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.started != null && message.hasOwnProperty("started"))
                $root.build_event_stream.BuildEventId.BuildStartedId.encode(message.started, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.pattern != null && message.hasOwnProperty("pattern"))
                $root.build_event_stream.BuildEventId.PatternExpandedId.encode(message.pattern, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.targetCompleted != null && message.hasOwnProperty("targetCompleted"))
                $root.build_event_stream.BuildEventId.TargetCompletedId.encode(message.targetCompleted, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.actionCompleted != null && message.hasOwnProperty("actionCompleted"))
                $root.build_event_stream.BuildEventId.ActionCompletedId.encode(message.actionCompleted, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.testSummary != null && message.hasOwnProperty("testSummary"))
                $root.build_event_stream.BuildEventId.TestSummaryId.encode(message.testSummary, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.testResult != null && message.hasOwnProperty("testResult"))
                $root.build_event_stream.BuildEventId.TestResultId.encode(message.testResult, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.buildFinished != null && message.hasOwnProperty("buildFinished"))
                $root.build_event_stream.BuildEventId.BuildFinishedId.encode(message.buildFinished, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.patternSkipped != null && message.hasOwnProperty("patternSkipped"))
                $root.build_event_stream.BuildEventId.PatternExpandedId.encode(message.patternSkipped, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.unstructuredCommandLine != null && message.hasOwnProperty("unstructuredCommandLine"))
                $root.build_event_stream.BuildEventId.UnstructuredCommandLineId.encode(message.unstructuredCommandLine, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
            if (message.optionsParsed != null && message.hasOwnProperty("optionsParsed"))
                $root.build_event_stream.BuildEventId.OptionsParsedId.encode(message.optionsParsed, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.namedSet != null && message.hasOwnProperty("namedSet"))
                $root.build_event_stream.BuildEventId.NamedSetOfFilesId.encode(message.namedSet, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.workspaceStatus != null && message.hasOwnProperty("workspaceStatus"))
                $root.build_event_stream.BuildEventId.WorkspaceStatusId.encode(message.workspaceStatus, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.configuration != null && message.hasOwnProperty("configuration"))
                $root.build_event_stream.BuildEventId.ConfigurationId.encode(message.configuration, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.targetConfigured != null && message.hasOwnProperty("targetConfigured"))
                $root.build_event_stream.BuildEventId.TargetConfiguredId.encode(message.targetConfigured, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                $root.build_event_stream.BuildEventId.FetchId.encode(message.fetch, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.structuredCommandLine != null && message.hasOwnProperty("structuredCommandLine"))
                $root.build_event_stream.BuildEventId.StructuredCommandLineId.encode(message.structuredCommandLine, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.unconfiguredLabel != null && message.hasOwnProperty("unconfiguredLabel"))
                $root.build_event_stream.BuildEventId.UnconfiguredLabelId.encode(message.unconfiguredLabel, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
            if (message.buildToolLogs != null && message.hasOwnProperty("buildToolLogs"))
                $root.build_event_stream.BuildEventId.BuildToolLogsId.encode(message.buildToolLogs, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
            if (message.configuredLabel != null && message.hasOwnProperty("configuredLabel"))
                $root.build_event_stream.BuildEventId.ConfiguredLabelId.encode(message.configuredLabel, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.buildMetrics != null && message.hasOwnProperty("buildMetrics"))
                $root.build_event_stream.BuildEventId.BuildMetricsId.encode(message.buildMetrics, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.workspace != null && message.hasOwnProperty("workspace"))
                $root.build_event_stream.BuildEventId.WorkspaceConfigId.encode(message.workspace, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.buildMetadata != null && message.hasOwnProperty("buildMetadata"))
                $root.build_event_stream.BuildEventId.BuildMetadataId.encode(message.buildMetadata, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.convenienceSymlinksIdentified != null && message.hasOwnProperty("convenienceSymlinksIdentified"))
                $root.build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId.encode(message.convenienceSymlinksIdentified, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified BuildEventId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.BuildEventId
         * @static
         * @param {build_event_stream.IBuildEventId} message BuildEventId message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildEventId.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BuildEventId message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.BuildEventId
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.BuildEventId} BuildEventId
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildEventId.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.unknown = $root.build_event_stream.BuildEventId.UnknownBuildEventId.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.progress = $root.build_event_stream.BuildEventId.ProgressId.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.started = $root.build_event_stream.BuildEventId.BuildStartedId.decode(reader, reader.uint32());
                    break;
                case 11:
                    message.unstructuredCommandLine = $root.build_event_stream.BuildEventId.UnstructuredCommandLineId.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.structuredCommandLine = $root.build_event_stream.BuildEventId.StructuredCommandLineId.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.workspaceStatus = $root.build_event_stream.BuildEventId.WorkspaceStatusId.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.optionsParsed = $root.build_event_stream.BuildEventId.OptionsParsedId.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.fetch = $root.build_event_stream.BuildEventId.FetchId.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.targetConfigured = $root.build_event_stream.BuildEventId.TargetConfiguredId.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.pattern = $root.build_event_stream.BuildEventId.PatternExpandedId.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.patternSkipped = $root.build_event_stream.BuildEventId.PatternExpandedId.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.namedSet = $root.build_event_stream.BuildEventId.NamedSetOfFilesId.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.targetCompleted = $root.build_event_stream.BuildEventId.TargetCompletedId.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.actionCompleted = $root.build_event_stream.BuildEventId.ActionCompletedId.decode(reader, reader.uint32());
                    break;
                case 19:
                    message.unconfiguredLabel = $root.build_event_stream.BuildEventId.UnconfiguredLabelId.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.configuredLabel = $root.build_event_stream.BuildEventId.ConfiguredLabelId.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.testResult = $root.build_event_stream.BuildEventId.TestResultId.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.testSummary = $root.build_event_stream.BuildEventId.TestSummaryId.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.buildFinished = $root.build_event_stream.BuildEventId.BuildFinishedId.decode(reader, reader.uint32());
                    break;
                case 20:
                    message.buildToolLogs = $root.build_event_stream.BuildEventId.BuildToolLogsId.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.buildMetrics = $root.build_event_stream.BuildEventId.BuildMetricsId.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.workspace = $root.build_event_stream.BuildEventId.WorkspaceConfigId.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.buildMetadata = $root.build_event_stream.BuildEventId.BuildMetadataId.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.convenienceSymlinksIdentified = $root.build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BuildEventId message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.BuildEventId
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.BuildEventId} BuildEventId
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildEventId.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BuildEventId message.
         * @function verify
         * @memberof build_event_stream.BuildEventId
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BuildEventId.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.unknown != null && message.hasOwnProperty("unknown")) {
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.UnknownBuildEventId.verify(message.unknown);
                    if (error)
                        return "unknown." + error;
                }
            }
            if (message.progress != null && message.hasOwnProperty("progress")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.ProgressId.verify(message.progress);
                    if (error)
                        return "progress." + error;
                }
            }
            if (message.started != null && message.hasOwnProperty("started")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.BuildStartedId.verify(message.started);
                    if (error)
                        return "started." + error;
                }
            }
            if (message.unstructuredCommandLine != null && message.hasOwnProperty("unstructuredCommandLine")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.UnstructuredCommandLineId.verify(message.unstructuredCommandLine);
                    if (error)
                        return "unstructuredCommandLine." + error;
                }
            }
            if (message.structuredCommandLine != null && message.hasOwnProperty("structuredCommandLine")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.StructuredCommandLineId.verify(message.structuredCommandLine);
                    if (error)
                        return "structuredCommandLine." + error;
                }
            }
            if (message.workspaceStatus != null && message.hasOwnProperty("workspaceStatus")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.WorkspaceStatusId.verify(message.workspaceStatus);
                    if (error)
                        return "workspaceStatus." + error;
                }
            }
            if (message.optionsParsed != null && message.hasOwnProperty("optionsParsed")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.OptionsParsedId.verify(message.optionsParsed);
                    if (error)
                        return "optionsParsed." + error;
                }
            }
            if (message.fetch != null && message.hasOwnProperty("fetch")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.FetchId.verify(message.fetch);
                    if (error)
                        return "fetch." + error;
                }
            }
            if (message.configuration != null && message.hasOwnProperty("configuration")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.ConfigurationId.verify(message.configuration);
                    if (error)
                        return "configuration." + error;
                }
            }
            if (message.targetConfigured != null && message.hasOwnProperty("targetConfigured")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.TargetConfiguredId.verify(message.targetConfigured);
                    if (error)
                        return "targetConfigured." + error;
                }
            }
            if (message.pattern != null && message.hasOwnProperty("pattern")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.PatternExpandedId.verify(message.pattern);
                    if (error)
                        return "pattern." + error;
                }
            }
            if (message.patternSkipped != null && message.hasOwnProperty("patternSkipped")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.PatternExpandedId.verify(message.patternSkipped);
                    if (error)
                        return "patternSkipped." + error;
                }
            }
            if (message.namedSet != null && message.hasOwnProperty("namedSet")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.NamedSetOfFilesId.verify(message.namedSet);
                    if (error)
                        return "namedSet." + error;
                }
            }
            if (message.targetCompleted != null && message.hasOwnProperty("targetCompleted")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.TargetCompletedId.verify(message.targetCompleted);
                    if (error)
                        return "targetCompleted." + error;
                }
            }
            if (message.actionCompleted != null && message.hasOwnProperty("actionCompleted")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.ActionCompletedId.verify(message.actionCompleted);
                    if (error)
                        return "actionCompleted." + error;
                }
            }
            if (message.unconfiguredLabel != null && message.hasOwnProperty("unconfiguredLabel")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.UnconfiguredLabelId.verify(message.unconfiguredLabel);
                    if (error)
                        return "unconfiguredLabel." + error;
                }
            }
            if (message.configuredLabel != null && message.hasOwnProperty("configuredLabel")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.ConfiguredLabelId.verify(message.configuredLabel);
                    if (error)
                        return "configuredLabel." + error;
                }
            }
            if (message.testResult != null && message.hasOwnProperty("testResult")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.TestResultId.verify(message.testResult);
                    if (error)
                        return "testResult." + error;
                }
            }
            if (message.testSummary != null && message.hasOwnProperty("testSummary")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.TestSummaryId.verify(message.testSummary);
                    if (error)
                        return "testSummary." + error;
                }
            }
            if (message.buildFinished != null && message.hasOwnProperty("buildFinished")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.BuildFinishedId.verify(message.buildFinished);
                    if (error)
                        return "buildFinished." + error;
                }
            }
            if (message.buildToolLogs != null && message.hasOwnProperty("buildToolLogs")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.BuildToolLogsId.verify(message.buildToolLogs);
                    if (error)
                        return "buildToolLogs." + error;
                }
            }
            if (message.buildMetrics != null && message.hasOwnProperty("buildMetrics")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.BuildMetricsId.verify(message.buildMetrics);
                    if (error)
                        return "buildMetrics." + error;
                }
            }
            if (message.workspace != null && message.hasOwnProperty("workspace")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.WorkspaceConfigId.verify(message.workspace);
                    if (error)
                        return "workspace." + error;
                }
            }
            if (message.buildMetadata != null && message.hasOwnProperty("buildMetadata")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.BuildMetadataId.verify(message.buildMetadata);
                    if (error)
                        return "buildMetadata." + error;
                }
            }
            if (message.convenienceSymlinksIdentified != null && message.hasOwnProperty("convenienceSymlinksIdentified")) {
                if (properties.id === 1)
                    return "id: multiple values";
                properties.id = 1;
                {
                    let error = $root.build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId.verify(message.convenienceSymlinksIdentified);
                    if (error)
                        return "convenienceSymlinksIdentified." + error;
                }
            }
            return null;
        };

        /**
         * Creates a BuildEventId message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.BuildEventId
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.BuildEventId} BuildEventId
         */
        BuildEventId.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.BuildEventId)
                return object;
            let message = new $root.build_event_stream.BuildEventId();
            if (object.unknown != null) {
                if (typeof object.unknown !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.unknown: object expected");
                message.unknown = $root.build_event_stream.BuildEventId.UnknownBuildEventId.fromObject(object.unknown);
            }
            if (object.progress != null) {
                if (typeof object.progress !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.progress: object expected");
                message.progress = $root.build_event_stream.BuildEventId.ProgressId.fromObject(object.progress);
            }
            if (object.started != null) {
                if (typeof object.started !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.started: object expected");
                message.started = $root.build_event_stream.BuildEventId.BuildStartedId.fromObject(object.started);
            }
            if (object.unstructuredCommandLine != null) {
                if (typeof object.unstructuredCommandLine !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.unstructuredCommandLine: object expected");
                message.unstructuredCommandLine = $root.build_event_stream.BuildEventId.UnstructuredCommandLineId.fromObject(object.unstructuredCommandLine);
            }
            if (object.structuredCommandLine != null) {
                if (typeof object.structuredCommandLine !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.structuredCommandLine: object expected");
                message.structuredCommandLine = $root.build_event_stream.BuildEventId.StructuredCommandLineId.fromObject(object.structuredCommandLine);
            }
            if (object.workspaceStatus != null) {
                if (typeof object.workspaceStatus !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.workspaceStatus: object expected");
                message.workspaceStatus = $root.build_event_stream.BuildEventId.WorkspaceStatusId.fromObject(object.workspaceStatus);
            }
            if (object.optionsParsed != null) {
                if (typeof object.optionsParsed !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.optionsParsed: object expected");
                message.optionsParsed = $root.build_event_stream.BuildEventId.OptionsParsedId.fromObject(object.optionsParsed);
            }
            if (object.fetch != null) {
                if (typeof object.fetch !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.fetch: object expected");
                message.fetch = $root.build_event_stream.BuildEventId.FetchId.fromObject(object.fetch);
            }
            if (object.configuration != null) {
                if (typeof object.configuration !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.configuration: object expected");
                message.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.fromObject(object.configuration);
            }
            if (object.targetConfigured != null) {
                if (typeof object.targetConfigured !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.targetConfigured: object expected");
                message.targetConfigured = $root.build_event_stream.BuildEventId.TargetConfiguredId.fromObject(object.targetConfigured);
            }
            if (object.pattern != null) {
                if (typeof object.pattern !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.pattern: object expected");
                message.pattern = $root.build_event_stream.BuildEventId.PatternExpandedId.fromObject(object.pattern);
            }
            if (object.patternSkipped != null) {
                if (typeof object.patternSkipped !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.patternSkipped: object expected");
                message.patternSkipped = $root.build_event_stream.BuildEventId.PatternExpandedId.fromObject(object.patternSkipped);
            }
            if (object.namedSet != null) {
                if (typeof object.namedSet !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.namedSet: object expected");
                message.namedSet = $root.build_event_stream.BuildEventId.NamedSetOfFilesId.fromObject(object.namedSet);
            }
            if (object.targetCompleted != null) {
                if (typeof object.targetCompleted !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.targetCompleted: object expected");
                message.targetCompleted = $root.build_event_stream.BuildEventId.TargetCompletedId.fromObject(object.targetCompleted);
            }
            if (object.actionCompleted != null) {
                if (typeof object.actionCompleted !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.actionCompleted: object expected");
                message.actionCompleted = $root.build_event_stream.BuildEventId.ActionCompletedId.fromObject(object.actionCompleted);
            }
            if (object.unconfiguredLabel != null) {
                if (typeof object.unconfiguredLabel !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.unconfiguredLabel: object expected");
                message.unconfiguredLabel = $root.build_event_stream.BuildEventId.UnconfiguredLabelId.fromObject(object.unconfiguredLabel);
            }
            if (object.configuredLabel != null) {
                if (typeof object.configuredLabel !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.configuredLabel: object expected");
                message.configuredLabel = $root.build_event_stream.BuildEventId.ConfiguredLabelId.fromObject(object.configuredLabel);
            }
            if (object.testResult != null) {
                if (typeof object.testResult !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.testResult: object expected");
                message.testResult = $root.build_event_stream.BuildEventId.TestResultId.fromObject(object.testResult);
            }
            if (object.testSummary != null) {
                if (typeof object.testSummary !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.testSummary: object expected");
                message.testSummary = $root.build_event_stream.BuildEventId.TestSummaryId.fromObject(object.testSummary);
            }
            if (object.buildFinished != null) {
                if (typeof object.buildFinished !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.buildFinished: object expected");
                message.buildFinished = $root.build_event_stream.BuildEventId.BuildFinishedId.fromObject(object.buildFinished);
            }
            if (object.buildToolLogs != null) {
                if (typeof object.buildToolLogs !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.buildToolLogs: object expected");
                message.buildToolLogs = $root.build_event_stream.BuildEventId.BuildToolLogsId.fromObject(object.buildToolLogs);
            }
            if (object.buildMetrics != null) {
                if (typeof object.buildMetrics !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.buildMetrics: object expected");
                message.buildMetrics = $root.build_event_stream.BuildEventId.BuildMetricsId.fromObject(object.buildMetrics);
            }
            if (object.workspace != null) {
                if (typeof object.workspace !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.workspace: object expected");
                message.workspace = $root.build_event_stream.BuildEventId.WorkspaceConfigId.fromObject(object.workspace);
            }
            if (object.buildMetadata != null) {
                if (typeof object.buildMetadata !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.buildMetadata: object expected");
                message.buildMetadata = $root.build_event_stream.BuildEventId.BuildMetadataId.fromObject(object.buildMetadata);
            }
            if (object.convenienceSymlinksIdentified != null) {
                if (typeof object.convenienceSymlinksIdentified !== "object")
                    throw TypeError(".build_event_stream.BuildEventId.convenienceSymlinksIdentified: object expected");
                message.convenienceSymlinksIdentified = $root.build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId.fromObject(object.convenienceSymlinksIdentified);
            }
            return message;
        };

        /**
         * Creates a plain object from a BuildEventId message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.BuildEventId
         * @static
         * @param {build_event_stream.BuildEventId} message BuildEventId
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BuildEventId.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (message.unknown != null && message.hasOwnProperty("unknown")) {
                object.unknown = $root.build_event_stream.BuildEventId.UnknownBuildEventId.toObject(message.unknown, options);
                if (options.oneofs)
                    object.id = "unknown";
            }
            if (message.progress != null && message.hasOwnProperty("progress")) {
                object.progress = $root.build_event_stream.BuildEventId.ProgressId.toObject(message.progress, options);
                if (options.oneofs)
                    object.id = "progress";
            }
            if (message.started != null && message.hasOwnProperty("started")) {
                object.started = $root.build_event_stream.BuildEventId.BuildStartedId.toObject(message.started, options);
                if (options.oneofs)
                    object.id = "started";
            }
            if (message.pattern != null && message.hasOwnProperty("pattern")) {
                object.pattern = $root.build_event_stream.BuildEventId.PatternExpandedId.toObject(message.pattern, options);
                if (options.oneofs)
                    object.id = "pattern";
            }
            if (message.targetCompleted != null && message.hasOwnProperty("targetCompleted")) {
                object.targetCompleted = $root.build_event_stream.BuildEventId.TargetCompletedId.toObject(message.targetCompleted, options);
                if (options.oneofs)
                    object.id = "targetCompleted";
            }
            if (message.actionCompleted != null && message.hasOwnProperty("actionCompleted")) {
                object.actionCompleted = $root.build_event_stream.BuildEventId.ActionCompletedId.toObject(message.actionCompleted, options);
                if (options.oneofs)
                    object.id = "actionCompleted";
            }
            if (message.testSummary != null && message.hasOwnProperty("testSummary")) {
                object.testSummary = $root.build_event_stream.BuildEventId.TestSummaryId.toObject(message.testSummary, options);
                if (options.oneofs)
                    object.id = "testSummary";
            }
            if (message.testResult != null && message.hasOwnProperty("testResult")) {
                object.testResult = $root.build_event_stream.BuildEventId.TestResultId.toObject(message.testResult, options);
                if (options.oneofs)
                    object.id = "testResult";
            }
            if (message.buildFinished != null && message.hasOwnProperty("buildFinished")) {
                object.buildFinished = $root.build_event_stream.BuildEventId.BuildFinishedId.toObject(message.buildFinished, options);
                if (options.oneofs)
                    object.id = "buildFinished";
            }
            if (message.patternSkipped != null && message.hasOwnProperty("patternSkipped")) {
                object.patternSkipped = $root.build_event_stream.BuildEventId.PatternExpandedId.toObject(message.patternSkipped, options);
                if (options.oneofs)
                    object.id = "patternSkipped";
            }
            if (message.unstructuredCommandLine != null && message.hasOwnProperty("unstructuredCommandLine")) {
                object.unstructuredCommandLine = $root.build_event_stream.BuildEventId.UnstructuredCommandLineId.toObject(message.unstructuredCommandLine, options);
                if (options.oneofs)
                    object.id = "unstructuredCommandLine";
            }
            if (message.optionsParsed != null && message.hasOwnProperty("optionsParsed")) {
                object.optionsParsed = $root.build_event_stream.BuildEventId.OptionsParsedId.toObject(message.optionsParsed, options);
                if (options.oneofs)
                    object.id = "optionsParsed";
            }
            if (message.namedSet != null && message.hasOwnProperty("namedSet")) {
                object.namedSet = $root.build_event_stream.BuildEventId.NamedSetOfFilesId.toObject(message.namedSet, options);
                if (options.oneofs)
                    object.id = "namedSet";
            }
            if (message.workspaceStatus != null && message.hasOwnProperty("workspaceStatus")) {
                object.workspaceStatus = $root.build_event_stream.BuildEventId.WorkspaceStatusId.toObject(message.workspaceStatus, options);
                if (options.oneofs)
                    object.id = "workspaceStatus";
            }
            if (message.configuration != null && message.hasOwnProperty("configuration")) {
                object.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.toObject(message.configuration, options);
                if (options.oneofs)
                    object.id = "configuration";
            }
            if (message.targetConfigured != null && message.hasOwnProperty("targetConfigured")) {
                object.targetConfigured = $root.build_event_stream.BuildEventId.TargetConfiguredId.toObject(message.targetConfigured, options);
                if (options.oneofs)
                    object.id = "targetConfigured";
            }
            if (message.fetch != null && message.hasOwnProperty("fetch")) {
                object.fetch = $root.build_event_stream.BuildEventId.FetchId.toObject(message.fetch, options);
                if (options.oneofs)
                    object.id = "fetch";
            }
            if (message.structuredCommandLine != null && message.hasOwnProperty("structuredCommandLine")) {
                object.structuredCommandLine = $root.build_event_stream.BuildEventId.StructuredCommandLineId.toObject(message.structuredCommandLine, options);
                if (options.oneofs)
                    object.id = "structuredCommandLine";
            }
            if (message.unconfiguredLabel != null && message.hasOwnProperty("unconfiguredLabel")) {
                object.unconfiguredLabel = $root.build_event_stream.BuildEventId.UnconfiguredLabelId.toObject(message.unconfiguredLabel, options);
                if (options.oneofs)
                    object.id = "unconfiguredLabel";
            }
            if (message.buildToolLogs != null && message.hasOwnProperty("buildToolLogs")) {
                object.buildToolLogs = $root.build_event_stream.BuildEventId.BuildToolLogsId.toObject(message.buildToolLogs, options);
                if (options.oneofs)
                    object.id = "buildToolLogs";
            }
            if (message.configuredLabel != null && message.hasOwnProperty("configuredLabel")) {
                object.configuredLabel = $root.build_event_stream.BuildEventId.ConfiguredLabelId.toObject(message.configuredLabel, options);
                if (options.oneofs)
                    object.id = "configuredLabel";
            }
            if (message.buildMetrics != null && message.hasOwnProperty("buildMetrics")) {
                object.buildMetrics = $root.build_event_stream.BuildEventId.BuildMetricsId.toObject(message.buildMetrics, options);
                if (options.oneofs)
                    object.id = "buildMetrics";
            }
            if (message.workspace != null && message.hasOwnProperty("workspace")) {
                object.workspace = $root.build_event_stream.BuildEventId.WorkspaceConfigId.toObject(message.workspace, options);
                if (options.oneofs)
                    object.id = "workspace";
            }
            if (message.buildMetadata != null && message.hasOwnProperty("buildMetadata")) {
                object.buildMetadata = $root.build_event_stream.BuildEventId.BuildMetadataId.toObject(message.buildMetadata, options);
                if (options.oneofs)
                    object.id = "buildMetadata";
            }
            if (message.convenienceSymlinksIdentified != null && message.hasOwnProperty("convenienceSymlinksIdentified")) {
                object.convenienceSymlinksIdentified = $root.build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId.toObject(message.convenienceSymlinksIdentified, options);
                if (options.oneofs)
                    object.id = "convenienceSymlinksIdentified";
            }
            return object;
        };

        /**
         * Converts this BuildEventId to JSON.
         * @function toJSON
         * @memberof build_event_stream.BuildEventId
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BuildEventId.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        BuildEventId.UnknownBuildEventId = (function() {

            /**
             * Properties of an UnknownBuildEventId.
             * @memberof build_event_stream.BuildEventId
             * @interface IUnknownBuildEventId
             * @property {string|null} [details] UnknownBuildEventId details
             */

            /**
             * Constructs a new UnknownBuildEventId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents an UnknownBuildEventId.
             * @implements IUnknownBuildEventId
             * @constructor
             * @param {build_event_stream.BuildEventId.IUnknownBuildEventId=} [properties] Properties to set
             */
            function UnknownBuildEventId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UnknownBuildEventId details.
             * @member {string} details
             * @memberof build_event_stream.BuildEventId.UnknownBuildEventId
             * @instance
             */
            UnknownBuildEventId.prototype.details = "";

            /**
             * Creates a new UnknownBuildEventId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.UnknownBuildEventId
             * @static
             * @param {build_event_stream.BuildEventId.IUnknownBuildEventId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.UnknownBuildEventId} UnknownBuildEventId instance
             */
            UnknownBuildEventId.create = function create(properties) {
                return new UnknownBuildEventId(properties);
            };

            /**
             * Encodes the specified UnknownBuildEventId message. Does not implicitly {@link build_event_stream.BuildEventId.UnknownBuildEventId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.UnknownBuildEventId
             * @static
             * @param {build_event_stream.BuildEventId.IUnknownBuildEventId} message UnknownBuildEventId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnknownBuildEventId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.details != null && message.hasOwnProperty("details"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.details);
                return writer;
            };

            /**
             * Encodes the specified UnknownBuildEventId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.UnknownBuildEventId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.UnknownBuildEventId
             * @static
             * @param {build_event_stream.BuildEventId.IUnknownBuildEventId} message UnknownBuildEventId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnknownBuildEventId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UnknownBuildEventId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.UnknownBuildEventId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.UnknownBuildEventId} UnknownBuildEventId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnknownBuildEventId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.UnknownBuildEventId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.details = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UnknownBuildEventId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.UnknownBuildEventId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.UnknownBuildEventId} UnknownBuildEventId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnknownBuildEventId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UnknownBuildEventId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.UnknownBuildEventId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UnknownBuildEventId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.details != null && message.hasOwnProperty("details"))
                    if (!$util.isString(message.details))
                        return "details: string expected";
                return null;
            };

            /**
             * Creates an UnknownBuildEventId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.UnknownBuildEventId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.UnknownBuildEventId} UnknownBuildEventId
             */
            UnknownBuildEventId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.UnknownBuildEventId)
                    return object;
                let message = new $root.build_event_stream.BuildEventId.UnknownBuildEventId();
                if (object.details != null)
                    message.details = String(object.details);
                return message;
            };

            /**
             * Creates a plain object from an UnknownBuildEventId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.UnknownBuildEventId
             * @static
             * @param {build_event_stream.BuildEventId.UnknownBuildEventId} message UnknownBuildEventId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UnknownBuildEventId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.details = "";
                if (message.details != null && message.hasOwnProperty("details"))
                    object.details = message.details;
                return object;
            };

            /**
             * Converts this UnknownBuildEventId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.UnknownBuildEventId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UnknownBuildEventId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UnknownBuildEventId;
        })();

        BuildEventId.ProgressId = (function() {

            /**
             * Properties of a ProgressId.
             * @memberof build_event_stream.BuildEventId
             * @interface IProgressId
             * @property {number|null} [opaqueCount] ProgressId opaqueCount
             */

            /**
             * Constructs a new ProgressId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a ProgressId.
             * @implements IProgressId
             * @constructor
             * @param {build_event_stream.BuildEventId.IProgressId=} [properties] Properties to set
             */
            function ProgressId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ProgressId opaqueCount.
             * @member {number} opaqueCount
             * @memberof build_event_stream.BuildEventId.ProgressId
             * @instance
             */
            ProgressId.prototype.opaqueCount = 0;

            /**
             * Creates a new ProgressId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.ProgressId
             * @static
             * @param {build_event_stream.BuildEventId.IProgressId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.ProgressId} ProgressId instance
             */
            ProgressId.create = function create(properties) {
                return new ProgressId(properties);
            };

            /**
             * Encodes the specified ProgressId message. Does not implicitly {@link build_event_stream.BuildEventId.ProgressId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.ProgressId
             * @static
             * @param {build_event_stream.BuildEventId.IProgressId} message ProgressId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProgressId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.opaqueCount != null && message.hasOwnProperty("opaqueCount"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.opaqueCount);
                return writer;
            };

            /**
             * Encodes the specified ProgressId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.ProgressId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.ProgressId
             * @static
             * @param {build_event_stream.BuildEventId.IProgressId} message ProgressId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ProgressId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ProgressId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.ProgressId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.ProgressId} ProgressId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProgressId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.ProgressId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.opaqueCount = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ProgressId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.ProgressId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.ProgressId} ProgressId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ProgressId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ProgressId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.ProgressId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ProgressId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.opaqueCount != null && message.hasOwnProperty("opaqueCount"))
                    if (!$util.isInteger(message.opaqueCount))
                        return "opaqueCount: integer expected";
                return null;
            };

            /**
             * Creates a ProgressId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.ProgressId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.ProgressId} ProgressId
             */
            ProgressId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.ProgressId)
                    return object;
                let message = new $root.build_event_stream.BuildEventId.ProgressId();
                if (object.opaqueCount != null)
                    message.opaqueCount = object.opaqueCount | 0;
                return message;
            };

            /**
             * Creates a plain object from a ProgressId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.ProgressId
             * @static
             * @param {build_event_stream.BuildEventId.ProgressId} message ProgressId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ProgressId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.opaqueCount = 0;
                if (message.opaqueCount != null && message.hasOwnProperty("opaqueCount"))
                    object.opaqueCount = message.opaqueCount;
                return object;
            };

            /**
             * Converts this ProgressId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.ProgressId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ProgressId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ProgressId;
        })();

        BuildEventId.BuildStartedId = (function() {

            /**
             * Properties of a BuildStartedId.
             * @memberof build_event_stream.BuildEventId
             * @interface IBuildStartedId
             */

            /**
             * Constructs a new BuildStartedId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a BuildStartedId.
             * @implements IBuildStartedId
             * @constructor
             * @param {build_event_stream.BuildEventId.IBuildStartedId=} [properties] Properties to set
             */
            function BuildStartedId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new BuildStartedId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.BuildStartedId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildStartedId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.BuildStartedId} BuildStartedId instance
             */
            BuildStartedId.create = function create(properties) {
                return new BuildStartedId(properties);
            };

            /**
             * Encodes the specified BuildStartedId message. Does not implicitly {@link build_event_stream.BuildEventId.BuildStartedId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.BuildStartedId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildStartedId} message BuildStartedId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuildStartedId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified BuildStartedId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.BuildStartedId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.BuildStartedId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildStartedId} message BuildStartedId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuildStartedId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuildStartedId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.BuildStartedId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.BuildStartedId} BuildStartedId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuildStartedId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.BuildStartedId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuildStartedId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.BuildStartedId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.BuildStartedId} BuildStartedId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuildStartedId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuildStartedId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.BuildStartedId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuildStartedId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a BuildStartedId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.BuildStartedId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.BuildStartedId} BuildStartedId
             */
            BuildStartedId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.BuildStartedId)
                    return object;
                return new $root.build_event_stream.BuildEventId.BuildStartedId();
            };

            /**
             * Creates a plain object from a BuildStartedId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.BuildStartedId
             * @static
             * @param {build_event_stream.BuildEventId.BuildStartedId} message BuildStartedId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuildStartedId.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this BuildStartedId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.BuildStartedId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuildStartedId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return BuildStartedId;
        })();

        BuildEventId.UnstructuredCommandLineId = (function() {

            /**
             * Properties of an UnstructuredCommandLineId.
             * @memberof build_event_stream.BuildEventId
             * @interface IUnstructuredCommandLineId
             */

            /**
             * Constructs a new UnstructuredCommandLineId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents an UnstructuredCommandLineId.
             * @implements IUnstructuredCommandLineId
             * @constructor
             * @param {build_event_stream.BuildEventId.IUnstructuredCommandLineId=} [properties] Properties to set
             */
            function UnstructuredCommandLineId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UnstructuredCommandLineId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.UnstructuredCommandLineId
             * @static
             * @param {build_event_stream.BuildEventId.IUnstructuredCommandLineId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.UnstructuredCommandLineId} UnstructuredCommandLineId instance
             */
            UnstructuredCommandLineId.create = function create(properties) {
                return new UnstructuredCommandLineId(properties);
            };

            /**
             * Encodes the specified UnstructuredCommandLineId message. Does not implicitly {@link build_event_stream.BuildEventId.UnstructuredCommandLineId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.UnstructuredCommandLineId
             * @static
             * @param {build_event_stream.BuildEventId.IUnstructuredCommandLineId} message UnstructuredCommandLineId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnstructuredCommandLineId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UnstructuredCommandLineId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.UnstructuredCommandLineId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.UnstructuredCommandLineId
             * @static
             * @param {build_event_stream.BuildEventId.IUnstructuredCommandLineId} message UnstructuredCommandLineId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnstructuredCommandLineId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UnstructuredCommandLineId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.UnstructuredCommandLineId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.UnstructuredCommandLineId} UnstructuredCommandLineId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnstructuredCommandLineId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.UnstructuredCommandLineId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UnstructuredCommandLineId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.UnstructuredCommandLineId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.UnstructuredCommandLineId} UnstructuredCommandLineId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnstructuredCommandLineId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UnstructuredCommandLineId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.UnstructuredCommandLineId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UnstructuredCommandLineId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an UnstructuredCommandLineId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.UnstructuredCommandLineId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.UnstructuredCommandLineId} UnstructuredCommandLineId
             */
            UnstructuredCommandLineId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.UnstructuredCommandLineId)
                    return object;
                return new $root.build_event_stream.BuildEventId.UnstructuredCommandLineId();
            };

            /**
             * Creates a plain object from an UnstructuredCommandLineId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.UnstructuredCommandLineId
             * @static
             * @param {build_event_stream.BuildEventId.UnstructuredCommandLineId} message UnstructuredCommandLineId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UnstructuredCommandLineId.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UnstructuredCommandLineId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.UnstructuredCommandLineId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UnstructuredCommandLineId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UnstructuredCommandLineId;
        })();

        BuildEventId.StructuredCommandLineId = (function() {

            /**
             * Properties of a StructuredCommandLineId.
             * @memberof build_event_stream.BuildEventId
             * @interface IStructuredCommandLineId
             * @property {string|null} [commandLineLabel] StructuredCommandLineId commandLineLabel
             */

            /**
             * Constructs a new StructuredCommandLineId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a StructuredCommandLineId.
             * @implements IStructuredCommandLineId
             * @constructor
             * @param {build_event_stream.BuildEventId.IStructuredCommandLineId=} [properties] Properties to set
             */
            function StructuredCommandLineId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StructuredCommandLineId commandLineLabel.
             * @member {string} commandLineLabel
             * @memberof build_event_stream.BuildEventId.StructuredCommandLineId
             * @instance
             */
            StructuredCommandLineId.prototype.commandLineLabel = "";

            /**
             * Creates a new StructuredCommandLineId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.StructuredCommandLineId
             * @static
             * @param {build_event_stream.BuildEventId.IStructuredCommandLineId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.StructuredCommandLineId} StructuredCommandLineId instance
             */
            StructuredCommandLineId.create = function create(properties) {
                return new StructuredCommandLineId(properties);
            };

            /**
             * Encodes the specified StructuredCommandLineId message. Does not implicitly {@link build_event_stream.BuildEventId.StructuredCommandLineId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.StructuredCommandLineId
             * @static
             * @param {build_event_stream.BuildEventId.IStructuredCommandLineId} message StructuredCommandLineId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StructuredCommandLineId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.commandLineLabel != null && message.hasOwnProperty("commandLineLabel"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.commandLineLabel);
                return writer;
            };

            /**
             * Encodes the specified StructuredCommandLineId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.StructuredCommandLineId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.StructuredCommandLineId
             * @static
             * @param {build_event_stream.BuildEventId.IStructuredCommandLineId} message StructuredCommandLineId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StructuredCommandLineId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a StructuredCommandLineId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.StructuredCommandLineId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.StructuredCommandLineId} StructuredCommandLineId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StructuredCommandLineId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.StructuredCommandLineId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.commandLineLabel = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a StructuredCommandLineId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.StructuredCommandLineId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.StructuredCommandLineId} StructuredCommandLineId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StructuredCommandLineId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a StructuredCommandLineId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.StructuredCommandLineId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StructuredCommandLineId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.commandLineLabel != null && message.hasOwnProperty("commandLineLabel"))
                    if (!$util.isString(message.commandLineLabel))
                        return "commandLineLabel: string expected";
                return null;
            };

            /**
             * Creates a StructuredCommandLineId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.StructuredCommandLineId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.StructuredCommandLineId} StructuredCommandLineId
             */
            StructuredCommandLineId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.StructuredCommandLineId)
                    return object;
                let message = new $root.build_event_stream.BuildEventId.StructuredCommandLineId();
                if (object.commandLineLabel != null)
                    message.commandLineLabel = String(object.commandLineLabel);
                return message;
            };

            /**
             * Creates a plain object from a StructuredCommandLineId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.StructuredCommandLineId
             * @static
             * @param {build_event_stream.BuildEventId.StructuredCommandLineId} message StructuredCommandLineId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StructuredCommandLineId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.commandLineLabel = "";
                if (message.commandLineLabel != null && message.hasOwnProperty("commandLineLabel"))
                    object.commandLineLabel = message.commandLineLabel;
                return object;
            };

            /**
             * Converts this StructuredCommandLineId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.StructuredCommandLineId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StructuredCommandLineId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return StructuredCommandLineId;
        })();

        BuildEventId.WorkspaceStatusId = (function() {

            /**
             * Properties of a WorkspaceStatusId.
             * @memberof build_event_stream.BuildEventId
             * @interface IWorkspaceStatusId
             */

            /**
             * Constructs a new WorkspaceStatusId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a WorkspaceStatusId.
             * @implements IWorkspaceStatusId
             * @constructor
             * @param {build_event_stream.BuildEventId.IWorkspaceStatusId=} [properties] Properties to set
             */
            function WorkspaceStatusId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new WorkspaceStatusId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.WorkspaceStatusId
             * @static
             * @param {build_event_stream.BuildEventId.IWorkspaceStatusId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.WorkspaceStatusId} WorkspaceStatusId instance
             */
            WorkspaceStatusId.create = function create(properties) {
                return new WorkspaceStatusId(properties);
            };

            /**
             * Encodes the specified WorkspaceStatusId message. Does not implicitly {@link build_event_stream.BuildEventId.WorkspaceStatusId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.WorkspaceStatusId
             * @static
             * @param {build_event_stream.BuildEventId.IWorkspaceStatusId} message WorkspaceStatusId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkspaceStatusId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified WorkspaceStatusId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.WorkspaceStatusId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.WorkspaceStatusId
             * @static
             * @param {build_event_stream.BuildEventId.IWorkspaceStatusId} message WorkspaceStatusId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkspaceStatusId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkspaceStatusId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.WorkspaceStatusId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.WorkspaceStatusId} WorkspaceStatusId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkspaceStatusId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.WorkspaceStatusId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkspaceStatusId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.WorkspaceStatusId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.WorkspaceStatusId} WorkspaceStatusId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkspaceStatusId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkspaceStatusId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.WorkspaceStatusId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkspaceStatusId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a WorkspaceStatusId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.WorkspaceStatusId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.WorkspaceStatusId} WorkspaceStatusId
             */
            WorkspaceStatusId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.WorkspaceStatusId)
                    return object;
                return new $root.build_event_stream.BuildEventId.WorkspaceStatusId();
            };

            /**
             * Creates a plain object from a WorkspaceStatusId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.WorkspaceStatusId
             * @static
             * @param {build_event_stream.BuildEventId.WorkspaceStatusId} message WorkspaceStatusId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkspaceStatusId.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this WorkspaceStatusId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.WorkspaceStatusId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkspaceStatusId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return WorkspaceStatusId;
        })();

        BuildEventId.OptionsParsedId = (function() {

            /**
             * Properties of an OptionsParsedId.
             * @memberof build_event_stream.BuildEventId
             * @interface IOptionsParsedId
             */

            /**
             * Constructs a new OptionsParsedId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents an OptionsParsedId.
             * @implements IOptionsParsedId
             * @constructor
             * @param {build_event_stream.BuildEventId.IOptionsParsedId=} [properties] Properties to set
             */
            function OptionsParsedId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new OptionsParsedId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.OptionsParsedId
             * @static
             * @param {build_event_stream.BuildEventId.IOptionsParsedId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.OptionsParsedId} OptionsParsedId instance
             */
            OptionsParsedId.create = function create(properties) {
                return new OptionsParsedId(properties);
            };

            /**
             * Encodes the specified OptionsParsedId message. Does not implicitly {@link build_event_stream.BuildEventId.OptionsParsedId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.OptionsParsedId
             * @static
             * @param {build_event_stream.BuildEventId.IOptionsParsedId} message OptionsParsedId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OptionsParsedId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified OptionsParsedId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.OptionsParsedId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.OptionsParsedId
             * @static
             * @param {build_event_stream.BuildEventId.IOptionsParsedId} message OptionsParsedId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OptionsParsedId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OptionsParsedId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.OptionsParsedId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.OptionsParsedId} OptionsParsedId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OptionsParsedId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.OptionsParsedId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OptionsParsedId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.OptionsParsedId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.OptionsParsedId} OptionsParsedId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OptionsParsedId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OptionsParsedId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.OptionsParsedId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OptionsParsedId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an OptionsParsedId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.OptionsParsedId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.OptionsParsedId} OptionsParsedId
             */
            OptionsParsedId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.OptionsParsedId)
                    return object;
                return new $root.build_event_stream.BuildEventId.OptionsParsedId();
            };

            /**
             * Creates a plain object from an OptionsParsedId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.OptionsParsedId
             * @static
             * @param {build_event_stream.BuildEventId.OptionsParsedId} message OptionsParsedId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OptionsParsedId.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this OptionsParsedId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.OptionsParsedId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OptionsParsedId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OptionsParsedId;
        })();

        BuildEventId.FetchId = (function() {

            /**
             * Properties of a FetchId.
             * @memberof build_event_stream.BuildEventId
             * @interface IFetchId
             * @property {string|null} [url] FetchId url
             */

            /**
             * Constructs a new FetchId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a FetchId.
             * @implements IFetchId
             * @constructor
             * @param {build_event_stream.BuildEventId.IFetchId=} [properties] Properties to set
             */
            function FetchId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FetchId url.
             * @member {string} url
             * @memberof build_event_stream.BuildEventId.FetchId
             * @instance
             */
            FetchId.prototype.url = "";

            /**
             * Creates a new FetchId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.FetchId
             * @static
             * @param {build_event_stream.BuildEventId.IFetchId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.FetchId} FetchId instance
             */
            FetchId.create = function create(properties) {
                return new FetchId(properties);
            };

            /**
             * Encodes the specified FetchId message. Does not implicitly {@link build_event_stream.BuildEventId.FetchId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.FetchId
             * @static
             * @param {build_event_stream.BuildEventId.IFetchId} message FetchId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FetchId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.url != null && message.hasOwnProperty("url"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.url);
                return writer;
            };

            /**
             * Encodes the specified FetchId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.FetchId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.FetchId
             * @static
             * @param {build_event_stream.BuildEventId.IFetchId} message FetchId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FetchId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FetchId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.FetchId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.FetchId} FetchId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FetchId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.FetchId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.url = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FetchId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.FetchId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.FetchId} FetchId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FetchId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FetchId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.FetchId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FetchId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.url != null && message.hasOwnProperty("url"))
                    if (!$util.isString(message.url))
                        return "url: string expected";
                return null;
            };

            /**
             * Creates a FetchId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.FetchId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.FetchId} FetchId
             */
            FetchId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.FetchId)
                    return object;
                let message = new $root.build_event_stream.BuildEventId.FetchId();
                if (object.url != null)
                    message.url = String(object.url);
                return message;
            };

            /**
             * Creates a plain object from a FetchId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.FetchId
             * @static
             * @param {build_event_stream.BuildEventId.FetchId} message FetchId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FetchId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.url = "";
                if (message.url != null && message.hasOwnProperty("url"))
                    object.url = message.url;
                return object;
            };

            /**
             * Converts this FetchId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.FetchId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FetchId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FetchId;
        })();

        BuildEventId.PatternExpandedId = (function() {

            /**
             * Properties of a PatternExpandedId.
             * @memberof build_event_stream.BuildEventId
             * @interface IPatternExpandedId
             * @property {Array.<string>|null} [pattern] PatternExpandedId pattern
             */

            /**
             * Constructs a new PatternExpandedId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a PatternExpandedId.
             * @implements IPatternExpandedId
             * @constructor
             * @param {build_event_stream.BuildEventId.IPatternExpandedId=} [properties] Properties to set
             */
            function PatternExpandedId(properties) {
                this.pattern = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PatternExpandedId pattern.
             * @member {Array.<string>} pattern
             * @memberof build_event_stream.BuildEventId.PatternExpandedId
             * @instance
             */
            PatternExpandedId.prototype.pattern = $util.emptyArray;

            /**
             * Creates a new PatternExpandedId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.PatternExpandedId
             * @static
             * @param {build_event_stream.BuildEventId.IPatternExpandedId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.PatternExpandedId} PatternExpandedId instance
             */
            PatternExpandedId.create = function create(properties) {
                return new PatternExpandedId(properties);
            };

            /**
             * Encodes the specified PatternExpandedId message. Does not implicitly {@link build_event_stream.BuildEventId.PatternExpandedId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.PatternExpandedId
             * @static
             * @param {build_event_stream.BuildEventId.IPatternExpandedId} message PatternExpandedId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PatternExpandedId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.pattern != null && message.pattern.length)
                    for (let i = 0; i < message.pattern.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.pattern[i]);
                return writer;
            };

            /**
             * Encodes the specified PatternExpandedId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.PatternExpandedId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.PatternExpandedId
             * @static
             * @param {build_event_stream.BuildEventId.IPatternExpandedId} message PatternExpandedId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PatternExpandedId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PatternExpandedId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.PatternExpandedId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.PatternExpandedId} PatternExpandedId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PatternExpandedId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.PatternExpandedId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.pattern && message.pattern.length))
                            message.pattern = [];
                        message.pattern.push(reader.string());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PatternExpandedId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.PatternExpandedId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.PatternExpandedId} PatternExpandedId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PatternExpandedId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PatternExpandedId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.PatternExpandedId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PatternExpandedId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.pattern != null && message.hasOwnProperty("pattern")) {
                    if (!Array.isArray(message.pattern))
                        return "pattern: array expected";
                    for (let i = 0; i < message.pattern.length; ++i)
                        if (!$util.isString(message.pattern[i]))
                            return "pattern: string[] expected";
                }
                return null;
            };

            /**
             * Creates a PatternExpandedId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.PatternExpandedId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.PatternExpandedId} PatternExpandedId
             */
            PatternExpandedId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.PatternExpandedId)
                    return object;
                let message = new $root.build_event_stream.BuildEventId.PatternExpandedId();
                if (object.pattern) {
                    if (!Array.isArray(object.pattern))
                        throw TypeError(".build_event_stream.BuildEventId.PatternExpandedId.pattern: array expected");
                    message.pattern = [];
                    for (let i = 0; i < object.pattern.length; ++i)
                        message.pattern[i] = String(object.pattern[i]);
                }
                return message;
            };

            /**
             * Creates a plain object from a PatternExpandedId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.PatternExpandedId
             * @static
             * @param {build_event_stream.BuildEventId.PatternExpandedId} message PatternExpandedId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PatternExpandedId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.pattern = [];
                if (message.pattern && message.pattern.length) {
                    object.pattern = [];
                    for (let j = 0; j < message.pattern.length; ++j)
                        object.pattern[j] = message.pattern[j];
                }
                return object;
            };

            /**
             * Converts this PatternExpandedId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.PatternExpandedId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PatternExpandedId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PatternExpandedId;
        })();

        BuildEventId.WorkspaceConfigId = (function() {

            /**
             * Properties of a WorkspaceConfigId.
             * @memberof build_event_stream.BuildEventId
             * @interface IWorkspaceConfigId
             */

            /**
             * Constructs a new WorkspaceConfigId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a WorkspaceConfigId.
             * @implements IWorkspaceConfigId
             * @constructor
             * @param {build_event_stream.BuildEventId.IWorkspaceConfigId=} [properties] Properties to set
             */
            function WorkspaceConfigId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new WorkspaceConfigId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.WorkspaceConfigId
             * @static
             * @param {build_event_stream.BuildEventId.IWorkspaceConfigId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.WorkspaceConfigId} WorkspaceConfigId instance
             */
            WorkspaceConfigId.create = function create(properties) {
                return new WorkspaceConfigId(properties);
            };

            /**
             * Encodes the specified WorkspaceConfigId message. Does not implicitly {@link build_event_stream.BuildEventId.WorkspaceConfigId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.WorkspaceConfigId
             * @static
             * @param {build_event_stream.BuildEventId.IWorkspaceConfigId} message WorkspaceConfigId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkspaceConfigId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified WorkspaceConfigId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.WorkspaceConfigId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.WorkspaceConfigId
             * @static
             * @param {build_event_stream.BuildEventId.IWorkspaceConfigId} message WorkspaceConfigId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            WorkspaceConfigId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a WorkspaceConfigId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.WorkspaceConfigId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.WorkspaceConfigId} WorkspaceConfigId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkspaceConfigId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.WorkspaceConfigId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a WorkspaceConfigId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.WorkspaceConfigId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.WorkspaceConfigId} WorkspaceConfigId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            WorkspaceConfigId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a WorkspaceConfigId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.WorkspaceConfigId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            WorkspaceConfigId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a WorkspaceConfigId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.WorkspaceConfigId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.WorkspaceConfigId} WorkspaceConfigId
             */
            WorkspaceConfigId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.WorkspaceConfigId)
                    return object;
                return new $root.build_event_stream.BuildEventId.WorkspaceConfigId();
            };

            /**
             * Creates a plain object from a WorkspaceConfigId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.WorkspaceConfigId
             * @static
             * @param {build_event_stream.BuildEventId.WorkspaceConfigId} message WorkspaceConfigId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            WorkspaceConfigId.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this WorkspaceConfigId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.WorkspaceConfigId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            WorkspaceConfigId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return WorkspaceConfigId;
        })();

        BuildEventId.BuildMetadataId = (function() {

            /**
             * Properties of a BuildMetadataId.
             * @memberof build_event_stream.BuildEventId
             * @interface IBuildMetadataId
             */

            /**
             * Constructs a new BuildMetadataId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a BuildMetadataId.
             * @implements IBuildMetadataId
             * @constructor
             * @param {build_event_stream.BuildEventId.IBuildMetadataId=} [properties] Properties to set
             */
            function BuildMetadataId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new BuildMetadataId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.BuildMetadataId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildMetadataId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.BuildMetadataId} BuildMetadataId instance
             */
            BuildMetadataId.create = function create(properties) {
                return new BuildMetadataId(properties);
            };

            /**
             * Encodes the specified BuildMetadataId message. Does not implicitly {@link build_event_stream.BuildEventId.BuildMetadataId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.BuildMetadataId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildMetadataId} message BuildMetadataId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuildMetadataId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified BuildMetadataId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.BuildMetadataId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.BuildMetadataId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildMetadataId} message BuildMetadataId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuildMetadataId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuildMetadataId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.BuildMetadataId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.BuildMetadataId} BuildMetadataId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuildMetadataId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.BuildMetadataId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuildMetadataId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.BuildMetadataId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.BuildMetadataId} BuildMetadataId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuildMetadataId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuildMetadataId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.BuildMetadataId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuildMetadataId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a BuildMetadataId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.BuildMetadataId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.BuildMetadataId} BuildMetadataId
             */
            BuildMetadataId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.BuildMetadataId)
                    return object;
                return new $root.build_event_stream.BuildEventId.BuildMetadataId();
            };

            /**
             * Creates a plain object from a BuildMetadataId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.BuildMetadataId
             * @static
             * @param {build_event_stream.BuildEventId.BuildMetadataId} message BuildMetadataId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuildMetadataId.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this BuildMetadataId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.BuildMetadataId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuildMetadataId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return BuildMetadataId;
        })();

        BuildEventId.TargetConfiguredId = (function() {

            /**
             * Properties of a TargetConfiguredId.
             * @memberof build_event_stream.BuildEventId
             * @interface ITargetConfiguredId
             * @property {string|null} [label] TargetConfiguredId label
             * @property {string|null} [aspect] TargetConfiguredId aspect
             */

            /**
             * Constructs a new TargetConfiguredId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a TargetConfiguredId.
             * @implements ITargetConfiguredId
             * @constructor
             * @param {build_event_stream.BuildEventId.ITargetConfiguredId=} [properties] Properties to set
             */
            function TargetConfiguredId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TargetConfiguredId label.
             * @member {string} label
             * @memberof build_event_stream.BuildEventId.TargetConfiguredId
             * @instance
             */
            TargetConfiguredId.prototype.label = "";

            /**
             * TargetConfiguredId aspect.
             * @member {string} aspect
             * @memberof build_event_stream.BuildEventId.TargetConfiguredId
             * @instance
             */
            TargetConfiguredId.prototype.aspect = "";

            /**
             * Creates a new TargetConfiguredId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.TargetConfiguredId
             * @static
             * @param {build_event_stream.BuildEventId.ITargetConfiguredId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.TargetConfiguredId} TargetConfiguredId instance
             */
            TargetConfiguredId.create = function create(properties) {
                return new TargetConfiguredId(properties);
            };

            /**
             * Encodes the specified TargetConfiguredId message. Does not implicitly {@link build_event_stream.BuildEventId.TargetConfiguredId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.TargetConfiguredId
             * @static
             * @param {build_event_stream.BuildEventId.ITargetConfiguredId} message TargetConfiguredId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TargetConfiguredId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.label != null && message.hasOwnProperty("label"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
                if (message.aspect != null && message.hasOwnProperty("aspect"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.aspect);
                return writer;
            };

            /**
             * Encodes the specified TargetConfiguredId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.TargetConfiguredId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.TargetConfiguredId
             * @static
             * @param {build_event_stream.BuildEventId.ITargetConfiguredId} message TargetConfiguredId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TargetConfiguredId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TargetConfiguredId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.TargetConfiguredId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.TargetConfiguredId} TargetConfiguredId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TargetConfiguredId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.TargetConfiguredId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.label = reader.string();
                        break;
                    case 2:
                        message.aspect = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TargetConfiguredId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.TargetConfiguredId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.TargetConfiguredId} TargetConfiguredId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TargetConfiguredId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TargetConfiguredId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.TargetConfiguredId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TargetConfiguredId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    if (!$util.isString(message.label))
                        return "label: string expected";
                if (message.aspect != null && message.hasOwnProperty("aspect"))
                    if (!$util.isString(message.aspect))
                        return "aspect: string expected";
                return null;
            };

            /**
             * Creates a TargetConfiguredId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.TargetConfiguredId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.TargetConfiguredId} TargetConfiguredId
             */
            TargetConfiguredId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.TargetConfiguredId)
                    return object;
                let message = new $root.build_event_stream.BuildEventId.TargetConfiguredId();
                if (object.label != null)
                    message.label = String(object.label);
                if (object.aspect != null)
                    message.aspect = String(object.aspect);
                return message;
            };

            /**
             * Creates a plain object from a TargetConfiguredId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.TargetConfiguredId
             * @static
             * @param {build_event_stream.BuildEventId.TargetConfiguredId} message TargetConfiguredId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TargetConfiguredId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.label = "";
                    object.aspect = "";
                }
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = message.label;
                if (message.aspect != null && message.hasOwnProperty("aspect"))
                    object.aspect = message.aspect;
                return object;
            };

            /**
             * Converts this TargetConfiguredId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.TargetConfiguredId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TargetConfiguredId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TargetConfiguredId;
        })();

        BuildEventId.NamedSetOfFilesId = (function() {

            /**
             * Properties of a NamedSetOfFilesId.
             * @memberof build_event_stream.BuildEventId
             * @interface INamedSetOfFilesId
             * @property {string|null} [id] NamedSetOfFilesId id
             */

            /**
             * Constructs a new NamedSetOfFilesId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a NamedSetOfFilesId.
             * @implements INamedSetOfFilesId
             * @constructor
             * @param {build_event_stream.BuildEventId.INamedSetOfFilesId=} [properties] Properties to set
             */
            function NamedSetOfFilesId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * NamedSetOfFilesId id.
             * @member {string} id
             * @memberof build_event_stream.BuildEventId.NamedSetOfFilesId
             * @instance
             */
            NamedSetOfFilesId.prototype.id = "";

            /**
             * Creates a new NamedSetOfFilesId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.NamedSetOfFilesId
             * @static
             * @param {build_event_stream.BuildEventId.INamedSetOfFilesId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.NamedSetOfFilesId} NamedSetOfFilesId instance
             */
            NamedSetOfFilesId.create = function create(properties) {
                return new NamedSetOfFilesId(properties);
            };

            /**
             * Encodes the specified NamedSetOfFilesId message. Does not implicitly {@link build_event_stream.BuildEventId.NamedSetOfFilesId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.NamedSetOfFilesId
             * @static
             * @param {build_event_stream.BuildEventId.INamedSetOfFilesId} message NamedSetOfFilesId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NamedSetOfFilesId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && message.hasOwnProperty("id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                return writer;
            };

            /**
             * Encodes the specified NamedSetOfFilesId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.NamedSetOfFilesId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.NamedSetOfFilesId
             * @static
             * @param {build_event_stream.BuildEventId.INamedSetOfFilesId} message NamedSetOfFilesId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            NamedSetOfFilesId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a NamedSetOfFilesId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.NamedSetOfFilesId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.NamedSetOfFilesId} NamedSetOfFilesId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NamedSetOfFilesId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.NamedSetOfFilesId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a NamedSetOfFilesId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.NamedSetOfFilesId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.NamedSetOfFilesId} NamedSetOfFilesId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            NamedSetOfFilesId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a NamedSetOfFilesId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.NamedSetOfFilesId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            NamedSetOfFilesId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                return null;
            };

            /**
             * Creates a NamedSetOfFilesId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.NamedSetOfFilesId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.NamedSetOfFilesId} NamedSetOfFilesId
             */
            NamedSetOfFilesId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.NamedSetOfFilesId)
                    return object;
                let message = new $root.build_event_stream.BuildEventId.NamedSetOfFilesId();
                if (object.id != null)
                    message.id = String(object.id);
                return message;
            };

            /**
             * Creates a plain object from a NamedSetOfFilesId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.NamedSetOfFilesId
             * @static
             * @param {build_event_stream.BuildEventId.NamedSetOfFilesId} message NamedSetOfFilesId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            NamedSetOfFilesId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.id = "";
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };

            /**
             * Converts this NamedSetOfFilesId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.NamedSetOfFilesId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            NamedSetOfFilesId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return NamedSetOfFilesId;
        })();

        BuildEventId.ConfigurationId = (function() {

            /**
             * Properties of a ConfigurationId.
             * @memberof build_event_stream.BuildEventId
             * @interface IConfigurationId
             * @property {string|null} [id] ConfigurationId id
             */

            /**
             * Constructs a new ConfigurationId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a ConfigurationId.
             * @implements IConfigurationId
             * @constructor
             * @param {build_event_stream.BuildEventId.IConfigurationId=} [properties] Properties to set
             */
            function ConfigurationId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ConfigurationId id.
             * @member {string} id
             * @memberof build_event_stream.BuildEventId.ConfigurationId
             * @instance
             */
            ConfigurationId.prototype.id = "";

            /**
             * Creates a new ConfigurationId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.ConfigurationId
             * @static
             * @param {build_event_stream.BuildEventId.IConfigurationId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.ConfigurationId} ConfigurationId instance
             */
            ConfigurationId.create = function create(properties) {
                return new ConfigurationId(properties);
            };

            /**
             * Encodes the specified ConfigurationId message. Does not implicitly {@link build_event_stream.BuildEventId.ConfigurationId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.ConfigurationId
             * @static
             * @param {build_event_stream.BuildEventId.IConfigurationId} message ConfigurationId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfigurationId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && message.hasOwnProperty("id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                return writer;
            };

            /**
             * Encodes the specified ConfigurationId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.ConfigurationId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.ConfigurationId
             * @static
             * @param {build_event_stream.BuildEventId.IConfigurationId} message ConfigurationId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfigurationId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ConfigurationId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.ConfigurationId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.ConfigurationId} ConfigurationId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfigurationId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.ConfigurationId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.id = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ConfigurationId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.ConfigurationId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.ConfigurationId} ConfigurationId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfigurationId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ConfigurationId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.ConfigurationId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConfigurationId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                return null;
            };

            /**
             * Creates a ConfigurationId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.ConfigurationId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.ConfigurationId} ConfigurationId
             */
            ConfigurationId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.ConfigurationId)
                    return object;
                let message = new $root.build_event_stream.BuildEventId.ConfigurationId();
                if (object.id != null)
                    message.id = String(object.id);
                return message;
            };

            /**
             * Creates a plain object from a ConfigurationId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.ConfigurationId
             * @static
             * @param {build_event_stream.BuildEventId.ConfigurationId} message ConfigurationId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConfigurationId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.id = "";
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                return object;
            };

            /**
             * Converts this ConfigurationId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.ConfigurationId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConfigurationId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ConfigurationId;
        })();

        BuildEventId.TargetCompletedId = (function() {

            /**
             * Properties of a TargetCompletedId.
             * @memberof build_event_stream.BuildEventId
             * @interface ITargetCompletedId
             * @property {string|null} [label] TargetCompletedId label
             * @property {build_event_stream.BuildEventId.IConfigurationId|null} [configuration] TargetCompletedId configuration
             * @property {string|null} [aspect] TargetCompletedId aspect
             */

            /**
             * Constructs a new TargetCompletedId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a TargetCompletedId.
             * @implements ITargetCompletedId
             * @constructor
             * @param {build_event_stream.BuildEventId.ITargetCompletedId=} [properties] Properties to set
             */
            function TargetCompletedId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TargetCompletedId label.
             * @member {string} label
             * @memberof build_event_stream.BuildEventId.TargetCompletedId
             * @instance
             */
            TargetCompletedId.prototype.label = "";

            /**
             * TargetCompletedId configuration.
             * @member {build_event_stream.BuildEventId.IConfigurationId|null|undefined} configuration
             * @memberof build_event_stream.BuildEventId.TargetCompletedId
             * @instance
             */
            TargetCompletedId.prototype.configuration = null;

            /**
             * TargetCompletedId aspect.
             * @member {string} aspect
             * @memberof build_event_stream.BuildEventId.TargetCompletedId
             * @instance
             */
            TargetCompletedId.prototype.aspect = "";

            /**
             * Creates a new TargetCompletedId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.TargetCompletedId
             * @static
             * @param {build_event_stream.BuildEventId.ITargetCompletedId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.TargetCompletedId} TargetCompletedId instance
             */
            TargetCompletedId.create = function create(properties) {
                return new TargetCompletedId(properties);
            };

            /**
             * Encodes the specified TargetCompletedId message. Does not implicitly {@link build_event_stream.BuildEventId.TargetCompletedId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.TargetCompletedId
             * @static
             * @param {build_event_stream.BuildEventId.ITargetCompletedId} message TargetCompletedId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TargetCompletedId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.label != null && message.hasOwnProperty("label"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
                if (message.aspect != null && message.hasOwnProperty("aspect"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.aspect);
                if (message.configuration != null && message.hasOwnProperty("configuration"))
                    $root.build_event_stream.BuildEventId.ConfigurationId.encode(message.configuration, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified TargetCompletedId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.TargetCompletedId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.TargetCompletedId
             * @static
             * @param {build_event_stream.BuildEventId.ITargetCompletedId} message TargetCompletedId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TargetCompletedId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TargetCompletedId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.TargetCompletedId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.TargetCompletedId} TargetCompletedId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TargetCompletedId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.TargetCompletedId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.label = reader.string();
                        break;
                    case 3:
                        message.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.aspect = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TargetCompletedId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.TargetCompletedId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.TargetCompletedId} TargetCompletedId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TargetCompletedId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TargetCompletedId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.TargetCompletedId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TargetCompletedId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    if (!$util.isString(message.label))
                        return "label: string expected";
                if (message.configuration != null && message.hasOwnProperty("configuration")) {
                    let error = $root.build_event_stream.BuildEventId.ConfigurationId.verify(message.configuration);
                    if (error)
                        return "configuration." + error;
                }
                if (message.aspect != null && message.hasOwnProperty("aspect"))
                    if (!$util.isString(message.aspect))
                        return "aspect: string expected";
                return null;
            };

            /**
             * Creates a TargetCompletedId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.TargetCompletedId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.TargetCompletedId} TargetCompletedId
             */
            TargetCompletedId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.TargetCompletedId)
                    return object;
                let message = new $root.build_event_stream.BuildEventId.TargetCompletedId();
                if (object.label != null)
                    message.label = String(object.label);
                if (object.configuration != null) {
                    if (typeof object.configuration !== "object")
                        throw TypeError(".build_event_stream.BuildEventId.TargetCompletedId.configuration: object expected");
                    message.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.fromObject(object.configuration);
                }
                if (object.aspect != null)
                    message.aspect = String(object.aspect);
                return message;
            };

            /**
             * Creates a plain object from a TargetCompletedId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.TargetCompletedId
             * @static
             * @param {build_event_stream.BuildEventId.TargetCompletedId} message TargetCompletedId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TargetCompletedId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.label = "";
                    object.aspect = "";
                    object.configuration = null;
                }
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = message.label;
                if (message.aspect != null && message.hasOwnProperty("aspect"))
                    object.aspect = message.aspect;
                if (message.configuration != null && message.hasOwnProperty("configuration"))
                    object.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.toObject(message.configuration, options);
                return object;
            };

            /**
             * Converts this TargetCompletedId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.TargetCompletedId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TargetCompletedId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TargetCompletedId;
        })();

        BuildEventId.ActionCompletedId = (function() {

            /**
             * Properties of an ActionCompletedId.
             * @memberof build_event_stream.BuildEventId
             * @interface IActionCompletedId
             * @property {string|null} [primaryOutput] ActionCompletedId primaryOutput
             * @property {string|null} [label] ActionCompletedId label
             * @property {build_event_stream.BuildEventId.IConfigurationId|null} [configuration] ActionCompletedId configuration
             */

            /**
             * Constructs a new ActionCompletedId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents an ActionCompletedId.
             * @implements IActionCompletedId
             * @constructor
             * @param {build_event_stream.BuildEventId.IActionCompletedId=} [properties] Properties to set
             */
            function ActionCompletedId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ActionCompletedId primaryOutput.
             * @member {string} primaryOutput
             * @memberof build_event_stream.BuildEventId.ActionCompletedId
             * @instance
             */
            ActionCompletedId.prototype.primaryOutput = "";

            /**
             * ActionCompletedId label.
             * @member {string} label
             * @memberof build_event_stream.BuildEventId.ActionCompletedId
             * @instance
             */
            ActionCompletedId.prototype.label = "";

            /**
             * ActionCompletedId configuration.
             * @member {build_event_stream.BuildEventId.IConfigurationId|null|undefined} configuration
             * @memberof build_event_stream.BuildEventId.ActionCompletedId
             * @instance
             */
            ActionCompletedId.prototype.configuration = null;

            /**
             * Creates a new ActionCompletedId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.ActionCompletedId
             * @static
             * @param {build_event_stream.BuildEventId.IActionCompletedId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.ActionCompletedId} ActionCompletedId instance
             */
            ActionCompletedId.create = function create(properties) {
                return new ActionCompletedId(properties);
            };

            /**
             * Encodes the specified ActionCompletedId message. Does not implicitly {@link build_event_stream.BuildEventId.ActionCompletedId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.ActionCompletedId
             * @static
             * @param {build_event_stream.BuildEventId.IActionCompletedId} message ActionCompletedId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ActionCompletedId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.primaryOutput != null && message.hasOwnProperty("primaryOutput"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.primaryOutput);
                if (message.label != null && message.hasOwnProperty("label"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.label);
                if (message.configuration != null && message.hasOwnProperty("configuration"))
                    $root.build_event_stream.BuildEventId.ConfigurationId.encode(message.configuration, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ActionCompletedId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.ActionCompletedId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.ActionCompletedId
             * @static
             * @param {build_event_stream.BuildEventId.IActionCompletedId} message ActionCompletedId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ActionCompletedId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an ActionCompletedId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.ActionCompletedId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.ActionCompletedId} ActionCompletedId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ActionCompletedId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.ActionCompletedId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.primaryOutput = reader.string();
                        break;
                    case 2:
                        message.label = reader.string();
                        break;
                    case 3:
                        message.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an ActionCompletedId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.ActionCompletedId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.ActionCompletedId} ActionCompletedId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ActionCompletedId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an ActionCompletedId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.ActionCompletedId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ActionCompletedId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.primaryOutput != null && message.hasOwnProperty("primaryOutput"))
                    if (!$util.isString(message.primaryOutput))
                        return "primaryOutput: string expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    if (!$util.isString(message.label))
                        return "label: string expected";
                if (message.configuration != null && message.hasOwnProperty("configuration")) {
                    let error = $root.build_event_stream.BuildEventId.ConfigurationId.verify(message.configuration);
                    if (error)
                        return "configuration." + error;
                }
                return null;
            };

            /**
             * Creates an ActionCompletedId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.ActionCompletedId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.ActionCompletedId} ActionCompletedId
             */
            ActionCompletedId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.ActionCompletedId)
                    return object;
                let message = new $root.build_event_stream.BuildEventId.ActionCompletedId();
                if (object.primaryOutput != null)
                    message.primaryOutput = String(object.primaryOutput);
                if (object.label != null)
                    message.label = String(object.label);
                if (object.configuration != null) {
                    if (typeof object.configuration !== "object")
                        throw TypeError(".build_event_stream.BuildEventId.ActionCompletedId.configuration: object expected");
                    message.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.fromObject(object.configuration);
                }
                return message;
            };

            /**
             * Creates a plain object from an ActionCompletedId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.ActionCompletedId
             * @static
             * @param {build_event_stream.BuildEventId.ActionCompletedId} message ActionCompletedId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ActionCompletedId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.primaryOutput = "";
                    object.label = "";
                    object.configuration = null;
                }
                if (message.primaryOutput != null && message.hasOwnProperty("primaryOutput"))
                    object.primaryOutput = message.primaryOutput;
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = message.label;
                if (message.configuration != null && message.hasOwnProperty("configuration"))
                    object.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.toObject(message.configuration, options);
                return object;
            };

            /**
             * Converts this ActionCompletedId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.ActionCompletedId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ActionCompletedId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ActionCompletedId;
        })();

        BuildEventId.UnconfiguredLabelId = (function() {

            /**
             * Properties of an UnconfiguredLabelId.
             * @memberof build_event_stream.BuildEventId
             * @interface IUnconfiguredLabelId
             * @property {string|null} [label] UnconfiguredLabelId label
             */

            /**
             * Constructs a new UnconfiguredLabelId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents an UnconfiguredLabelId.
             * @implements IUnconfiguredLabelId
             * @constructor
             * @param {build_event_stream.BuildEventId.IUnconfiguredLabelId=} [properties] Properties to set
             */
            function UnconfiguredLabelId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UnconfiguredLabelId label.
             * @member {string} label
             * @memberof build_event_stream.BuildEventId.UnconfiguredLabelId
             * @instance
             */
            UnconfiguredLabelId.prototype.label = "";

            /**
             * Creates a new UnconfiguredLabelId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.UnconfiguredLabelId
             * @static
             * @param {build_event_stream.BuildEventId.IUnconfiguredLabelId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.UnconfiguredLabelId} UnconfiguredLabelId instance
             */
            UnconfiguredLabelId.create = function create(properties) {
                return new UnconfiguredLabelId(properties);
            };

            /**
             * Encodes the specified UnconfiguredLabelId message. Does not implicitly {@link build_event_stream.BuildEventId.UnconfiguredLabelId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.UnconfiguredLabelId
             * @static
             * @param {build_event_stream.BuildEventId.IUnconfiguredLabelId} message UnconfiguredLabelId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnconfiguredLabelId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.label != null && message.hasOwnProperty("label"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
                return writer;
            };

            /**
             * Encodes the specified UnconfiguredLabelId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.UnconfiguredLabelId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.UnconfiguredLabelId
             * @static
             * @param {build_event_stream.BuildEventId.IUnconfiguredLabelId} message UnconfiguredLabelId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UnconfiguredLabelId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UnconfiguredLabelId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.UnconfiguredLabelId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.UnconfiguredLabelId} UnconfiguredLabelId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnconfiguredLabelId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.UnconfiguredLabelId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.label = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UnconfiguredLabelId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.UnconfiguredLabelId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.UnconfiguredLabelId} UnconfiguredLabelId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UnconfiguredLabelId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UnconfiguredLabelId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.UnconfiguredLabelId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UnconfiguredLabelId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    if (!$util.isString(message.label))
                        return "label: string expected";
                return null;
            };

            /**
             * Creates an UnconfiguredLabelId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.UnconfiguredLabelId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.UnconfiguredLabelId} UnconfiguredLabelId
             */
            UnconfiguredLabelId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.UnconfiguredLabelId)
                    return object;
                let message = new $root.build_event_stream.BuildEventId.UnconfiguredLabelId();
                if (object.label != null)
                    message.label = String(object.label);
                return message;
            };

            /**
             * Creates a plain object from an UnconfiguredLabelId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.UnconfiguredLabelId
             * @static
             * @param {build_event_stream.BuildEventId.UnconfiguredLabelId} message UnconfiguredLabelId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UnconfiguredLabelId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.label = "";
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = message.label;
                return object;
            };

            /**
             * Converts this UnconfiguredLabelId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.UnconfiguredLabelId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UnconfiguredLabelId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UnconfiguredLabelId;
        })();

        BuildEventId.ConfiguredLabelId = (function() {

            /**
             * Properties of a ConfiguredLabelId.
             * @memberof build_event_stream.BuildEventId
             * @interface IConfiguredLabelId
             * @property {string|null} [label] ConfiguredLabelId label
             * @property {build_event_stream.BuildEventId.IConfigurationId|null} [configuration] ConfiguredLabelId configuration
             */

            /**
             * Constructs a new ConfiguredLabelId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a ConfiguredLabelId.
             * @implements IConfiguredLabelId
             * @constructor
             * @param {build_event_stream.BuildEventId.IConfiguredLabelId=} [properties] Properties to set
             */
            function ConfiguredLabelId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ConfiguredLabelId label.
             * @member {string} label
             * @memberof build_event_stream.BuildEventId.ConfiguredLabelId
             * @instance
             */
            ConfiguredLabelId.prototype.label = "";

            /**
             * ConfiguredLabelId configuration.
             * @member {build_event_stream.BuildEventId.IConfigurationId|null|undefined} configuration
             * @memberof build_event_stream.BuildEventId.ConfiguredLabelId
             * @instance
             */
            ConfiguredLabelId.prototype.configuration = null;

            /**
             * Creates a new ConfiguredLabelId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.ConfiguredLabelId
             * @static
             * @param {build_event_stream.BuildEventId.IConfiguredLabelId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.ConfiguredLabelId} ConfiguredLabelId instance
             */
            ConfiguredLabelId.create = function create(properties) {
                return new ConfiguredLabelId(properties);
            };

            /**
             * Encodes the specified ConfiguredLabelId message. Does not implicitly {@link build_event_stream.BuildEventId.ConfiguredLabelId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.ConfiguredLabelId
             * @static
             * @param {build_event_stream.BuildEventId.IConfiguredLabelId} message ConfiguredLabelId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfiguredLabelId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.label != null && message.hasOwnProperty("label"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
                if (message.configuration != null && message.hasOwnProperty("configuration"))
                    $root.build_event_stream.BuildEventId.ConfigurationId.encode(message.configuration, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ConfiguredLabelId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.ConfiguredLabelId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.ConfiguredLabelId
             * @static
             * @param {build_event_stream.BuildEventId.IConfiguredLabelId} message ConfiguredLabelId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConfiguredLabelId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ConfiguredLabelId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.ConfiguredLabelId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.ConfiguredLabelId} ConfiguredLabelId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfiguredLabelId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.ConfiguredLabelId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.label = reader.string();
                        break;
                    case 2:
                        message.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ConfiguredLabelId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.ConfiguredLabelId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.ConfiguredLabelId} ConfiguredLabelId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConfiguredLabelId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ConfiguredLabelId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.ConfiguredLabelId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConfiguredLabelId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    if (!$util.isString(message.label))
                        return "label: string expected";
                if (message.configuration != null && message.hasOwnProperty("configuration")) {
                    let error = $root.build_event_stream.BuildEventId.ConfigurationId.verify(message.configuration);
                    if (error)
                        return "configuration." + error;
                }
                return null;
            };

            /**
             * Creates a ConfiguredLabelId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.ConfiguredLabelId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.ConfiguredLabelId} ConfiguredLabelId
             */
            ConfiguredLabelId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.ConfiguredLabelId)
                    return object;
                let message = new $root.build_event_stream.BuildEventId.ConfiguredLabelId();
                if (object.label != null)
                    message.label = String(object.label);
                if (object.configuration != null) {
                    if (typeof object.configuration !== "object")
                        throw TypeError(".build_event_stream.BuildEventId.ConfiguredLabelId.configuration: object expected");
                    message.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.fromObject(object.configuration);
                }
                return message;
            };

            /**
             * Creates a plain object from a ConfiguredLabelId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.ConfiguredLabelId
             * @static
             * @param {build_event_stream.BuildEventId.ConfiguredLabelId} message ConfiguredLabelId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConfiguredLabelId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.label = "";
                    object.configuration = null;
                }
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = message.label;
                if (message.configuration != null && message.hasOwnProperty("configuration"))
                    object.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.toObject(message.configuration, options);
                return object;
            };

            /**
             * Converts this ConfiguredLabelId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.ConfiguredLabelId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConfiguredLabelId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ConfiguredLabelId;
        })();

        BuildEventId.TestResultId = (function() {

            /**
             * Properties of a TestResultId.
             * @memberof build_event_stream.BuildEventId
             * @interface ITestResultId
             * @property {string|null} [label] TestResultId label
             * @property {build_event_stream.BuildEventId.IConfigurationId|null} [configuration] TestResultId configuration
             * @property {number|null} [run] TestResultId run
             * @property {number|null} [shard] TestResultId shard
             * @property {number|null} [attempt] TestResultId attempt
             */

            /**
             * Constructs a new TestResultId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a TestResultId.
             * @implements ITestResultId
             * @constructor
             * @param {build_event_stream.BuildEventId.ITestResultId=} [properties] Properties to set
             */
            function TestResultId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TestResultId label.
             * @member {string} label
             * @memberof build_event_stream.BuildEventId.TestResultId
             * @instance
             */
            TestResultId.prototype.label = "";

            /**
             * TestResultId configuration.
             * @member {build_event_stream.BuildEventId.IConfigurationId|null|undefined} configuration
             * @memberof build_event_stream.BuildEventId.TestResultId
             * @instance
             */
            TestResultId.prototype.configuration = null;

            /**
             * TestResultId run.
             * @member {number} run
             * @memberof build_event_stream.BuildEventId.TestResultId
             * @instance
             */
            TestResultId.prototype.run = 0;

            /**
             * TestResultId shard.
             * @member {number} shard
             * @memberof build_event_stream.BuildEventId.TestResultId
             * @instance
             */
            TestResultId.prototype.shard = 0;

            /**
             * TestResultId attempt.
             * @member {number} attempt
             * @memberof build_event_stream.BuildEventId.TestResultId
             * @instance
             */
            TestResultId.prototype.attempt = 0;

            /**
             * Creates a new TestResultId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.TestResultId
             * @static
             * @param {build_event_stream.BuildEventId.ITestResultId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.TestResultId} TestResultId instance
             */
            TestResultId.create = function create(properties) {
                return new TestResultId(properties);
            };

            /**
             * Encodes the specified TestResultId message. Does not implicitly {@link build_event_stream.BuildEventId.TestResultId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.TestResultId
             * @static
             * @param {build_event_stream.BuildEventId.ITestResultId} message TestResultId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TestResultId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.label != null && message.hasOwnProperty("label"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
                if (message.run != null && message.hasOwnProperty("run"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.run);
                if (message.shard != null && message.hasOwnProperty("shard"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.shard);
                if (message.attempt != null && message.hasOwnProperty("attempt"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.attempt);
                if (message.configuration != null && message.hasOwnProperty("configuration"))
                    $root.build_event_stream.BuildEventId.ConfigurationId.encode(message.configuration, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified TestResultId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.TestResultId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.TestResultId
             * @static
             * @param {build_event_stream.BuildEventId.ITestResultId} message TestResultId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TestResultId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TestResultId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.TestResultId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.TestResultId} TestResultId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TestResultId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.TestResultId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.label = reader.string();
                        break;
                    case 5:
                        message.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.decode(reader, reader.uint32());
                        break;
                    case 2:
                        message.run = reader.int32();
                        break;
                    case 3:
                        message.shard = reader.int32();
                        break;
                    case 4:
                        message.attempt = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TestResultId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.TestResultId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.TestResultId} TestResultId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TestResultId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TestResultId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.TestResultId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TestResultId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    if (!$util.isString(message.label))
                        return "label: string expected";
                if (message.configuration != null && message.hasOwnProperty("configuration")) {
                    let error = $root.build_event_stream.BuildEventId.ConfigurationId.verify(message.configuration);
                    if (error)
                        return "configuration." + error;
                }
                if (message.run != null && message.hasOwnProperty("run"))
                    if (!$util.isInteger(message.run))
                        return "run: integer expected";
                if (message.shard != null && message.hasOwnProperty("shard"))
                    if (!$util.isInteger(message.shard))
                        return "shard: integer expected";
                if (message.attempt != null && message.hasOwnProperty("attempt"))
                    if (!$util.isInteger(message.attempt))
                        return "attempt: integer expected";
                return null;
            };

            /**
             * Creates a TestResultId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.TestResultId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.TestResultId} TestResultId
             */
            TestResultId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.TestResultId)
                    return object;
                let message = new $root.build_event_stream.BuildEventId.TestResultId();
                if (object.label != null)
                    message.label = String(object.label);
                if (object.configuration != null) {
                    if (typeof object.configuration !== "object")
                        throw TypeError(".build_event_stream.BuildEventId.TestResultId.configuration: object expected");
                    message.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.fromObject(object.configuration);
                }
                if (object.run != null)
                    message.run = object.run | 0;
                if (object.shard != null)
                    message.shard = object.shard | 0;
                if (object.attempt != null)
                    message.attempt = object.attempt | 0;
                return message;
            };

            /**
             * Creates a plain object from a TestResultId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.TestResultId
             * @static
             * @param {build_event_stream.BuildEventId.TestResultId} message TestResultId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TestResultId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.label = "";
                    object.run = 0;
                    object.shard = 0;
                    object.attempt = 0;
                    object.configuration = null;
                }
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = message.label;
                if (message.run != null && message.hasOwnProperty("run"))
                    object.run = message.run;
                if (message.shard != null && message.hasOwnProperty("shard"))
                    object.shard = message.shard;
                if (message.attempt != null && message.hasOwnProperty("attempt"))
                    object.attempt = message.attempt;
                if (message.configuration != null && message.hasOwnProperty("configuration"))
                    object.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.toObject(message.configuration, options);
                return object;
            };

            /**
             * Converts this TestResultId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.TestResultId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TestResultId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TestResultId;
        })();

        BuildEventId.TestSummaryId = (function() {

            /**
             * Properties of a TestSummaryId.
             * @memberof build_event_stream.BuildEventId
             * @interface ITestSummaryId
             * @property {string|null} [label] TestSummaryId label
             * @property {build_event_stream.BuildEventId.IConfigurationId|null} [configuration] TestSummaryId configuration
             */

            /**
             * Constructs a new TestSummaryId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a TestSummaryId.
             * @implements ITestSummaryId
             * @constructor
             * @param {build_event_stream.BuildEventId.ITestSummaryId=} [properties] Properties to set
             */
            function TestSummaryId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TestSummaryId label.
             * @member {string} label
             * @memberof build_event_stream.BuildEventId.TestSummaryId
             * @instance
             */
            TestSummaryId.prototype.label = "";

            /**
             * TestSummaryId configuration.
             * @member {build_event_stream.BuildEventId.IConfigurationId|null|undefined} configuration
             * @memberof build_event_stream.BuildEventId.TestSummaryId
             * @instance
             */
            TestSummaryId.prototype.configuration = null;

            /**
             * Creates a new TestSummaryId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.TestSummaryId
             * @static
             * @param {build_event_stream.BuildEventId.ITestSummaryId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.TestSummaryId} TestSummaryId instance
             */
            TestSummaryId.create = function create(properties) {
                return new TestSummaryId(properties);
            };

            /**
             * Encodes the specified TestSummaryId message. Does not implicitly {@link build_event_stream.BuildEventId.TestSummaryId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.TestSummaryId
             * @static
             * @param {build_event_stream.BuildEventId.ITestSummaryId} message TestSummaryId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TestSummaryId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.label != null && message.hasOwnProperty("label"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.label);
                if (message.configuration != null && message.hasOwnProperty("configuration"))
                    $root.build_event_stream.BuildEventId.ConfigurationId.encode(message.configuration, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified TestSummaryId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.TestSummaryId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.TestSummaryId
             * @static
             * @param {build_event_stream.BuildEventId.ITestSummaryId} message TestSummaryId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TestSummaryId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TestSummaryId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.TestSummaryId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.TestSummaryId} TestSummaryId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TestSummaryId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.TestSummaryId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.label = reader.string();
                        break;
                    case 2:
                        message.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TestSummaryId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.TestSummaryId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.TestSummaryId} TestSummaryId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TestSummaryId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TestSummaryId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.TestSummaryId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TestSummaryId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.label != null && message.hasOwnProperty("label"))
                    if (!$util.isString(message.label))
                        return "label: string expected";
                if (message.configuration != null && message.hasOwnProperty("configuration")) {
                    let error = $root.build_event_stream.BuildEventId.ConfigurationId.verify(message.configuration);
                    if (error)
                        return "configuration." + error;
                }
                return null;
            };

            /**
             * Creates a TestSummaryId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.TestSummaryId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.TestSummaryId} TestSummaryId
             */
            TestSummaryId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.TestSummaryId)
                    return object;
                let message = new $root.build_event_stream.BuildEventId.TestSummaryId();
                if (object.label != null)
                    message.label = String(object.label);
                if (object.configuration != null) {
                    if (typeof object.configuration !== "object")
                        throw TypeError(".build_event_stream.BuildEventId.TestSummaryId.configuration: object expected");
                    message.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.fromObject(object.configuration);
                }
                return message;
            };

            /**
             * Creates a plain object from a TestSummaryId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.TestSummaryId
             * @static
             * @param {build_event_stream.BuildEventId.TestSummaryId} message TestSummaryId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TestSummaryId.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.label = "";
                    object.configuration = null;
                }
                if (message.label != null && message.hasOwnProperty("label"))
                    object.label = message.label;
                if (message.configuration != null && message.hasOwnProperty("configuration"))
                    object.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.toObject(message.configuration, options);
                return object;
            };

            /**
             * Converts this TestSummaryId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.TestSummaryId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TestSummaryId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TestSummaryId;
        })();

        BuildEventId.BuildFinishedId = (function() {

            /**
             * Properties of a BuildFinishedId.
             * @memberof build_event_stream.BuildEventId
             * @interface IBuildFinishedId
             */

            /**
             * Constructs a new BuildFinishedId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a BuildFinishedId.
             * @implements IBuildFinishedId
             * @constructor
             * @param {build_event_stream.BuildEventId.IBuildFinishedId=} [properties] Properties to set
             */
            function BuildFinishedId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new BuildFinishedId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.BuildFinishedId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildFinishedId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.BuildFinishedId} BuildFinishedId instance
             */
            BuildFinishedId.create = function create(properties) {
                return new BuildFinishedId(properties);
            };

            /**
             * Encodes the specified BuildFinishedId message. Does not implicitly {@link build_event_stream.BuildEventId.BuildFinishedId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.BuildFinishedId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildFinishedId} message BuildFinishedId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuildFinishedId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified BuildFinishedId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.BuildFinishedId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.BuildFinishedId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildFinishedId} message BuildFinishedId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuildFinishedId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuildFinishedId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.BuildFinishedId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.BuildFinishedId} BuildFinishedId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuildFinishedId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.BuildFinishedId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuildFinishedId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.BuildFinishedId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.BuildFinishedId} BuildFinishedId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuildFinishedId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuildFinishedId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.BuildFinishedId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuildFinishedId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a BuildFinishedId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.BuildFinishedId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.BuildFinishedId} BuildFinishedId
             */
            BuildFinishedId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.BuildFinishedId)
                    return object;
                return new $root.build_event_stream.BuildEventId.BuildFinishedId();
            };

            /**
             * Creates a plain object from a BuildFinishedId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.BuildFinishedId
             * @static
             * @param {build_event_stream.BuildEventId.BuildFinishedId} message BuildFinishedId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuildFinishedId.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this BuildFinishedId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.BuildFinishedId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuildFinishedId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return BuildFinishedId;
        })();

        BuildEventId.BuildToolLogsId = (function() {

            /**
             * Properties of a BuildToolLogsId.
             * @memberof build_event_stream.BuildEventId
             * @interface IBuildToolLogsId
             */

            /**
             * Constructs a new BuildToolLogsId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a BuildToolLogsId.
             * @implements IBuildToolLogsId
             * @constructor
             * @param {build_event_stream.BuildEventId.IBuildToolLogsId=} [properties] Properties to set
             */
            function BuildToolLogsId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new BuildToolLogsId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.BuildToolLogsId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildToolLogsId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.BuildToolLogsId} BuildToolLogsId instance
             */
            BuildToolLogsId.create = function create(properties) {
                return new BuildToolLogsId(properties);
            };

            /**
             * Encodes the specified BuildToolLogsId message. Does not implicitly {@link build_event_stream.BuildEventId.BuildToolLogsId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.BuildToolLogsId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildToolLogsId} message BuildToolLogsId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuildToolLogsId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified BuildToolLogsId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.BuildToolLogsId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.BuildToolLogsId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildToolLogsId} message BuildToolLogsId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuildToolLogsId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuildToolLogsId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.BuildToolLogsId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.BuildToolLogsId} BuildToolLogsId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuildToolLogsId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.BuildToolLogsId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuildToolLogsId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.BuildToolLogsId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.BuildToolLogsId} BuildToolLogsId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuildToolLogsId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuildToolLogsId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.BuildToolLogsId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuildToolLogsId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a BuildToolLogsId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.BuildToolLogsId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.BuildToolLogsId} BuildToolLogsId
             */
            BuildToolLogsId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.BuildToolLogsId)
                    return object;
                return new $root.build_event_stream.BuildEventId.BuildToolLogsId();
            };

            /**
             * Creates a plain object from a BuildToolLogsId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.BuildToolLogsId
             * @static
             * @param {build_event_stream.BuildEventId.BuildToolLogsId} message BuildToolLogsId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuildToolLogsId.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this BuildToolLogsId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.BuildToolLogsId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuildToolLogsId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return BuildToolLogsId;
        })();

        BuildEventId.BuildMetricsId = (function() {

            /**
             * Properties of a BuildMetricsId.
             * @memberof build_event_stream.BuildEventId
             * @interface IBuildMetricsId
             */

            /**
             * Constructs a new BuildMetricsId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a BuildMetricsId.
             * @implements IBuildMetricsId
             * @constructor
             * @param {build_event_stream.BuildEventId.IBuildMetricsId=} [properties] Properties to set
             */
            function BuildMetricsId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new BuildMetricsId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.BuildMetricsId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildMetricsId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.BuildMetricsId} BuildMetricsId instance
             */
            BuildMetricsId.create = function create(properties) {
                return new BuildMetricsId(properties);
            };

            /**
             * Encodes the specified BuildMetricsId message. Does not implicitly {@link build_event_stream.BuildEventId.BuildMetricsId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.BuildMetricsId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildMetricsId} message BuildMetricsId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuildMetricsId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified BuildMetricsId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.BuildMetricsId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.BuildMetricsId
             * @static
             * @param {build_event_stream.BuildEventId.IBuildMetricsId} message BuildMetricsId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BuildMetricsId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BuildMetricsId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.BuildMetricsId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.BuildMetricsId} BuildMetricsId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuildMetricsId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.BuildMetricsId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BuildMetricsId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.BuildMetricsId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.BuildMetricsId} BuildMetricsId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BuildMetricsId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BuildMetricsId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.BuildMetricsId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BuildMetricsId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a BuildMetricsId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.BuildMetricsId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.BuildMetricsId} BuildMetricsId
             */
            BuildMetricsId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.BuildMetricsId)
                    return object;
                return new $root.build_event_stream.BuildEventId.BuildMetricsId();
            };

            /**
             * Creates a plain object from a BuildMetricsId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.BuildMetricsId
             * @static
             * @param {build_event_stream.BuildEventId.BuildMetricsId} message BuildMetricsId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BuildMetricsId.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this BuildMetricsId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.BuildMetricsId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BuildMetricsId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return BuildMetricsId;
        })();

        BuildEventId.ConvenienceSymlinksIdentifiedId = (function() {

            /**
             * Properties of a ConvenienceSymlinksIdentifiedId.
             * @memberof build_event_stream.BuildEventId
             * @interface IConvenienceSymlinksIdentifiedId
             */

            /**
             * Constructs a new ConvenienceSymlinksIdentifiedId.
             * @memberof build_event_stream.BuildEventId
             * @classdesc Represents a ConvenienceSymlinksIdentifiedId.
             * @implements IConvenienceSymlinksIdentifiedId
             * @constructor
             * @param {build_event_stream.BuildEventId.IConvenienceSymlinksIdentifiedId=} [properties] Properties to set
             */
            function ConvenienceSymlinksIdentifiedId(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new ConvenienceSymlinksIdentifiedId instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId
             * @static
             * @param {build_event_stream.BuildEventId.IConvenienceSymlinksIdentifiedId=} [properties] Properties to set
             * @returns {build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId} ConvenienceSymlinksIdentifiedId instance
             */
            ConvenienceSymlinksIdentifiedId.create = function create(properties) {
                return new ConvenienceSymlinksIdentifiedId(properties);
            };

            /**
             * Encodes the specified ConvenienceSymlinksIdentifiedId message. Does not implicitly {@link build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId
             * @static
             * @param {build_event_stream.BuildEventId.IConvenienceSymlinksIdentifiedId} message ConvenienceSymlinksIdentifiedId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConvenienceSymlinksIdentifiedId.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified ConvenienceSymlinksIdentifiedId message, length delimited. Does not implicitly {@link build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId
             * @static
             * @param {build_event_stream.BuildEventId.IConvenienceSymlinksIdentifiedId} message ConvenienceSymlinksIdentifiedId message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConvenienceSymlinksIdentifiedId.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ConvenienceSymlinksIdentifiedId message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId} ConvenienceSymlinksIdentifiedId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConvenienceSymlinksIdentifiedId.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ConvenienceSymlinksIdentifiedId message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId} ConvenienceSymlinksIdentifiedId
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConvenienceSymlinksIdentifiedId.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ConvenienceSymlinksIdentifiedId message.
             * @function verify
             * @memberof build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConvenienceSymlinksIdentifiedId.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a ConvenienceSymlinksIdentifiedId message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId} ConvenienceSymlinksIdentifiedId
             */
            ConvenienceSymlinksIdentifiedId.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId)
                    return object;
                return new $root.build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId();
            };

            /**
             * Creates a plain object from a ConvenienceSymlinksIdentifiedId message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId
             * @static
             * @param {build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId} message ConvenienceSymlinksIdentifiedId
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConvenienceSymlinksIdentifiedId.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ConvenienceSymlinksIdentifiedId to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildEventId.ConvenienceSymlinksIdentifiedId
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConvenienceSymlinksIdentifiedId.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ConvenienceSymlinksIdentifiedId;
        })();

        return BuildEventId;
    })();

    build_event_stream.Progress = (function() {

        /**
         * Properties of a Progress.
         * @memberof build_event_stream
         * @interface IProgress
         * @property {string|null} [stdout] Progress stdout
         * @property {string|null} [stderr] Progress stderr
         */

        /**
         * Constructs a new Progress.
         * @memberof build_event_stream
         * @classdesc Represents a Progress.
         * @implements IProgress
         * @constructor
         * @param {build_event_stream.IProgress=} [properties] Properties to set
         */
        function Progress(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Progress stdout.
         * @member {string} stdout
         * @memberof build_event_stream.Progress
         * @instance
         */
        Progress.prototype.stdout = "";

        /**
         * Progress stderr.
         * @member {string} stderr
         * @memberof build_event_stream.Progress
         * @instance
         */
        Progress.prototype.stderr = "";

        /**
         * Creates a new Progress instance using the specified properties.
         * @function create
         * @memberof build_event_stream.Progress
         * @static
         * @param {build_event_stream.IProgress=} [properties] Properties to set
         * @returns {build_event_stream.Progress} Progress instance
         */
        Progress.create = function create(properties) {
            return new Progress(properties);
        };

        /**
         * Encodes the specified Progress message. Does not implicitly {@link build_event_stream.Progress.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.Progress
         * @static
         * @param {build_event_stream.IProgress} message Progress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Progress.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.stdout != null && message.hasOwnProperty("stdout"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.stdout);
            if (message.stderr != null && message.hasOwnProperty("stderr"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.stderr);
            return writer;
        };

        /**
         * Encodes the specified Progress message, length delimited. Does not implicitly {@link build_event_stream.Progress.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.Progress
         * @static
         * @param {build_event_stream.IProgress} message Progress message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Progress.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Progress message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.Progress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.Progress} Progress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Progress.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.Progress();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.stdout = reader.string();
                    break;
                case 2:
                    message.stderr = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Progress message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.Progress
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.Progress} Progress
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Progress.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Progress message.
         * @function verify
         * @memberof build_event_stream.Progress
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Progress.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.stdout != null && message.hasOwnProperty("stdout"))
                if (!$util.isString(message.stdout))
                    return "stdout: string expected";
            if (message.stderr != null && message.hasOwnProperty("stderr"))
                if (!$util.isString(message.stderr))
                    return "stderr: string expected";
            return null;
        };

        /**
         * Creates a Progress message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.Progress
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.Progress} Progress
         */
        Progress.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.Progress)
                return object;
            let message = new $root.build_event_stream.Progress();
            if (object.stdout != null)
                message.stdout = String(object.stdout);
            if (object.stderr != null)
                message.stderr = String(object.stderr);
            return message;
        };

        /**
         * Creates a plain object from a Progress message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.Progress
         * @static
         * @param {build_event_stream.Progress} message Progress
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Progress.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.stdout = "";
                object.stderr = "";
            }
            if (message.stdout != null && message.hasOwnProperty("stdout"))
                object.stdout = message.stdout;
            if (message.stderr != null && message.hasOwnProperty("stderr"))
                object.stderr = message.stderr;
            return object;
        };

        /**
         * Converts this Progress to JSON.
         * @function toJSON
         * @memberof build_event_stream.Progress
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Progress.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Progress;
    })();

    build_event_stream.Aborted = (function() {

        /**
         * Properties of an Aborted.
         * @memberof build_event_stream
         * @interface IAborted
         * @property {build_event_stream.Aborted.AbortReason|null} [reason] Aborted reason
         * @property {string|null} [description] Aborted description
         */

        /**
         * Constructs a new Aborted.
         * @memberof build_event_stream
         * @classdesc Represents an Aborted.
         * @implements IAborted
         * @constructor
         * @param {build_event_stream.IAborted=} [properties] Properties to set
         */
        function Aborted(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Aborted reason.
         * @member {build_event_stream.Aborted.AbortReason} reason
         * @memberof build_event_stream.Aborted
         * @instance
         */
        Aborted.prototype.reason = 0;

        /**
         * Aborted description.
         * @member {string} description
         * @memberof build_event_stream.Aborted
         * @instance
         */
        Aborted.prototype.description = "";

        /**
         * Creates a new Aborted instance using the specified properties.
         * @function create
         * @memberof build_event_stream.Aborted
         * @static
         * @param {build_event_stream.IAborted=} [properties] Properties to set
         * @returns {build_event_stream.Aborted} Aborted instance
         */
        Aborted.create = function create(properties) {
            return new Aborted(properties);
        };

        /**
         * Encodes the specified Aborted message. Does not implicitly {@link build_event_stream.Aborted.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.Aborted
         * @static
         * @param {build_event_stream.IAborted} message Aborted message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Aborted.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.reason != null && message.hasOwnProperty("reason"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.reason);
            if (message.description != null && message.hasOwnProperty("description"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            return writer;
        };

        /**
         * Encodes the specified Aborted message, length delimited. Does not implicitly {@link build_event_stream.Aborted.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.Aborted
         * @static
         * @param {build_event_stream.IAborted} message Aborted message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Aborted.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Aborted message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.Aborted
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.Aborted} Aborted
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Aborted.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.Aborted();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.reason = reader.int32();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Aborted message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.Aborted
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.Aborted} Aborted
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Aborted.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Aborted message.
         * @function verify
         * @memberof build_event_stream.Aborted
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Aborted.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.reason != null && message.hasOwnProperty("reason"))
                switch (message.reason) {
                default:
                    return "reason: enum value expected";
                case 0:
                case 1:
                case 8:
                case 9:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 10:
                    break;
                }
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            return null;
        };

        /**
         * Creates an Aborted message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.Aborted
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.Aborted} Aborted
         */
        Aborted.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.Aborted)
                return object;
            let message = new $root.build_event_stream.Aborted();
            switch (object.reason) {
            case "UNKNOWN":
            case 0:
                message.reason = 0;
                break;
            case "USER_INTERRUPTED":
            case 1:
                message.reason = 1;
                break;
            case "NO_ANALYZE":
            case 8:
                message.reason = 8;
                break;
            case "NO_BUILD":
            case 9:
                message.reason = 9;
                break;
            case "TIME_OUT":
            case 2:
                message.reason = 2;
                break;
            case "REMOTE_ENVIRONMENT_FAILURE":
            case 3:
                message.reason = 3;
                break;
            case "INTERNAL":
            case 4:
                message.reason = 4;
                break;
            case "LOADING_FAILURE":
            case 5:
                message.reason = 5;
                break;
            case "ANALYSIS_FAILURE":
            case 6:
                message.reason = 6;
                break;
            case "SKIPPED":
            case 7:
                message.reason = 7;
                break;
            case "INCOMPLETE":
            case 10:
                message.reason = 10;
                break;
            }
            if (object.description != null)
                message.description = String(object.description);
            return message;
        };

        /**
         * Creates a plain object from an Aborted message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.Aborted
         * @static
         * @param {build_event_stream.Aborted} message Aborted
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Aborted.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.reason = options.enums === String ? "UNKNOWN" : 0;
                object.description = "";
            }
            if (message.reason != null && message.hasOwnProperty("reason"))
                object.reason = options.enums === String ? $root.build_event_stream.Aborted.AbortReason[message.reason] : message.reason;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };

        /**
         * Converts this Aborted to JSON.
         * @function toJSON
         * @memberof build_event_stream.Aborted
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Aborted.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * AbortReason enum.
         * @name build_event_stream.Aborted.AbortReason
         * @enum {string}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} USER_INTERRUPTED=1 USER_INTERRUPTED value
         * @property {number} NO_ANALYZE=8 NO_ANALYZE value
         * @property {number} NO_BUILD=9 NO_BUILD value
         * @property {number} TIME_OUT=2 TIME_OUT value
         * @property {number} REMOTE_ENVIRONMENT_FAILURE=3 REMOTE_ENVIRONMENT_FAILURE value
         * @property {number} INTERNAL=4 INTERNAL value
         * @property {number} LOADING_FAILURE=5 LOADING_FAILURE value
         * @property {number} ANALYSIS_FAILURE=6 ANALYSIS_FAILURE value
         * @property {number} SKIPPED=7 SKIPPED value
         * @property {number} INCOMPLETE=10 INCOMPLETE value
         */
        Aborted.AbortReason = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "USER_INTERRUPTED"] = 1;
            values[valuesById[8] = "NO_ANALYZE"] = 8;
            values[valuesById[9] = "NO_BUILD"] = 9;
            values[valuesById[2] = "TIME_OUT"] = 2;
            values[valuesById[3] = "REMOTE_ENVIRONMENT_FAILURE"] = 3;
            values[valuesById[4] = "INTERNAL"] = 4;
            values[valuesById[5] = "LOADING_FAILURE"] = 5;
            values[valuesById[6] = "ANALYSIS_FAILURE"] = 6;
            values[valuesById[7] = "SKIPPED"] = 7;
            values[valuesById[10] = "INCOMPLETE"] = 10;
            return values;
        })();

        return Aborted;
    })();

    build_event_stream.BuildStarted = (function() {

        /**
         * Properties of a BuildStarted.
         * @memberof build_event_stream
         * @interface IBuildStarted
         * @property {string|null} [uuid] BuildStarted uuid
         * @property {Long|null} [startTimeMillis] BuildStarted startTimeMillis
         * @property {string|null} [buildToolVersion] BuildStarted buildToolVersion
         * @property {string|null} [optionsDescription] BuildStarted optionsDescription
         * @property {string|null} [command] BuildStarted command
         * @property {string|null} [workingDirectory] BuildStarted workingDirectory
         * @property {string|null} [workspaceDirectory] BuildStarted workspaceDirectory
         * @property {Long|null} [serverPid] BuildStarted serverPid
         */

        /**
         * Constructs a new BuildStarted.
         * @memberof build_event_stream
         * @classdesc Represents a BuildStarted.
         * @implements IBuildStarted
         * @constructor
         * @param {build_event_stream.IBuildStarted=} [properties] Properties to set
         */
        function BuildStarted(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BuildStarted uuid.
         * @member {string} uuid
         * @memberof build_event_stream.BuildStarted
         * @instance
         */
        BuildStarted.prototype.uuid = "";

        /**
         * BuildStarted startTimeMillis.
         * @member {Long} startTimeMillis
         * @memberof build_event_stream.BuildStarted
         * @instance
         */
        BuildStarted.prototype.startTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BuildStarted buildToolVersion.
         * @member {string} buildToolVersion
         * @memberof build_event_stream.BuildStarted
         * @instance
         */
        BuildStarted.prototype.buildToolVersion = "";

        /**
         * BuildStarted optionsDescription.
         * @member {string} optionsDescription
         * @memberof build_event_stream.BuildStarted
         * @instance
         */
        BuildStarted.prototype.optionsDescription = "";

        /**
         * BuildStarted command.
         * @member {string} command
         * @memberof build_event_stream.BuildStarted
         * @instance
         */
        BuildStarted.prototype.command = "";

        /**
         * BuildStarted workingDirectory.
         * @member {string} workingDirectory
         * @memberof build_event_stream.BuildStarted
         * @instance
         */
        BuildStarted.prototype.workingDirectory = "";

        /**
         * BuildStarted workspaceDirectory.
         * @member {string} workspaceDirectory
         * @memberof build_event_stream.BuildStarted
         * @instance
         */
        BuildStarted.prototype.workspaceDirectory = "";

        /**
         * BuildStarted serverPid.
         * @member {Long} serverPid
         * @memberof build_event_stream.BuildStarted
         * @instance
         */
        BuildStarted.prototype.serverPid = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new BuildStarted instance using the specified properties.
         * @function create
         * @memberof build_event_stream.BuildStarted
         * @static
         * @param {build_event_stream.IBuildStarted=} [properties] Properties to set
         * @returns {build_event_stream.BuildStarted} BuildStarted instance
         */
        BuildStarted.create = function create(properties) {
            return new BuildStarted(properties);
        };

        /**
         * Encodes the specified BuildStarted message. Does not implicitly {@link build_event_stream.BuildStarted.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.BuildStarted
         * @static
         * @param {build_event_stream.IBuildStarted} message BuildStarted message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildStarted.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.uuid);
            if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMillis);
            if (message.buildToolVersion != null && message.hasOwnProperty("buildToolVersion"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.buildToolVersion);
            if (message.optionsDescription != null && message.hasOwnProperty("optionsDescription"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.optionsDescription);
            if (message.command != null && message.hasOwnProperty("command"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.command);
            if (message.workingDirectory != null && message.hasOwnProperty("workingDirectory"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.workingDirectory);
            if (message.workspaceDirectory != null && message.hasOwnProperty("workspaceDirectory"))
                writer.uint32(/* id 7, wireType 2 =*/58).string(message.workspaceDirectory);
            if (message.serverPid != null && message.hasOwnProperty("serverPid"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.serverPid);
            return writer;
        };

        /**
         * Encodes the specified BuildStarted message, length delimited. Does not implicitly {@link build_event_stream.BuildStarted.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.BuildStarted
         * @static
         * @param {build_event_stream.IBuildStarted} message BuildStarted message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildStarted.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BuildStarted message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.BuildStarted
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.BuildStarted} BuildStarted
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildStarted.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildStarted();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.uuid = reader.string();
                    break;
                case 2:
                    message.startTimeMillis = reader.int64();
                    break;
                case 3:
                    message.buildToolVersion = reader.string();
                    break;
                case 4:
                    message.optionsDescription = reader.string();
                    break;
                case 5:
                    message.command = reader.string();
                    break;
                case 6:
                    message.workingDirectory = reader.string();
                    break;
                case 7:
                    message.workspaceDirectory = reader.string();
                    break;
                case 8:
                    message.serverPid = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BuildStarted message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.BuildStarted
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.BuildStarted} BuildStarted
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildStarted.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BuildStarted message.
         * @function verify
         * @memberof build_event_stream.BuildStarted
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BuildStarted.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                if (!$util.isString(message.uuid))
                    return "uuid: string expected";
            if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                if (!$util.isInteger(message.startTimeMillis) && !(message.startTimeMillis && $util.isInteger(message.startTimeMillis.low) && $util.isInteger(message.startTimeMillis.high)))
                    return "startTimeMillis: integer|Long expected";
            if (message.buildToolVersion != null && message.hasOwnProperty("buildToolVersion"))
                if (!$util.isString(message.buildToolVersion))
                    return "buildToolVersion: string expected";
            if (message.optionsDescription != null && message.hasOwnProperty("optionsDescription"))
                if (!$util.isString(message.optionsDescription))
                    return "optionsDescription: string expected";
            if (message.command != null && message.hasOwnProperty("command"))
                if (!$util.isString(message.command))
                    return "command: string expected";
            if (message.workingDirectory != null && message.hasOwnProperty("workingDirectory"))
                if (!$util.isString(message.workingDirectory))
                    return "workingDirectory: string expected";
            if (message.workspaceDirectory != null && message.hasOwnProperty("workspaceDirectory"))
                if (!$util.isString(message.workspaceDirectory))
                    return "workspaceDirectory: string expected";
            if (message.serverPid != null && message.hasOwnProperty("serverPid"))
                if (!$util.isInteger(message.serverPid) && !(message.serverPid && $util.isInteger(message.serverPid.low) && $util.isInteger(message.serverPid.high)))
                    return "serverPid: integer|Long expected";
            return null;
        };

        /**
         * Creates a BuildStarted message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.BuildStarted
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.BuildStarted} BuildStarted
         */
        BuildStarted.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.BuildStarted)
                return object;
            let message = new $root.build_event_stream.BuildStarted();
            if (object.uuid != null)
                message.uuid = String(object.uuid);
            if (object.startTimeMillis != null)
                if ($util.Long)
                    (message.startTimeMillis = $util.Long.fromValue(object.startTimeMillis)).unsigned = false;
                else if (typeof object.startTimeMillis === "string")
                    message.startTimeMillis = parseInt(object.startTimeMillis, 10);
                else if (typeof object.startTimeMillis === "number")
                    message.startTimeMillis = object.startTimeMillis;
                else if (typeof object.startTimeMillis === "object")
                    message.startTimeMillis = new $util.LongBits(object.startTimeMillis.low >>> 0, object.startTimeMillis.high >>> 0).toNumber();
            if (object.buildToolVersion != null)
                message.buildToolVersion = String(object.buildToolVersion);
            if (object.optionsDescription != null)
                message.optionsDescription = String(object.optionsDescription);
            if (object.command != null)
                message.command = String(object.command);
            if (object.workingDirectory != null)
                message.workingDirectory = String(object.workingDirectory);
            if (object.workspaceDirectory != null)
                message.workspaceDirectory = String(object.workspaceDirectory);
            if (object.serverPid != null)
                if ($util.Long)
                    (message.serverPid = $util.Long.fromValue(object.serverPid)).unsigned = false;
                else if (typeof object.serverPid === "string")
                    message.serverPid = parseInt(object.serverPid, 10);
                else if (typeof object.serverPid === "number")
                    message.serverPid = object.serverPid;
                else if (typeof object.serverPid === "object")
                    message.serverPid = new $util.LongBits(object.serverPid.low >>> 0, object.serverPid.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a BuildStarted message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.BuildStarted
         * @static
         * @param {build_event_stream.BuildStarted} message BuildStarted
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BuildStarted.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.uuid = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.startTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.startTimeMillis = options.longs === String ? "0" : 0;
                object.buildToolVersion = "";
                object.optionsDescription = "";
                object.command = "";
                object.workingDirectory = "";
                object.workspaceDirectory = "";
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.serverPid = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.serverPid = options.longs === String ? "0" : 0;
            }
            if (message.uuid != null && message.hasOwnProperty("uuid"))
                object.uuid = message.uuid;
            if (message.startTimeMillis != null && message.hasOwnProperty("startTimeMillis"))
                if (typeof message.startTimeMillis === "number")
                    object.startTimeMillis = options.longs === String ? String(message.startTimeMillis) : message.startTimeMillis;
                else
                    object.startTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMillis) : options.longs === Number ? new $util.LongBits(message.startTimeMillis.low >>> 0, message.startTimeMillis.high >>> 0).toNumber() : message.startTimeMillis;
            if (message.buildToolVersion != null && message.hasOwnProperty("buildToolVersion"))
                object.buildToolVersion = message.buildToolVersion;
            if (message.optionsDescription != null && message.hasOwnProperty("optionsDescription"))
                object.optionsDescription = message.optionsDescription;
            if (message.command != null && message.hasOwnProperty("command"))
                object.command = message.command;
            if (message.workingDirectory != null && message.hasOwnProperty("workingDirectory"))
                object.workingDirectory = message.workingDirectory;
            if (message.workspaceDirectory != null && message.hasOwnProperty("workspaceDirectory"))
                object.workspaceDirectory = message.workspaceDirectory;
            if (message.serverPid != null && message.hasOwnProperty("serverPid"))
                if (typeof message.serverPid === "number")
                    object.serverPid = options.longs === String ? String(message.serverPid) : message.serverPid;
                else
                    object.serverPid = options.longs === String ? $util.Long.prototype.toString.call(message.serverPid) : options.longs === Number ? new $util.LongBits(message.serverPid.low >>> 0, message.serverPid.high >>> 0).toNumber() : message.serverPid;
            return object;
        };

        /**
         * Converts this BuildStarted to JSON.
         * @function toJSON
         * @memberof build_event_stream.BuildStarted
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BuildStarted.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BuildStarted;
    })();

    build_event_stream.WorkspaceConfig = (function() {

        /**
         * Properties of a WorkspaceConfig.
         * @memberof build_event_stream
         * @interface IWorkspaceConfig
         * @property {string|null} [localExecRoot] WorkspaceConfig localExecRoot
         */

        /**
         * Constructs a new WorkspaceConfig.
         * @memberof build_event_stream
         * @classdesc Represents a WorkspaceConfig.
         * @implements IWorkspaceConfig
         * @constructor
         * @param {build_event_stream.IWorkspaceConfig=} [properties] Properties to set
         */
        function WorkspaceConfig(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceConfig localExecRoot.
         * @member {string} localExecRoot
         * @memberof build_event_stream.WorkspaceConfig
         * @instance
         */
        WorkspaceConfig.prototype.localExecRoot = "";

        /**
         * Creates a new WorkspaceConfig instance using the specified properties.
         * @function create
         * @memberof build_event_stream.WorkspaceConfig
         * @static
         * @param {build_event_stream.IWorkspaceConfig=} [properties] Properties to set
         * @returns {build_event_stream.WorkspaceConfig} WorkspaceConfig instance
         */
        WorkspaceConfig.create = function create(properties) {
            return new WorkspaceConfig(properties);
        };

        /**
         * Encodes the specified WorkspaceConfig message. Does not implicitly {@link build_event_stream.WorkspaceConfig.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.WorkspaceConfig
         * @static
         * @param {build_event_stream.IWorkspaceConfig} message WorkspaceConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceConfig.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.localExecRoot != null && message.hasOwnProperty("localExecRoot"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.localExecRoot);
            return writer;
        };

        /**
         * Encodes the specified WorkspaceConfig message, length delimited. Does not implicitly {@link build_event_stream.WorkspaceConfig.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.WorkspaceConfig
         * @static
         * @param {build_event_stream.IWorkspaceConfig} message WorkspaceConfig message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceConfig.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceConfig message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.WorkspaceConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.WorkspaceConfig} WorkspaceConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceConfig.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.WorkspaceConfig();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.localExecRoot = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceConfig message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.WorkspaceConfig
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.WorkspaceConfig} WorkspaceConfig
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceConfig.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceConfig message.
         * @function verify
         * @memberof build_event_stream.WorkspaceConfig
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceConfig.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.localExecRoot != null && message.hasOwnProperty("localExecRoot"))
                if (!$util.isString(message.localExecRoot))
                    return "localExecRoot: string expected";
            return null;
        };

        /**
         * Creates a WorkspaceConfig message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.WorkspaceConfig
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.WorkspaceConfig} WorkspaceConfig
         */
        WorkspaceConfig.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.WorkspaceConfig)
                return object;
            let message = new $root.build_event_stream.WorkspaceConfig();
            if (object.localExecRoot != null)
                message.localExecRoot = String(object.localExecRoot);
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceConfig message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.WorkspaceConfig
         * @static
         * @param {build_event_stream.WorkspaceConfig} message WorkspaceConfig
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceConfig.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.localExecRoot = "";
            if (message.localExecRoot != null && message.hasOwnProperty("localExecRoot"))
                object.localExecRoot = message.localExecRoot;
            return object;
        };

        /**
         * Converts this WorkspaceConfig to JSON.
         * @function toJSON
         * @memberof build_event_stream.WorkspaceConfig
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WorkspaceConfig;
    })();

    build_event_stream.UnstructuredCommandLine = (function() {

        /**
         * Properties of an UnstructuredCommandLine.
         * @memberof build_event_stream
         * @interface IUnstructuredCommandLine
         * @property {Array.<string>|null} [args] UnstructuredCommandLine args
         */

        /**
         * Constructs a new UnstructuredCommandLine.
         * @memberof build_event_stream
         * @classdesc Represents an UnstructuredCommandLine.
         * @implements IUnstructuredCommandLine
         * @constructor
         * @param {build_event_stream.IUnstructuredCommandLine=} [properties] Properties to set
         */
        function UnstructuredCommandLine(properties) {
            this.args = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UnstructuredCommandLine args.
         * @member {Array.<string>} args
         * @memberof build_event_stream.UnstructuredCommandLine
         * @instance
         */
        UnstructuredCommandLine.prototype.args = $util.emptyArray;

        /**
         * Creates a new UnstructuredCommandLine instance using the specified properties.
         * @function create
         * @memberof build_event_stream.UnstructuredCommandLine
         * @static
         * @param {build_event_stream.IUnstructuredCommandLine=} [properties] Properties to set
         * @returns {build_event_stream.UnstructuredCommandLine} UnstructuredCommandLine instance
         */
        UnstructuredCommandLine.create = function create(properties) {
            return new UnstructuredCommandLine(properties);
        };

        /**
         * Encodes the specified UnstructuredCommandLine message. Does not implicitly {@link build_event_stream.UnstructuredCommandLine.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.UnstructuredCommandLine
         * @static
         * @param {build_event_stream.IUnstructuredCommandLine} message UnstructuredCommandLine message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnstructuredCommandLine.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.args != null && message.args.length)
                for (let i = 0; i < message.args.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.args[i]);
            return writer;
        };

        /**
         * Encodes the specified UnstructuredCommandLine message, length delimited. Does not implicitly {@link build_event_stream.UnstructuredCommandLine.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.UnstructuredCommandLine
         * @static
         * @param {build_event_stream.IUnstructuredCommandLine} message UnstructuredCommandLine message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UnstructuredCommandLine.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an UnstructuredCommandLine message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.UnstructuredCommandLine
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.UnstructuredCommandLine} UnstructuredCommandLine
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnstructuredCommandLine.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.UnstructuredCommandLine();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.args && message.args.length))
                        message.args = [];
                    message.args.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an UnstructuredCommandLine message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.UnstructuredCommandLine
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.UnstructuredCommandLine} UnstructuredCommandLine
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UnstructuredCommandLine.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an UnstructuredCommandLine message.
         * @function verify
         * @memberof build_event_stream.UnstructuredCommandLine
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UnstructuredCommandLine.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.args != null && message.hasOwnProperty("args")) {
                if (!Array.isArray(message.args))
                    return "args: array expected";
                for (let i = 0; i < message.args.length; ++i)
                    if (!$util.isString(message.args[i]))
                        return "args: string[] expected";
            }
            return null;
        };

        /**
         * Creates an UnstructuredCommandLine message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.UnstructuredCommandLine
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.UnstructuredCommandLine} UnstructuredCommandLine
         */
        UnstructuredCommandLine.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.UnstructuredCommandLine)
                return object;
            let message = new $root.build_event_stream.UnstructuredCommandLine();
            if (object.args) {
                if (!Array.isArray(object.args))
                    throw TypeError(".build_event_stream.UnstructuredCommandLine.args: array expected");
                message.args = [];
                for (let i = 0; i < object.args.length; ++i)
                    message.args[i] = String(object.args[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from an UnstructuredCommandLine message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.UnstructuredCommandLine
         * @static
         * @param {build_event_stream.UnstructuredCommandLine} message UnstructuredCommandLine
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UnstructuredCommandLine.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.args = [];
            if (message.args && message.args.length) {
                object.args = [];
                for (let j = 0; j < message.args.length; ++j)
                    object.args[j] = message.args[j];
            }
            return object;
        };

        /**
         * Converts this UnstructuredCommandLine to JSON.
         * @function toJSON
         * @memberof build_event_stream.UnstructuredCommandLine
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UnstructuredCommandLine.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UnstructuredCommandLine;
    })();

    build_event_stream.OptionsParsed = (function() {

        /**
         * Properties of an OptionsParsed.
         * @memberof build_event_stream
         * @interface IOptionsParsed
         * @property {Array.<string>|null} [startupOptions] OptionsParsed startupOptions
         * @property {Array.<string>|null} [explicitStartupOptions] OptionsParsed explicitStartupOptions
         * @property {Array.<string>|null} [cmdLine] OptionsParsed cmdLine
         * @property {Array.<string>|null} [explicitCmdLine] OptionsParsed explicitCmdLine
         * @property {blaze.invocation_policy.IInvocationPolicy|null} [invocationPolicy] OptionsParsed invocationPolicy
         * @property {string|null} [toolTag] OptionsParsed toolTag
         */

        /**
         * Constructs a new OptionsParsed.
         * @memberof build_event_stream
         * @classdesc Represents an OptionsParsed.
         * @implements IOptionsParsed
         * @constructor
         * @param {build_event_stream.IOptionsParsed=} [properties] Properties to set
         */
        function OptionsParsed(properties) {
            this.startupOptions = [];
            this.explicitStartupOptions = [];
            this.cmdLine = [];
            this.explicitCmdLine = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OptionsParsed startupOptions.
         * @member {Array.<string>} startupOptions
         * @memberof build_event_stream.OptionsParsed
         * @instance
         */
        OptionsParsed.prototype.startupOptions = $util.emptyArray;

        /**
         * OptionsParsed explicitStartupOptions.
         * @member {Array.<string>} explicitStartupOptions
         * @memberof build_event_stream.OptionsParsed
         * @instance
         */
        OptionsParsed.prototype.explicitStartupOptions = $util.emptyArray;

        /**
         * OptionsParsed cmdLine.
         * @member {Array.<string>} cmdLine
         * @memberof build_event_stream.OptionsParsed
         * @instance
         */
        OptionsParsed.prototype.cmdLine = $util.emptyArray;

        /**
         * OptionsParsed explicitCmdLine.
         * @member {Array.<string>} explicitCmdLine
         * @memberof build_event_stream.OptionsParsed
         * @instance
         */
        OptionsParsed.prototype.explicitCmdLine = $util.emptyArray;

        /**
         * OptionsParsed invocationPolicy.
         * @member {blaze.invocation_policy.IInvocationPolicy|null|undefined} invocationPolicy
         * @memberof build_event_stream.OptionsParsed
         * @instance
         */
        OptionsParsed.prototype.invocationPolicy = null;

        /**
         * OptionsParsed toolTag.
         * @member {string} toolTag
         * @memberof build_event_stream.OptionsParsed
         * @instance
         */
        OptionsParsed.prototype.toolTag = "";

        /**
         * Creates a new OptionsParsed instance using the specified properties.
         * @function create
         * @memberof build_event_stream.OptionsParsed
         * @static
         * @param {build_event_stream.IOptionsParsed=} [properties] Properties to set
         * @returns {build_event_stream.OptionsParsed} OptionsParsed instance
         */
        OptionsParsed.create = function create(properties) {
            return new OptionsParsed(properties);
        };

        /**
         * Encodes the specified OptionsParsed message. Does not implicitly {@link build_event_stream.OptionsParsed.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.OptionsParsed
         * @static
         * @param {build_event_stream.IOptionsParsed} message OptionsParsed message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OptionsParsed.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.startupOptions != null && message.startupOptions.length)
                for (let i = 0; i < message.startupOptions.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.startupOptions[i]);
            if (message.explicitStartupOptions != null && message.explicitStartupOptions.length)
                for (let i = 0; i < message.explicitStartupOptions.length; ++i)
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.explicitStartupOptions[i]);
            if (message.cmdLine != null && message.cmdLine.length)
                for (let i = 0; i < message.cmdLine.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.cmdLine[i]);
            if (message.explicitCmdLine != null && message.explicitCmdLine.length)
                for (let i = 0; i < message.explicitCmdLine.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.explicitCmdLine[i]);
            if (message.invocationPolicy != null && message.hasOwnProperty("invocationPolicy"))
                $root.blaze.invocation_policy.InvocationPolicy.encode(message.invocationPolicy, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.toolTag != null && message.hasOwnProperty("toolTag"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.toolTag);
            return writer;
        };

        /**
         * Encodes the specified OptionsParsed message, length delimited. Does not implicitly {@link build_event_stream.OptionsParsed.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.OptionsParsed
         * @static
         * @param {build_event_stream.IOptionsParsed} message OptionsParsed message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OptionsParsed.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OptionsParsed message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.OptionsParsed
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.OptionsParsed} OptionsParsed
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OptionsParsed.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.OptionsParsed();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.startupOptions && message.startupOptions.length))
                        message.startupOptions = [];
                    message.startupOptions.push(reader.string());
                    break;
                case 2:
                    if (!(message.explicitStartupOptions && message.explicitStartupOptions.length))
                        message.explicitStartupOptions = [];
                    message.explicitStartupOptions.push(reader.string());
                    break;
                case 3:
                    if (!(message.cmdLine && message.cmdLine.length))
                        message.cmdLine = [];
                    message.cmdLine.push(reader.string());
                    break;
                case 4:
                    if (!(message.explicitCmdLine && message.explicitCmdLine.length))
                        message.explicitCmdLine = [];
                    message.explicitCmdLine.push(reader.string());
                    break;
                case 5:
                    message.invocationPolicy = $root.blaze.invocation_policy.InvocationPolicy.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.toolTag = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OptionsParsed message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.OptionsParsed
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.OptionsParsed} OptionsParsed
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OptionsParsed.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OptionsParsed message.
         * @function verify
         * @memberof build_event_stream.OptionsParsed
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OptionsParsed.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.startupOptions != null && message.hasOwnProperty("startupOptions")) {
                if (!Array.isArray(message.startupOptions))
                    return "startupOptions: array expected";
                for (let i = 0; i < message.startupOptions.length; ++i)
                    if (!$util.isString(message.startupOptions[i]))
                        return "startupOptions: string[] expected";
            }
            if (message.explicitStartupOptions != null && message.hasOwnProperty("explicitStartupOptions")) {
                if (!Array.isArray(message.explicitStartupOptions))
                    return "explicitStartupOptions: array expected";
                for (let i = 0; i < message.explicitStartupOptions.length; ++i)
                    if (!$util.isString(message.explicitStartupOptions[i]))
                        return "explicitStartupOptions: string[] expected";
            }
            if (message.cmdLine != null && message.hasOwnProperty("cmdLine")) {
                if (!Array.isArray(message.cmdLine))
                    return "cmdLine: array expected";
                for (let i = 0; i < message.cmdLine.length; ++i)
                    if (!$util.isString(message.cmdLine[i]))
                        return "cmdLine: string[] expected";
            }
            if (message.explicitCmdLine != null && message.hasOwnProperty("explicitCmdLine")) {
                if (!Array.isArray(message.explicitCmdLine))
                    return "explicitCmdLine: array expected";
                for (let i = 0; i < message.explicitCmdLine.length; ++i)
                    if (!$util.isString(message.explicitCmdLine[i]))
                        return "explicitCmdLine: string[] expected";
            }
            if (message.invocationPolicy != null && message.hasOwnProperty("invocationPolicy")) {
                let error = $root.blaze.invocation_policy.InvocationPolicy.verify(message.invocationPolicy);
                if (error)
                    return "invocationPolicy." + error;
            }
            if (message.toolTag != null && message.hasOwnProperty("toolTag"))
                if (!$util.isString(message.toolTag))
                    return "toolTag: string expected";
            return null;
        };

        /**
         * Creates an OptionsParsed message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.OptionsParsed
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.OptionsParsed} OptionsParsed
         */
        OptionsParsed.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.OptionsParsed)
                return object;
            let message = new $root.build_event_stream.OptionsParsed();
            if (object.startupOptions) {
                if (!Array.isArray(object.startupOptions))
                    throw TypeError(".build_event_stream.OptionsParsed.startupOptions: array expected");
                message.startupOptions = [];
                for (let i = 0; i < object.startupOptions.length; ++i)
                    message.startupOptions[i] = String(object.startupOptions[i]);
            }
            if (object.explicitStartupOptions) {
                if (!Array.isArray(object.explicitStartupOptions))
                    throw TypeError(".build_event_stream.OptionsParsed.explicitStartupOptions: array expected");
                message.explicitStartupOptions = [];
                for (let i = 0; i < object.explicitStartupOptions.length; ++i)
                    message.explicitStartupOptions[i] = String(object.explicitStartupOptions[i]);
            }
            if (object.cmdLine) {
                if (!Array.isArray(object.cmdLine))
                    throw TypeError(".build_event_stream.OptionsParsed.cmdLine: array expected");
                message.cmdLine = [];
                for (let i = 0; i < object.cmdLine.length; ++i)
                    message.cmdLine[i] = String(object.cmdLine[i]);
            }
            if (object.explicitCmdLine) {
                if (!Array.isArray(object.explicitCmdLine))
                    throw TypeError(".build_event_stream.OptionsParsed.explicitCmdLine: array expected");
                message.explicitCmdLine = [];
                for (let i = 0; i < object.explicitCmdLine.length; ++i)
                    message.explicitCmdLine[i] = String(object.explicitCmdLine[i]);
            }
            if (object.invocationPolicy != null) {
                if (typeof object.invocationPolicy !== "object")
                    throw TypeError(".build_event_stream.OptionsParsed.invocationPolicy: object expected");
                message.invocationPolicy = $root.blaze.invocation_policy.InvocationPolicy.fromObject(object.invocationPolicy);
            }
            if (object.toolTag != null)
                message.toolTag = String(object.toolTag);
            return message;
        };

        /**
         * Creates a plain object from an OptionsParsed message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.OptionsParsed
         * @static
         * @param {build_event_stream.OptionsParsed} message OptionsParsed
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OptionsParsed.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.startupOptions = [];
                object.explicitStartupOptions = [];
                object.cmdLine = [];
                object.explicitCmdLine = [];
            }
            if (options.defaults) {
                object.invocationPolicy = null;
                object.toolTag = "";
            }
            if (message.startupOptions && message.startupOptions.length) {
                object.startupOptions = [];
                for (let j = 0; j < message.startupOptions.length; ++j)
                    object.startupOptions[j] = message.startupOptions[j];
            }
            if (message.explicitStartupOptions && message.explicitStartupOptions.length) {
                object.explicitStartupOptions = [];
                for (let j = 0; j < message.explicitStartupOptions.length; ++j)
                    object.explicitStartupOptions[j] = message.explicitStartupOptions[j];
            }
            if (message.cmdLine && message.cmdLine.length) {
                object.cmdLine = [];
                for (let j = 0; j < message.cmdLine.length; ++j)
                    object.cmdLine[j] = message.cmdLine[j];
            }
            if (message.explicitCmdLine && message.explicitCmdLine.length) {
                object.explicitCmdLine = [];
                for (let j = 0; j < message.explicitCmdLine.length; ++j)
                    object.explicitCmdLine[j] = message.explicitCmdLine[j];
            }
            if (message.invocationPolicy != null && message.hasOwnProperty("invocationPolicy"))
                object.invocationPolicy = $root.blaze.invocation_policy.InvocationPolicy.toObject(message.invocationPolicy, options);
            if (message.toolTag != null && message.hasOwnProperty("toolTag"))
                object.toolTag = message.toolTag;
            return object;
        };

        /**
         * Converts this OptionsParsed to JSON.
         * @function toJSON
         * @memberof build_event_stream.OptionsParsed
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OptionsParsed.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OptionsParsed;
    })();

    build_event_stream.Fetch = (function() {

        /**
         * Properties of a Fetch.
         * @memberof build_event_stream
         * @interface IFetch
         * @property {boolean|null} [success] Fetch success
         */

        /**
         * Constructs a new Fetch.
         * @memberof build_event_stream
         * @classdesc Represents a Fetch.
         * @implements IFetch
         * @constructor
         * @param {build_event_stream.IFetch=} [properties] Properties to set
         */
        function Fetch(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Fetch success.
         * @member {boolean} success
         * @memberof build_event_stream.Fetch
         * @instance
         */
        Fetch.prototype.success = false;

        /**
         * Creates a new Fetch instance using the specified properties.
         * @function create
         * @memberof build_event_stream.Fetch
         * @static
         * @param {build_event_stream.IFetch=} [properties] Properties to set
         * @returns {build_event_stream.Fetch} Fetch instance
         */
        Fetch.create = function create(properties) {
            return new Fetch(properties);
        };

        /**
         * Encodes the specified Fetch message. Does not implicitly {@link build_event_stream.Fetch.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.Fetch
         * @static
         * @param {build_event_stream.IFetch} message Fetch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Fetch.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && message.hasOwnProperty("success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            return writer;
        };

        /**
         * Encodes the specified Fetch message, length delimited. Does not implicitly {@link build_event_stream.Fetch.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.Fetch
         * @static
         * @param {build_event_stream.IFetch} message Fetch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Fetch.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Fetch message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.Fetch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.Fetch} Fetch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Fetch.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.Fetch();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.success = reader.bool();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Fetch message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.Fetch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.Fetch} Fetch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Fetch.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Fetch message.
         * @function verify
         * @memberof build_event_stream.Fetch
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Fetch.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            return null;
        };

        /**
         * Creates a Fetch message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.Fetch
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.Fetch} Fetch
         */
        Fetch.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.Fetch)
                return object;
            let message = new $root.build_event_stream.Fetch();
            if (object.success != null)
                message.success = Boolean(object.success);
            return message;
        };

        /**
         * Creates a plain object from a Fetch message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.Fetch
         * @static
         * @param {build_event_stream.Fetch} message Fetch
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Fetch.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.success = false;
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            return object;
        };

        /**
         * Converts this Fetch to JSON.
         * @function toJSON
         * @memberof build_event_stream.Fetch
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Fetch.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Fetch;
    })();

    build_event_stream.WorkspaceStatus = (function() {

        /**
         * Properties of a WorkspaceStatus.
         * @memberof build_event_stream
         * @interface IWorkspaceStatus
         * @property {Array.<build_event_stream.WorkspaceStatus.IItem>|null} [item] WorkspaceStatus item
         */

        /**
         * Constructs a new WorkspaceStatus.
         * @memberof build_event_stream
         * @classdesc Represents a WorkspaceStatus.
         * @implements IWorkspaceStatus
         * @constructor
         * @param {build_event_stream.IWorkspaceStatus=} [properties] Properties to set
         */
        function WorkspaceStatus(properties) {
            this.item = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * WorkspaceStatus item.
         * @member {Array.<build_event_stream.WorkspaceStatus.IItem>} item
         * @memberof build_event_stream.WorkspaceStatus
         * @instance
         */
        WorkspaceStatus.prototype.item = $util.emptyArray;

        /**
         * Creates a new WorkspaceStatus instance using the specified properties.
         * @function create
         * @memberof build_event_stream.WorkspaceStatus
         * @static
         * @param {build_event_stream.IWorkspaceStatus=} [properties] Properties to set
         * @returns {build_event_stream.WorkspaceStatus} WorkspaceStatus instance
         */
        WorkspaceStatus.create = function create(properties) {
            return new WorkspaceStatus(properties);
        };

        /**
         * Encodes the specified WorkspaceStatus message. Does not implicitly {@link build_event_stream.WorkspaceStatus.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.WorkspaceStatus
         * @static
         * @param {build_event_stream.IWorkspaceStatus} message WorkspaceStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceStatus.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.item != null && message.item.length)
                for (let i = 0; i < message.item.length; ++i)
                    $root.build_event_stream.WorkspaceStatus.Item.encode(message.item[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified WorkspaceStatus message, length delimited. Does not implicitly {@link build_event_stream.WorkspaceStatus.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.WorkspaceStatus
         * @static
         * @param {build_event_stream.IWorkspaceStatus} message WorkspaceStatus message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkspaceStatus.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a WorkspaceStatus message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.WorkspaceStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.WorkspaceStatus} WorkspaceStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceStatus.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.WorkspaceStatus();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.item && message.item.length))
                        message.item = [];
                    message.item.push($root.build_event_stream.WorkspaceStatus.Item.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a WorkspaceStatus message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.WorkspaceStatus
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.WorkspaceStatus} WorkspaceStatus
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkspaceStatus.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a WorkspaceStatus message.
         * @function verify
         * @memberof build_event_stream.WorkspaceStatus
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkspaceStatus.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.item != null && message.hasOwnProperty("item")) {
                if (!Array.isArray(message.item))
                    return "item: array expected";
                for (let i = 0; i < message.item.length; ++i) {
                    let error = $root.build_event_stream.WorkspaceStatus.Item.verify(message.item[i]);
                    if (error)
                        return "item." + error;
                }
            }
            return null;
        };

        /**
         * Creates a WorkspaceStatus message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.WorkspaceStatus
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.WorkspaceStatus} WorkspaceStatus
         */
        WorkspaceStatus.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.WorkspaceStatus)
                return object;
            let message = new $root.build_event_stream.WorkspaceStatus();
            if (object.item) {
                if (!Array.isArray(object.item))
                    throw TypeError(".build_event_stream.WorkspaceStatus.item: array expected");
                message.item = [];
                for (let i = 0; i < object.item.length; ++i) {
                    if (typeof object.item[i] !== "object")
                        throw TypeError(".build_event_stream.WorkspaceStatus.item: object expected");
                    message.item[i] = $root.build_event_stream.WorkspaceStatus.Item.fromObject(object.item[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a WorkspaceStatus message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.WorkspaceStatus
         * @static
         * @param {build_event_stream.WorkspaceStatus} message WorkspaceStatus
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkspaceStatus.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.item = [];
            if (message.item && message.item.length) {
                object.item = [];
                for (let j = 0; j < message.item.length; ++j)
                    object.item[j] = $root.build_event_stream.WorkspaceStatus.Item.toObject(message.item[j], options);
            }
            return object;
        };

        /**
         * Converts this WorkspaceStatus to JSON.
         * @function toJSON
         * @memberof build_event_stream.WorkspaceStatus
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkspaceStatus.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        WorkspaceStatus.Item = (function() {

            /**
             * Properties of an Item.
             * @memberof build_event_stream.WorkspaceStatus
             * @interface IItem
             * @property {string|null} [key] Item key
             * @property {string|null} [value] Item value
             */

            /**
             * Constructs a new Item.
             * @memberof build_event_stream.WorkspaceStatus
             * @classdesc Represents an Item.
             * @implements IItem
             * @constructor
             * @param {build_event_stream.WorkspaceStatus.IItem=} [properties] Properties to set
             */
            function Item(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Item key.
             * @member {string} key
             * @memberof build_event_stream.WorkspaceStatus.Item
             * @instance
             */
            Item.prototype.key = "";

            /**
             * Item value.
             * @member {string} value
             * @memberof build_event_stream.WorkspaceStatus.Item
             * @instance
             */
            Item.prototype.value = "";

            /**
             * Creates a new Item instance using the specified properties.
             * @function create
             * @memberof build_event_stream.WorkspaceStatus.Item
             * @static
             * @param {build_event_stream.WorkspaceStatus.IItem=} [properties] Properties to set
             * @returns {build_event_stream.WorkspaceStatus.Item} Item instance
             */
            Item.create = function create(properties) {
                return new Item(properties);
            };

            /**
             * Encodes the specified Item message. Does not implicitly {@link build_event_stream.WorkspaceStatus.Item.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.WorkspaceStatus.Item
             * @static
             * @param {build_event_stream.WorkspaceStatus.IItem} message Item message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Item.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.key != null && message.hasOwnProperty("key"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.key);
                if (message.value != null && message.hasOwnProperty("value"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
                return writer;
            };

            /**
             * Encodes the specified Item message, length delimited. Does not implicitly {@link build_event_stream.WorkspaceStatus.Item.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.WorkspaceStatus.Item
             * @static
             * @param {build_event_stream.WorkspaceStatus.IItem} message Item message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Item.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Item message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.WorkspaceStatus.Item
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.WorkspaceStatus.Item} Item
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Item.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.WorkspaceStatus.Item();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.key = reader.string();
                        break;
                    case 2:
                        message.value = reader.string();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Item message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.WorkspaceStatus.Item
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.WorkspaceStatus.Item} Item
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Item.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Item message.
             * @function verify
             * @memberof build_event_stream.WorkspaceStatus.Item
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Item.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.key != null && message.hasOwnProperty("key"))
                    if (!$util.isString(message.key))
                        return "key: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isString(message.value))
                        return "value: string expected";
                return null;
            };

            /**
             * Creates an Item message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.WorkspaceStatus.Item
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.WorkspaceStatus.Item} Item
             */
            Item.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.WorkspaceStatus.Item)
                    return object;
                let message = new $root.build_event_stream.WorkspaceStatus.Item();
                if (object.key != null)
                    message.key = String(object.key);
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };

            /**
             * Creates a plain object from an Item message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.WorkspaceStatus.Item
             * @static
             * @param {build_event_stream.WorkspaceStatus.Item} message Item
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Item.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.key = "";
                    object.value = "";
                }
                if (message.key != null && message.hasOwnProperty("key"))
                    object.key = message.key;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this Item to JSON.
             * @function toJSON
             * @memberof build_event_stream.WorkspaceStatus.Item
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Item.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Item;
        })();

        return WorkspaceStatus;
    })();

    build_event_stream.BuildMetadata = (function() {

        /**
         * Properties of a BuildMetadata.
         * @memberof build_event_stream
         * @interface IBuildMetadata
         * @property {Object.<string,string>|null} [metadata] BuildMetadata metadata
         */

        /**
         * Constructs a new BuildMetadata.
         * @memberof build_event_stream
         * @classdesc Represents a BuildMetadata.
         * @implements IBuildMetadata
         * @constructor
         * @param {build_event_stream.IBuildMetadata=} [properties] Properties to set
         */
        function BuildMetadata(properties) {
            this.metadata = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BuildMetadata metadata.
         * @member {Object.<string,string>} metadata
         * @memberof build_event_stream.BuildMetadata
         * @instance
         */
        BuildMetadata.prototype.metadata = $util.emptyObject;

        /**
         * Creates a new BuildMetadata instance using the specified properties.
         * @function create
         * @memberof build_event_stream.BuildMetadata
         * @static
         * @param {build_event_stream.IBuildMetadata=} [properties] Properties to set
         * @returns {build_event_stream.BuildMetadata} BuildMetadata instance
         */
        BuildMetadata.create = function create(properties) {
            return new BuildMetadata(properties);
        };

        /**
         * Encodes the specified BuildMetadata message. Does not implicitly {@link build_event_stream.BuildMetadata.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.BuildMetadata
         * @static
         * @param {build_event_stream.IBuildMetadata} message BuildMetadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildMetadata.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.metadata != null && message.hasOwnProperty("metadata"))
                for (let keys = Object.keys(message.metadata), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.metadata[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified BuildMetadata message, length delimited. Does not implicitly {@link build_event_stream.BuildMetadata.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.BuildMetadata
         * @static
         * @param {build_event_stream.IBuildMetadata} message BuildMetadata message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildMetadata.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BuildMetadata message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.BuildMetadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.BuildMetadata} BuildMetadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildMetadata.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildMetadata(), key;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    reader.skip().pos++;
                    if (message.metadata === $util.emptyObject)
                        message.metadata = {};
                    key = reader.string();
                    reader.pos++;
                    message.metadata[key] = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BuildMetadata message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.BuildMetadata
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.BuildMetadata} BuildMetadata
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildMetadata.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BuildMetadata message.
         * @function verify
         * @memberof build_event_stream.BuildMetadata
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BuildMetadata.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.metadata != null && message.hasOwnProperty("metadata")) {
                if (!$util.isObject(message.metadata))
                    return "metadata: object expected";
                let key = Object.keys(message.metadata);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.metadata[key[i]]))
                        return "metadata: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates a BuildMetadata message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.BuildMetadata
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.BuildMetadata} BuildMetadata
         */
        BuildMetadata.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.BuildMetadata)
                return object;
            let message = new $root.build_event_stream.BuildMetadata();
            if (object.metadata) {
                if (typeof object.metadata !== "object")
                    throw TypeError(".build_event_stream.BuildMetadata.metadata: object expected");
                message.metadata = {};
                for (let keys = Object.keys(object.metadata), i = 0; i < keys.length; ++i)
                    message.metadata[keys[i]] = String(object.metadata[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from a BuildMetadata message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.BuildMetadata
         * @static
         * @param {build_event_stream.BuildMetadata} message BuildMetadata
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BuildMetadata.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.metadata = {};
            let keys2;
            if (message.metadata && (keys2 = Object.keys(message.metadata)).length) {
                object.metadata = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.metadata[keys2[j]] = message.metadata[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this BuildMetadata to JSON.
         * @function toJSON
         * @memberof build_event_stream.BuildMetadata
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BuildMetadata.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BuildMetadata;
    })();

    build_event_stream.Configuration = (function() {

        /**
         * Properties of a Configuration.
         * @memberof build_event_stream
         * @interface IConfiguration
         * @property {string|null} [mnemonic] Configuration mnemonic
         * @property {string|null} [platformName] Configuration platformName
         * @property {string|null} [cpu] Configuration cpu
         * @property {Object.<string,string>|null} [makeVariable] Configuration makeVariable
         */

        /**
         * Constructs a new Configuration.
         * @memberof build_event_stream
         * @classdesc Represents a Configuration.
         * @implements IConfiguration
         * @constructor
         * @param {build_event_stream.IConfiguration=} [properties] Properties to set
         */
        function Configuration(properties) {
            this.makeVariable = {};
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Configuration mnemonic.
         * @member {string} mnemonic
         * @memberof build_event_stream.Configuration
         * @instance
         */
        Configuration.prototype.mnemonic = "";

        /**
         * Configuration platformName.
         * @member {string} platformName
         * @memberof build_event_stream.Configuration
         * @instance
         */
        Configuration.prototype.platformName = "";

        /**
         * Configuration cpu.
         * @member {string} cpu
         * @memberof build_event_stream.Configuration
         * @instance
         */
        Configuration.prototype.cpu = "";

        /**
         * Configuration makeVariable.
         * @member {Object.<string,string>} makeVariable
         * @memberof build_event_stream.Configuration
         * @instance
         */
        Configuration.prototype.makeVariable = $util.emptyObject;

        /**
         * Creates a new Configuration instance using the specified properties.
         * @function create
         * @memberof build_event_stream.Configuration
         * @static
         * @param {build_event_stream.IConfiguration=} [properties] Properties to set
         * @returns {build_event_stream.Configuration} Configuration instance
         */
        Configuration.create = function create(properties) {
            return new Configuration(properties);
        };

        /**
         * Encodes the specified Configuration message. Does not implicitly {@link build_event_stream.Configuration.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.Configuration
         * @static
         * @param {build_event_stream.IConfiguration} message Configuration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Configuration.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.mnemonic != null && message.hasOwnProperty("mnemonic"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.mnemonic);
            if (message.platformName != null && message.hasOwnProperty("platformName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.platformName);
            if (message.cpu != null && message.hasOwnProperty("cpu"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.cpu);
            if (message.makeVariable != null && message.hasOwnProperty("makeVariable"))
                for (let keys = Object.keys(message.makeVariable), i = 0; i < keys.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).fork().uint32(/* id 1, wireType 2 =*/10).string(keys[i]).uint32(/* id 2, wireType 2 =*/18).string(message.makeVariable[keys[i]]).ldelim();
            return writer;
        };

        /**
         * Encodes the specified Configuration message, length delimited. Does not implicitly {@link build_event_stream.Configuration.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.Configuration
         * @static
         * @param {build_event_stream.IConfiguration} message Configuration message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Configuration.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Configuration message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.Configuration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.Configuration} Configuration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Configuration.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.Configuration(), key;
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.mnemonic = reader.string();
                    break;
                case 2:
                    message.platformName = reader.string();
                    break;
                case 3:
                    message.cpu = reader.string();
                    break;
                case 4:
                    reader.skip().pos++;
                    if (message.makeVariable === $util.emptyObject)
                        message.makeVariable = {};
                    key = reader.string();
                    reader.pos++;
                    message.makeVariable[key] = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Configuration message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.Configuration
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.Configuration} Configuration
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Configuration.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Configuration message.
         * @function verify
         * @memberof build_event_stream.Configuration
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Configuration.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.mnemonic != null && message.hasOwnProperty("mnemonic"))
                if (!$util.isString(message.mnemonic))
                    return "mnemonic: string expected";
            if (message.platformName != null && message.hasOwnProperty("platformName"))
                if (!$util.isString(message.platformName))
                    return "platformName: string expected";
            if (message.cpu != null && message.hasOwnProperty("cpu"))
                if (!$util.isString(message.cpu))
                    return "cpu: string expected";
            if (message.makeVariable != null && message.hasOwnProperty("makeVariable")) {
                if (!$util.isObject(message.makeVariable))
                    return "makeVariable: object expected";
                let key = Object.keys(message.makeVariable);
                for (let i = 0; i < key.length; ++i)
                    if (!$util.isString(message.makeVariable[key[i]]))
                        return "makeVariable: string{k:string} expected";
            }
            return null;
        };

        /**
         * Creates a Configuration message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.Configuration
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.Configuration} Configuration
         */
        Configuration.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.Configuration)
                return object;
            let message = new $root.build_event_stream.Configuration();
            if (object.mnemonic != null)
                message.mnemonic = String(object.mnemonic);
            if (object.platformName != null)
                message.platformName = String(object.platformName);
            if (object.cpu != null)
                message.cpu = String(object.cpu);
            if (object.makeVariable) {
                if (typeof object.makeVariable !== "object")
                    throw TypeError(".build_event_stream.Configuration.makeVariable: object expected");
                message.makeVariable = {};
                for (let keys = Object.keys(object.makeVariable), i = 0; i < keys.length; ++i)
                    message.makeVariable[keys[i]] = String(object.makeVariable[keys[i]]);
            }
            return message;
        };

        /**
         * Creates a plain object from a Configuration message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.Configuration
         * @static
         * @param {build_event_stream.Configuration} message Configuration
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Configuration.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.objects || options.defaults)
                object.makeVariable = {};
            if (options.defaults) {
                object.mnemonic = "";
                object.platformName = "";
                object.cpu = "";
            }
            if (message.mnemonic != null && message.hasOwnProperty("mnemonic"))
                object.mnemonic = message.mnemonic;
            if (message.platformName != null && message.hasOwnProperty("platformName"))
                object.platformName = message.platformName;
            if (message.cpu != null && message.hasOwnProperty("cpu"))
                object.cpu = message.cpu;
            let keys2;
            if (message.makeVariable && (keys2 = Object.keys(message.makeVariable)).length) {
                object.makeVariable = {};
                for (let j = 0; j < keys2.length; ++j)
                    object.makeVariable[keys2[j]] = message.makeVariable[keys2[j]];
            }
            return object;
        };

        /**
         * Converts this Configuration to JSON.
         * @function toJSON
         * @memberof build_event_stream.Configuration
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Configuration.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Configuration;
    })();

    build_event_stream.PatternExpanded = (function() {

        /**
         * Properties of a PatternExpanded.
         * @memberof build_event_stream
         * @interface IPatternExpanded
         */

        /**
         * Constructs a new PatternExpanded.
         * @memberof build_event_stream
         * @classdesc Represents a PatternExpanded.
         * @implements IPatternExpanded
         * @constructor
         * @param {build_event_stream.IPatternExpanded=} [properties] Properties to set
         */
        function PatternExpanded(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new PatternExpanded instance using the specified properties.
         * @function create
         * @memberof build_event_stream.PatternExpanded
         * @static
         * @param {build_event_stream.IPatternExpanded=} [properties] Properties to set
         * @returns {build_event_stream.PatternExpanded} PatternExpanded instance
         */
        PatternExpanded.create = function create(properties) {
            return new PatternExpanded(properties);
        };

        /**
         * Encodes the specified PatternExpanded message. Does not implicitly {@link build_event_stream.PatternExpanded.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.PatternExpanded
         * @static
         * @param {build_event_stream.IPatternExpanded} message PatternExpanded message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PatternExpanded.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified PatternExpanded message, length delimited. Does not implicitly {@link build_event_stream.PatternExpanded.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.PatternExpanded
         * @static
         * @param {build_event_stream.IPatternExpanded} message PatternExpanded message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PatternExpanded.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a PatternExpanded message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.PatternExpanded
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.PatternExpanded} PatternExpanded
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PatternExpanded.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.PatternExpanded();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a PatternExpanded message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.PatternExpanded
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.PatternExpanded} PatternExpanded
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PatternExpanded.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a PatternExpanded message.
         * @function verify
         * @memberof build_event_stream.PatternExpanded
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PatternExpanded.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a PatternExpanded message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.PatternExpanded
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.PatternExpanded} PatternExpanded
         */
        PatternExpanded.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.PatternExpanded)
                return object;
            return new $root.build_event_stream.PatternExpanded();
        };

        /**
         * Creates a plain object from a PatternExpanded message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.PatternExpanded
         * @static
         * @param {build_event_stream.PatternExpanded} message PatternExpanded
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PatternExpanded.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this PatternExpanded to JSON.
         * @function toJSON
         * @memberof build_event_stream.PatternExpanded
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PatternExpanded.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PatternExpanded;
    })();

    /**
     * TestSize enum.
     * @name build_event_stream.TestSize
     * @enum {string}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} SMALL=1 SMALL value
     * @property {number} MEDIUM=2 MEDIUM value
     * @property {number} LARGE=3 LARGE value
     * @property {number} ENORMOUS=4 ENORMOUS value
     */
    build_event_stream.TestSize = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "SMALL"] = 1;
        values[valuesById[2] = "MEDIUM"] = 2;
        values[valuesById[3] = "LARGE"] = 3;
        values[valuesById[4] = "ENORMOUS"] = 4;
        return values;
    })();

    build_event_stream.TargetConfigured = (function() {

        /**
         * Properties of a TargetConfigured.
         * @memberof build_event_stream
         * @interface ITargetConfigured
         * @property {string|null} [targetKind] TargetConfigured targetKind
         * @property {build_event_stream.TestSize|null} [testSize] TargetConfigured testSize
         * @property {Array.<string>|null} [tag] TargetConfigured tag
         */

        /**
         * Constructs a new TargetConfigured.
         * @memberof build_event_stream
         * @classdesc Represents a TargetConfigured.
         * @implements ITargetConfigured
         * @constructor
         * @param {build_event_stream.ITargetConfigured=} [properties] Properties to set
         */
        function TargetConfigured(properties) {
            this.tag = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TargetConfigured targetKind.
         * @member {string} targetKind
         * @memberof build_event_stream.TargetConfigured
         * @instance
         */
        TargetConfigured.prototype.targetKind = "";

        /**
         * TargetConfigured testSize.
         * @member {build_event_stream.TestSize} testSize
         * @memberof build_event_stream.TargetConfigured
         * @instance
         */
        TargetConfigured.prototype.testSize = 0;

        /**
         * TargetConfigured tag.
         * @member {Array.<string>} tag
         * @memberof build_event_stream.TargetConfigured
         * @instance
         */
        TargetConfigured.prototype.tag = $util.emptyArray;

        /**
         * Creates a new TargetConfigured instance using the specified properties.
         * @function create
         * @memberof build_event_stream.TargetConfigured
         * @static
         * @param {build_event_stream.ITargetConfigured=} [properties] Properties to set
         * @returns {build_event_stream.TargetConfigured} TargetConfigured instance
         */
        TargetConfigured.create = function create(properties) {
            return new TargetConfigured(properties);
        };

        /**
         * Encodes the specified TargetConfigured message. Does not implicitly {@link build_event_stream.TargetConfigured.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.TargetConfigured
         * @static
         * @param {build_event_stream.ITargetConfigured} message TargetConfigured message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TargetConfigured.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.targetKind != null && message.hasOwnProperty("targetKind"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.targetKind);
            if (message.testSize != null && message.hasOwnProperty("testSize"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.testSize);
            if (message.tag != null && message.tag.length)
                for (let i = 0; i < message.tag.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.tag[i]);
            return writer;
        };

        /**
         * Encodes the specified TargetConfigured message, length delimited. Does not implicitly {@link build_event_stream.TargetConfigured.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.TargetConfigured
         * @static
         * @param {build_event_stream.ITargetConfigured} message TargetConfigured message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TargetConfigured.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TargetConfigured message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.TargetConfigured
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.TargetConfigured} TargetConfigured
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TargetConfigured.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.TargetConfigured();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.targetKind = reader.string();
                    break;
                case 2:
                    message.testSize = reader.int32();
                    break;
                case 3:
                    if (!(message.tag && message.tag.length))
                        message.tag = [];
                    message.tag.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TargetConfigured message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.TargetConfigured
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.TargetConfigured} TargetConfigured
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TargetConfigured.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TargetConfigured message.
         * @function verify
         * @memberof build_event_stream.TargetConfigured
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TargetConfigured.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.targetKind != null && message.hasOwnProperty("targetKind"))
                if (!$util.isString(message.targetKind))
                    return "targetKind: string expected";
            if (message.testSize != null && message.hasOwnProperty("testSize"))
                switch (message.testSize) {
                default:
                    return "testSize: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.tag != null && message.hasOwnProperty("tag")) {
                if (!Array.isArray(message.tag))
                    return "tag: array expected";
                for (let i = 0; i < message.tag.length; ++i)
                    if (!$util.isString(message.tag[i]))
                        return "tag: string[] expected";
            }
            return null;
        };

        /**
         * Creates a TargetConfigured message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.TargetConfigured
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.TargetConfigured} TargetConfigured
         */
        TargetConfigured.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.TargetConfigured)
                return object;
            let message = new $root.build_event_stream.TargetConfigured();
            if (object.targetKind != null)
                message.targetKind = String(object.targetKind);
            switch (object.testSize) {
            case "UNKNOWN":
            case 0:
                message.testSize = 0;
                break;
            case "SMALL":
            case 1:
                message.testSize = 1;
                break;
            case "MEDIUM":
            case 2:
                message.testSize = 2;
                break;
            case "LARGE":
            case 3:
                message.testSize = 3;
                break;
            case "ENORMOUS":
            case 4:
                message.testSize = 4;
                break;
            }
            if (object.tag) {
                if (!Array.isArray(object.tag))
                    throw TypeError(".build_event_stream.TargetConfigured.tag: array expected");
                message.tag = [];
                for (let i = 0; i < object.tag.length; ++i)
                    message.tag[i] = String(object.tag[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a TargetConfigured message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.TargetConfigured
         * @static
         * @param {build_event_stream.TargetConfigured} message TargetConfigured
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TargetConfigured.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.tag = [];
            if (options.defaults) {
                object.targetKind = "";
                object.testSize = options.enums === String ? "UNKNOWN" : 0;
            }
            if (message.targetKind != null && message.hasOwnProperty("targetKind"))
                object.targetKind = message.targetKind;
            if (message.testSize != null && message.hasOwnProperty("testSize"))
                object.testSize = options.enums === String ? $root.build_event_stream.TestSize[message.testSize] : message.testSize;
            if (message.tag && message.tag.length) {
                object.tag = [];
                for (let j = 0; j < message.tag.length; ++j)
                    object.tag[j] = message.tag[j];
            }
            return object;
        };

        /**
         * Converts this TargetConfigured to JSON.
         * @function toJSON
         * @memberof build_event_stream.TargetConfigured
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TargetConfigured.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TargetConfigured;
    })();

    build_event_stream.File = (function() {

        /**
         * Properties of a File.
         * @memberof build_event_stream
         * @interface IFile
         * @property {Array.<string>|null} [pathPrefix] File pathPrefix
         * @property {string|null} [name] File name
         * @property {string|null} [uri] File uri
         * @property {Uint8Array|null} [contents] File contents
         */

        /**
         * Constructs a new File.
         * @memberof build_event_stream
         * @classdesc Represents a File.
         * @implements IFile
         * @constructor
         * @param {build_event_stream.IFile=} [properties] Properties to set
         */
        function File(properties) {
            this.pathPrefix = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * File pathPrefix.
         * @member {Array.<string>} pathPrefix
         * @memberof build_event_stream.File
         * @instance
         */
        File.prototype.pathPrefix = $util.emptyArray;

        /**
         * File name.
         * @member {string} name
         * @memberof build_event_stream.File
         * @instance
         */
        File.prototype.name = "";

        /**
         * File uri.
         * @member {string} uri
         * @memberof build_event_stream.File
         * @instance
         */
        File.prototype.uri = "";

        /**
         * File contents.
         * @member {Uint8Array} contents
         * @memberof build_event_stream.File
         * @instance
         */
        File.prototype.contents = $util.newBuffer([]);

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * File file.
         * @member {"uri"|"contents"|undefined} file
         * @memberof build_event_stream.File
         * @instance
         */
        Object.defineProperty(File.prototype, "file", {
            get: $util.oneOfGetter($oneOfFields = ["uri", "contents"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new File instance using the specified properties.
         * @function create
         * @memberof build_event_stream.File
         * @static
         * @param {build_event_stream.IFile=} [properties] Properties to set
         * @returns {build_event_stream.File} File instance
         */
        File.create = function create(properties) {
            return new File(properties);
        };

        /**
         * Encodes the specified File message. Does not implicitly {@link build_event_stream.File.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.File
         * @static
         * @param {build_event_stream.IFile} message File message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        File.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && message.hasOwnProperty("name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.uri != null && message.hasOwnProperty("uri"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.uri);
            if (message.contents != null && message.hasOwnProperty("contents"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.contents);
            if (message.pathPrefix != null && message.pathPrefix.length)
                for (let i = 0; i < message.pathPrefix.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.pathPrefix[i]);
            return writer;
        };

        /**
         * Encodes the specified File message, length delimited. Does not implicitly {@link build_event_stream.File.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.File
         * @static
         * @param {build_event_stream.IFile} message File message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        File.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a File message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.File
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.File} File
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        File.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.File();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 4:
                    if (!(message.pathPrefix && message.pathPrefix.length))
                        message.pathPrefix = [];
                    message.pathPrefix.push(reader.string());
                    break;
                case 1:
                    message.name = reader.string();
                    break;
                case 2:
                    message.uri = reader.string();
                    break;
                case 3:
                    message.contents = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a File message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.File
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.File} File
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        File.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a File message.
         * @function verify
         * @memberof build_event_stream.File
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        File.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.pathPrefix != null && message.hasOwnProperty("pathPrefix")) {
                if (!Array.isArray(message.pathPrefix))
                    return "pathPrefix: array expected";
                for (let i = 0; i < message.pathPrefix.length; ++i)
                    if (!$util.isString(message.pathPrefix[i]))
                        return "pathPrefix: string[] expected";
            }
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.uri != null && message.hasOwnProperty("uri")) {
                properties.file = 1;
                if (!$util.isString(message.uri))
                    return "uri: string expected";
            }
            if (message.contents != null && message.hasOwnProperty("contents")) {
                if (properties.file === 1)
                    return "file: multiple values";
                properties.file = 1;
                if (!(message.contents && typeof message.contents.length === "number" || $util.isString(message.contents)))
                    return "contents: buffer expected";
            }
            return null;
        };

        /**
         * Creates a File message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.File
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.File} File
         */
        File.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.File)
                return object;
            let message = new $root.build_event_stream.File();
            if (object.pathPrefix) {
                if (!Array.isArray(object.pathPrefix))
                    throw TypeError(".build_event_stream.File.pathPrefix: array expected");
                message.pathPrefix = [];
                for (let i = 0; i < object.pathPrefix.length; ++i)
                    message.pathPrefix[i] = String(object.pathPrefix[i]);
            }
            if (object.name != null)
                message.name = String(object.name);
            if (object.uri != null)
                message.uri = String(object.uri);
            if (object.contents != null)
                if (typeof object.contents === "string")
                    $util.base64.decode(object.contents, message.contents = $util.newBuffer($util.base64.length(object.contents)), 0);
                else if (object.contents.length)
                    message.contents = object.contents;
            return message;
        };

        /**
         * Creates a plain object from a File message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.File
         * @static
         * @param {build_event_stream.File} message File
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        File.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.pathPrefix = [];
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.uri != null && message.hasOwnProperty("uri")) {
                object.uri = message.uri;
                if (options.oneofs)
                    object.file = "uri";
            }
            if (message.contents != null && message.hasOwnProperty("contents")) {
                object.contents = options.bytes === String ? $util.base64.encode(message.contents, 0, message.contents.length) : options.bytes === Array ? Array.prototype.slice.call(message.contents) : message.contents;
                if (options.oneofs)
                    object.file = "contents";
            }
            if (message.pathPrefix && message.pathPrefix.length) {
                object.pathPrefix = [];
                for (let j = 0; j < message.pathPrefix.length; ++j)
                    object.pathPrefix[j] = message.pathPrefix[j];
            }
            return object;
        };

        /**
         * Converts this File to JSON.
         * @function toJSON
         * @memberof build_event_stream.File
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        File.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return File;
    })();

    build_event_stream.NamedSetOfFiles = (function() {

        /**
         * Properties of a NamedSetOfFiles.
         * @memberof build_event_stream
         * @interface INamedSetOfFiles
         * @property {Array.<build_event_stream.IFile>|null} [files] NamedSetOfFiles files
         * @property {Array.<build_event_stream.BuildEventId.INamedSetOfFilesId>|null} [fileSets] NamedSetOfFiles fileSets
         */

        /**
         * Constructs a new NamedSetOfFiles.
         * @memberof build_event_stream
         * @classdesc Represents a NamedSetOfFiles.
         * @implements INamedSetOfFiles
         * @constructor
         * @param {build_event_stream.INamedSetOfFiles=} [properties] Properties to set
         */
        function NamedSetOfFiles(properties) {
            this.files = [];
            this.fileSets = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * NamedSetOfFiles files.
         * @member {Array.<build_event_stream.IFile>} files
         * @memberof build_event_stream.NamedSetOfFiles
         * @instance
         */
        NamedSetOfFiles.prototype.files = $util.emptyArray;

        /**
         * NamedSetOfFiles fileSets.
         * @member {Array.<build_event_stream.BuildEventId.INamedSetOfFilesId>} fileSets
         * @memberof build_event_stream.NamedSetOfFiles
         * @instance
         */
        NamedSetOfFiles.prototype.fileSets = $util.emptyArray;

        /**
         * Creates a new NamedSetOfFiles instance using the specified properties.
         * @function create
         * @memberof build_event_stream.NamedSetOfFiles
         * @static
         * @param {build_event_stream.INamedSetOfFiles=} [properties] Properties to set
         * @returns {build_event_stream.NamedSetOfFiles} NamedSetOfFiles instance
         */
        NamedSetOfFiles.create = function create(properties) {
            return new NamedSetOfFiles(properties);
        };

        /**
         * Encodes the specified NamedSetOfFiles message. Does not implicitly {@link build_event_stream.NamedSetOfFiles.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.NamedSetOfFiles
         * @static
         * @param {build_event_stream.INamedSetOfFiles} message NamedSetOfFiles message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NamedSetOfFiles.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.files != null && message.files.length)
                for (let i = 0; i < message.files.length; ++i)
                    $root.build_event_stream.File.encode(message.files[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.fileSets != null && message.fileSets.length)
                for (let i = 0; i < message.fileSets.length; ++i)
                    $root.build_event_stream.BuildEventId.NamedSetOfFilesId.encode(message.fileSets[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified NamedSetOfFiles message, length delimited. Does not implicitly {@link build_event_stream.NamedSetOfFiles.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.NamedSetOfFiles
         * @static
         * @param {build_event_stream.INamedSetOfFiles} message NamedSetOfFiles message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NamedSetOfFiles.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a NamedSetOfFiles message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.NamedSetOfFiles
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.NamedSetOfFiles} NamedSetOfFiles
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NamedSetOfFiles.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.NamedSetOfFiles();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.files && message.files.length))
                        message.files = [];
                    message.files.push($root.build_event_stream.File.decode(reader, reader.uint32()));
                    break;
                case 2:
                    if (!(message.fileSets && message.fileSets.length))
                        message.fileSets = [];
                    message.fileSets.push($root.build_event_stream.BuildEventId.NamedSetOfFilesId.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a NamedSetOfFiles message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.NamedSetOfFiles
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.NamedSetOfFiles} NamedSetOfFiles
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NamedSetOfFiles.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a NamedSetOfFiles message.
         * @function verify
         * @memberof build_event_stream.NamedSetOfFiles
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NamedSetOfFiles.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.files != null && message.hasOwnProperty("files")) {
                if (!Array.isArray(message.files))
                    return "files: array expected";
                for (let i = 0; i < message.files.length; ++i) {
                    let error = $root.build_event_stream.File.verify(message.files[i]);
                    if (error)
                        return "files." + error;
                }
            }
            if (message.fileSets != null && message.hasOwnProperty("fileSets")) {
                if (!Array.isArray(message.fileSets))
                    return "fileSets: array expected";
                for (let i = 0; i < message.fileSets.length; ++i) {
                    let error = $root.build_event_stream.BuildEventId.NamedSetOfFilesId.verify(message.fileSets[i]);
                    if (error)
                        return "fileSets." + error;
                }
            }
            return null;
        };

        /**
         * Creates a NamedSetOfFiles message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.NamedSetOfFiles
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.NamedSetOfFiles} NamedSetOfFiles
         */
        NamedSetOfFiles.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.NamedSetOfFiles)
                return object;
            let message = new $root.build_event_stream.NamedSetOfFiles();
            if (object.files) {
                if (!Array.isArray(object.files))
                    throw TypeError(".build_event_stream.NamedSetOfFiles.files: array expected");
                message.files = [];
                for (let i = 0; i < object.files.length; ++i) {
                    if (typeof object.files[i] !== "object")
                        throw TypeError(".build_event_stream.NamedSetOfFiles.files: object expected");
                    message.files[i] = $root.build_event_stream.File.fromObject(object.files[i]);
                }
            }
            if (object.fileSets) {
                if (!Array.isArray(object.fileSets))
                    throw TypeError(".build_event_stream.NamedSetOfFiles.fileSets: array expected");
                message.fileSets = [];
                for (let i = 0; i < object.fileSets.length; ++i) {
                    if (typeof object.fileSets[i] !== "object")
                        throw TypeError(".build_event_stream.NamedSetOfFiles.fileSets: object expected");
                    message.fileSets[i] = $root.build_event_stream.BuildEventId.NamedSetOfFilesId.fromObject(object.fileSets[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a NamedSetOfFiles message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.NamedSetOfFiles
         * @static
         * @param {build_event_stream.NamedSetOfFiles} message NamedSetOfFiles
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NamedSetOfFiles.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.files = [];
                object.fileSets = [];
            }
            if (message.files && message.files.length) {
                object.files = [];
                for (let j = 0; j < message.files.length; ++j)
                    object.files[j] = $root.build_event_stream.File.toObject(message.files[j], options);
            }
            if (message.fileSets && message.fileSets.length) {
                object.fileSets = [];
                for (let j = 0; j < message.fileSets.length; ++j)
                    object.fileSets[j] = $root.build_event_stream.BuildEventId.NamedSetOfFilesId.toObject(message.fileSets[j], options);
            }
            return object;
        };

        /**
         * Converts this NamedSetOfFiles to JSON.
         * @function toJSON
         * @memberof build_event_stream.NamedSetOfFiles
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NamedSetOfFiles.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NamedSetOfFiles;
    })();

    build_event_stream.ActionExecuted = (function() {

        /**
         * Properties of an ActionExecuted.
         * @memberof build_event_stream
         * @interface IActionExecuted
         * @property {boolean|null} [success] ActionExecuted success
         * @property {string|null} [type] ActionExecuted type
         * @property {number|null} [exitCode] ActionExecuted exitCode
         * @property {build_event_stream.IFile|null} [stdout] ActionExecuted stdout
         * @property {build_event_stream.IFile|null} [stderr] ActionExecuted stderr
         * @property {string|null} [label] ActionExecuted label
         * @property {build_event_stream.BuildEventId.IConfigurationId|null} [configuration] ActionExecuted configuration
         * @property {build_event_stream.IFile|null} [primaryOutput] ActionExecuted primaryOutput
         * @property {Array.<string>|null} [commandLine] ActionExecuted commandLine
         * @property {Array.<build_event_stream.IFile>|null} [actionMetadataLogs] ActionExecuted actionMetadataLogs
         */

        /**
         * Constructs a new ActionExecuted.
         * @memberof build_event_stream
         * @classdesc Represents an ActionExecuted.
         * @implements IActionExecuted
         * @constructor
         * @param {build_event_stream.IActionExecuted=} [properties] Properties to set
         */
        function ActionExecuted(properties) {
            this.commandLine = [];
            this.actionMetadataLogs = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ActionExecuted success.
         * @member {boolean} success
         * @memberof build_event_stream.ActionExecuted
         * @instance
         */
        ActionExecuted.prototype.success = false;

        /**
         * ActionExecuted type.
         * @member {string} type
         * @memberof build_event_stream.ActionExecuted
         * @instance
         */
        ActionExecuted.prototype.type = "";

        /**
         * ActionExecuted exitCode.
         * @member {number} exitCode
         * @memberof build_event_stream.ActionExecuted
         * @instance
         */
        ActionExecuted.prototype.exitCode = 0;

        /**
         * ActionExecuted stdout.
         * @member {build_event_stream.IFile|null|undefined} stdout
         * @memberof build_event_stream.ActionExecuted
         * @instance
         */
        ActionExecuted.prototype.stdout = null;

        /**
         * ActionExecuted stderr.
         * @member {build_event_stream.IFile|null|undefined} stderr
         * @memberof build_event_stream.ActionExecuted
         * @instance
         */
        ActionExecuted.prototype.stderr = null;

        /**
         * ActionExecuted label.
         * @member {string} label
         * @memberof build_event_stream.ActionExecuted
         * @instance
         */
        ActionExecuted.prototype.label = "";

        /**
         * ActionExecuted configuration.
         * @member {build_event_stream.BuildEventId.IConfigurationId|null|undefined} configuration
         * @memberof build_event_stream.ActionExecuted
         * @instance
         */
        ActionExecuted.prototype.configuration = null;

        /**
         * ActionExecuted primaryOutput.
         * @member {build_event_stream.IFile|null|undefined} primaryOutput
         * @memberof build_event_stream.ActionExecuted
         * @instance
         */
        ActionExecuted.prototype.primaryOutput = null;

        /**
         * ActionExecuted commandLine.
         * @member {Array.<string>} commandLine
         * @memberof build_event_stream.ActionExecuted
         * @instance
         */
        ActionExecuted.prototype.commandLine = $util.emptyArray;

        /**
         * ActionExecuted actionMetadataLogs.
         * @member {Array.<build_event_stream.IFile>} actionMetadataLogs
         * @memberof build_event_stream.ActionExecuted
         * @instance
         */
        ActionExecuted.prototype.actionMetadataLogs = $util.emptyArray;

        /**
         * Creates a new ActionExecuted instance using the specified properties.
         * @function create
         * @memberof build_event_stream.ActionExecuted
         * @static
         * @param {build_event_stream.IActionExecuted=} [properties] Properties to set
         * @returns {build_event_stream.ActionExecuted} ActionExecuted instance
         */
        ActionExecuted.create = function create(properties) {
            return new ActionExecuted(properties);
        };

        /**
         * Encodes the specified ActionExecuted message. Does not implicitly {@link build_event_stream.ActionExecuted.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.ActionExecuted
         * @static
         * @param {build_event_stream.IActionExecuted} message ActionExecuted message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ActionExecuted.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && message.hasOwnProperty("success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            if (message.exitCode != null && message.hasOwnProperty("exitCode"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.exitCode);
            if (message.stdout != null && message.hasOwnProperty("stdout"))
                $root.build_event_stream.File.encode(message.stdout, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.stderr != null && message.hasOwnProperty("stderr"))
                $root.build_event_stream.File.encode(message.stderr, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.label != null && message.hasOwnProperty("label"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.label);
            if (message.primaryOutput != null && message.hasOwnProperty("primaryOutput"))
                $root.build_event_stream.File.encode(message.primaryOutput, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.configuration != null && message.hasOwnProperty("configuration"))
                $root.build_event_stream.BuildEventId.ConfigurationId.encode(message.configuration, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.type != null && message.hasOwnProperty("type"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.type);
            if (message.commandLine != null && message.commandLine.length)
                for (let i = 0; i < message.commandLine.length; ++i)
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.commandLine[i]);
            if (message.actionMetadataLogs != null && message.actionMetadataLogs.length)
                for (let i = 0; i < message.actionMetadataLogs.length; ++i)
                    $root.build_event_stream.File.encode(message.actionMetadataLogs[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ActionExecuted message, length delimited. Does not implicitly {@link build_event_stream.ActionExecuted.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.ActionExecuted
         * @static
         * @param {build_event_stream.IActionExecuted} message ActionExecuted message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ActionExecuted.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an ActionExecuted message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.ActionExecuted
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.ActionExecuted} ActionExecuted
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ActionExecuted.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.ActionExecuted();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.success = reader.bool();
                    break;
                case 8:
                    message.type = reader.string();
                    break;
                case 2:
                    message.exitCode = reader.int32();
                    break;
                case 3:
                    message.stdout = $root.build_event_stream.File.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.stderr = $root.build_event_stream.File.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.label = reader.string();
                    break;
                case 7:
                    message.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.primaryOutput = $root.build_event_stream.File.decode(reader, reader.uint32());
                    break;
                case 9:
                    if (!(message.commandLine && message.commandLine.length))
                        message.commandLine = [];
                    message.commandLine.push(reader.string());
                    break;
                case 10:
                    if (!(message.actionMetadataLogs && message.actionMetadataLogs.length))
                        message.actionMetadataLogs = [];
                    message.actionMetadataLogs.push($root.build_event_stream.File.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an ActionExecuted message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.ActionExecuted
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.ActionExecuted} ActionExecuted
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ActionExecuted.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an ActionExecuted message.
         * @function verify
         * @memberof build_event_stream.ActionExecuted
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ActionExecuted.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            if (message.type != null && message.hasOwnProperty("type"))
                if (!$util.isString(message.type))
                    return "type: string expected";
            if (message.exitCode != null && message.hasOwnProperty("exitCode"))
                if (!$util.isInteger(message.exitCode))
                    return "exitCode: integer expected";
            if (message.stdout != null && message.hasOwnProperty("stdout")) {
                let error = $root.build_event_stream.File.verify(message.stdout);
                if (error)
                    return "stdout." + error;
            }
            if (message.stderr != null && message.hasOwnProperty("stderr")) {
                let error = $root.build_event_stream.File.verify(message.stderr);
                if (error)
                    return "stderr." + error;
            }
            if (message.label != null && message.hasOwnProperty("label"))
                if (!$util.isString(message.label))
                    return "label: string expected";
            if (message.configuration != null && message.hasOwnProperty("configuration")) {
                let error = $root.build_event_stream.BuildEventId.ConfigurationId.verify(message.configuration);
                if (error)
                    return "configuration." + error;
            }
            if (message.primaryOutput != null && message.hasOwnProperty("primaryOutput")) {
                let error = $root.build_event_stream.File.verify(message.primaryOutput);
                if (error)
                    return "primaryOutput." + error;
            }
            if (message.commandLine != null && message.hasOwnProperty("commandLine")) {
                if (!Array.isArray(message.commandLine))
                    return "commandLine: array expected";
                for (let i = 0; i < message.commandLine.length; ++i)
                    if (!$util.isString(message.commandLine[i]))
                        return "commandLine: string[] expected";
            }
            if (message.actionMetadataLogs != null && message.hasOwnProperty("actionMetadataLogs")) {
                if (!Array.isArray(message.actionMetadataLogs))
                    return "actionMetadataLogs: array expected";
                for (let i = 0; i < message.actionMetadataLogs.length; ++i) {
                    let error = $root.build_event_stream.File.verify(message.actionMetadataLogs[i]);
                    if (error)
                        return "actionMetadataLogs." + error;
                }
            }
            return null;
        };

        /**
         * Creates an ActionExecuted message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.ActionExecuted
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.ActionExecuted} ActionExecuted
         */
        ActionExecuted.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.ActionExecuted)
                return object;
            let message = new $root.build_event_stream.ActionExecuted();
            if (object.success != null)
                message.success = Boolean(object.success);
            if (object.type != null)
                message.type = String(object.type);
            if (object.exitCode != null)
                message.exitCode = object.exitCode | 0;
            if (object.stdout != null) {
                if (typeof object.stdout !== "object")
                    throw TypeError(".build_event_stream.ActionExecuted.stdout: object expected");
                message.stdout = $root.build_event_stream.File.fromObject(object.stdout);
            }
            if (object.stderr != null) {
                if (typeof object.stderr !== "object")
                    throw TypeError(".build_event_stream.ActionExecuted.stderr: object expected");
                message.stderr = $root.build_event_stream.File.fromObject(object.stderr);
            }
            if (object.label != null)
                message.label = String(object.label);
            if (object.configuration != null) {
                if (typeof object.configuration !== "object")
                    throw TypeError(".build_event_stream.ActionExecuted.configuration: object expected");
                message.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.fromObject(object.configuration);
            }
            if (object.primaryOutput != null) {
                if (typeof object.primaryOutput !== "object")
                    throw TypeError(".build_event_stream.ActionExecuted.primaryOutput: object expected");
                message.primaryOutput = $root.build_event_stream.File.fromObject(object.primaryOutput);
            }
            if (object.commandLine) {
                if (!Array.isArray(object.commandLine))
                    throw TypeError(".build_event_stream.ActionExecuted.commandLine: array expected");
                message.commandLine = [];
                for (let i = 0; i < object.commandLine.length; ++i)
                    message.commandLine[i] = String(object.commandLine[i]);
            }
            if (object.actionMetadataLogs) {
                if (!Array.isArray(object.actionMetadataLogs))
                    throw TypeError(".build_event_stream.ActionExecuted.actionMetadataLogs: array expected");
                message.actionMetadataLogs = [];
                for (let i = 0; i < object.actionMetadataLogs.length; ++i) {
                    if (typeof object.actionMetadataLogs[i] !== "object")
                        throw TypeError(".build_event_stream.ActionExecuted.actionMetadataLogs: object expected");
                    message.actionMetadataLogs[i] = $root.build_event_stream.File.fromObject(object.actionMetadataLogs[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an ActionExecuted message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.ActionExecuted
         * @static
         * @param {build_event_stream.ActionExecuted} message ActionExecuted
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ActionExecuted.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.commandLine = [];
                object.actionMetadataLogs = [];
            }
            if (options.defaults) {
                object.success = false;
                object.exitCode = 0;
                object.stdout = null;
                object.stderr = null;
                object.label = "";
                object.primaryOutput = null;
                object.configuration = null;
                object.type = "";
            }
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            if (message.exitCode != null && message.hasOwnProperty("exitCode"))
                object.exitCode = message.exitCode;
            if (message.stdout != null && message.hasOwnProperty("stdout"))
                object.stdout = $root.build_event_stream.File.toObject(message.stdout, options);
            if (message.stderr != null && message.hasOwnProperty("stderr"))
                object.stderr = $root.build_event_stream.File.toObject(message.stderr, options);
            if (message.label != null && message.hasOwnProperty("label"))
                object.label = message.label;
            if (message.primaryOutput != null && message.hasOwnProperty("primaryOutput"))
                object.primaryOutput = $root.build_event_stream.File.toObject(message.primaryOutput, options);
            if (message.configuration != null && message.hasOwnProperty("configuration"))
                object.configuration = $root.build_event_stream.BuildEventId.ConfigurationId.toObject(message.configuration, options);
            if (message.type != null && message.hasOwnProperty("type"))
                object.type = message.type;
            if (message.commandLine && message.commandLine.length) {
                object.commandLine = [];
                for (let j = 0; j < message.commandLine.length; ++j)
                    object.commandLine[j] = message.commandLine[j];
            }
            if (message.actionMetadataLogs && message.actionMetadataLogs.length) {
                object.actionMetadataLogs = [];
                for (let j = 0; j < message.actionMetadataLogs.length; ++j)
                    object.actionMetadataLogs[j] = $root.build_event_stream.File.toObject(message.actionMetadataLogs[j], options);
            }
            return object;
        };

        /**
         * Converts this ActionExecuted to JSON.
         * @function toJSON
         * @memberof build_event_stream.ActionExecuted
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ActionExecuted.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ActionExecuted;
    })();

    build_event_stream.OutputGroup = (function() {

        /**
         * Properties of an OutputGroup.
         * @memberof build_event_stream
         * @interface IOutputGroup
         * @property {string|null} [name] OutputGroup name
         * @property {Array.<build_event_stream.BuildEventId.INamedSetOfFilesId>|null} [fileSets] OutputGroup fileSets
         */

        /**
         * Constructs a new OutputGroup.
         * @memberof build_event_stream
         * @classdesc Represents an OutputGroup.
         * @implements IOutputGroup
         * @constructor
         * @param {build_event_stream.IOutputGroup=} [properties] Properties to set
         */
        function OutputGroup(properties) {
            this.fileSets = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OutputGroup name.
         * @member {string} name
         * @memberof build_event_stream.OutputGroup
         * @instance
         */
        OutputGroup.prototype.name = "";

        /**
         * OutputGroup fileSets.
         * @member {Array.<build_event_stream.BuildEventId.INamedSetOfFilesId>} fileSets
         * @memberof build_event_stream.OutputGroup
         * @instance
         */
        OutputGroup.prototype.fileSets = $util.emptyArray;

        /**
         * Creates a new OutputGroup instance using the specified properties.
         * @function create
         * @memberof build_event_stream.OutputGroup
         * @static
         * @param {build_event_stream.IOutputGroup=} [properties] Properties to set
         * @returns {build_event_stream.OutputGroup} OutputGroup instance
         */
        OutputGroup.create = function create(properties) {
            return new OutputGroup(properties);
        };

        /**
         * Encodes the specified OutputGroup message. Does not implicitly {@link build_event_stream.OutputGroup.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.OutputGroup
         * @static
         * @param {build_event_stream.IOutputGroup} message OutputGroup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutputGroup.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && message.hasOwnProperty("name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            if (message.fileSets != null && message.fileSets.length)
                for (let i = 0; i < message.fileSets.length; ++i)
                    $root.build_event_stream.BuildEventId.NamedSetOfFilesId.encode(message.fileSets[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified OutputGroup message, length delimited. Does not implicitly {@link build_event_stream.OutputGroup.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.OutputGroup
         * @static
         * @param {build_event_stream.IOutputGroup} message OutputGroup message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OutputGroup.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OutputGroup message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.OutputGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.OutputGroup} OutputGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutputGroup.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.OutputGroup();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                case 3:
                    if (!(message.fileSets && message.fileSets.length))
                        message.fileSets = [];
                    message.fileSets.push($root.build_event_stream.BuildEventId.NamedSetOfFilesId.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OutputGroup message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.OutputGroup
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.OutputGroup} OutputGroup
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OutputGroup.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OutputGroup message.
         * @function verify
         * @memberof build_event_stream.OutputGroup
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OutputGroup.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.fileSets != null && message.hasOwnProperty("fileSets")) {
                if (!Array.isArray(message.fileSets))
                    return "fileSets: array expected";
                for (let i = 0; i < message.fileSets.length; ++i) {
                    let error = $root.build_event_stream.BuildEventId.NamedSetOfFilesId.verify(message.fileSets[i]);
                    if (error)
                        return "fileSets." + error;
                }
            }
            return null;
        };

        /**
         * Creates an OutputGroup message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.OutputGroup
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.OutputGroup} OutputGroup
         */
        OutputGroup.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.OutputGroup)
                return object;
            let message = new $root.build_event_stream.OutputGroup();
            if (object.name != null)
                message.name = String(object.name);
            if (object.fileSets) {
                if (!Array.isArray(object.fileSets))
                    throw TypeError(".build_event_stream.OutputGroup.fileSets: array expected");
                message.fileSets = [];
                for (let i = 0; i < object.fileSets.length; ++i) {
                    if (typeof object.fileSets[i] !== "object")
                        throw TypeError(".build_event_stream.OutputGroup.fileSets: object expected");
                    message.fileSets[i] = $root.build_event_stream.BuildEventId.NamedSetOfFilesId.fromObject(object.fileSets[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an OutputGroup message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.OutputGroup
         * @static
         * @param {build_event_stream.OutputGroup} message OutputGroup
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OutputGroup.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.fileSets = [];
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.fileSets && message.fileSets.length) {
                object.fileSets = [];
                for (let j = 0; j < message.fileSets.length; ++j)
                    object.fileSets[j] = $root.build_event_stream.BuildEventId.NamedSetOfFilesId.toObject(message.fileSets[j], options);
            }
            return object;
        };

        /**
         * Converts this OutputGroup to JSON.
         * @function toJSON
         * @memberof build_event_stream.OutputGroup
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OutputGroup.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OutputGroup;
    })();

    build_event_stream.TargetComplete = (function() {

        /**
         * Properties of a TargetComplete.
         * @memberof build_event_stream
         * @interface ITargetComplete
         * @property {boolean|null} [success] TargetComplete success
         * @property {string|null} [targetKind] TargetComplete targetKind
         * @property {build_event_stream.TestSize|null} [testSize] TargetComplete testSize
         * @property {Array.<build_event_stream.IOutputGroup>|null} [outputGroup] TargetComplete outputGroup
         * @property {Array.<build_event_stream.IFile>|null} [importantOutput] TargetComplete importantOutput
         * @property {Array.<string>|null} [tag] TargetComplete tag
         * @property {Long|null} [testTimeoutSeconds] TargetComplete testTimeoutSeconds
         */

        /**
         * Constructs a new TargetComplete.
         * @memberof build_event_stream
         * @classdesc Represents a TargetComplete.
         * @implements ITargetComplete
         * @constructor
         * @param {build_event_stream.ITargetComplete=} [properties] Properties to set
         */
        function TargetComplete(properties) {
            this.outputGroup = [];
            this.importantOutput = [];
            this.tag = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TargetComplete success.
         * @member {boolean} success
         * @memberof build_event_stream.TargetComplete
         * @instance
         */
        TargetComplete.prototype.success = false;

        /**
         * TargetComplete targetKind.
         * @member {string} targetKind
         * @memberof build_event_stream.TargetComplete
         * @instance
         */
        TargetComplete.prototype.targetKind = "";

        /**
         * TargetComplete testSize.
         * @member {build_event_stream.TestSize} testSize
         * @memberof build_event_stream.TargetComplete
         * @instance
         */
        TargetComplete.prototype.testSize = 0;

        /**
         * TargetComplete outputGroup.
         * @member {Array.<build_event_stream.IOutputGroup>} outputGroup
         * @memberof build_event_stream.TargetComplete
         * @instance
         */
        TargetComplete.prototype.outputGroup = $util.emptyArray;

        /**
         * TargetComplete importantOutput.
         * @member {Array.<build_event_stream.IFile>} importantOutput
         * @memberof build_event_stream.TargetComplete
         * @instance
         */
        TargetComplete.prototype.importantOutput = $util.emptyArray;

        /**
         * TargetComplete tag.
         * @member {Array.<string>} tag
         * @memberof build_event_stream.TargetComplete
         * @instance
         */
        TargetComplete.prototype.tag = $util.emptyArray;

        /**
         * TargetComplete testTimeoutSeconds.
         * @member {Long} testTimeoutSeconds
         * @memberof build_event_stream.TargetComplete
         * @instance
         */
        TargetComplete.prototype.testTimeoutSeconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new TargetComplete instance using the specified properties.
         * @function create
         * @memberof build_event_stream.TargetComplete
         * @static
         * @param {build_event_stream.ITargetComplete=} [properties] Properties to set
         * @returns {build_event_stream.TargetComplete} TargetComplete instance
         */
        TargetComplete.create = function create(properties) {
            return new TargetComplete(properties);
        };

        /**
         * Encodes the specified TargetComplete message. Does not implicitly {@link build_event_stream.TargetComplete.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.TargetComplete
         * @static
         * @param {build_event_stream.ITargetComplete} message TargetComplete message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TargetComplete.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && message.hasOwnProperty("success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            if (message.outputGroup != null && message.outputGroup.length)
                for (let i = 0; i < message.outputGroup.length; ++i)
                    $root.build_event_stream.OutputGroup.encode(message.outputGroup[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.tag != null && message.tag.length)
                for (let i = 0; i < message.tag.length; ++i)
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.tag[i]);
            if (message.importantOutput != null && message.importantOutput.length)
                for (let i = 0; i < message.importantOutput.length; ++i)
                    $root.build_event_stream.File.encode(message.importantOutput[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.targetKind != null && message.hasOwnProperty("targetKind"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.targetKind);
            if (message.testSize != null && message.hasOwnProperty("testSize"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.testSize);
            if (message.testTimeoutSeconds != null && message.hasOwnProperty("testTimeoutSeconds"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.testTimeoutSeconds);
            return writer;
        };

        /**
         * Encodes the specified TargetComplete message, length delimited. Does not implicitly {@link build_event_stream.TargetComplete.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.TargetComplete
         * @static
         * @param {build_event_stream.ITargetComplete} message TargetComplete message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TargetComplete.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TargetComplete message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.TargetComplete
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.TargetComplete} TargetComplete
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TargetComplete.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.TargetComplete();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.success = reader.bool();
                    break;
                case 5:
                    message.targetKind = reader.string();
                    break;
                case 6:
                    message.testSize = reader.int32();
                    break;
                case 2:
                    if (!(message.outputGroup && message.outputGroup.length))
                        message.outputGroup = [];
                    message.outputGroup.push($root.build_event_stream.OutputGroup.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.importantOutput && message.importantOutput.length))
                        message.importantOutput = [];
                    message.importantOutput.push($root.build_event_stream.File.decode(reader, reader.uint32()));
                    break;
                case 3:
                    if (!(message.tag && message.tag.length))
                        message.tag = [];
                    message.tag.push(reader.string());
                    break;
                case 7:
                    message.testTimeoutSeconds = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TargetComplete message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.TargetComplete
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.TargetComplete} TargetComplete
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TargetComplete.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TargetComplete message.
         * @function verify
         * @memberof build_event_stream.TargetComplete
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TargetComplete.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            if (message.targetKind != null && message.hasOwnProperty("targetKind"))
                if (!$util.isString(message.targetKind))
                    return "targetKind: string expected";
            if (message.testSize != null && message.hasOwnProperty("testSize"))
                switch (message.testSize) {
                default:
                    return "testSize: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                    break;
                }
            if (message.outputGroup != null && message.hasOwnProperty("outputGroup")) {
                if (!Array.isArray(message.outputGroup))
                    return "outputGroup: array expected";
                for (let i = 0; i < message.outputGroup.length; ++i) {
                    let error = $root.build_event_stream.OutputGroup.verify(message.outputGroup[i]);
                    if (error)
                        return "outputGroup." + error;
                }
            }
            if (message.importantOutput != null && message.hasOwnProperty("importantOutput")) {
                if (!Array.isArray(message.importantOutput))
                    return "importantOutput: array expected";
                for (let i = 0; i < message.importantOutput.length; ++i) {
                    let error = $root.build_event_stream.File.verify(message.importantOutput[i]);
                    if (error)
                        return "importantOutput." + error;
                }
            }
            if (message.tag != null && message.hasOwnProperty("tag")) {
                if (!Array.isArray(message.tag))
                    return "tag: array expected";
                for (let i = 0; i < message.tag.length; ++i)
                    if (!$util.isString(message.tag[i]))
                        return "tag: string[] expected";
            }
            if (message.testTimeoutSeconds != null && message.hasOwnProperty("testTimeoutSeconds"))
                if (!$util.isInteger(message.testTimeoutSeconds) && !(message.testTimeoutSeconds && $util.isInteger(message.testTimeoutSeconds.low) && $util.isInteger(message.testTimeoutSeconds.high)))
                    return "testTimeoutSeconds: integer|Long expected";
            return null;
        };

        /**
         * Creates a TargetComplete message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.TargetComplete
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.TargetComplete} TargetComplete
         */
        TargetComplete.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.TargetComplete)
                return object;
            let message = new $root.build_event_stream.TargetComplete();
            if (object.success != null)
                message.success = Boolean(object.success);
            if (object.targetKind != null)
                message.targetKind = String(object.targetKind);
            switch (object.testSize) {
            case "UNKNOWN":
            case 0:
                message.testSize = 0;
                break;
            case "SMALL":
            case 1:
                message.testSize = 1;
                break;
            case "MEDIUM":
            case 2:
                message.testSize = 2;
                break;
            case "LARGE":
            case 3:
                message.testSize = 3;
                break;
            case "ENORMOUS":
            case 4:
                message.testSize = 4;
                break;
            }
            if (object.outputGroup) {
                if (!Array.isArray(object.outputGroup))
                    throw TypeError(".build_event_stream.TargetComplete.outputGroup: array expected");
                message.outputGroup = [];
                for (let i = 0; i < object.outputGroup.length; ++i) {
                    if (typeof object.outputGroup[i] !== "object")
                        throw TypeError(".build_event_stream.TargetComplete.outputGroup: object expected");
                    message.outputGroup[i] = $root.build_event_stream.OutputGroup.fromObject(object.outputGroup[i]);
                }
            }
            if (object.importantOutput) {
                if (!Array.isArray(object.importantOutput))
                    throw TypeError(".build_event_stream.TargetComplete.importantOutput: array expected");
                message.importantOutput = [];
                for (let i = 0; i < object.importantOutput.length; ++i) {
                    if (typeof object.importantOutput[i] !== "object")
                        throw TypeError(".build_event_stream.TargetComplete.importantOutput: object expected");
                    message.importantOutput[i] = $root.build_event_stream.File.fromObject(object.importantOutput[i]);
                }
            }
            if (object.tag) {
                if (!Array.isArray(object.tag))
                    throw TypeError(".build_event_stream.TargetComplete.tag: array expected");
                message.tag = [];
                for (let i = 0; i < object.tag.length; ++i)
                    message.tag[i] = String(object.tag[i]);
            }
            if (object.testTimeoutSeconds != null)
                if ($util.Long)
                    (message.testTimeoutSeconds = $util.Long.fromValue(object.testTimeoutSeconds)).unsigned = false;
                else if (typeof object.testTimeoutSeconds === "string")
                    message.testTimeoutSeconds = parseInt(object.testTimeoutSeconds, 10);
                else if (typeof object.testTimeoutSeconds === "number")
                    message.testTimeoutSeconds = object.testTimeoutSeconds;
                else if (typeof object.testTimeoutSeconds === "object")
                    message.testTimeoutSeconds = new $util.LongBits(object.testTimeoutSeconds.low >>> 0, object.testTimeoutSeconds.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a TargetComplete message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.TargetComplete
         * @static
         * @param {build_event_stream.TargetComplete} message TargetComplete
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TargetComplete.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.outputGroup = [];
                object.tag = [];
                object.importantOutput = [];
            }
            if (options.defaults) {
                object.success = false;
                object.targetKind = "";
                object.testSize = options.enums === String ? "UNKNOWN" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.testTimeoutSeconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.testTimeoutSeconds = options.longs === String ? "0" : 0;
            }
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            if (message.outputGroup && message.outputGroup.length) {
                object.outputGroup = [];
                for (let j = 0; j < message.outputGroup.length; ++j)
                    object.outputGroup[j] = $root.build_event_stream.OutputGroup.toObject(message.outputGroup[j], options);
            }
            if (message.tag && message.tag.length) {
                object.tag = [];
                for (let j = 0; j < message.tag.length; ++j)
                    object.tag[j] = message.tag[j];
            }
            if (message.importantOutput && message.importantOutput.length) {
                object.importantOutput = [];
                for (let j = 0; j < message.importantOutput.length; ++j)
                    object.importantOutput[j] = $root.build_event_stream.File.toObject(message.importantOutput[j], options);
            }
            if (message.targetKind != null && message.hasOwnProperty("targetKind"))
                object.targetKind = message.targetKind;
            if (message.testSize != null && message.hasOwnProperty("testSize"))
                object.testSize = options.enums === String ? $root.build_event_stream.TestSize[message.testSize] : message.testSize;
            if (message.testTimeoutSeconds != null && message.hasOwnProperty("testTimeoutSeconds"))
                if (typeof message.testTimeoutSeconds === "number")
                    object.testTimeoutSeconds = options.longs === String ? String(message.testTimeoutSeconds) : message.testTimeoutSeconds;
                else
                    object.testTimeoutSeconds = options.longs === String ? $util.Long.prototype.toString.call(message.testTimeoutSeconds) : options.longs === Number ? new $util.LongBits(message.testTimeoutSeconds.low >>> 0, message.testTimeoutSeconds.high >>> 0).toNumber() : message.testTimeoutSeconds;
            return object;
        };

        /**
         * Converts this TargetComplete to JSON.
         * @function toJSON
         * @memberof build_event_stream.TargetComplete
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TargetComplete.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TargetComplete;
    })();

    /**
     * TestStatus enum.
     * @name build_event_stream.TestStatus
     * @enum {string}
     * @property {number} NO_STATUS=0 NO_STATUS value
     * @property {number} PASSED=1 PASSED value
     * @property {number} FLAKY=2 FLAKY value
     * @property {number} TIMEOUT=3 TIMEOUT value
     * @property {number} FAILED=4 FAILED value
     * @property {number} INCOMPLETE=5 INCOMPLETE value
     * @property {number} REMOTE_FAILURE=6 REMOTE_FAILURE value
     * @property {number} FAILED_TO_BUILD=7 FAILED_TO_BUILD value
     * @property {number} TOOL_HALTED_BEFORE_TESTING=8 TOOL_HALTED_BEFORE_TESTING value
     */
    build_event_stream.TestStatus = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NO_STATUS"] = 0;
        values[valuesById[1] = "PASSED"] = 1;
        values[valuesById[2] = "FLAKY"] = 2;
        values[valuesById[3] = "TIMEOUT"] = 3;
        values[valuesById[4] = "FAILED"] = 4;
        values[valuesById[5] = "INCOMPLETE"] = 5;
        values[valuesById[6] = "REMOTE_FAILURE"] = 6;
        values[valuesById[7] = "FAILED_TO_BUILD"] = 7;
        values[valuesById[8] = "TOOL_HALTED_BEFORE_TESTING"] = 8;
        return values;
    })();

    build_event_stream.TestResult = (function() {

        /**
         * Properties of a TestResult.
         * @memberof build_event_stream
         * @interface ITestResult
         * @property {build_event_stream.TestStatus|null} [status] TestResult status
         * @property {string|null} [statusDetails] TestResult statusDetails
         * @property {boolean|null} [cachedLocally] TestResult cachedLocally
         * @property {Long|null} [testAttemptStartMillisEpoch] TestResult testAttemptStartMillisEpoch
         * @property {Long|null} [testAttemptDurationMillis] TestResult testAttemptDurationMillis
         * @property {Array.<build_event_stream.IFile>|null} [testActionOutput] TestResult testActionOutput
         * @property {Array.<string>|null} [warning] TestResult warning
         * @property {build_event_stream.TestResult.IExecutionInfo|null} [executionInfo] TestResult executionInfo
         */

        /**
         * Constructs a new TestResult.
         * @memberof build_event_stream
         * @classdesc Represents a TestResult.
         * @implements ITestResult
         * @constructor
         * @param {build_event_stream.ITestResult=} [properties] Properties to set
         */
        function TestResult(properties) {
            this.testActionOutput = [];
            this.warning = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TestResult status.
         * @member {build_event_stream.TestStatus} status
         * @memberof build_event_stream.TestResult
         * @instance
         */
        TestResult.prototype.status = 0;

        /**
         * TestResult statusDetails.
         * @member {string} statusDetails
         * @memberof build_event_stream.TestResult
         * @instance
         */
        TestResult.prototype.statusDetails = "";

        /**
         * TestResult cachedLocally.
         * @member {boolean} cachedLocally
         * @memberof build_event_stream.TestResult
         * @instance
         */
        TestResult.prototype.cachedLocally = false;

        /**
         * TestResult testAttemptStartMillisEpoch.
         * @member {Long} testAttemptStartMillisEpoch
         * @memberof build_event_stream.TestResult
         * @instance
         */
        TestResult.prototype.testAttemptStartMillisEpoch = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TestResult testAttemptDurationMillis.
         * @member {Long} testAttemptDurationMillis
         * @memberof build_event_stream.TestResult
         * @instance
         */
        TestResult.prototype.testAttemptDurationMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TestResult testActionOutput.
         * @member {Array.<build_event_stream.IFile>} testActionOutput
         * @memberof build_event_stream.TestResult
         * @instance
         */
        TestResult.prototype.testActionOutput = $util.emptyArray;

        /**
         * TestResult warning.
         * @member {Array.<string>} warning
         * @memberof build_event_stream.TestResult
         * @instance
         */
        TestResult.prototype.warning = $util.emptyArray;

        /**
         * TestResult executionInfo.
         * @member {build_event_stream.TestResult.IExecutionInfo|null|undefined} executionInfo
         * @memberof build_event_stream.TestResult
         * @instance
         */
        TestResult.prototype.executionInfo = null;

        /**
         * Creates a new TestResult instance using the specified properties.
         * @function create
         * @memberof build_event_stream.TestResult
         * @static
         * @param {build_event_stream.ITestResult=} [properties] Properties to set
         * @returns {build_event_stream.TestResult} TestResult instance
         */
        TestResult.create = function create(properties) {
            return new TestResult(properties);
        };

        /**
         * Encodes the specified TestResult message. Does not implicitly {@link build_event_stream.TestResult.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.TestResult
         * @static
         * @param {build_event_stream.ITestResult} message TestResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TestResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.testActionOutput != null && message.testActionOutput.length)
                for (let i = 0; i < message.testActionOutput.length; ++i)
                    $root.build_event_stream.File.encode(message.testActionOutput[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.testAttemptDurationMillis != null && message.hasOwnProperty("testAttemptDurationMillis"))
                writer.uint32(/* id 3, wireType 0 =*/24).int64(message.testAttemptDurationMillis);
            if (message.cachedLocally != null && message.hasOwnProperty("cachedLocally"))
                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.cachedLocally);
            if (message.status != null && message.hasOwnProperty("status"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.status);
            if (message.testAttemptStartMillisEpoch != null && message.hasOwnProperty("testAttemptStartMillisEpoch"))
                writer.uint32(/* id 6, wireType 0 =*/48).int64(message.testAttemptStartMillisEpoch);
            if (message.warning != null && message.warning.length)
                for (let i = 0; i < message.warning.length; ++i)
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.warning[i]);
            if (message.executionInfo != null && message.hasOwnProperty("executionInfo"))
                $root.build_event_stream.TestResult.ExecutionInfo.encode(message.executionInfo, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.statusDetails != null && message.hasOwnProperty("statusDetails"))
                writer.uint32(/* id 9, wireType 2 =*/74).string(message.statusDetails);
            return writer;
        };

        /**
         * Encodes the specified TestResult message, length delimited. Does not implicitly {@link build_event_stream.TestResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.TestResult
         * @static
         * @param {build_event_stream.ITestResult} message TestResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TestResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TestResult message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.TestResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.TestResult} TestResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TestResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.TestResult();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 5:
                    message.status = reader.int32();
                    break;
                case 9:
                    message.statusDetails = reader.string();
                    break;
                case 4:
                    message.cachedLocally = reader.bool();
                    break;
                case 6:
                    message.testAttemptStartMillisEpoch = reader.int64();
                    break;
                case 3:
                    message.testAttemptDurationMillis = reader.int64();
                    break;
                case 2:
                    if (!(message.testActionOutput && message.testActionOutput.length))
                        message.testActionOutput = [];
                    message.testActionOutput.push($root.build_event_stream.File.decode(reader, reader.uint32()));
                    break;
                case 7:
                    if (!(message.warning && message.warning.length))
                        message.warning = [];
                    message.warning.push(reader.string());
                    break;
                case 8:
                    message.executionInfo = $root.build_event_stream.TestResult.ExecutionInfo.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TestResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.TestResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.TestResult} TestResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TestResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TestResult message.
         * @function verify
         * @memberof build_event_stream.TestResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TestResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                    break;
                }
            if (message.statusDetails != null && message.hasOwnProperty("statusDetails"))
                if (!$util.isString(message.statusDetails))
                    return "statusDetails: string expected";
            if (message.cachedLocally != null && message.hasOwnProperty("cachedLocally"))
                if (typeof message.cachedLocally !== "boolean")
                    return "cachedLocally: boolean expected";
            if (message.testAttemptStartMillisEpoch != null && message.hasOwnProperty("testAttemptStartMillisEpoch"))
                if (!$util.isInteger(message.testAttemptStartMillisEpoch) && !(message.testAttemptStartMillisEpoch && $util.isInteger(message.testAttemptStartMillisEpoch.low) && $util.isInteger(message.testAttemptStartMillisEpoch.high)))
                    return "testAttemptStartMillisEpoch: integer|Long expected";
            if (message.testAttemptDurationMillis != null && message.hasOwnProperty("testAttemptDurationMillis"))
                if (!$util.isInteger(message.testAttemptDurationMillis) && !(message.testAttemptDurationMillis && $util.isInteger(message.testAttemptDurationMillis.low) && $util.isInteger(message.testAttemptDurationMillis.high)))
                    return "testAttemptDurationMillis: integer|Long expected";
            if (message.testActionOutput != null && message.hasOwnProperty("testActionOutput")) {
                if (!Array.isArray(message.testActionOutput))
                    return "testActionOutput: array expected";
                for (let i = 0; i < message.testActionOutput.length; ++i) {
                    let error = $root.build_event_stream.File.verify(message.testActionOutput[i]);
                    if (error)
                        return "testActionOutput." + error;
                }
            }
            if (message.warning != null && message.hasOwnProperty("warning")) {
                if (!Array.isArray(message.warning))
                    return "warning: array expected";
                for (let i = 0; i < message.warning.length; ++i)
                    if (!$util.isString(message.warning[i]))
                        return "warning: string[] expected";
            }
            if (message.executionInfo != null && message.hasOwnProperty("executionInfo")) {
                let error = $root.build_event_stream.TestResult.ExecutionInfo.verify(message.executionInfo);
                if (error)
                    return "executionInfo." + error;
            }
            return null;
        };

        /**
         * Creates a TestResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.TestResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.TestResult} TestResult
         */
        TestResult.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.TestResult)
                return object;
            let message = new $root.build_event_stream.TestResult();
            switch (object.status) {
            case "NO_STATUS":
            case 0:
                message.status = 0;
                break;
            case "PASSED":
            case 1:
                message.status = 1;
                break;
            case "FLAKY":
            case 2:
                message.status = 2;
                break;
            case "TIMEOUT":
            case 3:
                message.status = 3;
                break;
            case "FAILED":
            case 4:
                message.status = 4;
                break;
            case "INCOMPLETE":
            case 5:
                message.status = 5;
                break;
            case "REMOTE_FAILURE":
            case 6:
                message.status = 6;
                break;
            case "FAILED_TO_BUILD":
            case 7:
                message.status = 7;
                break;
            case "TOOL_HALTED_BEFORE_TESTING":
            case 8:
                message.status = 8;
                break;
            }
            if (object.statusDetails != null)
                message.statusDetails = String(object.statusDetails);
            if (object.cachedLocally != null)
                message.cachedLocally = Boolean(object.cachedLocally);
            if (object.testAttemptStartMillisEpoch != null)
                if ($util.Long)
                    (message.testAttemptStartMillisEpoch = $util.Long.fromValue(object.testAttemptStartMillisEpoch)).unsigned = false;
                else if (typeof object.testAttemptStartMillisEpoch === "string")
                    message.testAttemptStartMillisEpoch = parseInt(object.testAttemptStartMillisEpoch, 10);
                else if (typeof object.testAttemptStartMillisEpoch === "number")
                    message.testAttemptStartMillisEpoch = object.testAttemptStartMillisEpoch;
                else if (typeof object.testAttemptStartMillisEpoch === "object")
                    message.testAttemptStartMillisEpoch = new $util.LongBits(object.testAttemptStartMillisEpoch.low >>> 0, object.testAttemptStartMillisEpoch.high >>> 0).toNumber();
            if (object.testAttemptDurationMillis != null)
                if ($util.Long)
                    (message.testAttemptDurationMillis = $util.Long.fromValue(object.testAttemptDurationMillis)).unsigned = false;
                else if (typeof object.testAttemptDurationMillis === "string")
                    message.testAttemptDurationMillis = parseInt(object.testAttemptDurationMillis, 10);
                else if (typeof object.testAttemptDurationMillis === "number")
                    message.testAttemptDurationMillis = object.testAttemptDurationMillis;
                else if (typeof object.testAttemptDurationMillis === "object")
                    message.testAttemptDurationMillis = new $util.LongBits(object.testAttemptDurationMillis.low >>> 0, object.testAttemptDurationMillis.high >>> 0).toNumber();
            if (object.testActionOutput) {
                if (!Array.isArray(object.testActionOutput))
                    throw TypeError(".build_event_stream.TestResult.testActionOutput: array expected");
                message.testActionOutput = [];
                for (let i = 0; i < object.testActionOutput.length; ++i) {
                    if (typeof object.testActionOutput[i] !== "object")
                        throw TypeError(".build_event_stream.TestResult.testActionOutput: object expected");
                    message.testActionOutput[i] = $root.build_event_stream.File.fromObject(object.testActionOutput[i]);
                }
            }
            if (object.warning) {
                if (!Array.isArray(object.warning))
                    throw TypeError(".build_event_stream.TestResult.warning: array expected");
                message.warning = [];
                for (let i = 0; i < object.warning.length; ++i)
                    message.warning[i] = String(object.warning[i]);
            }
            if (object.executionInfo != null) {
                if (typeof object.executionInfo !== "object")
                    throw TypeError(".build_event_stream.TestResult.executionInfo: object expected");
                message.executionInfo = $root.build_event_stream.TestResult.ExecutionInfo.fromObject(object.executionInfo);
            }
            return message;
        };

        /**
         * Creates a plain object from a TestResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.TestResult
         * @static
         * @param {build_event_stream.TestResult} message TestResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TestResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.testActionOutput = [];
                object.warning = [];
            }
            if (options.defaults) {
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.testAttemptDurationMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.testAttemptDurationMillis = options.longs === String ? "0" : 0;
                object.cachedLocally = false;
                object.status = options.enums === String ? "NO_STATUS" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.testAttemptStartMillisEpoch = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.testAttemptStartMillisEpoch = options.longs === String ? "0" : 0;
                object.executionInfo = null;
                object.statusDetails = "";
            }
            if (message.testActionOutput && message.testActionOutput.length) {
                object.testActionOutput = [];
                for (let j = 0; j < message.testActionOutput.length; ++j)
                    object.testActionOutput[j] = $root.build_event_stream.File.toObject(message.testActionOutput[j], options);
            }
            if (message.testAttemptDurationMillis != null && message.hasOwnProperty("testAttemptDurationMillis"))
                if (typeof message.testAttemptDurationMillis === "number")
                    object.testAttemptDurationMillis = options.longs === String ? String(message.testAttemptDurationMillis) : message.testAttemptDurationMillis;
                else
                    object.testAttemptDurationMillis = options.longs === String ? $util.Long.prototype.toString.call(message.testAttemptDurationMillis) : options.longs === Number ? new $util.LongBits(message.testAttemptDurationMillis.low >>> 0, message.testAttemptDurationMillis.high >>> 0).toNumber() : message.testAttemptDurationMillis;
            if (message.cachedLocally != null && message.hasOwnProperty("cachedLocally"))
                object.cachedLocally = message.cachedLocally;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.build_event_stream.TestStatus[message.status] : message.status;
            if (message.testAttemptStartMillisEpoch != null && message.hasOwnProperty("testAttemptStartMillisEpoch"))
                if (typeof message.testAttemptStartMillisEpoch === "number")
                    object.testAttemptStartMillisEpoch = options.longs === String ? String(message.testAttemptStartMillisEpoch) : message.testAttemptStartMillisEpoch;
                else
                    object.testAttemptStartMillisEpoch = options.longs === String ? $util.Long.prototype.toString.call(message.testAttemptStartMillisEpoch) : options.longs === Number ? new $util.LongBits(message.testAttemptStartMillisEpoch.low >>> 0, message.testAttemptStartMillisEpoch.high >>> 0).toNumber() : message.testAttemptStartMillisEpoch;
            if (message.warning && message.warning.length) {
                object.warning = [];
                for (let j = 0; j < message.warning.length; ++j)
                    object.warning[j] = message.warning[j];
            }
            if (message.executionInfo != null && message.hasOwnProperty("executionInfo"))
                object.executionInfo = $root.build_event_stream.TestResult.ExecutionInfo.toObject(message.executionInfo, options);
            if (message.statusDetails != null && message.hasOwnProperty("statusDetails"))
                object.statusDetails = message.statusDetails;
            return object;
        };

        /**
         * Converts this TestResult to JSON.
         * @function toJSON
         * @memberof build_event_stream.TestResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TestResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        TestResult.ExecutionInfo = (function() {

            /**
             * Properties of an ExecutionInfo.
             * @memberof build_event_stream.TestResult
             * @interface IExecutionInfo
             * @property {number|null} [timeoutSeconds] ExecutionInfo timeoutSeconds
             * @property {string|null} [strategy] ExecutionInfo strategy
             * @property {boolean|null} [cachedRemotely] ExecutionInfo cachedRemotely
             * @property {number|null} [exitCode] ExecutionInfo exitCode
             * @property {string|null} [hostname] ExecutionInfo hostname
             * @property {build_event_stream.TestResult.ExecutionInfo.ITimingBreakdown|null} [timingBreakdown] ExecutionInfo timingBreakdown
             * @property {Array.<build_event_stream.TestResult.ExecutionInfo.IResourceUsage>|null} [resourceUsage] ExecutionInfo resourceUsage
             */

            /**
             * Constructs a new ExecutionInfo.
             * @memberof build_event_stream.TestResult
             * @classdesc Represents an ExecutionInfo.
             * @implements IExecutionInfo
             * @constructor
             * @param {build_event_stream.TestResult.IExecutionInfo=} [properties] Properties to set
             */
            function ExecutionInfo(properties) {
                this.resourceUsage = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ExecutionInfo timeoutSeconds.
             * @member {number} timeoutSeconds
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @instance
             */
            ExecutionInfo.prototype.timeoutSeconds = 0;

            /**
             * ExecutionInfo strategy.
             * @member {string} strategy
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @instance
             */
            ExecutionInfo.prototype.strategy = "";

            /**
             * ExecutionInfo cachedRemotely.
             * @member {boolean} cachedRemotely
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @instance
             */
            ExecutionInfo.prototype.cachedRemotely = false;

            /**
             * ExecutionInfo exitCode.
             * @member {number} exitCode
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @instance
             */
            ExecutionInfo.prototype.exitCode = 0;

            /**
             * ExecutionInfo hostname.
             * @member {string} hostname
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @instance
             */
            ExecutionInfo.prototype.hostname = "";

            /**
             * ExecutionInfo timingBreakdown.
             * @member {build_event_stream.TestResult.ExecutionInfo.ITimingBreakdown|null|undefined} timingBreakdown
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @instance
             */
            ExecutionInfo.prototype.timingBreakdown = null;

            /**
             * ExecutionInfo resourceUsage.
             * @member {Array.<build_event_stream.TestResult.ExecutionInfo.IResourceUsage>} resourceUsage
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @instance
             */
            ExecutionInfo.prototype.resourceUsage = $util.emptyArray;

            /**
             * Creates a new ExecutionInfo instance using the specified properties.
             * @function create
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @static
             * @param {build_event_stream.TestResult.IExecutionInfo=} [properties] Properties to set
             * @returns {build_event_stream.TestResult.ExecutionInfo} ExecutionInfo instance
             */
            ExecutionInfo.create = function create(properties) {
                return new ExecutionInfo(properties);
            };

            /**
             * Encodes the specified ExecutionInfo message. Does not implicitly {@link build_event_stream.TestResult.ExecutionInfo.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @static
             * @param {build_event_stream.TestResult.IExecutionInfo} message ExecutionInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExecutionInfo.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.timeoutSeconds != null && message.hasOwnProperty("timeoutSeconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.timeoutSeconds);
                if (message.strategy != null && message.hasOwnProperty("strategy"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.strategy);
                if (message.hostname != null && message.hasOwnProperty("hostname"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.hostname);
                if (message.timingBreakdown != null && message.hasOwnProperty("timingBreakdown"))
                    $root.build_event_stream.TestResult.ExecutionInfo.TimingBreakdown.encode(message.timingBreakdown, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.resourceUsage != null && message.resourceUsage.length)
                    for (let i = 0; i < message.resourceUsage.length; ++i)
                        $root.build_event_stream.TestResult.ExecutionInfo.ResourceUsage.encode(message.resourceUsage[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.cachedRemotely != null && message.hasOwnProperty("cachedRemotely"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.cachedRemotely);
                if (message.exitCode != null && message.hasOwnProperty("exitCode"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.exitCode);
                return writer;
            };

            /**
             * Encodes the specified ExecutionInfo message, length delimited. Does not implicitly {@link build_event_stream.TestResult.ExecutionInfo.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @static
             * @param {build_event_stream.TestResult.IExecutionInfo} message ExecutionInfo message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExecutionInfo.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an ExecutionInfo message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.TestResult.ExecutionInfo} ExecutionInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExecutionInfo.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.TestResult.ExecutionInfo();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.timeoutSeconds = reader.int32();
                        break;
                    case 2:
                        message.strategy = reader.string();
                        break;
                    case 6:
                        message.cachedRemotely = reader.bool();
                        break;
                    case 7:
                        message.exitCode = reader.int32();
                        break;
                    case 3:
                        message.hostname = reader.string();
                        break;
                    case 4:
                        message.timingBreakdown = $root.build_event_stream.TestResult.ExecutionInfo.TimingBreakdown.decode(reader, reader.uint32());
                        break;
                    case 5:
                        if (!(message.resourceUsage && message.resourceUsage.length))
                            message.resourceUsage = [];
                        message.resourceUsage.push($root.build_event_stream.TestResult.ExecutionInfo.ResourceUsage.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an ExecutionInfo message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.TestResult.ExecutionInfo} ExecutionInfo
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExecutionInfo.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an ExecutionInfo message.
             * @function verify
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ExecutionInfo.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.timeoutSeconds != null && message.hasOwnProperty("timeoutSeconds"))
                    if (!$util.isInteger(message.timeoutSeconds))
                        return "timeoutSeconds: integer expected";
                if (message.strategy != null && message.hasOwnProperty("strategy"))
                    if (!$util.isString(message.strategy))
                        return "strategy: string expected";
                if (message.cachedRemotely != null && message.hasOwnProperty("cachedRemotely"))
                    if (typeof message.cachedRemotely !== "boolean")
                        return "cachedRemotely: boolean expected";
                if (message.exitCode != null && message.hasOwnProperty("exitCode"))
                    if (!$util.isInteger(message.exitCode))
                        return "exitCode: integer expected";
                if (message.hostname != null && message.hasOwnProperty("hostname"))
                    if (!$util.isString(message.hostname))
                        return "hostname: string expected";
                if (message.timingBreakdown != null && message.hasOwnProperty("timingBreakdown")) {
                    let error = $root.build_event_stream.TestResult.ExecutionInfo.TimingBreakdown.verify(message.timingBreakdown);
                    if (error)
                        return "timingBreakdown." + error;
                }
                if (message.resourceUsage != null && message.hasOwnProperty("resourceUsage")) {
                    if (!Array.isArray(message.resourceUsage))
                        return "resourceUsage: array expected";
                    for (let i = 0; i < message.resourceUsage.length; ++i) {
                        let error = $root.build_event_stream.TestResult.ExecutionInfo.ResourceUsage.verify(message.resourceUsage[i]);
                        if (error)
                            return "resourceUsage." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an ExecutionInfo message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.TestResult.ExecutionInfo} ExecutionInfo
             */
            ExecutionInfo.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.TestResult.ExecutionInfo)
                    return object;
                let message = new $root.build_event_stream.TestResult.ExecutionInfo();
                if (object.timeoutSeconds != null)
                    message.timeoutSeconds = object.timeoutSeconds | 0;
                if (object.strategy != null)
                    message.strategy = String(object.strategy);
                if (object.cachedRemotely != null)
                    message.cachedRemotely = Boolean(object.cachedRemotely);
                if (object.exitCode != null)
                    message.exitCode = object.exitCode | 0;
                if (object.hostname != null)
                    message.hostname = String(object.hostname);
                if (object.timingBreakdown != null) {
                    if (typeof object.timingBreakdown !== "object")
                        throw TypeError(".build_event_stream.TestResult.ExecutionInfo.timingBreakdown: object expected");
                    message.timingBreakdown = $root.build_event_stream.TestResult.ExecutionInfo.TimingBreakdown.fromObject(object.timingBreakdown);
                }
                if (object.resourceUsage) {
                    if (!Array.isArray(object.resourceUsage))
                        throw TypeError(".build_event_stream.TestResult.ExecutionInfo.resourceUsage: array expected");
                    message.resourceUsage = [];
                    for (let i = 0; i < object.resourceUsage.length; ++i) {
                        if (typeof object.resourceUsage[i] !== "object")
                            throw TypeError(".build_event_stream.TestResult.ExecutionInfo.resourceUsage: object expected");
                        message.resourceUsage[i] = $root.build_event_stream.TestResult.ExecutionInfo.ResourceUsage.fromObject(object.resourceUsage[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an ExecutionInfo message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @static
             * @param {build_event_stream.TestResult.ExecutionInfo} message ExecutionInfo
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ExecutionInfo.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.resourceUsage = [];
                if (options.defaults) {
                    object.timeoutSeconds = 0;
                    object.strategy = "";
                    object.hostname = "";
                    object.timingBreakdown = null;
                    object.cachedRemotely = false;
                    object.exitCode = 0;
                }
                if (message.timeoutSeconds != null && message.hasOwnProperty("timeoutSeconds"))
                    object.timeoutSeconds = message.timeoutSeconds;
                if (message.strategy != null && message.hasOwnProperty("strategy"))
                    object.strategy = message.strategy;
                if (message.hostname != null && message.hasOwnProperty("hostname"))
                    object.hostname = message.hostname;
                if (message.timingBreakdown != null && message.hasOwnProperty("timingBreakdown"))
                    object.timingBreakdown = $root.build_event_stream.TestResult.ExecutionInfo.TimingBreakdown.toObject(message.timingBreakdown, options);
                if (message.resourceUsage && message.resourceUsage.length) {
                    object.resourceUsage = [];
                    for (let j = 0; j < message.resourceUsage.length; ++j)
                        object.resourceUsage[j] = $root.build_event_stream.TestResult.ExecutionInfo.ResourceUsage.toObject(message.resourceUsage[j], options);
                }
                if (message.cachedRemotely != null && message.hasOwnProperty("cachedRemotely"))
                    object.cachedRemotely = message.cachedRemotely;
                if (message.exitCode != null && message.hasOwnProperty("exitCode"))
                    object.exitCode = message.exitCode;
                return object;
            };

            /**
             * Converts this ExecutionInfo to JSON.
             * @function toJSON
             * @memberof build_event_stream.TestResult.ExecutionInfo
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ExecutionInfo.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            ExecutionInfo.TimingBreakdown = (function() {

                /**
                 * Properties of a TimingBreakdown.
                 * @memberof build_event_stream.TestResult.ExecutionInfo
                 * @interface ITimingBreakdown
                 * @property {Array.<build_event_stream.TestResult.ExecutionInfo.ITimingBreakdown>|null} [child] TimingBreakdown child
                 * @property {string|null} [name] TimingBreakdown name
                 * @property {Long|null} [timeMillis] TimingBreakdown timeMillis
                 */

                /**
                 * Constructs a new TimingBreakdown.
                 * @memberof build_event_stream.TestResult.ExecutionInfo
                 * @classdesc Represents a TimingBreakdown.
                 * @implements ITimingBreakdown
                 * @constructor
                 * @param {build_event_stream.TestResult.ExecutionInfo.ITimingBreakdown=} [properties] Properties to set
                 */
                function TimingBreakdown(properties) {
                    this.child = [];
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TimingBreakdown child.
                 * @member {Array.<build_event_stream.TestResult.ExecutionInfo.ITimingBreakdown>} child
                 * @memberof build_event_stream.TestResult.ExecutionInfo.TimingBreakdown
                 * @instance
                 */
                TimingBreakdown.prototype.child = $util.emptyArray;

                /**
                 * TimingBreakdown name.
                 * @member {string} name
                 * @memberof build_event_stream.TestResult.ExecutionInfo.TimingBreakdown
                 * @instance
                 */
                TimingBreakdown.prototype.name = "";

                /**
                 * TimingBreakdown timeMillis.
                 * @member {Long} timeMillis
                 * @memberof build_event_stream.TestResult.ExecutionInfo.TimingBreakdown
                 * @instance
                 */
                TimingBreakdown.prototype.timeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new TimingBreakdown instance using the specified properties.
                 * @function create
                 * @memberof build_event_stream.TestResult.ExecutionInfo.TimingBreakdown
                 * @static
                 * @param {build_event_stream.TestResult.ExecutionInfo.ITimingBreakdown=} [properties] Properties to set
                 * @returns {build_event_stream.TestResult.ExecutionInfo.TimingBreakdown} TimingBreakdown instance
                 */
                TimingBreakdown.create = function create(properties) {
                    return new TimingBreakdown(properties);
                };

                /**
                 * Encodes the specified TimingBreakdown message. Does not implicitly {@link build_event_stream.TestResult.ExecutionInfo.TimingBreakdown.verify|verify} messages.
                 * @function encode
                 * @memberof build_event_stream.TestResult.ExecutionInfo.TimingBreakdown
                 * @static
                 * @param {build_event_stream.TestResult.ExecutionInfo.ITimingBreakdown} message TimingBreakdown message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TimingBreakdown.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.child != null && message.child.length)
                        for (let i = 0; i < message.child.length; ++i)
                            $root.build_event_stream.TestResult.ExecutionInfo.TimingBreakdown.encode(message.child[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.name != null && message.hasOwnProperty("name"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                    if (message.timeMillis != null && message.hasOwnProperty("timeMillis"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.timeMillis);
                    return writer;
                };

                /**
                 * Encodes the specified TimingBreakdown message, length delimited. Does not implicitly {@link build_event_stream.TestResult.ExecutionInfo.TimingBreakdown.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof build_event_stream.TestResult.ExecutionInfo.TimingBreakdown
                 * @static
                 * @param {build_event_stream.TestResult.ExecutionInfo.ITimingBreakdown} message TimingBreakdown message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TimingBreakdown.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TimingBreakdown message from the specified reader or buffer.
                 * @function decode
                 * @memberof build_event_stream.TestResult.ExecutionInfo.TimingBreakdown
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {build_event_stream.TestResult.ExecutionInfo.TimingBreakdown} TimingBreakdown
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TimingBreakdown.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.TestResult.ExecutionInfo.TimingBreakdown();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            if (!(message.child && message.child.length))
                                message.child = [];
                            message.child.push($root.build_event_stream.TestResult.ExecutionInfo.TimingBreakdown.decode(reader, reader.uint32()));
                            break;
                        case 2:
                            message.name = reader.string();
                            break;
                        case 3:
                            message.timeMillis = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TimingBreakdown message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof build_event_stream.TestResult.ExecutionInfo.TimingBreakdown
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {build_event_stream.TestResult.ExecutionInfo.TimingBreakdown} TimingBreakdown
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TimingBreakdown.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TimingBreakdown message.
                 * @function verify
                 * @memberof build_event_stream.TestResult.ExecutionInfo.TimingBreakdown
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TimingBreakdown.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.child != null && message.hasOwnProperty("child")) {
                        if (!Array.isArray(message.child))
                            return "child: array expected";
                        for (let i = 0; i < message.child.length; ++i) {
                            let error = $root.build_event_stream.TestResult.ExecutionInfo.TimingBreakdown.verify(message.child[i]);
                            if (error)
                                return "child." + error;
                        }
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.timeMillis != null && message.hasOwnProperty("timeMillis"))
                        if (!$util.isInteger(message.timeMillis) && !(message.timeMillis && $util.isInteger(message.timeMillis.low) && $util.isInteger(message.timeMillis.high)))
                            return "timeMillis: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a TimingBreakdown message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof build_event_stream.TestResult.ExecutionInfo.TimingBreakdown
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {build_event_stream.TestResult.ExecutionInfo.TimingBreakdown} TimingBreakdown
                 */
                TimingBreakdown.fromObject = function fromObject(object) {
                    if (object instanceof $root.build_event_stream.TestResult.ExecutionInfo.TimingBreakdown)
                        return object;
                    let message = new $root.build_event_stream.TestResult.ExecutionInfo.TimingBreakdown();
                    if (object.child) {
                        if (!Array.isArray(object.child))
                            throw TypeError(".build_event_stream.TestResult.ExecutionInfo.TimingBreakdown.child: array expected");
                        message.child = [];
                        for (let i = 0; i < object.child.length; ++i) {
                            if (typeof object.child[i] !== "object")
                                throw TypeError(".build_event_stream.TestResult.ExecutionInfo.TimingBreakdown.child: object expected");
                            message.child[i] = $root.build_event_stream.TestResult.ExecutionInfo.TimingBreakdown.fromObject(object.child[i]);
                        }
                    }
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.timeMillis != null)
                        if ($util.Long)
                            (message.timeMillis = $util.Long.fromValue(object.timeMillis)).unsigned = false;
                        else if (typeof object.timeMillis === "string")
                            message.timeMillis = parseInt(object.timeMillis, 10);
                        else if (typeof object.timeMillis === "number")
                            message.timeMillis = object.timeMillis;
                        else if (typeof object.timeMillis === "object")
                            message.timeMillis = new $util.LongBits(object.timeMillis.low >>> 0, object.timeMillis.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a TimingBreakdown message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof build_event_stream.TestResult.ExecutionInfo.TimingBreakdown
                 * @static
                 * @param {build_event_stream.TestResult.ExecutionInfo.TimingBreakdown} message TimingBreakdown
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TimingBreakdown.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.arrays || options.defaults)
                        object.child = [];
                    if (options.defaults) {
                        object.name = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.timeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.timeMillis = options.longs === String ? "0" : 0;
                    }
                    if (message.child && message.child.length) {
                        object.child = [];
                        for (let j = 0; j < message.child.length; ++j)
                            object.child[j] = $root.build_event_stream.TestResult.ExecutionInfo.TimingBreakdown.toObject(message.child[j], options);
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.timeMillis != null && message.hasOwnProperty("timeMillis"))
                        if (typeof message.timeMillis === "number")
                            object.timeMillis = options.longs === String ? String(message.timeMillis) : message.timeMillis;
                        else
                            object.timeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.timeMillis) : options.longs === Number ? new $util.LongBits(message.timeMillis.low >>> 0, message.timeMillis.high >>> 0).toNumber() : message.timeMillis;
                    return object;
                };

                /**
                 * Converts this TimingBreakdown to JSON.
                 * @function toJSON
                 * @memberof build_event_stream.TestResult.ExecutionInfo.TimingBreakdown
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TimingBreakdown.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return TimingBreakdown;
            })();

            ExecutionInfo.ResourceUsage = (function() {

                /**
                 * Properties of a ResourceUsage.
                 * @memberof build_event_stream.TestResult.ExecutionInfo
                 * @interface IResourceUsage
                 * @property {string|null} [name] ResourceUsage name
                 * @property {Long|null} [value] ResourceUsage value
                 */

                /**
                 * Constructs a new ResourceUsage.
                 * @memberof build_event_stream.TestResult.ExecutionInfo
                 * @classdesc Represents a ResourceUsage.
                 * @implements IResourceUsage
                 * @constructor
                 * @param {build_event_stream.TestResult.ExecutionInfo.IResourceUsage=} [properties] Properties to set
                 */
                function ResourceUsage(properties) {
                    if (properties)
                        for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResourceUsage name.
                 * @member {string} name
                 * @memberof build_event_stream.TestResult.ExecutionInfo.ResourceUsage
                 * @instance
                 */
                ResourceUsage.prototype.name = "";

                /**
                 * ResourceUsage value.
                 * @member {Long} value
                 * @memberof build_event_stream.TestResult.ExecutionInfo.ResourceUsage
                 * @instance
                 */
                ResourceUsage.prototype.value = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new ResourceUsage instance using the specified properties.
                 * @function create
                 * @memberof build_event_stream.TestResult.ExecutionInfo.ResourceUsage
                 * @static
                 * @param {build_event_stream.TestResult.ExecutionInfo.IResourceUsage=} [properties] Properties to set
                 * @returns {build_event_stream.TestResult.ExecutionInfo.ResourceUsage} ResourceUsage instance
                 */
                ResourceUsage.create = function create(properties) {
                    return new ResourceUsage(properties);
                };

                /**
                 * Encodes the specified ResourceUsage message. Does not implicitly {@link build_event_stream.TestResult.ExecutionInfo.ResourceUsage.verify|verify} messages.
                 * @function encode
                 * @memberof build_event_stream.TestResult.ExecutionInfo.ResourceUsage
                 * @static
                 * @param {build_event_stream.TestResult.ExecutionInfo.IResourceUsage} message ResourceUsage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResourceUsage.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && message.hasOwnProperty("name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.value != null && message.hasOwnProperty("value"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.value);
                    return writer;
                };

                /**
                 * Encodes the specified ResourceUsage message, length delimited. Does not implicitly {@link build_event_stream.TestResult.ExecutionInfo.ResourceUsage.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof build_event_stream.TestResult.ExecutionInfo.ResourceUsage
                 * @static
                 * @param {build_event_stream.TestResult.ExecutionInfo.IResourceUsage} message ResourceUsage message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResourceUsage.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResourceUsage message from the specified reader or buffer.
                 * @function decode
                 * @memberof build_event_stream.TestResult.ExecutionInfo.ResourceUsage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {build_event_stream.TestResult.ExecutionInfo.ResourceUsage} ResourceUsage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResourceUsage.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.TestResult.ExecutionInfo.ResourceUsage();
                    while (reader.pos < end) {
                        let tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1:
                            message.name = reader.string();
                            break;
                        case 2:
                            message.value = reader.int64();
                            break;
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResourceUsage message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof build_event_stream.TestResult.ExecutionInfo.ResourceUsage
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {build_event_stream.TestResult.ExecutionInfo.ResourceUsage} ResourceUsage
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResourceUsage.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResourceUsage message.
                 * @function verify
                 * @memberof build_event_stream.TestResult.ExecutionInfo.ResourceUsage
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResourceUsage.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (!$util.isInteger(message.value) && !(message.value && $util.isInteger(message.value.low) && $util.isInteger(message.value.high)))
                            return "value: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a ResourceUsage message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof build_event_stream.TestResult.ExecutionInfo.ResourceUsage
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {build_event_stream.TestResult.ExecutionInfo.ResourceUsage} ResourceUsage
                 */
                ResourceUsage.fromObject = function fromObject(object) {
                    if (object instanceof $root.build_event_stream.TestResult.ExecutionInfo.ResourceUsage)
                        return object;
                    let message = new $root.build_event_stream.TestResult.ExecutionInfo.ResourceUsage();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.value != null)
                        if ($util.Long)
                            (message.value = $util.Long.fromValue(object.value)).unsigned = false;
                        else if (typeof object.value === "string")
                            message.value = parseInt(object.value, 10);
                        else if (typeof object.value === "number")
                            message.value = object.value;
                        else if (typeof object.value === "object")
                            message.value = new $util.LongBits(object.value.low >>> 0, object.value.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a ResourceUsage message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof build_event_stream.TestResult.ExecutionInfo.ResourceUsage
                 * @static
                 * @param {build_event_stream.TestResult.ExecutionInfo.ResourceUsage} message ResourceUsage
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResourceUsage.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    let object = {};
                    if (options.defaults) {
                        object.name = "";
                        if ($util.Long) {
                            let long = new $util.Long(0, 0, false);
                            object.value = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.value = options.longs === String ? "0" : 0;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.value != null && message.hasOwnProperty("value"))
                        if (typeof message.value === "number")
                            object.value = options.longs === String ? String(message.value) : message.value;
                        else
                            object.value = options.longs === String ? $util.Long.prototype.toString.call(message.value) : options.longs === Number ? new $util.LongBits(message.value.low >>> 0, message.value.high >>> 0).toNumber() : message.value;
                    return object;
                };

                /**
                 * Converts this ResourceUsage to JSON.
                 * @function toJSON
                 * @memberof build_event_stream.TestResult.ExecutionInfo.ResourceUsage
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResourceUsage.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return ResourceUsage;
            })();

            return ExecutionInfo;
        })();

        return TestResult;
    })();

    build_event_stream.TestSummary = (function() {

        /**
         * Properties of a TestSummary.
         * @memberof build_event_stream
         * @interface ITestSummary
         * @property {build_event_stream.TestStatus|null} [overallStatus] TestSummary overallStatus
         * @property {number|null} [totalRunCount] TestSummary totalRunCount
         * @property {Array.<build_event_stream.IFile>|null} [passed] TestSummary passed
         * @property {Array.<build_event_stream.IFile>|null} [failed] TestSummary failed
         * @property {number|null} [totalNumCached] TestSummary totalNumCached
         * @property {Long|null} [firstStartTimeMillis] TestSummary firstStartTimeMillis
         * @property {Long|null} [lastStopTimeMillis] TestSummary lastStopTimeMillis
         * @property {Long|null} [totalRunDurationMillis] TestSummary totalRunDurationMillis
         */

        /**
         * Constructs a new TestSummary.
         * @memberof build_event_stream
         * @classdesc Represents a TestSummary.
         * @implements ITestSummary
         * @constructor
         * @param {build_event_stream.ITestSummary=} [properties] Properties to set
         */
        function TestSummary(properties) {
            this.passed = [];
            this.failed = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * TestSummary overallStatus.
         * @member {build_event_stream.TestStatus} overallStatus
         * @memberof build_event_stream.TestSummary
         * @instance
         */
        TestSummary.prototype.overallStatus = 0;

        /**
         * TestSummary totalRunCount.
         * @member {number} totalRunCount
         * @memberof build_event_stream.TestSummary
         * @instance
         */
        TestSummary.prototype.totalRunCount = 0;

        /**
         * TestSummary passed.
         * @member {Array.<build_event_stream.IFile>} passed
         * @memberof build_event_stream.TestSummary
         * @instance
         */
        TestSummary.prototype.passed = $util.emptyArray;

        /**
         * TestSummary failed.
         * @member {Array.<build_event_stream.IFile>} failed
         * @memberof build_event_stream.TestSummary
         * @instance
         */
        TestSummary.prototype.failed = $util.emptyArray;

        /**
         * TestSummary totalNumCached.
         * @member {number} totalNumCached
         * @memberof build_event_stream.TestSummary
         * @instance
         */
        TestSummary.prototype.totalNumCached = 0;

        /**
         * TestSummary firstStartTimeMillis.
         * @member {Long} firstStartTimeMillis
         * @memberof build_event_stream.TestSummary
         * @instance
         */
        TestSummary.prototype.firstStartTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TestSummary lastStopTimeMillis.
         * @member {Long} lastStopTimeMillis
         * @memberof build_event_stream.TestSummary
         * @instance
         */
        TestSummary.prototype.lastStopTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * TestSummary totalRunDurationMillis.
         * @member {Long} totalRunDurationMillis
         * @memberof build_event_stream.TestSummary
         * @instance
         */
        TestSummary.prototype.totalRunDurationMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * Creates a new TestSummary instance using the specified properties.
         * @function create
         * @memberof build_event_stream.TestSummary
         * @static
         * @param {build_event_stream.ITestSummary=} [properties] Properties to set
         * @returns {build_event_stream.TestSummary} TestSummary instance
         */
        TestSummary.create = function create(properties) {
            return new TestSummary(properties);
        };

        /**
         * Encodes the specified TestSummary message. Does not implicitly {@link build_event_stream.TestSummary.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.TestSummary
         * @static
         * @param {build_event_stream.ITestSummary} message TestSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TestSummary.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.totalRunCount != null && message.hasOwnProperty("totalRunCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.totalRunCount);
            if (message.passed != null && message.passed.length)
                for (let i = 0; i < message.passed.length; ++i)
                    $root.build_event_stream.File.encode(message.passed[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.failed != null && message.failed.length)
                for (let i = 0; i < message.failed.length; ++i)
                    $root.build_event_stream.File.encode(message.failed[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.overallStatus != null && message.hasOwnProperty("overallStatus"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.overallStatus);
            if (message.totalNumCached != null && message.hasOwnProperty("totalNumCached"))
                writer.uint32(/* id 6, wireType 0 =*/48).int32(message.totalNumCached);
            if (message.firstStartTimeMillis != null && message.hasOwnProperty("firstStartTimeMillis"))
                writer.uint32(/* id 7, wireType 0 =*/56).int64(message.firstStartTimeMillis);
            if (message.lastStopTimeMillis != null && message.hasOwnProperty("lastStopTimeMillis"))
                writer.uint32(/* id 8, wireType 0 =*/64).int64(message.lastStopTimeMillis);
            if (message.totalRunDurationMillis != null && message.hasOwnProperty("totalRunDurationMillis"))
                writer.uint32(/* id 9, wireType 0 =*/72).int64(message.totalRunDurationMillis);
            return writer;
        };

        /**
         * Encodes the specified TestSummary message, length delimited. Does not implicitly {@link build_event_stream.TestSummary.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.TestSummary
         * @static
         * @param {build_event_stream.ITestSummary} message TestSummary message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        TestSummary.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a TestSummary message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.TestSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.TestSummary} TestSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TestSummary.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.TestSummary();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 5:
                    message.overallStatus = reader.int32();
                    break;
                case 1:
                    message.totalRunCount = reader.int32();
                    break;
                case 3:
                    if (!(message.passed && message.passed.length))
                        message.passed = [];
                    message.passed.push($root.build_event_stream.File.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.failed && message.failed.length))
                        message.failed = [];
                    message.failed.push($root.build_event_stream.File.decode(reader, reader.uint32()));
                    break;
                case 6:
                    message.totalNumCached = reader.int32();
                    break;
                case 7:
                    message.firstStartTimeMillis = reader.int64();
                    break;
                case 8:
                    message.lastStopTimeMillis = reader.int64();
                    break;
                case 9:
                    message.totalRunDurationMillis = reader.int64();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a TestSummary message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.TestSummary
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.TestSummary} TestSummary
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        TestSummary.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a TestSummary message.
         * @function verify
         * @memberof build_event_stream.TestSummary
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        TestSummary.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.overallStatus != null && message.hasOwnProperty("overallStatus"))
                switch (message.overallStatus) {
                default:
                    return "overallStatus: enum value expected";
                case 0:
                case 1:
                case 2:
                case 3:
                case 4:
                case 5:
                case 6:
                case 7:
                case 8:
                    break;
                }
            if (message.totalRunCount != null && message.hasOwnProperty("totalRunCount"))
                if (!$util.isInteger(message.totalRunCount))
                    return "totalRunCount: integer expected";
            if (message.passed != null && message.hasOwnProperty("passed")) {
                if (!Array.isArray(message.passed))
                    return "passed: array expected";
                for (let i = 0; i < message.passed.length; ++i) {
                    let error = $root.build_event_stream.File.verify(message.passed[i]);
                    if (error)
                        return "passed." + error;
                }
            }
            if (message.failed != null && message.hasOwnProperty("failed")) {
                if (!Array.isArray(message.failed))
                    return "failed: array expected";
                for (let i = 0; i < message.failed.length; ++i) {
                    let error = $root.build_event_stream.File.verify(message.failed[i]);
                    if (error)
                        return "failed." + error;
                }
            }
            if (message.totalNumCached != null && message.hasOwnProperty("totalNumCached"))
                if (!$util.isInteger(message.totalNumCached))
                    return "totalNumCached: integer expected";
            if (message.firstStartTimeMillis != null && message.hasOwnProperty("firstStartTimeMillis"))
                if (!$util.isInteger(message.firstStartTimeMillis) && !(message.firstStartTimeMillis && $util.isInteger(message.firstStartTimeMillis.low) && $util.isInteger(message.firstStartTimeMillis.high)))
                    return "firstStartTimeMillis: integer|Long expected";
            if (message.lastStopTimeMillis != null && message.hasOwnProperty("lastStopTimeMillis"))
                if (!$util.isInteger(message.lastStopTimeMillis) && !(message.lastStopTimeMillis && $util.isInteger(message.lastStopTimeMillis.low) && $util.isInteger(message.lastStopTimeMillis.high)))
                    return "lastStopTimeMillis: integer|Long expected";
            if (message.totalRunDurationMillis != null && message.hasOwnProperty("totalRunDurationMillis"))
                if (!$util.isInteger(message.totalRunDurationMillis) && !(message.totalRunDurationMillis && $util.isInteger(message.totalRunDurationMillis.low) && $util.isInteger(message.totalRunDurationMillis.high)))
                    return "totalRunDurationMillis: integer|Long expected";
            return null;
        };

        /**
         * Creates a TestSummary message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.TestSummary
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.TestSummary} TestSummary
         */
        TestSummary.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.TestSummary)
                return object;
            let message = new $root.build_event_stream.TestSummary();
            switch (object.overallStatus) {
            case "NO_STATUS":
            case 0:
                message.overallStatus = 0;
                break;
            case "PASSED":
            case 1:
                message.overallStatus = 1;
                break;
            case "FLAKY":
            case 2:
                message.overallStatus = 2;
                break;
            case "TIMEOUT":
            case 3:
                message.overallStatus = 3;
                break;
            case "FAILED":
            case 4:
                message.overallStatus = 4;
                break;
            case "INCOMPLETE":
            case 5:
                message.overallStatus = 5;
                break;
            case "REMOTE_FAILURE":
            case 6:
                message.overallStatus = 6;
                break;
            case "FAILED_TO_BUILD":
            case 7:
                message.overallStatus = 7;
                break;
            case "TOOL_HALTED_BEFORE_TESTING":
            case 8:
                message.overallStatus = 8;
                break;
            }
            if (object.totalRunCount != null)
                message.totalRunCount = object.totalRunCount | 0;
            if (object.passed) {
                if (!Array.isArray(object.passed))
                    throw TypeError(".build_event_stream.TestSummary.passed: array expected");
                message.passed = [];
                for (let i = 0; i < object.passed.length; ++i) {
                    if (typeof object.passed[i] !== "object")
                        throw TypeError(".build_event_stream.TestSummary.passed: object expected");
                    message.passed[i] = $root.build_event_stream.File.fromObject(object.passed[i]);
                }
            }
            if (object.failed) {
                if (!Array.isArray(object.failed))
                    throw TypeError(".build_event_stream.TestSummary.failed: array expected");
                message.failed = [];
                for (let i = 0; i < object.failed.length; ++i) {
                    if (typeof object.failed[i] !== "object")
                        throw TypeError(".build_event_stream.TestSummary.failed: object expected");
                    message.failed[i] = $root.build_event_stream.File.fromObject(object.failed[i]);
                }
            }
            if (object.totalNumCached != null)
                message.totalNumCached = object.totalNumCached | 0;
            if (object.firstStartTimeMillis != null)
                if ($util.Long)
                    (message.firstStartTimeMillis = $util.Long.fromValue(object.firstStartTimeMillis)).unsigned = false;
                else if (typeof object.firstStartTimeMillis === "string")
                    message.firstStartTimeMillis = parseInt(object.firstStartTimeMillis, 10);
                else if (typeof object.firstStartTimeMillis === "number")
                    message.firstStartTimeMillis = object.firstStartTimeMillis;
                else if (typeof object.firstStartTimeMillis === "object")
                    message.firstStartTimeMillis = new $util.LongBits(object.firstStartTimeMillis.low >>> 0, object.firstStartTimeMillis.high >>> 0).toNumber();
            if (object.lastStopTimeMillis != null)
                if ($util.Long)
                    (message.lastStopTimeMillis = $util.Long.fromValue(object.lastStopTimeMillis)).unsigned = false;
                else if (typeof object.lastStopTimeMillis === "string")
                    message.lastStopTimeMillis = parseInt(object.lastStopTimeMillis, 10);
                else if (typeof object.lastStopTimeMillis === "number")
                    message.lastStopTimeMillis = object.lastStopTimeMillis;
                else if (typeof object.lastStopTimeMillis === "object")
                    message.lastStopTimeMillis = new $util.LongBits(object.lastStopTimeMillis.low >>> 0, object.lastStopTimeMillis.high >>> 0).toNumber();
            if (object.totalRunDurationMillis != null)
                if ($util.Long)
                    (message.totalRunDurationMillis = $util.Long.fromValue(object.totalRunDurationMillis)).unsigned = false;
                else if (typeof object.totalRunDurationMillis === "string")
                    message.totalRunDurationMillis = parseInt(object.totalRunDurationMillis, 10);
                else if (typeof object.totalRunDurationMillis === "number")
                    message.totalRunDurationMillis = object.totalRunDurationMillis;
                else if (typeof object.totalRunDurationMillis === "object")
                    message.totalRunDurationMillis = new $util.LongBits(object.totalRunDurationMillis.low >>> 0, object.totalRunDurationMillis.high >>> 0).toNumber();
            return message;
        };

        /**
         * Creates a plain object from a TestSummary message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.TestSummary
         * @static
         * @param {build_event_stream.TestSummary} message TestSummary
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        TestSummary.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.passed = [];
                object.failed = [];
            }
            if (options.defaults) {
                object.totalRunCount = 0;
                object.overallStatus = options.enums === String ? "NO_STATUS" : 0;
                object.totalNumCached = 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.firstStartTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.firstStartTimeMillis = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.lastStopTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.lastStopTimeMillis = options.longs === String ? "0" : 0;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.totalRunDurationMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.totalRunDurationMillis = options.longs === String ? "0" : 0;
            }
            if (message.totalRunCount != null && message.hasOwnProperty("totalRunCount"))
                object.totalRunCount = message.totalRunCount;
            if (message.passed && message.passed.length) {
                object.passed = [];
                for (let j = 0; j < message.passed.length; ++j)
                    object.passed[j] = $root.build_event_stream.File.toObject(message.passed[j], options);
            }
            if (message.failed && message.failed.length) {
                object.failed = [];
                for (let j = 0; j < message.failed.length; ++j)
                    object.failed[j] = $root.build_event_stream.File.toObject(message.failed[j], options);
            }
            if (message.overallStatus != null && message.hasOwnProperty("overallStatus"))
                object.overallStatus = options.enums === String ? $root.build_event_stream.TestStatus[message.overallStatus] : message.overallStatus;
            if (message.totalNumCached != null && message.hasOwnProperty("totalNumCached"))
                object.totalNumCached = message.totalNumCached;
            if (message.firstStartTimeMillis != null && message.hasOwnProperty("firstStartTimeMillis"))
                if (typeof message.firstStartTimeMillis === "number")
                    object.firstStartTimeMillis = options.longs === String ? String(message.firstStartTimeMillis) : message.firstStartTimeMillis;
                else
                    object.firstStartTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.firstStartTimeMillis) : options.longs === Number ? new $util.LongBits(message.firstStartTimeMillis.low >>> 0, message.firstStartTimeMillis.high >>> 0).toNumber() : message.firstStartTimeMillis;
            if (message.lastStopTimeMillis != null && message.hasOwnProperty("lastStopTimeMillis"))
                if (typeof message.lastStopTimeMillis === "number")
                    object.lastStopTimeMillis = options.longs === String ? String(message.lastStopTimeMillis) : message.lastStopTimeMillis;
                else
                    object.lastStopTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.lastStopTimeMillis) : options.longs === Number ? new $util.LongBits(message.lastStopTimeMillis.low >>> 0, message.lastStopTimeMillis.high >>> 0).toNumber() : message.lastStopTimeMillis;
            if (message.totalRunDurationMillis != null && message.hasOwnProperty("totalRunDurationMillis"))
                if (typeof message.totalRunDurationMillis === "number")
                    object.totalRunDurationMillis = options.longs === String ? String(message.totalRunDurationMillis) : message.totalRunDurationMillis;
                else
                    object.totalRunDurationMillis = options.longs === String ? $util.Long.prototype.toString.call(message.totalRunDurationMillis) : options.longs === Number ? new $util.LongBits(message.totalRunDurationMillis.low >>> 0, message.totalRunDurationMillis.high >>> 0).toNumber() : message.totalRunDurationMillis;
            return object;
        };

        /**
         * Converts this TestSummary to JSON.
         * @function toJSON
         * @memberof build_event_stream.TestSummary
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        TestSummary.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TestSummary;
    })();

    build_event_stream.BuildFinished = (function() {

        /**
         * Properties of a BuildFinished.
         * @memberof build_event_stream
         * @interface IBuildFinished
         * @property {boolean|null} [overallSuccess] BuildFinished overallSuccess
         * @property {build_event_stream.BuildFinished.IExitCode|null} [exitCode] BuildFinished exitCode
         * @property {Long|null} [finishTimeMillis] BuildFinished finishTimeMillis
         * @property {build_event_stream.BuildFinished.IAnomalyReport|null} [anomalyReport] BuildFinished anomalyReport
         */

        /**
         * Constructs a new BuildFinished.
         * @memberof build_event_stream
         * @classdesc Represents a BuildFinished.
         * @implements IBuildFinished
         * @constructor
         * @param {build_event_stream.IBuildFinished=} [properties] Properties to set
         */
        function BuildFinished(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BuildFinished overallSuccess.
         * @member {boolean} overallSuccess
         * @memberof build_event_stream.BuildFinished
         * @instance
         */
        BuildFinished.prototype.overallSuccess = false;

        /**
         * BuildFinished exitCode.
         * @member {build_event_stream.BuildFinished.IExitCode|null|undefined} exitCode
         * @memberof build_event_stream.BuildFinished
         * @instance
         */
        BuildFinished.prototype.exitCode = null;

        /**
         * BuildFinished finishTimeMillis.
         * @member {Long} finishTimeMillis
         * @memberof build_event_stream.BuildFinished
         * @instance
         */
        BuildFinished.prototype.finishTimeMillis = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        /**
         * BuildFinished anomalyReport.
         * @member {build_event_stream.BuildFinished.IAnomalyReport|null|undefined} anomalyReport
         * @memberof build_event_stream.BuildFinished
         * @instance
         */
        BuildFinished.prototype.anomalyReport = null;

        /**
         * Creates a new BuildFinished instance using the specified properties.
         * @function create
         * @memberof build_event_stream.BuildFinished
         * @static
         * @param {build_event_stream.IBuildFinished=} [properties] Properties to set
         * @returns {build_event_stream.BuildFinished} BuildFinished instance
         */
        BuildFinished.create = function create(properties) {
            return new BuildFinished(properties);
        };

        /**
         * Encodes the specified BuildFinished message. Does not implicitly {@link build_event_stream.BuildFinished.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.BuildFinished
         * @static
         * @param {build_event_stream.IBuildFinished} message BuildFinished message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildFinished.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.overallSuccess != null && message.hasOwnProperty("overallSuccess"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.overallSuccess);
            if (message.finishTimeMillis != null && message.hasOwnProperty("finishTimeMillis"))
                writer.uint32(/* id 2, wireType 0 =*/16).int64(message.finishTimeMillis);
            if (message.exitCode != null && message.hasOwnProperty("exitCode"))
                $root.build_event_stream.BuildFinished.ExitCode.encode(message.exitCode, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.anomalyReport != null && message.hasOwnProperty("anomalyReport"))
                $root.build_event_stream.BuildFinished.AnomalyReport.encode(message.anomalyReport, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified BuildFinished message, length delimited. Does not implicitly {@link build_event_stream.BuildFinished.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.BuildFinished
         * @static
         * @param {build_event_stream.IBuildFinished} message BuildFinished message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildFinished.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BuildFinished message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.BuildFinished
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.BuildFinished} BuildFinished
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildFinished.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildFinished();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.overallSuccess = reader.bool();
                    break;
                case 3:
                    message.exitCode = $root.build_event_stream.BuildFinished.ExitCode.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.finishTimeMillis = reader.int64();
                    break;
                case 4:
                    message.anomalyReport = $root.build_event_stream.BuildFinished.AnomalyReport.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BuildFinished message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.BuildFinished
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.BuildFinished} BuildFinished
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildFinished.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BuildFinished message.
         * @function verify
         * @memberof build_event_stream.BuildFinished
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BuildFinished.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.overallSuccess != null && message.hasOwnProperty("overallSuccess"))
                if (typeof message.overallSuccess !== "boolean")
                    return "overallSuccess: boolean expected";
            if (message.exitCode != null && message.hasOwnProperty("exitCode")) {
                let error = $root.build_event_stream.BuildFinished.ExitCode.verify(message.exitCode);
                if (error)
                    return "exitCode." + error;
            }
            if (message.finishTimeMillis != null && message.hasOwnProperty("finishTimeMillis"))
                if (!$util.isInteger(message.finishTimeMillis) && !(message.finishTimeMillis && $util.isInteger(message.finishTimeMillis.low) && $util.isInteger(message.finishTimeMillis.high)))
                    return "finishTimeMillis: integer|Long expected";
            if (message.anomalyReport != null && message.hasOwnProperty("anomalyReport")) {
                let error = $root.build_event_stream.BuildFinished.AnomalyReport.verify(message.anomalyReport);
                if (error)
                    return "anomalyReport." + error;
            }
            return null;
        };

        /**
         * Creates a BuildFinished message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.BuildFinished
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.BuildFinished} BuildFinished
         */
        BuildFinished.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.BuildFinished)
                return object;
            let message = new $root.build_event_stream.BuildFinished();
            if (object.overallSuccess != null)
                message.overallSuccess = Boolean(object.overallSuccess);
            if (object.exitCode != null) {
                if (typeof object.exitCode !== "object")
                    throw TypeError(".build_event_stream.BuildFinished.exitCode: object expected");
                message.exitCode = $root.build_event_stream.BuildFinished.ExitCode.fromObject(object.exitCode);
            }
            if (object.finishTimeMillis != null)
                if ($util.Long)
                    (message.finishTimeMillis = $util.Long.fromValue(object.finishTimeMillis)).unsigned = false;
                else if (typeof object.finishTimeMillis === "string")
                    message.finishTimeMillis = parseInt(object.finishTimeMillis, 10);
                else if (typeof object.finishTimeMillis === "number")
                    message.finishTimeMillis = object.finishTimeMillis;
                else if (typeof object.finishTimeMillis === "object")
                    message.finishTimeMillis = new $util.LongBits(object.finishTimeMillis.low >>> 0, object.finishTimeMillis.high >>> 0).toNumber();
            if (object.anomalyReport != null) {
                if (typeof object.anomalyReport !== "object")
                    throw TypeError(".build_event_stream.BuildFinished.anomalyReport: object expected");
                message.anomalyReport = $root.build_event_stream.BuildFinished.AnomalyReport.fromObject(object.anomalyReport);
            }
            return message;
        };

        /**
         * Creates a plain object from a BuildFinished message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.BuildFinished
         * @static
         * @param {build_event_stream.BuildFinished} message BuildFinished
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BuildFinished.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.overallSuccess = false;
                if ($util.Long) {
                    let long = new $util.Long(0, 0, false);
                    object.finishTimeMillis = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.finishTimeMillis = options.longs === String ? "0" : 0;
                object.exitCode = null;
                object.anomalyReport = null;
            }
            if (message.overallSuccess != null && message.hasOwnProperty("overallSuccess"))
                object.overallSuccess = message.overallSuccess;
            if (message.finishTimeMillis != null && message.hasOwnProperty("finishTimeMillis"))
                if (typeof message.finishTimeMillis === "number")
                    object.finishTimeMillis = options.longs === String ? String(message.finishTimeMillis) : message.finishTimeMillis;
                else
                    object.finishTimeMillis = options.longs === String ? $util.Long.prototype.toString.call(message.finishTimeMillis) : options.longs === Number ? new $util.LongBits(message.finishTimeMillis.low >>> 0, message.finishTimeMillis.high >>> 0).toNumber() : message.finishTimeMillis;
            if (message.exitCode != null && message.hasOwnProperty("exitCode"))
                object.exitCode = $root.build_event_stream.BuildFinished.ExitCode.toObject(message.exitCode, options);
            if (message.anomalyReport != null && message.hasOwnProperty("anomalyReport"))
                object.anomalyReport = $root.build_event_stream.BuildFinished.AnomalyReport.toObject(message.anomalyReport, options);
            return object;
        };

        /**
         * Converts this BuildFinished to JSON.
         * @function toJSON
         * @memberof build_event_stream.BuildFinished
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BuildFinished.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        BuildFinished.ExitCode = (function() {

            /**
             * Properties of an ExitCode.
             * @memberof build_event_stream.BuildFinished
             * @interface IExitCode
             * @property {string|null} [name] ExitCode name
             * @property {number|null} [code] ExitCode code
             */

            /**
             * Constructs a new ExitCode.
             * @memberof build_event_stream.BuildFinished
             * @classdesc Represents an ExitCode.
             * @implements IExitCode
             * @constructor
             * @param {build_event_stream.BuildFinished.IExitCode=} [properties] Properties to set
             */
            function ExitCode(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ExitCode name.
             * @member {string} name
             * @memberof build_event_stream.BuildFinished.ExitCode
             * @instance
             */
            ExitCode.prototype.name = "";

            /**
             * ExitCode code.
             * @member {number} code
             * @memberof build_event_stream.BuildFinished.ExitCode
             * @instance
             */
            ExitCode.prototype.code = 0;

            /**
             * Creates a new ExitCode instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildFinished.ExitCode
             * @static
             * @param {build_event_stream.BuildFinished.IExitCode=} [properties] Properties to set
             * @returns {build_event_stream.BuildFinished.ExitCode} ExitCode instance
             */
            ExitCode.create = function create(properties) {
                return new ExitCode(properties);
            };

            /**
             * Encodes the specified ExitCode message. Does not implicitly {@link build_event_stream.BuildFinished.ExitCode.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildFinished.ExitCode
             * @static
             * @param {build_event_stream.BuildFinished.IExitCode} message ExitCode message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExitCode.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && message.hasOwnProperty("name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.code != null && message.hasOwnProperty("code"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.code);
                return writer;
            };

            /**
             * Encodes the specified ExitCode message, length delimited. Does not implicitly {@link build_event_stream.BuildFinished.ExitCode.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildFinished.ExitCode
             * @static
             * @param {build_event_stream.BuildFinished.IExitCode} message ExitCode message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ExitCode.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an ExitCode message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildFinished.ExitCode
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildFinished.ExitCode} ExitCode
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExitCode.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildFinished.ExitCode();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.name = reader.string();
                        break;
                    case 2:
                        message.code = reader.int32();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an ExitCode message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildFinished.ExitCode
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildFinished.ExitCode} ExitCode
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ExitCode.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an ExitCode message.
             * @function verify
             * @memberof build_event_stream.BuildFinished.ExitCode
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ExitCode.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isInteger(message.code))
                        return "code: integer expected";
                return null;
            };

            /**
             * Creates an ExitCode message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildFinished.ExitCode
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildFinished.ExitCode} ExitCode
             */
            ExitCode.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildFinished.ExitCode)
                    return object;
                let message = new $root.build_event_stream.BuildFinished.ExitCode();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.code != null)
                    message.code = object.code | 0;
                return message;
            };

            /**
             * Creates a plain object from an ExitCode message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildFinished.ExitCode
             * @static
             * @param {build_event_stream.BuildFinished.ExitCode} message ExitCode
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ExitCode.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    object.name = "";
                    object.code = 0;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                return object;
            };

            /**
             * Converts this ExitCode to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildFinished.ExitCode
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ExitCode.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ExitCode;
        })();

        BuildFinished.AnomalyReport = (function() {

            /**
             * Properties of an AnomalyReport.
             * @memberof build_event_stream.BuildFinished
             * @interface IAnomalyReport
             * @property {boolean|null} [wasSuspended] AnomalyReport wasSuspended
             */

            /**
             * Constructs a new AnomalyReport.
             * @memberof build_event_stream.BuildFinished
             * @classdesc Represents an AnomalyReport.
             * @implements IAnomalyReport
             * @constructor
             * @param {build_event_stream.BuildFinished.IAnomalyReport=} [properties] Properties to set
             */
            function AnomalyReport(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AnomalyReport wasSuspended.
             * @member {boolean} wasSuspended
             * @memberof build_event_stream.BuildFinished.AnomalyReport
             * @instance
             */
            AnomalyReport.prototype.wasSuspended = false;

            /**
             * Creates a new AnomalyReport instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildFinished.AnomalyReport
             * @static
             * @param {build_event_stream.BuildFinished.IAnomalyReport=} [properties] Properties to set
             * @returns {build_event_stream.BuildFinished.AnomalyReport} AnomalyReport instance
             */
            AnomalyReport.create = function create(properties) {
                return new AnomalyReport(properties);
            };

            /**
             * Encodes the specified AnomalyReport message. Does not implicitly {@link build_event_stream.BuildFinished.AnomalyReport.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildFinished.AnomalyReport
             * @static
             * @param {build_event_stream.BuildFinished.IAnomalyReport} message AnomalyReport message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AnomalyReport.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.wasSuspended != null && message.hasOwnProperty("wasSuspended"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.wasSuspended);
                return writer;
            };

            /**
             * Encodes the specified AnomalyReport message, length delimited. Does not implicitly {@link build_event_stream.BuildFinished.AnomalyReport.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildFinished.AnomalyReport
             * @static
             * @param {build_event_stream.BuildFinished.IAnomalyReport} message AnomalyReport message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AnomalyReport.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AnomalyReport message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildFinished.AnomalyReport
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildFinished.AnomalyReport} AnomalyReport
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AnomalyReport.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildFinished.AnomalyReport();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.wasSuspended = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AnomalyReport message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildFinished.AnomalyReport
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildFinished.AnomalyReport} AnomalyReport
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AnomalyReport.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AnomalyReport message.
             * @function verify
             * @memberof build_event_stream.BuildFinished.AnomalyReport
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AnomalyReport.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.wasSuspended != null && message.hasOwnProperty("wasSuspended"))
                    if (typeof message.wasSuspended !== "boolean")
                        return "wasSuspended: boolean expected";
                return null;
            };

            /**
             * Creates an AnomalyReport message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildFinished.AnomalyReport
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildFinished.AnomalyReport} AnomalyReport
             */
            AnomalyReport.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildFinished.AnomalyReport)
                    return object;
                let message = new $root.build_event_stream.BuildFinished.AnomalyReport();
                if (object.wasSuspended != null)
                    message.wasSuspended = Boolean(object.wasSuspended);
                return message;
            };

            /**
             * Creates a plain object from an AnomalyReport message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildFinished.AnomalyReport
             * @static
             * @param {build_event_stream.BuildFinished.AnomalyReport} message AnomalyReport
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AnomalyReport.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    object.wasSuspended = false;
                if (message.wasSuspended != null && message.hasOwnProperty("wasSuspended"))
                    object.wasSuspended = message.wasSuspended;
                return object;
            };

            /**
             * Converts this AnomalyReport to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildFinished.AnomalyReport
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AnomalyReport.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AnomalyReport;
        })();

        return BuildFinished;
    })();

    build_event_stream.BuildMetrics = (function() {

        /**
         * Properties of a BuildMetrics.
         * @memberof build_event_stream
         * @interface IBuildMetrics
         * @property {build_event_stream.BuildMetrics.IActionSummary|null} [actionSummary] BuildMetrics actionSummary
         * @property {build_event_stream.BuildMetrics.IMemoryMetrics|null} [memoryMetrics] BuildMetrics memoryMetrics
         * @property {build_event_stream.BuildMetrics.ITargetMetrics|null} [targetMetrics] BuildMetrics targetMetrics
         * @property {build_event_stream.BuildMetrics.IPackageMetrics|null} [packageMetrics] BuildMetrics packageMetrics
         * @property {build_event_stream.BuildMetrics.ITimingMetrics|null} [timingMetrics] BuildMetrics timingMetrics
         */

        /**
         * Constructs a new BuildMetrics.
         * @memberof build_event_stream
         * @classdesc Represents a BuildMetrics.
         * @implements IBuildMetrics
         * @constructor
         * @param {build_event_stream.IBuildMetrics=} [properties] Properties to set
         */
        function BuildMetrics(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BuildMetrics actionSummary.
         * @member {build_event_stream.BuildMetrics.IActionSummary|null|undefined} actionSummary
         * @memberof build_event_stream.BuildMetrics
         * @instance
         */
        BuildMetrics.prototype.actionSummary = null;

        /**
         * BuildMetrics memoryMetrics.
         * @member {build_event_stream.BuildMetrics.IMemoryMetrics|null|undefined} memoryMetrics
         * @memberof build_event_stream.BuildMetrics
         * @instance
         */
        BuildMetrics.prototype.memoryMetrics = null;

        /**
         * BuildMetrics targetMetrics.
         * @member {build_event_stream.BuildMetrics.ITargetMetrics|null|undefined} targetMetrics
         * @memberof build_event_stream.BuildMetrics
         * @instance
         */
        BuildMetrics.prototype.targetMetrics = null;

        /**
         * BuildMetrics packageMetrics.
         * @member {build_event_stream.BuildMetrics.IPackageMetrics|null|undefined} packageMetrics
         * @memberof build_event_stream.BuildMetrics
         * @instance
         */
        BuildMetrics.prototype.packageMetrics = null;

        /**
         * BuildMetrics timingMetrics.
         * @member {build_event_stream.BuildMetrics.ITimingMetrics|null|undefined} timingMetrics
         * @memberof build_event_stream.BuildMetrics
         * @instance
         */
        BuildMetrics.prototype.timingMetrics = null;

        /**
         * Creates a new BuildMetrics instance using the specified properties.
         * @function create
         * @memberof build_event_stream.BuildMetrics
         * @static
         * @param {build_event_stream.IBuildMetrics=} [properties] Properties to set
         * @returns {build_event_stream.BuildMetrics} BuildMetrics instance
         */
        BuildMetrics.create = function create(properties) {
            return new BuildMetrics(properties);
        };

        /**
         * Encodes the specified BuildMetrics message. Does not implicitly {@link build_event_stream.BuildMetrics.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.BuildMetrics
         * @static
         * @param {build_event_stream.IBuildMetrics} message BuildMetrics message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildMetrics.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.actionSummary != null && message.hasOwnProperty("actionSummary"))
                $root.build_event_stream.BuildMetrics.ActionSummary.encode(message.actionSummary, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.memoryMetrics != null && message.hasOwnProperty("memoryMetrics"))
                $root.build_event_stream.BuildMetrics.MemoryMetrics.encode(message.memoryMetrics, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.targetMetrics != null && message.hasOwnProperty("targetMetrics"))
                $root.build_event_stream.BuildMetrics.TargetMetrics.encode(message.targetMetrics, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.packageMetrics != null && message.hasOwnProperty("packageMetrics"))
                $root.build_event_stream.BuildMetrics.PackageMetrics.encode(message.packageMetrics, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.timingMetrics != null && message.hasOwnProperty("timingMetrics"))
                $root.build_event_stream.BuildMetrics.TimingMetrics.encode(message.timingMetrics, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified BuildMetrics message, length delimited. Does not implicitly {@link build_event_stream.BuildMetrics.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.BuildMetrics
         * @static
         * @param {build_event_stream.IBuildMetrics} message BuildMetrics message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildMetrics.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BuildMetrics message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.BuildMetrics
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.BuildMetrics} BuildMetrics
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildMetrics.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildMetrics();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.actionSummary = $root.build_event_stream.BuildMetrics.ActionSummary.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.memoryMetrics = $root.build_event_stream.BuildMetrics.MemoryMetrics.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.targetMetrics = $root.build_event_stream.BuildMetrics.TargetMetrics.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.packageMetrics = $root.build_event_stream.BuildMetrics.PackageMetrics.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.timingMetrics = $root.build_event_stream.BuildMetrics.TimingMetrics.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BuildMetrics message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.BuildMetrics
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.BuildMetrics} BuildMetrics
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildMetrics.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BuildMetrics message.
         * @function verify
         * @memberof build_event_stream.BuildMetrics
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BuildMetrics.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.actionSummary != null && message.hasOwnProperty("actionSummary")) {
                let error = $root.build_event_stream.BuildMetrics.ActionSummary.verify(message.actionSummary);
                if (error)
                    return "actionSummary." + error;
            }
            if (message.memoryMetrics != null && message.hasOwnProperty("memoryMetrics")) {
                let error = $root.build_event_stream.BuildMetrics.MemoryMetrics.verify(message.memoryMetrics);
                if (error)
                    return "memoryMetrics." + error;
            }
            if (message.targetMetrics != null && message.hasOwnProperty("targetMetrics")) {
                let error = $root.build_event_stream.BuildMetrics.TargetMetrics.verify(message.targetMetrics);
                if (error)
                    return "targetMetrics." + error;
            }
            if (message.packageMetrics != null && message.hasOwnProperty("packageMetrics")) {
                let error = $root.build_event_stream.BuildMetrics.PackageMetrics.verify(message.packageMetrics);
                if (error)
                    return "packageMetrics." + error;
            }
            if (message.timingMetrics != null && message.hasOwnProperty("timingMetrics")) {
                let error = $root.build_event_stream.BuildMetrics.TimingMetrics.verify(message.timingMetrics);
                if (error)
                    return "timingMetrics." + error;
            }
            return null;
        };

        /**
         * Creates a BuildMetrics message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.BuildMetrics
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.BuildMetrics} BuildMetrics
         */
        BuildMetrics.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.BuildMetrics)
                return object;
            let message = new $root.build_event_stream.BuildMetrics();
            if (object.actionSummary != null) {
                if (typeof object.actionSummary !== "object")
                    throw TypeError(".build_event_stream.BuildMetrics.actionSummary: object expected");
                message.actionSummary = $root.build_event_stream.BuildMetrics.ActionSummary.fromObject(object.actionSummary);
            }
            if (object.memoryMetrics != null) {
                if (typeof object.memoryMetrics !== "object")
                    throw TypeError(".build_event_stream.BuildMetrics.memoryMetrics: object expected");
                message.memoryMetrics = $root.build_event_stream.BuildMetrics.MemoryMetrics.fromObject(object.memoryMetrics);
            }
            if (object.targetMetrics != null) {
                if (typeof object.targetMetrics !== "object")
                    throw TypeError(".build_event_stream.BuildMetrics.targetMetrics: object expected");
                message.targetMetrics = $root.build_event_stream.BuildMetrics.TargetMetrics.fromObject(object.targetMetrics);
            }
            if (object.packageMetrics != null) {
                if (typeof object.packageMetrics !== "object")
                    throw TypeError(".build_event_stream.BuildMetrics.packageMetrics: object expected");
                message.packageMetrics = $root.build_event_stream.BuildMetrics.PackageMetrics.fromObject(object.packageMetrics);
            }
            if (object.timingMetrics != null) {
                if (typeof object.timingMetrics !== "object")
                    throw TypeError(".build_event_stream.BuildMetrics.timingMetrics: object expected");
                message.timingMetrics = $root.build_event_stream.BuildMetrics.TimingMetrics.fromObject(object.timingMetrics);
            }
            return message;
        };

        /**
         * Creates a plain object from a BuildMetrics message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.BuildMetrics
         * @static
         * @param {build_event_stream.BuildMetrics} message BuildMetrics
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BuildMetrics.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.actionSummary = null;
                object.memoryMetrics = null;
                object.targetMetrics = null;
                object.packageMetrics = null;
                object.timingMetrics = null;
            }
            if (message.actionSummary != null && message.hasOwnProperty("actionSummary"))
                object.actionSummary = $root.build_event_stream.BuildMetrics.ActionSummary.toObject(message.actionSummary, options);
            if (message.memoryMetrics != null && message.hasOwnProperty("memoryMetrics"))
                object.memoryMetrics = $root.build_event_stream.BuildMetrics.MemoryMetrics.toObject(message.memoryMetrics, options);
            if (message.targetMetrics != null && message.hasOwnProperty("targetMetrics"))
                object.targetMetrics = $root.build_event_stream.BuildMetrics.TargetMetrics.toObject(message.targetMetrics, options);
            if (message.packageMetrics != null && message.hasOwnProperty("packageMetrics"))
                object.packageMetrics = $root.build_event_stream.BuildMetrics.PackageMetrics.toObject(message.packageMetrics, options);
            if (message.timingMetrics != null && message.hasOwnProperty("timingMetrics"))
                object.timingMetrics = $root.build_event_stream.BuildMetrics.TimingMetrics.toObject(message.timingMetrics, options);
            return object;
        };

        /**
         * Converts this BuildMetrics to JSON.
         * @function toJSON
         * @memberof build_event_stream.BuildMetrics
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BuildMetrics.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        BuildMetrics.ActionSummary = (function() {

            /**
             * Properties of an ActionSummary.
             * @memberof build_event_stream.BuildMetrics
             * @interface IActionSummary
             * @property {Long|null} [actionsCreated] ActionSummary actionsCreated
             * @property {Long|null} [actionsExecuted] ActionSummary actionsExecuted
             */

            /**
             * Constructs a new ActionSummary.
             * @memberof build_event_stream.BuildMetrics
             * @classdesc Represents an ActionSummary.
             * @implements IActionSummary
             * @constructor
             * @param {build_event_stream.BuildMetrics.IActionSummary=} [properties] Properties to set
             */
            function ActionSummary(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ActionSummary actionsCreated.
             * @member {Long} actionsCreated
             * @memberof build_event_stream.BuildMetrics.ActionSummary
             * @instance
             */
            ActionSummary.prototype.actionsCreated = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * ActionSummary actionsExecuted.
             * @member {Long} actionsExecuted
             * @memberof build_event_stream.BuildMetrics.ActionSummary
             * @instance
             */
            ActionSummary.prototype.actionsExecuted = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new ActionSummary instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildMetrics.ActionSummary
             * @static
             * @param {build_event_stream.BuildMetrics.IActionSummary=} [properties] Properties to set
             * @returns {build_event_stream.BuildMetrics.ActionSummary} ActionSummary instance
             */
            ActionSummary.create = function create(properties) {
                return new ActionSummary(properties);
            };

            /**
             * Encodes the specified ActionSummary message. Does not implicitly {@link build_event_stream.BuildMetrics.ActionSummary.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildMetrics.ActionSummary
             * @static
             * @param {build_event_stream.BuildMetrics.IActionSummary} message ActionSummary message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ActionSummary.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.actionsCreated != null && message.hasOwnProperty("actionsCreated"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.actionsCreated);
                if (message.actionsExecuted != null && message.hasOwnProperty("actionsExecuted"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.actionsExecuted);
                return writer;
            };

            /**
             * Encodes the specified ActionSummary message, length delimited. Does not implicitly {@link build_event_stream.BuildMetrics.ActionSummary.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildMetrics.ActionSummary
             * @static
             * @param {build_event_stream.BuildMetrics.IActionSummary} message ActionSummary message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ActionSummary.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an ActionSummary message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildMetrics.ActionSummary
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildMetrics.ActionSummary} ActionSummary
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ActionSummary.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildMetrics.ActionSummary();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.actionsCreated = reader.int64();
                        break;
                    case 2:
                        message.actionsExecuted = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an ActionSummary message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildMetrics.ActionSummary
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildMetrics.ActionSummary} ActionSummary
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ActionSummary.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an ActionSummary message.
             * @function verify
             * @memberof build_event_stream.BuildMetrics.ActionSummary
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ActionSummary.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.actionsCreated != null && message.hasOwnProperty("actionsCreated"))
                    if (!$util.isInteger(message.actionsCreated) && !(message.actionsCreated && $util.isInteger(message.actionsCreated.low) && $util.isInteger(message.actionsCreated.high)))
                        return "actionsCreated: integer|Long expected";
                if (message.actionsExecuted != null && message.hasOwnProperty("actionsExecuted"))
                    if (!$util.isInteger(message.actionsExecuted) && !(message.actionsExecuted && $util.isInteger(message.actionsExecuted.low) && $util.isInteger(message.actionsExecuted.high)))
                        return "actionsExecuted: integer|Long expected";
                return null;
            };

            /**
             * Creates an ActionSummary message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildMetrics.ActionSummary
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildMetrics.ActionSummary} ActionSummary
             */
            ActionSummary.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildMetrics.ActionSummary)
                    return object;
                let message = new $root.build_event_stream.BuildMetrics.ActionSummary();
                if (object.actionsCreated != null)
                    if ($util.Long)
                        (message.actionsCreated = $util.Long.fromValue(object.actionsCreated)).unsigned = false;
                    else if (typeof object.actionsCreated === "string")
                        message.actionsCreated = parseInt(object.actionsCreated, 10);
                    else if (typeof object.actionsCreated === "number")
                        message.actionsCreated = object.actionsCreated;
                    else if (typeof object.actionsCreated === "object")
                        message.actionsCreated = new $util.LongBits(object.actionsCreated.low >>> 0, object.actionsCreated.high >>> 0).toNumber();
                if (object.actionsExecuted != null)
                    if ($util.Long)
                        (message.actionsExecuted = $util.Long.fromValue(object.actionsExecuted)).unsigned = false;
                    else if (typeof object.actionsExecuted === "string")
                        message.actionsExecuted = parseInt(object.actionsExecuted, 10);
                    else if (typeof object.actionsExecuted === "number")
                        message.actionsExecuted = object.actionsExecuted;
                    else if (typeof object.actionsExecuted === "object")
                        message.actionsExecuted = new $util.LongBits(object.actionsExecuted.low >>> 0, object.actionsExecuted.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from an ActionSummary message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildMetrics.ActionSummary
             * @static
             * @param {build_event_stream.BuildMetrics.ActionSummary} message ActionSummary
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ActionSummary.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.actionsCreated = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.actionsCreated = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.actionsExecuted = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.actionsExecuted = options.longs === String ? "0" : 0;
                }
                if (message.actionsCreated != null && message.hasOwnProperty("actionsCreated"))
                    if (typeof message.actionsCreated === "number")
                        object.actionsCreated = options.longs === String ? String(message.actionsCreated) : message.actionsCreated;
                    else
                        object.actionsCreated = options.longs === String ? $util.Long.prototype.toString.call(message.actionsCreated) : options.longs === Number ? new $util.LongBits(message.actionsCreated.low >>> 0, message.actionsCreated.high >>> 0).toNumber() : message.actionsCreated;
                if (message.actionsExecuted != null && message.hasOwnProperty("actionsExecuted"))
                    if (typeof message.actionsExecuted === "number")
                        object.actionsExecuted = options.longs === String ? String(message.actionsExecuted) : message.actionsExecuted;
                    else
                        object.actionsExecuted = options.longs === String ? $util.Long.prototype.toString.call(message.actionsExecuted) : options.longs === Number ? new $util.LongBits(message.actionsExecuted.low >>> 0, message.actionsExecuted.high >>> 0).toNumber() : message.actionsExecuted;
                return object;
            };

            /**
             * Converts this ActionSummary to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildMetrics.ActionSummary
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ActionSummary.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ActionSummary;
        })();

        BuildMetrics.MemoryMetrics = (function() {

            /**
             * Properties of a MemoryMetrics.
             * @memberof build_event_stream.BuildMetrics
             * @interface IMemoryMetrics
             * @property {Long|null} [usedHeapSizePostBuild] MemoryMetrics usedHeapSizePostBuild
             */

            /**
             * Constructs a new MemoryMetrics.
             * @memberof build_event_stream.BuildMetrics
             * @classdesc Represents a MemoryMetrics.
             * @implements IMemoryMetrics
             * @constructor
             * @param {build_event_stream.BuildMetrics.IMemoryMetrics=} [properties] Properties to set
             */
            function MemoryMetrics(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MemoryMetrics usedHeapSizePostBuild.
             * @member {Long} usedHeapSizePostBuild
             * @memberof build_event_stream.BuildMetrics.MemoryMetrics
             * @instance
             */
            MemoryMetrics.prototype.usedHeapSizePostBuild = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new MemoryMetrics instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildMetrics.MemoryMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.IMemoryMetrics=} [properties] Properties to set
             * @returns {build_event_stream.BuildMetrics.MemoryMetrics} MemoryMetrics instance
             */
            MemoryMetrics.create = function create(properties) {
                return new MemoryMetrics(properties);
            };

            /**
             * Encodes the specified MemoryMetrics message. Does not implicitly {@link build_event_stream.BuildMetrics.MemoryMetrics.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildMetrics.MemoryMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.IMemoryMetrics} message MemoryMetrics message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MemoryMetrics.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.usedHeapSizePostBuild != null && message.hasOwnProperty("usedHeapSizePostBuild"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.usedHeapSizePostBuild);
                return writer;
            };

            /**
             * Encodes the specified MemoryMetrics message, length delimited. Does not implicitly {@link build_event_stream.BuildMetrics.MemoryMetrics.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildMetrics.MemoryMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.IMemoryMetrics} message MemoryMetrics message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MemoryMetrics.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MemoryMetrics message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildMetrics.MemoryMetrics
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildMetrics.MemoryMetrics} MemoryMetrics
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MemoryMetrics.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildMetrics.MemoryMetrics();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.usedHeapSizePostBuild = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MemoryMetrics message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildMetrics.MemoryMetrics
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildMetrics.MemoryMetrics} MemoryMetrics
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MemoryMetrics.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MemoryMetrics message.
             * @function verify
             * @memberof build_event_stream.BuildMetrics.MemoryMetrics
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MemoryMetrics.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.usedHeapSizePostBuild != null && message.hasOwnProperty("usedHeapSizePostBuild"))
                    if (!$util.isInteger(message.usedHeapSizePostBuild) && !(message.usedHeapSizePostBuild && $util.isInteger(message.usedHeapSizePostBuild.low) && $util.isInteger(message.usedHeapSizePostBuild.high)))
                        return "usedHeapSizePostBuild: integer|Long expected";
                return null;
            };

            /**
             * Creates a MemoryMetrics message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildMetrics.MemoryMetrics
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildMetrics.MemoryMetrics} MemoryMetrics
             */
            MemoryMetrics.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildMetrics.MemoryMetrics)
                    return object;
                let message = new $root.build_event_stream.BuildMetrics.MemoryMetrics();
                if (object.usedHeapSizePostBuild != null)
                    if ($util.Long)
                        (message.usedHeapSizePostBuild = $util.Long.fromValue(object.usedHeapSizePostBuild)).unsigned = false;
                    else if (typeof object.usedHeapSizePostBuild === "string")
                        message.usedHeapSizePostBuild = parseInt(object.usedHeapSizePostBuild, 10);
                    else if (typeof object.usedHeapSizePostBuild === "number")
                        message.usedHeapSizePostBuild = object.usedHeapSizePostBuild;
                    else if (typeof object.usedHeapSizePostBuild === "object")
                        message.usedHeapSizePostBuild = new $util.LongBits(object.usedHeapSizePostBuild.low >>> 0, object.usedHeapSizePostBuild.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a MemoryMetrics message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildMetrics.MemoryMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.MemoryMetrics} message MemoryMetrics
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MemoryMetrics.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.usedHeapSizePostBuild = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.usedHeapSizePostBuild = options.longs === String ? "0" : 0;
                if (message.usedHeapSizePostBuild != null && message.hasOwnProperty("usedHeapSizePostBuild"))
                    if (typeof message.usedHeapSizePostBuild === "number")
                        object.usedHeapSizePostBuild = options.longs === String ? String(message.usedHeapSizePostBuild) : message.usedHeapSizePostBuild;
                    else
                        object.usedHeapSizePostBuild = options.longs === String ? $util.Long.prototype.toString.call(message.usedHeapSizePostBuild) : options.longs === Number ? new $util.LongBits(message.usedHeapSizePostBuild.low >>> 0, message.usedHeapSizePostBuild.high >>> 0).toNumber() : message.usedHeapSizePostBuild;
                return object;
            };

            /**
             * Converts this MemoryMetrics to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildMetrics.MemoryMetrics
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MemoryMetrics.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return MemoryMetrics;
        })();

        BuildMetrics.TargetMetrics = (function() {

            /**
             * Properties of a TargetMetrics.
             * @memberof build_event_stream.BuildMetrics
             * @interface ITargetMetrics
             * @property {Long|null} [targetsLoaded] TargetMetrics targetsLoaded
             * @property {Long|null} [targetsConfigured] TargetMetrics targetsConfigured
             */

            /**
             * Constructs a new TargetMetrics.
             * @memberof build_event_stream.BuildMetrics
             * @classdesc Represents a TargetMetrics.
             * @implements ITargetMetrics
             * @constructor
             * @param {build_event_stream.BuildMetrics.ITargetMetrics=} [properties] Properties to set
             */
            function TargetMetrics(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TargetMetrics targetsLoaded.
             * @member {Long} targetsLoaded
             * @memberof build_event_stream.BuildMetrics.TargetMetrics
             * @instance
             */
            TargetMetrics.prototype.targetsLoaded = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * TargetMetrics targetsConfigured.
             * @member {Long} targetsConfigured
             * @memberof build_event_stream.BuildMetrics.TargetMetrics
             * @instance
             */
            TargetMetrics.prototype.targetsConfigured = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new TargetMetrics instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildMetrics.TargetMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.ITargetMetrics=} [properties] Properties to set
             * @returns {build_event_stream.BuildMetrics.TargetMetrics} TargetMetrics instance
             */
            TargetMetrics.create = function create(properties) {
                return new TargetMetrics(properties);
            };

            /**
             * Encodes the specified TargetMetrics message. Does not implicitly {@link build_event_stream.BuildMetrics.TargetMetrics.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildMetrics.TargetMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.ITargetMetrics} message TargetMetrics message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TargetMetrics.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.targetsLoaded != null && message.hasOwnProperty("targetsLoaded"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.targetsLoaded);
                if (message.targetsConfigured != null && message.hasOwnProperty("targetsConfigured"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.targetsConfigured);
                return writer;
            };

            /**
             * Encodes the specified TargetMetrics message, length delimited. Does not implicitly {@link build_event_stream.BuildMetrics.TargetMetrics.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildMetrics.TargetMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.ITargetMetrics} message TargetMetrics message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TargetMetrics.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TargetMetrics message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildMetrics.TargetMetrics
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildMetrics.TargetMetrics} TargetMetrics
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TargetMetrics.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildMetrics.TargetMetrics();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.targetsLoaded = reader.int64();
                        break;
                    case 2:
                        message.targetsConfigured = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TargetMetrics message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildMetrics.TargetMetrics
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildMetrics.TargetMetrics} TargetMetrics
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TargetMetrics.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TargetMetrics message.
             * @function verify
             * @memberof build_event_stream.BuildMetrics.TargetMetrics
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TargetMetrics.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.targetsLoaded != null && message.hasOwnProperty("targetsLoaded"))
                    if (!$util.isInteger(message.targetsLoaded) && !(message.targetsLoaded && $util.isInteger(message.targetsLoaded.low) && $util.isInteger(message.targetsLoaded.high)))
                        return "targetsLoaded: integer|Long expected";
                if (message.targetsConfigured != null && message.hasOwnProperty("targetsConfigured"))
                    if (!$util.isInteger(message.targetsConfigured) && !(message.targetsConfigured && $util.isInteger(message.targetsConfigured.low) && $util.isInteger(message.targetsConfigured.high)))
                        return "targetsConfigured: integer|Long expected";
                return null;
            };

            /**
             * Creates a TargetMetrics message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildMetrics.TargetMetrics
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildMetrics.TargetMetrics} TargetMetrics
             */
            TargetMetrics.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildMetrics.TargetMetrics)
                    return object;
                let message = new $root.build_event_stream.BuildMetrics.TargetMetrics();
                if (object.targetsLoaded != null)
                    if ($util.Long)
                        (message.targetsLoaded = $util.Long.fromValue(object.targetsLoaded)).unsigned = false;
                    else if (typeof object.targetsLoaded === "string")
                        message.targetsLoaded = parseInt(object.targetsLoaded, 10);
                    else if (typeof object.targetsLoaded === "number")
                        message.targetsLoaded = object.targetsLoaded;
                    else if (typeof object.targetsLoaded === "object")
                        message.targetsLoaded = new $util.LongBits(object.targetsLoaded.low >>> 0, object.targetsLoaded.high >>> 0).toNumber();
                if (object.targetsConfigured != null)
                    if ($util.Long)
                        (message.targetsConfigured = $util.Long.fromValue(object.targetsConfigured)).unsigned = false;
                    else if (typeof object.targetsConfigured === "string")
                        message.targetsConfigured = parseInt(object.targetsConfigured, 10);
                    else if (typeof object.targetsConfigured === "number")
                        message.targetsConfigured = object.targetsConfigured;
                    else if (typeof object.targetsConfigured === "object")
                        message.targetsConfigured = new $util.LongBits(object.targetsConfigured.low >>> 0, object.targetsConfigured.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a TargetMetrics message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildMetrics.TargetMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.TargetMetrics} message TargetMetrics
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TargetMetrics.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.targetsLoaded = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.targetsLoaded = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.targetsConfigured = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.targetsConfigured = options.longs === String ? "0" : 0;
                }
                if (message.targetsLoaded != null && message.hasOwnProperty("targetsLoaded"))
                    if (typeof message.targetsLoaded === "number")
                        object.targetsLoaded = options.longs === String ? String(message.targetsLoaded) : message.targetsLoaded;
                    else
                        object.targetsLoaded = options.longs === String ? $util.Long.prototype.toString.call(message.targetsLoaded) : options.longs === Number ? new $util.LongBits(message.targetsLoaded.low >>> 0, message.targetsLoaded.high >>> 0).toNumber() : message.targetsLoaded;
                if (message.targetsConfigured != null && message.hasOwnProperty("targetsConfigured"))
                    if (typeof message.targetsConfigured === "number")
                        object.targetsConfigured = options.longs === String ? String(message.targetsConfigured) : message.targetsConfigured;
                    else
                        object.targetsConfigured = options.longs === String ? $util.Long.prototype.toString.call(message.targetsConfigured) : options.longs === Number ? new $util.LongBits(message.targetsConfigured.low >>> 0, message.targetsConfigured.high >>> 0).toNumber() : message.targetsConfigured;
                return object;
            };

            /**
             * Converts this TargetMetrics to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildMetrics.TargetMetrics
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TargetMetrics.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TargetMetrics;
        })();

        BuildMetrics.PackageMetrics = (function() {

            /**
             * Properties of a PackageMetrics.
             * @memberof build_event_stream.BuildMetrics
             * @interface IPackageMetrics
             * @property {Long|null} [packagesLoaded] PackageMetrics packagesLoaded
             */

            /**
             * Constructs a new PackageMetrics.
             * @memberof build_event_stream.BuildMetrics
             * @classdesc Represents a PackageMetrics.
             * @implements IPackageMetrics
             * @constructor
             * @param {build_event_stream.BuildMetrics.IPackageMetrics=} [properties] Properties to set
             */
            function PackageMetrics(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PackageMetrics packagesLoaded.
             * @member {Long} packagesLoaded
             * @memberof build_event_stream.BuildMetrics.PackageMetrics
             * @instance
             */
            PackageMetrics.prototype.packagesLoaded = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new PackageMetrics instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildMetrics.PackageMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.IPackageMetrics=} [properties] Properties to set
             * @returns {build_event_stream.BuildMetrics.PackageMetrics} PackageMetrics instance
             */
            PackageMetrics.create = function create(properties) {
                return new PackageMetrics(properties);
            };

            /**
             * Encodes the specified PackageMetrics message. Does not implicitly {@link build_event_stream.BuildMetrics.PackageMetrics.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildMetrics.PackageMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.IPackageMetrics} message PackageMetrics message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PackageMetrics.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.packagesLoaded != null && message.hasOwnProperty("packagesLoaded"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.packagesLoaded);
                return writer;
            };

            /**
             * Encodes the specified PackageMetrics message, length delimited. Does not implicitly {@link build_event_stream.BuildMetrics.PackageMetrics.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildMetrics.PackageMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.IPackageMetrics} message PackageMetrics message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PackageMetrics.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PackageMetrics message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildMetrics.PackageMetrics
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildMetrics.PackageMetrics} PackageMetrics
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PackageMetrics.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildMetrics.PackageMetrics();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.packagesLoaded = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PackageMetrics message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildMetrics.PackageMetrics
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildMetrics.PackageMetrics} PackageMetrics
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PackageMetrics.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PackageMetrics message.
             * @function verify
             * @memberof build_event_stream.BuildMetrics.PackageMetrics
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PackageMetrics.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.packagesLoaded != null && message.hasOwnProperty("packagesLoaded"))
                    if (!$util.isInteger(message.packagesLoaded) && !(message.packagesLoaded && $util.isInteger(message.packagesLoaded.low) && $util.isInteger(message.packagesLoaded.high)))
                        return "packagesLoaded: integer|Long expected";
                return null;
            };

            /**
             * Creates a PackageMetrics message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildMetrics.PackageMetrics
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildMetrics.PackageMetrics} PackageMetrics
             */
            PackageMetrics.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildMetrics.PackageMetrics)
                    return object;
                let message = new $root.build_event_stream.BuildMetrics.PackageMetrics();
                if (object.packagesLoaded != null)
                    if ($util.Long)
                        (message.packagesLoaded = $util.Long.fromValue(object.packagesLoaded)).unsigned = false;
                    else if (typeof object.packagesLoaded === "string")
                        message.packagesLoaded = parseInt(object.packagesLoaded, 10);
                    else if (typeof object.packagesLoaded === "number")
                        message.packagesLoaded = object.packagesLoaded;
                    else if (typeof object.packagesLoaded === "object")
                        message.packagesLoaded = new $util.LongBits(object.packagesLoaded.low >>> 0, object.packagesLoaded.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a PackageMetrics message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildMetrics.PackageMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.PackageMetrics} message PackageMetrics
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PackageMetrics.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults)
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.packagesLoaded = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.packagesLoaded = options.longs === String ? "0" : 0;
                if (message.packagesLoaded != null && message.hasOwnProperty("packagesLoaded"))
                    if (typeof message.packagesLoaded === "number")
                        object.packagesLoaded = options.longs === String ? String(message.packagesLoaded) : message.packagesLoaded;
                    else
                        object.packagesLoaded = options.longs === String ? $util.Long.prototype.toString.call(message.packagesLoaded) : options.longs === Number ? new $util.LongBits(message.packagesLoaded.low >>> 0, message.packagesLoaded.high >>> 0).toNumber() : message.packagesLoaded;
                return object;
            };

            /**
             * Converts this PackageMetrics to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildMetrics.PackageMetrics
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PackageMetrics.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PackageMetrics;
        })();

        BuildMetrics.TimingMetrics = (function() {

            /**
             * Properties of a TimingMetrics.
             * @memberof build_event_stream.BuildMetrics
             * @interface ITimingMetrics
             * @property {Long|null} [cpuTimeInMs] TimingMetrics cpuTimeInMs
             * @property {Long|null} [wallTimeInMs] TimingMetrics wallTimeInMs
             */

            /**
             * Constructs a new TimingMetrics.
             * @memberof build_event_stream.BuildMetrics
             * @classdesc Represents a TimingMetrics.
             * @implements ITimingMetrics
             * @constructor
             * @param {build_event_stream.BuildMetrics.ITimingMetrics=} [properties] Properties to set
             */
            function TimingMetrics(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TimingMetrics cpuTimeInMs.
             * @member {Long} cpuTimeInMs
             * @memberof build_event_stream.BuildMetrics.TimingMetrics
             * @instance
             */
            TimingMetrics.prototype.cpuTimeInMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * TimingMetrics wallTimeInMs.
             * @member {Long} wallTimeInMs
             * @memberof build_event_stream.BuildMetrics.TimingMetrics
             * @instance
             */
            TimingMetrics.prototype.wallTimeInMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new TimingMetrics instance using the specified properties.
             * @function create
             * @memberof build_event_stream.BuildMetrics.TimingMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.ITimingMetrics=} [properties] Properties to set
             * @returns {build_event_stream.BuildMetrics.TimingMetrics} TimingMetrics instance
             */
            TimingMetrics.create = function create(properties) {
                return new TimingMetrics(properties);
            };

            /**
             * Encodes the specified TimingMetrics message. Does not implicitly {@link build_event_stream.BuildMetrics.TimingMetrics.verify|verify} messages.
             * @function encode
             * @memberof build_event_stream.BuildMetrics.TimingMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.ITimingMetrics} message TimingMetrics message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TimingMetrics.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.cpuTimeInMs != null && message.hasOwnProperty("cpuTimeInMs"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.cpuTimeInMs);
                if (message.wallTimeInMs != null && message.hasOwnProperty("wallTimeInMs"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.wallTimeInMs);
                return writer;
            };

            /**
             * Encodes the specified TimingMetrics message, length delimited. Does not implicitly {@link build_event_stream.BuildMetrics.TimingMetrics.verify|verify} messages.
             * @function encodeDelimited
             * @memberof build_event_stream.BuildMetrics.TimingMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.ITimingMetrics} message TimingMetrics message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TimingMetrics.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TimingMetrics message from the specified reader or buffer.
             * @function decode
             * @memberof build_event_stream.BuildMetrics.TimingMetrics
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {build_event_stream.BuildMetrics.TimingMetrics} TimingMetrics
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TimingMetrics.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildMetrics.TimingMetrics();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.cpuTimeInMs = reader.int64();
                        break;
                    case 2:
                        message.wallTimeInMs = reader.int64();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TimingMetrics message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof build_event_stream.BuildMetrics.TimingMetrics
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {build_event_stream.BuildMetrics.TimingMetrics} TimingMetrics
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TimingMetrics.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TimingMetrics message.
             * @function verify
             * @memberof build_event_stream.BuildMetrics.TimingMetrics
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TimingMetrics.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.cpuTimeInMs != null && message.hasOwnProperty("cpuTimeInMs"))
                    if (!$util.isInteger(message.cpuTimeInMs) && !(message.cpuTimeInMs && $util.isInteger(message.cpuTimeInMs.low) && $util.isInteger(message.cpuTimeInMs.high)))
                        return "cpuTimeInMs: integer|Long expected";
                if (message.wallTimeInMs != null && message.hasOwnProperty("wallTimeInMs"))
                    if (!$util.isInteger(message.wallTimeInMs) && !(message.wallTimeInMs && $util.isInteger(message.wallTimeInMs.low) && $util.isInteger(message.wallTimeInMs.high)))
                        return "wallTimeInMs: integer|Long expected";
                return null;
            };

            /**
             * Creates a TimingMetrics message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof build_event_stream.BuildMetrics.TimingMetrics
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {build_event_stream.BuildMetrics.TimingMetrics} TimingMetrics
             */
            TimingMetrics.fromObject = function fromObject(object) {
                if (object instanceof $root.build_event_stream.BuildMetrics.TimingMetrics)
                    return object;
                let message = new $root.build_event_stream.BuildMetrics.TimingMetrics();
                if (object.cpuTimeInMs != null)
                    if ($util.Long)
                        (message.cpuTimeInMs = $util.Long.fromValue(object.cpuTimeInMs)).unsigned = false;
                    else if (typeof object.cpuTimeInMs === "string")
                        message.cpuTimeInMs = parseInt(object.cpuTimeInMs, 10);
                    else if (typeof object.cpuTimeInMs === "number")
                        message.cpuTimeInMs = object.cpuTimeInMs;
                    else if (typeof object.cpuTimeInMs === "object")
                        message.cpuTimeInMs = new $util.LongBits(object.cpuTimeInMs.low >>> 0, object.cpuTimeInMs.high >>> 0).toNumber();
                if (object.wallTimeInMs != null)
                    if ($util.Long)
                        (message.wallTimeInMs = $util.Long.fromValue(object.wallTimeInMs)).unsigned = false;
                    else if (typeof object.wallTimeInMs === "string")
                        message.wallTimeInMs = parseInt(object.wallTimeInMs, 10);
                    else if (typeof object.wallTimeInMs === "number")
                        message.wallTimeInMs = object.wallTimeInMs;
                    else if (typeof object.wallTimeInMs === "object")
                        message.wallTimeInMs = new $util.LongBits(object.wallTimeInMs.low >>> 0, object.wallTimeInMs.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a TimingMetrics message. Also converts values to other types if specified.
             * @function toObject
             * @memberof build_event_stream.BuildMetrics.TimingMetrics
             * @static
             * @param {build_event_stream.BuildMetrics.TimingMetrics} message TimingMetrics
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TimingMetrics.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.cpuTimeInMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.cpuTimeInMs = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        let long = new $util.Long(0, 0, false);
                        object.wallTimeInMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.wallTimeInMs = options.longs === String ? "0" : 0;
                }
                if (message.cpuTimeInMs != null && message.hasOwnProperty("cpuTimeInMs"))
                    if (typeof message.cpuTimeInMs === "number")
                        object.cpuTimeInMs = options.longs === String ? String(message.cpuTimeInMs) : message.cpuTimeInMs;
                    else
                        object.cpuTimeInMs = options.longs === String ? $util.Long.prototype.toString.call(message.cpuTimeInMs) : options.longs === Number ? new $util.LongBits(message.cpuTimeInMs.low >>> 0, message.cpuTimeInMs.high >>> 0).toNumber() : message.cpuTimeInMs;
                if (message.wallTimeInMs != null && message.hasOwnProperty("wallTimeInMs"))
                    if (typeof message.wallTimeInMs === "number")
                        object.wallTimeInMs = options.longs === String ? String(message.wallTimeInMs) : message.wallTimeInMs;
                    else
                        object.wallTimeInMs = options.longs === String ? $util.Long.prototype.toString.call(message.wallTimeInMs) : options.longs === Number ? new $util.LongBits(message.wallTimeInMs.low >>> 0, message.wallTimeInMs.high >>> 0).toNumber() : message.wallTimeInMs;
                return object;
            };

            /**
             * Converts this TimingMetrics to JSON.
             * @function toJSON
             * @memberof build_event_stream.BuildMetrics.TimingMetrics
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TimingMetrics.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return TimingMetrics;
        })();

        return BuildMetrics;
    })();

    build_event_stream.BuildToolLogs = (function() {

        /**
         * Properties of a BuildToolLogs.
         * @memberof build_event_stream
         * @interface IBuildToolLogs
         * @property {Array.<build_event_stream.IFile>|null} [log] BuildToolLogs log
         */

        /**
         * Constructs a new BuildToolLogs.
         * @memberof build_event_stream
         * @classdesc Represents a BuildToolLogs.
         * @implements IBuildToolLogs
         * @constructor
         * @param {build_event_stream.IBuildToolLogs=} [properties] Properties to set
         */
        function BuildToolLogs(properties) {
            this.log = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BuildToolLogs log.
         * @member {Array.<build_event_stream.IFile>} log
         * @memberof build_event_stream.BuildToolLogs
         * @instance
         */
        BuildToolLogs.prototype.log = $util.emptyArray;

        /**
         * Creates a new BuildToolLogs instance using the specified properties.
         * @function create
         * @memberof build_event_stream.BuildToolLogs
         * @static
         * @param {build_event_stream.IBuildToolLogs=} [properties] Properties to set
         * @returns {build_event_stream.BuildToolLogs} BuildToolLogs instance
         */
        BuildToolLogs.create = function create(properties) {
            return new BuildToolLogs(properties);
        };

        /**
         * Encodes the specified BuildToolLogs message. Does not implicitly {@link build_event_stream.BuildToolLogs.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.BuildToolLogs
         * @static
         * @param {build_event_stream.IBuildToolLogs} message BuildToolLogs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildToolLogs.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.log != null && message.log.length)
                for (let i = 0; i < message.log.length; ++i)
                    $root.build_event_stream.File.encode(message.log[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified BuildToolLogs message, length delimited. Does not implicitly {@link build_event_stream.BuildToolLogs.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.BuildToolLogs
         * @static
         * @param {build_event_stream.IBuildToolLogs} message BuildToolLogs message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildToolLogs.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BuildToolLogs message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.BuildToolLogs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.BuildToolLogs} BuildToolLogs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildToolLogs.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildToolLogs();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.log && message.log.length))
                        message.log = [];
                    message.log.push($root.build_event_stream.File.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BuildToolLogs message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.BuildToolLogs
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.BuildToolLogs} BuildToolLogs
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildToolLogs.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BuildToolLogs message.
         * @function verify
         * @memberof build_event_stream.BuildToolLogs
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BuildToolLogs.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.log != null && message.hasOwnProperty("log")) {
                if (!Array.isArray(message.log))
                    return "log: array expected";
                for (let i = 0; i < message.log.length; ++i) {
                    let error = $root.build_event_stream.File.verify(message.log[i]);
                    if (error)
                        return "log." + error;
                }
            }
            return null;
        };

        /**
         * Creates a BuildToolLogs message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.BuildToolLogs
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.BuildToolLogs} BuildToolLogs
         */
        BuildToolLogs.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.BuildToolLogs)
                return object;
            let message = new $root.build_event_stream.BuildToolLogs();
            if (object.log) {
                if (!Array.isArray(object.log))
                    throw TypeError(".build_event_stream.BuildToolLogs.log: array expected");
                message.log = [];
                for (let i = 0; i < object.log.length; ++i) {
                    if (typeof object.log[i] !== "object")
                        throw TypeError(".build_event_stream.BuildToolLogs.log: object expected");
                    message.log[i] = $root.build_event_stream.File.fromObject(object.log[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a BuildToolLogs message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.BuildToolLogs
         * @static
         * @param {build_event_stream.BuildToolLogs} message BuildToolLogs
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BuildToolLogs.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.log = [];
            if (message.log && message.log.length) {
                object.log = [];
                for (let j = 0; j < message.log.length; ++j)
                    object.log[j] = $root.build_event_stream.File.toObject(message.log[j], options);
            }
            return object;
        };

        /**
         * Converts this BuildToolLogs to JSON.
         * @function toJSON
         * @memberof build_event_stream.BuildToolLogs
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BuildToolLogs.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BuildToolLogs;
    })();

    build_event_stream.ConvenienceSymlinksIdentified = (function() {

        /**
         * Properties of a ConvenienceSymlinksIdentified.
         * @memberof build_event_stream
         * @interface IConvenienceSymlinksIdentified
         * @property {Array.<build_event_stream.IConvenienceSymlink>|null} [convenienceSymlinks] ConvenienceSymlinksIdentified convenienceSymlinks
         */

        /**
         * Constructs a new ConvenienceSymlinksIdentified.
         * @memberof build_event_stream
         * @classdesc Represents a ConvenienceSymlinksIdentified.
         * @implements IConvenienceSymlinksIdentified
         * @constructor
         * @param {build_event_stream.IConvenienceSymlinksIdentified=} [properties] Properties to set
         */
        function ConvenienceSymlinksIdentified(properties) {
            this.convenienceSymlinks = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConvenienceSymlinksIdentified convenienceSymlinks.
         * @member {Array.<build_event_stream.IConvenienceSymlink>} convenienceSymlinks
         * @memberof build_event_stream.ConvenienceSymlinksIdentified
         * @instance
         */
        ConvenienceSymlinksIdentified.prototype.convenienceSymlinks = $util.emptyArray;

        /**
         * Creates a new ConvenienceSymlinksIdentified instance using the specified properties.
         * @function create
         * @memberof build_event_stream.ConvenienceSymlinksIdentified
         * @static
         * @param {build_event_stream.IConvenienceSymlinksIdentified=} [properties] Properties to set
         * @returns {build_event_stream.ConvenienceSymlinksIdentified} ConvenienceSymlinksIdentified instance
         */
        ConvenienceSymlinksIdentified.create = function create(properties) {
            return new ConvenienceSymlinksIdentified(properties);
        };

        /**
         * Encodes the specified ConvenienceSymlinksIdentified message. Does not implicitly {@link build_event_stream.ConvenienceSymlinksIdentified.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.ConvenienceSymlinksIdentified
         * @static
         * @param {build_event_stream.IConvenienceSymlinksIdentified} message ConvenienceSymlinksIdentified message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConvenienceSymlinksIdentified.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.convenienceSymlinks != null && message.convenienceSymlinks.length)
                for (let i = 0; i < message.convenienceSymlinks.length; ++i)
                    $root.build_event_stream.ConvenienceSymlink.encode(message.convenienceSymlinks[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified ConvenienceSymlinksIdentified message, length delimited. Does not implicitly {@link build_event_stream.ConvenienceSymlinksIdentified.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.ConvenienceSymlinksIdentified
         * @static
         * @param {build_event_stream.IConvenienceSymlinksIdentified} message ConvenienceSymlinksIdentified message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConvenienceSymlinksIdentified.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConvenienceSymlinksIdentified message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.ConvenienceSymlinksIdentified
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.ConvenienceSymlinksIdentified} ConvenienceSymlinksIdentified
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConvenienceSymlinksIdentified.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.ConvenienceSymlinksIdentified();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.convenienceSymlinks && message.convenienceSymlinks.length))
                        message.convenienceSymlinks = [];
                    message.convenienceSymlinks.push($root.build_event_stream.ConvenienceSymlink.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConvenienceSymlinksIdentified message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.ConvenienceSymlinksIdentified
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.ConvenienceSymlinksIdentified} ConvenienceSymlinksIdentified
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConvenienceSymlinksIdentified.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConvenienceSymlinksIdentified message.
         * @function verify
         * @memberof build_event_stream.ConvenienceSymlinksIdentified
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConvenienceSymlinksIdentified.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.convenienceSymlinks != null && message.hasOwnProperty("convenienceSymlinks")) {
                if (!Array.isArray(message.convenienceSymlinks))
                    return "convenienceSymlinks: array expected";
                for (let i = 0; i < message.convenienceSymlinks.length; ++i) {
                    let error = $root.build_event_stream.ConvenienceSymlink.verify(message.convenienceSymlinks[i]);
                    if (error)
                        return "convenienceSymlinks." + error;
                }
            }
            return null;
        };

        /**
         * Creates a ConvenienceSymlinksIdentified message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.ConvenienceSymlinksIdentified
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.ConvenienceSymlinksIdentified} ConvenienceSymlinksIdentified
         */
        ConvenienceSymlinksIdentified.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.ConvenienceSymlinksIdentified)
                return object;
            let message = new $root.build_event_stream.ConvenienceSymlinksIdentified();
            if (object.convenienceSymlinks) {
                if (!Array.isArray(object.convenienceSymlinks))
                    throw TypeError(".build_event_stream.ConvenienceSymlinksIdentified.convenienceSymlinks: array expected");
                message.convenienceSymlinks = [];
                for (let i = 0; i < object.convenienceSymlinks.length; ++i) {
                    if (typeof object.convenienceSymlinks[i] !== "object")
                        throw TypeError(".build_event_stream.ConvenienceSymlinksIdentified.convenienceSymlinks: object expected");
                    message.convenienceSymlinks[i] = $root.build_event_stream.ConvenienceSymlink.fromObject(object.convenienceSymlinks[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a ConvenienceSymlinksIdentified message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.ConvenienceSymlinksIdentified
         * @static
         * @param {build_event_stream.ConvenienceSymlinksIdentified} message ConvenienceSymlinksIdentified
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConvenienceSymlinksIdentified.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.convenienceSymlinks = [];
            if (message.convenienceSymlinks && message.convenienceSymlinks.length) {
                object.convenienceSymlinks = [];
                for (let j = 0; j < message.convenienceSymlinks.length; ++j)
                    object.convenienceSymlinks[j] = $root.build_event_stream.ConvenienceSymlink.toObject(message.convenienceSymlinks[j], options);
            }
            return object;
        };

        /**
         * Converts this ConvenienceSymlinksIdentified to JSON.
         * @function toJSON
         * @memberof build_event_stream.ConvenienceSymlinksIdentified
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConvenienceSymlinksIdentified.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ConvenienceSymlinksIdentified;
    })();

    build_event_stream.ConvenienceSymlink = (function() {

        /**
         * Properties of a ConvenienceSymlink.
         * @memberof build_event_stream
         * @interface IConvenienceSymlink
         * @property {string|null} [path] ConvenienceSymlink path
         * @property {build_event_stream.ConvenienceSymlink.Action|null} [action] ConvenienceSymlink action
         * @property {string|null} [target] ConvenienceSymlink target
         */

        /**
         * Constructs a new ConvenienceSymlink.
         * @memberof build_event_stream
         * @classdesc Represents a ConvenienceSymlink.
         * @implements IConvenienceSymlink
         * @constructor
         * @param {build_event_stream.IConvenienceSymlink=} [properties] Properties to set
         */
        function ConvenienceSymlink(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ConvenienceSymlink path.
         * @member {string} path
         * @memberof build_event_stream.ConvenienceSymlink
         * @instance
         */
        ConvenienceSymlink.prototype.path = "";

        /**
         * ConvenienceSymlink action.
         * @member {build_event_stream.ConvenienceSymlink.Action} action
         * @memberof build_event_stream.ConvenienceSymlink
         * @instance
         */
        ConvenienceSymlink.prototype.action = 0;

        /**
         * ConvenienceSymlink target.
         * @member {string} target
         * @memberof build_event_stream.ConvenienceSymlink
         * @instance
         */
        ConvenienceSymlink.prototype.target = "";

        /**
         * Creates a new ConvenienceSymlink instance using the specified properties.
         * @function create
         * @memberof build_event_stream.ConvenienceSymlink
         * @static
         * @param {build_event_stream.IConvenienceSymlink=} [properties] Properties to set
         * @returns {build_event_stream.ConvenienceSymlink} ConvenienceSymlink instance
         */
        ConvenienceSymlink.create = function create(properties) {
            return new ConvenienceSymlink(properties);
        };

        /**
         * Encodes the specified ConvenienceSymlink message. Does not implicitly {@link build_event_stream.ConvenienceSymlink.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.ConvenienceSymlink
         * @static
         * @param {build_event_stream.IConvenienceSymlink} message ConvenienceSymlink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConvenienceSymlink.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.path != null && message.hasOwnProperty("path"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.path);
            if (message.action != null && message.hasOwnProperty("action"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.action);
            if (message.target != null && message.hasOwnProperty("target"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.target);
            return writer;
        };

        /**
         * Encodes the specified ConvenienceSymlink message, length delimited. Does not implicitly {@link build_event_stream.ConvenienceSymlink.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.ConvenienceSymlink
         * @static
         * @param {build_event_stream.IConvenienceSymlink} message ConvenienceSymlink message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ConvenienceSymlink.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ConvenienceSymlink message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.ConvenienceSymlink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.ConvenienceSymlink} ConvenienceSymlink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConvenienceSymlink.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.ConvenienceSymlink();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.path = reader.string();
                    break;
                case 2:
                    message.action = reader.int32();
                    break;
                case 3:
                    message.target = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ConvenienceSymlink message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.ConvenienceSymlink
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.ConvenienceSymlink} ConvenienceSymlink
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ConvenienceSymlink.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ConvenienceSymlink message.
         * @function verify
         * @memberof build_event_stream.ConvenienceSymlink
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ConvenienceSymlink.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.path != null && message.hasOwnProperty("path"))
                if (!$util.isString(message.path))
                    return "path: string expected";
            if (message.action != null && message.hasOwnProperty("action"))
                switch (message.action) {
                default:
                    return "action: enum value expected";
                case 0:
                case 1:
                case 2:
                    break;
                }
            if (message.target != null && message.hasOwnProperty("target"))
                if (!$util.isString(message.target))
                    return "target: string expected";
            return null;
        };

        /**
         * Creates a ConvenienceSymlink message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.ConvenienceSymlink
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.ConvenienceSymlink} ConvenienceSymlink
         */
        ConvenienceSymlink.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.ConvenienceSymlink)
                return object;
            let message = new $root.build_event_stream.ConvenienceSymlink();
            if (object.path != null)
                message.path = String(object.path);
            switch (object.action) {
            case "UNKNOWN":
            case 0:
                message.action = 0;
                break;
            case "CREATE":
            case 1:
                message.action = 1;
                break;
            case "DELETE":
            case 2:
                message.action = 2;
                break;
            }
            if (object.target != null)
                message.target = String(object.target);
            return message;
        };

        /**
         * Creates a plain object from a ConvenienceSymlink message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.ConvenienceSymlink
         * @static
         * @param {build_event_stream.ConvenienceSymlink} message ConvenienceSymlink
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ConvenienceSymlink.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.path = "";
                object.action = options.enums === String ? "UNKNOWN" : 0;
                object.target = "";
            }
            if (message.path != null && message.hasOwnProperty("path"))
                object.path = message.path;
            if (message.action != null && message.hasOwnProperty("action"))
                object.action = options.enums === String ? $root.build_event_stream.ConvenienceSymlink.Action[message.action] : message.action;
            if (message.target != null && message.hasOwnProperty("target"))
                object.target = message.target;
            return object;
        };

        /**
         * Converts this ConvenienceSymlink to JSON.
         * @function toJSON
         * @memberof build_event_stream.ConvenienceSymlink
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ConvenienceSymlink.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        /**
         * Action enum.
         * @name build_event_stream.ConvenienceSymlink.Action
         * @enum {string}
         * @property {number} UNKNOWN=0 UNKNOWN value
         * @property {number} CREATE=1 CREATE value
         * @property {number} DELETE=2 DELETE value
         */
        ConvenienceSymlink.Action = (function() {
            const valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "UNKNOWN"] = 0;
            values[valuesById[1] = "CREATE"] = 1;
            values[valuesById[2] = "DELETE"] = 2;
            return values;
        })();

        return ConvenienceSymlink;
    })();

    build_event_stream.BuildEvent = (function() {

        /**
         * Properties of a BuildEvent.
         * @memberof build_event_stream
         * @interface IBuildEvent
         * @property {build_event_stream.IBuildEventId|null} [id] BuildEvent id
         * @property {Array.<build_event_stream.IBuildEventId>|null} [children] BuildEvent children
         * @property {boolean|null} [lastMessage] BuildEvent lastMessage
         * @property {build_event_stream.IProgress|null} [progress] BuildEvent progress
         * @property {build_event_stream.IAborted|null} [aborted] BuildEvent aborted
         * @property {build_event_stream.IBuildStarted|null} [started] BuildEvent started
         * @property {build_event_stream.IUnstructuredCommandLine|null} [unstructuredCommandLine] BuildEvent unstructuredCommandLine
         * @property {command_line.ICommandLine|null} [structuredCommandLine] BuildEvent structuredCommandLine
         * @property {build_event_stream.IOptionsParsed|null} [optionsParsed] BuildEvent optionsParsed
         * @property {build_event_stream.IWorkspaceStatus|null} [workspaceStatus] BuildEvent workspaceStatus
         * @property {build_event_stream.IFetch|null} [fetch] BuildEvent fetch
         * @property {build_event_stream.IConfiguration|null} [configuration] BuildEvent configuration
         * @property {build_event_stream.IPatternExpanded|null} [expanded] BuildEvent expanded
         * @property {build_event_stream.ITargetConfigured|null} [configured] BuildEvent configured
         * @property {build_event_stream.IActionExecuted|null} [action] BuildEvent action
         * @property {build_event_stream.INamedSetOfFiles|null} [namedSetOfFiles] BuildEvent namedSetOfFiles
         * @property {build_event_stream.ITargetComplete|null} [completed] BuildEvent completed
         * @property {build_event_stream.ITestResult|null} [testResult] BuildEvent testResult
         * @property {build_event_stream.ITestSummary|null} [testSummary] BuildEvent testSummary
         * @property {build_event_stream.IBuildFinished|null} [finished] BuildEvent finished
         * @property {build_event_stream.IBuildToolLogs|null} [buildToolLogs] BuildEvent buildToolLogs
         * @property {build_event_stream.IBuildMetrics|null} [buildMetrics] BuildEvent buildMetrics
         * @property {build_event_stream.IWorkspaceConfig|null} [workspaceInfo] BuildEvent workspaceInfo
         * @property {build_event_stream.IBuildMetadata|null} [buildMetadata] BuildEvent buildMetadata
         * @property {build_event_stream.IConvenienceSymlinksIdentified|null} [convenienceSymlinksIdentified] BuildEvent convenienceSymlinksIdentified
         */

        /**
         * Constructs a new BuildEvent.
         * @memberof build_event_stream
         * @classdesc Represents a BuildEvent.
         * @implements IBuildEvent
         * @constructor
         * @param {build_event_stream.IBuildEvent=} [properties] Properties to set
         */
        function BuildEvent(properties) {
            this.children = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * BuildEvent id.
         * @member {build_event_stream.IBuildEventId|null|undefined} id
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.id = null;

        /**
         * BuildEvent children.
         * @member {Array.<build_event_stream.IBuildEventId>} children
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.children = $util.emptyArray;

        /**
         * BuildEvent lastMessage.
         * @member {boolean} lastMessage
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.lastMessage = false;

        /**
         * BuildEvent progress.
         * @member {build_event_stream.IProgress|null|undefined} progress
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.progress = null;

        /**
         * BuildEvent aborted.
         * @member {build_event_stream.IAborted|null|undefined} aborted
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.aborted = null;

        /**
         * BuildEvent started.
         * @member {build_event_stream.IBuildStarted|null|undefined} started
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.started = null;

        /**
         * BuildEvent unstructuredCommandLine.
         * @member {build_event_stream.IUnstructuredCommandLine|null|undefined} unstructuredCommandLine
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.unstructuredCommandLine = null;

        /**
         * BuildEvent structuredCommandLine.
         * @member {command_line.ICommandLine|null|undefined} structuredCommandLine
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.structuredCommandLine = null;

        /**
         * BuildEvent optionsParsed.
         * @member {build_event_stream.IOptionsParsed|null|undefined} optionsParsed
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.optionsParsed = null;

        /**
         * BuildEvent workspaceStatus.
         * @member {build_event_stream.IWorkspaceStatus|null|undefined} workspaceStatus
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.workspaceStatus = null;

        /**
         * BuildEvent fetch.
         * @member {build_event_stream.IFetch|null|undefined} fetch
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.fetch = null;

        /**
         * BuildEvent configuration.
         * @member {build_event_stream.IConfiguration|null|undefined} configuration
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.configuration = null;

        /**
         * BuildEvent expanded.
         * @member {build_event_stream.IPatternExpanded|null|undefined} expanded
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.expanded = null;

        /**
         * BuildEvent configured.
         * @member {build_event_stream.ITargetConfigured|null|undefined} configured
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.configured = null;

        /**
         * BuildEvent action.
         * @member {build_event_stream.IActionExecuted|null|undefined} action
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.action = null;

        /**
         * BuildEvent namedSetOfFiles.
         * @member {build_event_stream.INamedSetOfFiles|null|undefined} namedSetOfFiles
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.namedSetOfFiles = null;

        /**
         * BuildEvent completed.
         * @member {build_event_stream.ITargetComplete|null|undefined} completed
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.completed = null;

        /**
         * BuildEvent testResult.
         * @member {build_event_stream.ITestResult|null|undefined} testResult
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.testResult = null;

        /**
         * BuildEvent testSummary.
         * @member {build_event_stream.ITestSummary|null|undefined} testSummary
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.testSummary = null;

        /**
         * BuildEvent finished.
         * @member {build_event_stream.IBuildFinished|null|undefined} finished
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.finished = null;

        /**
         * BuildEvent buildToolLogs.
         * @member {build_event_stream.IBuildToolLogs|null|undefined} buildToolLogs
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.buildToolLogs = null;

        /**
         * BuildEvent buildMetrics.
         * @member {build_event_stream.IBuildMetrics|null|undefined} buildMetrics
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.buildMetrics = null;

        /**
         * BuildEvent workspaceInfo.
         * @member {build_event_stream.IWorkspaceConfig|null|undefined} workspaceInfo
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.workspaceInfo = null;

        /**
         * BuildEvent buildMetadata.
         * @member {build_event_stream.IBuildMetadata|null|undefined} buildMetadata
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.buildMetadata = null;

        /**
         * BuildEvent convenienceSymlinksIdentified.
         * @member {build_event_stream.IConvenienceSymlinksIdentified|null|undefined} convenienceSymlinksIdentified
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        BuildEvent.prototype.convenienceSymlinksIdentified = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * BuildEvent payload.
         * @member {"progress"|"aborted"|"started"|"unstructuredCommandLine"|"structuredCommandLine"|"optionsParsed"|"workspaceStatus"|"fetch"|"configuration"|"expanded"|"configured"|"action"|"namedSetOfFiles"|"completed"|"testResult"|"testSummary"|"finished"|"buildToolLogs"|"buildMetrics"|"workspaceInfo"|"buildMetadata"|"convenienceSymlinksIdentified"|undefined} payload
         * @memberof build_event_stream.BuildEvent
         * @instance
         */
        Object.defineProperty(BuildEvent.prototype, "payload", {
            get: $util.oneOfGetter($oneOfFields = ["progress", "aborted", "started", "unstructuredCommandLine", "structuredCommandLine", "optionsParsed", "workspaceStatus", "fetch", "configuration", "expanded", "configured", "action", "namedSetOfFiles", "completed", "testResult", "testSummary", "finished", "buildToolLogs", "buildMetrics", "workspaceInfo", "buildMetadata", "convenienceSymlinksIdentified"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new BuildEvent instance using the specified properties.
         * @function create
         * @memberof build_event_stream.BuildEvent
         * @static
         * @param {build_event_stream.IBuildEvent=} [properties] Properties to set
         * @returns {build_event_stream.BuildEvent} BuildEvent instance
         */
        BuildEvent.create = function create(properties) {
            return new BuildEvent(properties);
        };

        /**
         * Encodes the specified BuildEvent message. Does not implicitly {@link build_event_stream.BuildEvent.verify|verify} messages.
         * @function encode
         * @memberof build_event_stream.BuildEvent
         * @static
         * @param {build_event_stream.IBuildEvent} message BuildEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildEvent.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && message.hasOwnProperty("id"))
                $root.build_event_stream.BuildEventId.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.children != null && message.children.length)
                for (let i = 0; i < message.children.length; ++i)
                    $root.build_event_stream.BuildEventId.encode(message.children[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.progress != null && message.hasOwnProperty("progress"))
                $root.build_event_stream.Progress.encode(message.progress, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.aborted != null && message.hasOwnProperty("aborted"))
                $root.build_event_stream.Aborted.encode(message.aborted, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.started != null && message.hasOwnProperty("started"))
                $root.build_event_stream.BuildStarted.encode(message.started, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
            if (message.expanded != null && message.hasOwnProperty("expanded"))
                $root.build_event_stream.PatternExpanded.encode(message.expanded, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
            if (message.action != null && message.hasOwnProperty("action"))
                $root.build_event_stream.ActionExecuted.encode(message.action, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            if (message.completed != null && message.hasOwnProperty("completed"))
                $root.build_event_stream.TargetComplete.encode(message.completed, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
            if (message.testSummary != null && message.hasOwnProperty("testSummary"))
                $root.build_event_stream.TestSummary.encode(message.testSummary, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
            if (message.testResult != null && message.hasOwnProperty("testResult"))
                $root.build_event_stream.TestResult.encode(message.testResult, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
            if (message.unstructuredCommandLine != null && message.hasOwnProperty("unstructuredCommandLine"))
                $root.build_event_stream.UnstructuredCommandLine.encode(message.unstructuredCommandLine, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
            if (message.optionsParsed != null && message.hasOwnProperty("optionsParsed"))
                $root.build_event_stream.OptionsParsed.encode(message.optionsParsed, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
            if (message.finished != null && message.hasOwnProperty("finished"))
                $root.build_event_stream.BuildFinished.encode(message.finished, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
            if (message.namedSetOfFiles != null && message.hasOwnProperty("namedSetOfFiles"))
                $root.build_event_stream.NamedSetOfFiles.encode(message.namedSetOfFiles, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
            if (message.workspaceStatus != null && message.hasOwnProperty("workspaceStatus"))
                $root.build_event_stream.WorkspaceStatus.encode(message.workspaceStatus, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
            if (message.configuration != null && message.hasOwnProperty("configuration"))
                $root.build_event_stream.Configuration.encode(message.configuration, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
            if (message.configured != null && message.hasOwnProperty("configured"))
                $root.build_event_stream.TargetConfigured.encode(message.configured, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
            if (message.lastMessage != null && message.hasOwnProperty("lastMessage"))
                writer.uint32(/* id 20, wireType 0 =*/160).bool(message.lastMessage);
            if (message.fetch != null && message.hasOwnProperty("fetch"))
                $root.build_event_stream.Fetch.encode(message.fetch, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
            if (message.structuredCommandLine != null && message.hasOwnProperty("structuredCommandLine"))
                $root.command_line.CommandLine.encode(message.structuredCommandLine, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
            if (message.buildToolLogs != null && message.hasOwnProperty("buildToolLogs"))
                $root.build_event_stream.BuildToolLogs.encode(message.buildToolLogs, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
            if (message.buildMetrics != null && message.hasOwnProperty("buildMetrics"))
                $root.build_event_stream.BuildMetrics.encode(message.buildMetrics, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
            if (message.workspaceInfo != null && message.hasOwnProperty("workspaceInfo"))
                $root.build_event_stream.WorkspaceConfig.encode(message.workspaceInfo, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
            if (message.buildMetadata != null && message.hasOwnProperty("buildMetadata"))
                $root.build_event_stream.BuildMetadata.encode(message.buildMetadata, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
            if (message.convenienceSymlinksIdentified != null && message.hasOwnProperty("convenienceSymlinksIdentified"))
                $root.build_event_stream.ConvenienceSymlinksIdentified.encode(message.convenienceSymlinksIdentified, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified BuildEvent message, length delimited. Does not implicitly {@link build_event_stream.BuildEvent.verify|verify} messages.
         * @function encodeDelimited
         * @memberof build_event_stream.BuildEvent
         * @static
         * @param {build_event_stream.IBuildEvent} message BuildEvent message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        BuildEvent.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a BuildEvent message from the specified reader or buffer.
         * @function decode
         * @memberof build_event_stream.BuildEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {build_event_stream.BuildEvent} BuildEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildEvent.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.build_event_stream.BuildEvent();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = $root.build_event_stream.BuildEventId.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.children && message.children.length))
                        message.children = [];
                    message.children.push($root.build_event_stream.BuildEventId.decode(reader, reader.uint32()));
                    break;
                case 20:
                    message.lastMessage = reader.bool();
                    break;
                case 3:
                    message.progress = $root.build_event_stream.Progress.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.aborted = $root.build_event_stream.Aborted.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.started = $root.build_event_stream.BuildStarted.decode(reader, reader.uint32());
                    break;
                case 12:
                    message.unstructuredCommandLine = $root.build_event_stream.UnstructuredCommandLine.decode(reader, reader.uint32());
                    break;
                case 22:
                    message.structuredCommandLine = $root.command_line.CommandLine.decode(reader, reader.uint32());
                    break;
                case 13:
                    message.optionsParsed = $root.build_event_stream.OptionsParsed.decode(reader, reader.uint32());
                    break;
                case 16:
                    message.workspaceStatus = $root.build_event_stream.WorkspaceStatus.decode(reader, reader.uint32());
                    break;
                case 21:
                    message.fetch = $root.build_event_stream.Fetch.decode(reader, reader.uint32());
                    break;
                case 17:
                    message.configuration = $root.build_event_stream.Configuration.decode(reader, reader.uint32());
                    break;
                case 6:
                    message.expanded = $root.build_event_stream.PatternExpanded.decode(reader, reader.uint32());
                    break;
                case 18:
                    message.configured = $root.build_event_stream.TargetConfigured.decode(reader, reader.uint32());
                    break;
                case 7:
                    message.action = $root.build_event_stream.ActionExecuted.decode(reader, reader.uint32());
                    break;
                case 15:
                    message.namedSetOfFiles = $root.build_event_stream.NamedSetOfFiles.decode(reader, reader.uint32());
                    break;
                case 8:
                    message.completed = $root.build_event_stream.TargetComplete.decode(reader, reader.uint32());
                    break;
                case 10:
                    message.testResult = $root.build_event_stream.TestResult.decode(reader, reader.uint32());
                    break;
                case 9:
                    message.testSummary = $root.build_event_stream.TestSummary.decode(reader, reader.uint32());
                    break;
                case 14:
                    message.finished = $root.build_event_stream.BuildFinished.decode(reader, reader.uint32());
                    break;
                case 23:
                    message.buildToolLogs = $root.build_event_stream.BuildToolLogs.decode(reader, reader.uint32());
                    break;
                case 24:
                    message.buildMetrics = $root.build_event_stream.BuildMetrics.decode(reader, reader.uint32());
                    break;
                case 25:
                    message.workspaceInfo = $root.build_event_stream.WorkspaceConfig.decode(reader, reader.uint32());
                    break;
                case 26:
                    message.buildMetadata = $root.build_event_stream.BuildMetadata.decode(reader, reader.uint32());
                    break;
                case 27:
                    message.convenienceSymlinksIdentified = $root.build_event_stream.ConvenienceSymlinksIdentified.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a BuildEvent message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof build_event_stream.BuildEvent
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {build_event_stream.BuildEvent} BuildEvent
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        BuildEvent.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a BuildEvent message.
         * @function verify
         * @memberof build_event_stream.BuildEvent
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        BuildEvent.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.id != null && message.hasOwnProperty("id")) {
                let error = $root.build_event_stream.BuildEventId.verify(message.id);
                if (error)
                    return "id." + error;
            }
            if (message.children != null && message.hasOwnProperty("children")) {
                if (!Array.isArray(message.children))
                    return "children: array expected";
                for (let i = 0; i < message.children.length; ++i) {
                    let error = $root.build_event_stream.BuildEventId.verify(message.children[i]);
                    if (error)
                        return "children." + error;
                }
            }
            if (message.lastMessage != null && message.hasOwnProperty("lastMessage"))
                if (typeof message.lastMessage !== "boolean")
                    return "lastMessage: boolean expected";
            if (message.progress != null && message.hasOwnProperty("progress")) {
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.Progress.verify(message.progress);
                    if (error)
                        return "progress." + error;
                }
            }
            if (message.aborted != null && message.hasOwnProperty("aborted")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.Aborted.verify(message.aborted);
                    if (error)
                        return "aborted." + error;
                }
            }
            if (message.started != null && message.hasOwnProperty("started")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.BuildStarted.verify(message.started);
                    if (error)
                        return "started." + error;
                }
            }
            if (message.unstructuredCommandLine != null && message.hasOwnProperty("unstructuredCommandLine")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.UnstructuredCommandLine.verify(message.unstructuredCommandLine);
                    if (error)
                        return "unstructuredCommandLine." + error;
                }
            }
            if (message.structuredCommandLine != null && message.hasOwnProperty("structuredCommandLine")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.command_line.CommandLine.verify(message.structuredCommandLine);
                    if (error)
                        return "structuredCommandLine." + error;
                }
            }
            if (message.optionsParsed != null && message.hasOwnProperty("optionsParsed")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.OptionsParsed.verify(message.optionsParsed);
                    if (error)
                        return "optionsParsed." + error;
                }
            }
            if (message.workspaceStatus != null && message.hasOwnProperty("workspaceStatus")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.WorkspaceStatus.verify(message.workspaceStatus);
                    if (error)
                        return "workspaceStatus." + error;
                }
            }
            if (message.fetch != null && message.hasOwnProperty("fetch")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.Fetch.verify(message.fetch);
                    if (error)
                        return "fetch." + error;
                }
            }
            if (message.configuration != null && message.hasOwnProperty("configuration")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.Configuration.verify(message.configuration);
                    if (error)
                        return "configuration." + error;
                }
            }
            if (message.expanded != null && message.hasOwnProperty("expanded")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.PatternExpanded.verify(message.expanded);
                    if (error)
                        return "expanded." + error;
                }
            }
            if (message.configured != null && message.hasOwnProperty("configured")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.TargetConfigured.verify(message.configured);
                    if (error)
                        return "configured." + error;
                }
            }
            if (message.action != null && message.hasOwnProperty("action")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.ActionExecuted.verify(message.action);
                    if (error)
                        return "action." + error;
                }
            }
            if (message.namedSetOfFiles != null && message.hasOwnProperty("namedSetOfFiles")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.NamedSetOfFiles.verify(message.namedSetOfFiles);
                    if (error)
                        return "namedSetOfFiles." + error;
                }
            }
            if (message.completed != null && message.hasOwnProperty("completed")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.TargetComplete.verify(message.completed);
                    if (error)
                        return "completed." + error;
                }
            }
            if (message.testResult != null && message.hasOwnProperty("testResult")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.TestResult.verify(message.testResult);
                    if (error)
                        return "testResult." + error;
                }
            }
            if (message.testSummary != null && message.hasOwnProperty("testSummary")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.TestSummary.verify(message.testSummary);
                    if (error)
                        return "testSummary." + error;
                }
            }
            if (message.finished != null && message.hasOwnProperty("finished")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.BuildFinished.verify(message.finished);
                    if (error)
                        return "finished." + error;
                }
            }
            if (message.buildToolLogs != null && message.hasOwnProperty("buildToolLogs")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.BuildToolLogs.verify(message.buildToolLogs);
                    if (error)
                        return "buildToolLogs." + error;
                }
            }
            if (message.buildMetrics != null && message.hasOwnProperty("buildMetrics")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.BuildMetrics.verify(message.buildMetrics);
                    if (error)
                        return "buildMetrics." + error;
                }
            }
            if (message.workspaceInfo != null && message.hasOwnProperty("workspaceInfo")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.WorkspaceConfig.verify(message.workspaceInfo);
                    if (error)
                        return "workspaceInfo." + error;
                }
            }
            if (message.buildMetadata != null && message.hasOwnProperty("buildMetadata")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.BuildMetadata.verify(message.buildMetadata);
                    if (error)
                        return "buildMetadata." + error;
                }
            }
            if (message.convenienceSymlinksIdentified != null && message.hasOwnProperty("convenienceSymlinksIdentified")) {
                if (properties.payload === 1)
                    return "payload: multiple values";
                properties.payload = 1;
                {
                    let error = $root.build_event_stream.ConvenienceSymlinksIdentified.verify(message.convenienceSymlinksIdentified);
                    if (error)
                        return "convenienceSymlinksIdentified." + error;
                }
            }
            return null;
        };

        /**
         * Creates a BuildEvent message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof build_event_stream.BuildEvent
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {build_event_stream.BuildEvent} BuildEvent
         */
        BuildEvent.fromObject = function fromObject(object) {
            if (object instanceof $root.build_event_stream.BuildEvent)
                return object;
            let message = new $root.build_event_stream.BuildEvent();
            if (object.id != null) {
                if (typeof object.id !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.id: object expected");
                message.id = $root.build_event_stream.BuildEventId.fromObject(object.id);
            }
            if (object.children) {
                if (!Array.isArray(object.children))
                    throw TypeError(".build_event_stream.BuildEvent.children: array expected");
                message.children = [];
                for (let i = 0; i < object.children.length; ++i) {
                    if (typeof object.children[i] !== "object")
                        throw TypeError(".build_event_stream.BuildEvent.children: object expected");
                    message.children[i] = $root.build_event_stream.BuildEventId.fromObject(object.children[i]);
                }
            }
            if (object.lastMessage != null)
                message.lastMessage = Boolean(object.lastMessage);
            if (object.progress != null) {
                if (typeof object.progress !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.progress: object expected");
                message.progress = $root.build_event_stream.Progress.fromObject(object.progress);
            }
            if (object.aborted != null) {
                if (typeof object.aborted !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.aborted: object expected");
                message.aborted = $root.build_event_stream.Aborted.fromObject(object.aborted);
            }
            if (object.started != null) {
                if (typeof object.started !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.started: object expected");
                message.started = $root.build_event_stream.BuildStarted.fromObject(object.started);
            }
            if (object.unstructuredCommandLine != null) {
                if (typeof object.unstructuredCommandLine !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.unstructuredCommandLine: object expected");
                message.unstructuredCommandLine = $root.build_event_stream.UnstructuredCommandLine.fromObject(object.unstructuredCommandLine);
            }
            if (object.structuredCommandLine != null) {
                if (typeof object.structuredCommandLine !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.structuredCommandLine: object expected");
                message.structuredCommandLine = $root.command_line.CommandLine.fromObject(object.structuredCommandLine);
            }
            if (object.optionsParsed != null) {
                if (typeof object.optionsParsed !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.optionsParsed: object expected");
                message.optionsParsed = $root.build_event_stream.OptionsParsed.fromObject(object.optionsParsed);
            }
            if (object.workspaceStatus != null) {
                if (typeof object.workspaceStatus !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.workspaceStatus: object expected");
                message.workspaceStatus = $root.build_event_stream.WorkspaceStatus.fromObject(object.workspaceStatus);
            }
            if (object.fetch != null) {
                if (typeof object.fetch !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.fetch: object expected");
                message.fetch = $root.build_event_stream.Fetch.fromObject(object.fetch);
            }
            if (object.configuration != null) {
                if (typeof object.configuration !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.configuration: object expected");
                message.configuration = $root.build_event_stream.Configuration.fromObject(object.configuration);
            }
            if (object.expanded != null) {
                if (typeof object.expanded !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.expanded: object expected");
                message.expanded = $root.build_event_stream.PatternExpanded.fromObject(object.expanded);
            }
            if (object.configured != null) {
                if (typeof object.configured !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.configured: object expected");
                message.configured = $root.build_event_stream.TargetConfigured.fromObject(object.configured);
            }
            if (object.action != null) {
                if (typeof object.action !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.action: object expected");
                message.action = $root.build_event_stream.ActionExecuted.fromObject(object.action);
            }
            if (object.namedSetOfFiles != null) {
                if (typeof object.namedSetOfFiles !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.namedSetOfFiles: object expected");
                message.namedSetOfFiles = $root.build_event_stream.NamedSetOfFiles.fromObject(object.namedSetOfFiles);
            }
            if (object.completed != null) {
                if (typeof object.completed !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.completed: object expected");
                message.completed = $root.build_event_stream.TargetComplete.fromObject(object.completed);
            }
            if (object.testResult != null) {
                if (typeof object.testResult !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.testResult: object expected");
                message.testResult = $root.build_event_stream.TestResult.fromObject(object.testResult);
            }
            if (object.testSummary != null) {
                if (typeof object.testSummary !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.testSummary: object expected");
                message.testSummary = $root.build_event_stream.TestSummary.fromObject(object.testSummary);
            }
            if (object.finished != null) {
                if (typeof object.finished !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.finished: object expected");
                message.finished = $root.build_event_stream.BuildFinished.fromObject(object.finished);
            }
            if (object.buildToolLogs != null) {
                if (typeof object.buildToolLogs !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.buildToolLogs: object expected");
                message.buildToolLogs = $root.build_event_stream.BuildToolLogs.fromObject(object.buildToolLogs);
            }
            if (object.buildMetrics != null) {
                if (typeof object.buildMetrics !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.buildMetrics: object expected");
                message.buildMetrics = $root.build_event_stream.BuildMetrics.fromObject(object.buildMetrics);
            }
            if (object.workspaceInfo != null) {
                if (typeof object.workspaceInfo !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.workspaceInfo: object expected");
                message.workspaceInfo = $root.build_event_stream.WorkspaceConfig.fromObject(object.workspaceInfo);
            }
            if (object.buildMetadata != null) {
                if (typeof object.buildMetadata !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.buildMetadata: object expected");
                message.buildMetadata = $root.build_event_stream.BuildMetadata.fromObject(object.buildMetadata);
            }
            if (object.convenienceSymlinksIdentified != null) {
                if (typeof object.convenienceSymlinksIdentified !== "object")
                    throw TypeError(".build_event_stream.BuildEvent.convenienceSymlinksIdentified: object expected");
                message.convenienceSymlinksIdentified = $root.build_event_stream.ConvenienceSymlinksIdentified.fromObject(object.convenienceSymlinksIdentified);
            }
            return message;
        };

        /**
         * Creates a plain object from a BuildEvent message. Also converts values to other types if specified.
         * @function toObject
         * @memberof build_event_stream.BuildEvent
         * @static
         * @param {build_event_stream.BuildEvent} message BuildEvent
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        BuildEvent.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.children = [];
            if (options.defaults) {
                object.id = null;
                object.lastMessage = false;
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = $root.build_event_stream.BuildEventId.toObject(message.id, options);
            if (message.children && message.children.length) {
                object.children = [];
                for (let j = 0; j < message.children.length; ++j)
                    object.children[j] = $root.build_event_stream.BuildEventId.toObject(message.children[j], options);
            }
            if (message.progress != null && message.hasOwnProperty("progress")) {
                object.progress = $root.build_event_stream.Progress.toObject(message.progress, options);
                if (options.oneofs)
                    object.payload = "progress";
            }
            if (message.aborted != null && message.hasOwnProperty("aborted")) {
                object.aborted = $root.build_event_stream.Aborted.toObject(message.aborted, options);
                if (options.oneofs)
                    object.payload = "aborted";
            }
            if (message.started != null && message.hasOwnProperty("started")) {
                object.started = $root.build_event_stream.BuildStarted.toObject(message.started, options);
                if (options.oneofs)
                    object.payload = "started";
            }
            if (message.expanded != null && message.hasOwnProperty("expanded")) {
                object.expanded = $root.build_event_stream.PatternExpanded.toObject(message.expanded, options);
                if (options.oneofs)
                    object.payload = "expanded";
            }
            if (message.action != null && message.hasOwnProperty("action")) {
                object.action = $root.build_event_stream.ActionExecuted.toObject(message.action, options);
                if (options.oneofs)
                    object.payload = "action";
            }
            if (message.completed != null && message.hasOwnProperty("completed")) {
                object.completed = $root.build_event_stream.TargetComplete.toObject(message.completed, options);
                if (options.oneofs)
                    object.payload = "completed";
            }
            if (message.testSummary != null && message.hasOwnProperty("testSummary")) {
                object.testSummary = $root.build_event_stream.TestSummary.toObject(message.testSummary, options);
                if (options.oneofs)
                    object.payload = "testSummary";
            }
            if (message.testResult != null && message.hasOwnProperty("testResult")) {
                object.testResult = $root.build_event_stream.TestResult.toObject(message.testResult, options);
                if (options.oneofs)
                    object.payload = "testResult";
            }
            if (message.unstructuredCommandLine != null && message.hasOwnProperty("unstructuredCommandLine")) {
                object.unstructuredCommandLine = $root.build_event_stream.UnstructuredCommandLine.toObject(message.unstructuredCommandLine, options);
                if (options.oneofs)
                    object.payload = "unstructuredCommandLine";
            }
            if (message.optionsParsed != null && message.hasOwnProperty("optionsParsed")) {
                object.optionsParsed = $root.build_event_stream.OptionsParsed.toObject(message.optionsParsed, options);
                if (options.oneofs)
                    object.payload = "optionsParsed";
            }
            if (message.finished != null && message.hasOwnProperty("finished")) {
                object.finished = $root.build_event_stream.BuildFinished.toObject(message.finished, options);
                if (options.oneofs)
                    object.payload = "finished";
            }
            if (message.namedSetOfFiles != null && message.hasOwnProperty("namedSetOfFiles")) {
                object.namedSetOfFiles = $root.build_event_stream.NamedSetOfFiles.toObject(message.namedSetOfFiles, options);
                if (options.oneofs)
                    object.payload = "namedSetOfFiles";
            }
            if (message.workspaceStatus != null && message.hasOwnProperty("workspaceStatus")) {
                object.workspaceStatus = $root.build_event_stream.WorkspaceStatus.toObject(message.workspaceStatus, options);
                if (options.oneofs)
                    object.payload = "workspaceStatus";
            }
            if (message.configuration != null && message.hasOwnProperty("configuration")) {
                object.configuration = $root.build_event_stream.Configuration.toObject(message.configuration, options);
                if (options.oneofs)
                    object.payload = "configuration";
            }
            if (message.configured != null && message.hasOwnProperty("configured")) {
                object.configured = $root.build_event_stream.TargetConfigured.toObject(message.configured, options);
                if (options.oneofs)
                    object.payload = "configured";
            }
            if (message.lastMessage != null && message.hasOwnProperty("lastMessage"))
                object.lastMessage = message.lastMessage;
            if (message.fetch != null && message.hasOwnProperty("fetch")) {
                object.fetch = $root.build_event_stream.Fetch.toObject(message.fetch, options);
                if (options.oneofs)
                    object.payload = "fetch";
            }
            if (message.structuredCommandLine != null && message.hasOwnProperty("structuredCommandLine")) {
                object.structuredCommandLine = $root.command_line.CommandLine.toObject(message.structuredCommandLine, options);
                if (options.oneofs)
                    object.payload = "structuredCommandLine";
            }
            if (message.buildToolLogs != null && message.hasOwnProperty("buildToolLogs")) {
                object.buildToolLogs = $root.build_event_stream.BuildToolLogs.toObject(message.buildToolLogs, options);
                if (options.oneofs)
                    object.payload = "buildToolLogs";
            }
            if (message.buildMetrics != null && message.hasOwnProperty("buildMetrics")) {
                object.buildMetrics = $root.build_event_stream.BuildMetrics.toObject(message.buildMetrics, options);
                if (options.oneofs)
                    object.payload = "buildMetrics";
            }
            if (message.workspaceInfo != null && message.hasOwnProperty("workspaceInfo")) {
                object.workspaceInfo = $root.build_event_stream.WorkspaceConfig.toObject(message.workspaceInfo, options);
                if (options.oneofs)
                    object.payload = "workspaceInfo";
            }
            if (message.buildMetadata != null && message.hasOwnProperty("buildMetadata")) {
                object.buildMetadata = $root.build_event_stream.BuildMetadata.toObject(message.buildMetadata, options);
                if (options.oneofs)
                    object.payload = "buildMetadata";
            }
            if (message.convenienceSymlinksIdentified != null && message.hasOwnProperty("convenienceSymlinksIdentified")) {
                object.convenienceSymlinksIdentified = $root.build_event_stream.ConvenienceSymlinksIdentified.toObject(message.convenienceSymlinksIdentified, options);
                if (options.oneofs)
                    object.payload = "convenienceSymlinksIdentified";
            }
            return object;
        };

        /**
         * Converts this BuildEvent to JSON.
         * @function toJSON
         * @memberof build_event_stream.BuildEvent
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        BuildEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BuildEvent;
    })();

    return build_event_stream;
})();

export const command_line = $root.command_line = (() => {

    /**
     * Namespace command_line.
     * @exports command_line
     * @namespace
     */
    const command_line = {};

    command_line.CommandLine = (function() {

        /**
         * Properties of a CommandLine.
         * @memberof command_line
         * @interface ICommandLine
         * @property {string|null} [commandLineLabel] CommandLine commandLineLabel
         * @property {Array.<command_line.ICommandLineSection>|null} [sections] CommandLine sections
         */

        /**
         * Constructs a new CommandLine.
         * @memberof command_line
         * @classdesc Represents a CommandLine.
         * @implements ICommandLine
         * @constructor
         * @param {command_line.ICommandLine=} [properties] Properties to set
         */
        function CommandLine(properties) {
            this.sections = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommandLine commandLineLabel.
         * @member {string} commandLineLabel
         * @memberof command_line.CommandLine
         * @instance
         */
        CommandLine.prototype.commandLineLabel = "";

        /**
         * CommandLine sections.
         * @member {Array.<command_line.ICommandLineSection>} sections
         * @memberof command_line.CommandLine
         * @instance
         */
        CommandLine.prototype.sections = $util.emptyArray;

        /**
         * Creates a new CommandLine instance using the specified properties.
         * @function create
         * @memberof command_line.CommandLine
         * @static
         * @param {command_line.ICommandLine=} [properties] Properties to set
         * @returns {command_line.CommandLine} CommandLine instance
         */
        CommandLine.create = function create(properties) {
            return new CommandLine(properties);
        };

        /**
         * Encodes the specified CommandLine message. Does not implicitly {@link command_line.CommandLine.verify|verify} messages.
         * @function encode
         * @memberof command_line.CommandLine
         * @static
         * @param {command_line.ICommandLine} message CommandLine message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommandLine.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.commandLineLabel != null && message.hasOwnProperty("commandLineLabel"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.commandLineLabel);
            if (message.sections != null && message.sections.length)
                for (let i = 0; i < message.sections.length; ++i)
                    $root.command_line.CommandLineSection.encode(message.sections[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CommandLine message, length delimited. Does not implicitly {@link command_line.CommandLine.verify|verify} messages.
         * @function encodeDelimited
         * @memberof command_line.CommandLine
         * @static
         * @param {command_line.ICommandLine} message CommandLine message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommandLine.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CommandLine message from the specified reader or buffer.
         * @function decode
         * @memberof command_line.CommandLine
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {command_line.CommandLine} CommandLine
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommandLine.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.command_line.CommandLine();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.commandLineLabel = reader.string();
                    break;
                case 2:
                    if (!(message.sections && message.sections.length))
                        message.sections = [];
                    message.sections.push($root.command_line.CommandLineSection.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CommandLine message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof command_line.CommandLine
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {command_line.CommandLine} CommandLine
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommandLine.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CommandLine message.
         * @function verify
         * @memberof command_line.CommandLine
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CommandLine.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.commandLineLabel != null && message.hasOwnProperty("commandLineLabel"))
                if (!$util.isString(message.commandLineLabel))
                    return "commandLineLabel: string expected";
            if (message.sections != null && message.hasOwnProperty("sections")) {
                if (!Array.isArray(message.sections))
                    return "sections: array expected";
                for (let i = 0; i < message.sections.length; ++i) {
                    let error = $root.command_line.CommandLineSection.verify(message.sections[i]);
                    if (error)
                        return "sections." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CommandLine message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof command_line.CommandLine
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {command_line.CommandLine} CommandLine
         */
        CommandLine.fromObject = function fromObject(object) {
            if (object instanceof $root.command_line.CommandLine)
                return object;
            let message = new $root.command_line.CommandLine();
            if (object.commandLineLabel != null)
                message.commandLineLabel = String(object.commandLineLabel);
            if (object.sections) {
                if (!Array.isArray(object.sections))
                    throw TypeError(".command_line.CommandLine.sections: array expected");
                message.sections = [];
                for (let i = 0; i < object.sections.length; ++i) {
                    if (typeof object.sections[i] !== "object")
                        throw TypeError(".command_line.CommandLine.sections: object expected");
                    message.sections[i] = $root.command_line.CommandLineSection.fromObject(object.sections[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a CommandLine message. Also converts values to other types if specified.
         * @function toObject
         * @memberof command_line.CommandLine
         * @static
         * @param {command_line.CommandLine} message CommandLine
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommandLine.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.sections = [];
            if (options.defaults)
                object.commandLineLabel = "";
            if (message.commandLineLabel != null && message.hasOwnProperty("commandLineLabel"))
                object.commandLineLabel = message.commandLineLabel;
            if (message.sections && message.sections.length) {
                object.sections = [];
                for (let j = 0; j < message.sections.length; ++j)
                    object.sections[j] = $root.command_line.CommandLineSection.toObject(message.sections[j], options);
            }
            return object;
        };

        /**
         * Converts this CommandLine to JSON.
         * @function toJSON
         * @memberof command_line.CommandLine
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommandLine.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommandLine;
    })();

    command_line.CommandLineSection = (function() {

        /**
         * Properties of a CommandLineSection.
         * @memberof command_line
         * @interface ICommandLineSection
         * @property {string|null} [sectionLabel] CommandLineSection sectionLabel
         * @property {command_line.IChunkList|null} [chunkList] CommandLineSection chunkList
         * @property {command_line.IOptionList|null} [optionList] CommandLineSection optionList
         */

        /**
         * Constructs a new CommandLineSection.
         * @memberof command_line
         * @classdesc Represents a CommandLineSection.
         * @implements ICommandLineSection
         * @constructor
         * @param {command_line.ICommandLineSection=} [properties] Properties to set
         */
        function CommandLineSection(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CommandLineSection sectionLabel.
         * @member {string} sectionLabel
         * @memberof command_line.CommandLineSection
         * @instance
         */
        CommandLineSection.prototype.sectionLabel = "";

        /**
         * CommandLineSection chunkList.
         * @member {command_line.IChunkList|null|undefined} chunkList
         * @memberof command_line.CommandLineSection
         * @instance
         */
        CommandLineSection.prototype.chunkList = null;

        /**
         * CommandLineSection optionList.
         * @member {command_line.IOptionList|null|undefined} optionList
         * @memberof command_line.CommandLineSection
         * @instance
         */
        CommandLineSection.prototype.optionList = null;

        // OneOf field names bound to virtual getters and setters
        let $oneOfFields;

        /**
         * CommandLineSection sectionType.
         * @member {"chunkList"|"optionList"|undefined} sectionType
         * @memberof command_line.CommandLineSection
         * @instance
         */
        Object.defineProperty(CommandLineSection.prototype, "sectionType", {
            get: $util.oneOfGetter($oneOfFields = ["chunkList", "optionList"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Creates a new CommandLineSection instance using the specified properties.
         * @function create
         * @memberof command_line.CommandLineSection
         * @static
         * @param {command_line.ICommandLineSection=} [properties] Properties to set
         * @returns {command_line.CommandLineSection} CommandLineSection instance
         */
        CommandLineSection.create = function create(properties) {
            return new CommandLineSection(properties);
        };

        /**
         * Encodes the specified CommandLineSection message. Does not implicitly {@link command_line.CommandLineSection.verify|verify} messages.
         * @function encode
         * @memberof command_line.CommandLineSection
         * @static
         * @param {command_line.ICommandLineSection} message CommandLineSection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommandLineSection.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sectionLabel != null && message.hasOwnProperty("sectionLabel"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.sectionLabel);
            if (message.chunkList != null && message.hasOwnProperty("chunkList"))
                $root.command_line.ChunkList.encode(message.chunkList, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.optionList != null && message.hasOwnProperty("optionList"))
                $root.command_line.OptionList.encode(message.optionList, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CommandLineSection message, length delimited. Does not implicitly {@link command_line.CommandLineSection.verify|verify} messages.
         * @function encodeDelimited
         * @memberof command_line.CommandLineSection
         * @static
         * @param {command_line.ICommandLineSection} message CommandLineSection message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CommandLineSection.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CommandLineSection message from the specified reader or buffer.
         * @function decode
         * @memberof command_line.CommandLineSection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {command_line.CommandLineSection} CommandLineSection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommandLineSection.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.command_line.CommandLineSection();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.sectionLabel = reader.string();
                    break;
                case 2:
                    message.chunkList = $root.command_line.ChunkList.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.optionList = $root.command_line.OptionList.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CommandLineSection message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof command_line.CommandLineSection
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {command_line.CommandLineSection} CommandLineSection
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CommandLineSection.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CommandLineSection message.
         * @function verify
         * @memberof command_line.CommandLineSection
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CommandLineSection.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            let properties = {};
            if (message.sectionLabel != null && message.hasOwnProperty("sectionLabel"))
                if (!$util.isString(message.sectionLabel))
                    return "sectionLabel: string expected";
            if (message.chunkList != null && message.hasOwnProperty("chunkList")) {
                properties.sectionType = 1;
                {
                    let error = $root.command_line.ChunkList.verify(message.chunkList);
                    if (error)
                        return "chunkList." + error;
                }
            }
            if (message.optionList != null && message.hasOwnProperty("optionList")) {
                if (properties.sectionType === 1)
                    return "sectionType: multiple values";
                properties.sectionType = 1;
                {
                    let error = $root.command_line.OptionList.verify(message.optionList);
                    if (error)
                        return "optionList." + error;
                }
            }
            return null;
        };

        /**
         * Creates a CommandLineSection message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof command_line.CommandLineSection
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {command_line.CommandLineSection} CommandLineSection
         */
        CommandLineSection.fromObject = function fromObject(object) {
            if (object instanceof $root.command_line.CommandLineSection)
                return object;
            let message = new $root.command_line.CommandLineSection();
            if (object.sectionLabel != null)
                message.sectionLabel = String(object.sectionLabel);
            if (object.chunkList != null) {
                if (typeof object.chunkList !== "object")
                    throw TypeError(".command_line.CommandLineSection.chunkList: object expected");
                message.chunkList = $root.command_line.ChunkList.fromObject(object.chunkList);
            }
            if (object.optionList != null) {
                if (typeof object.optionList !== "object")
                    throw TypeError(".command_line.CommandLineSection.optionList: object expected");
                message.optionList = $root.command_line.OptionList.fromObject(object.optionList);
            }
            return message;
        };

        /**
         * Creates a plain object from a CommandLineSection message. Also converts values to other types if specified.
         * @function toObject
         * @memberof command_line.CommandLineSection
         * @static
         * @param {command_line.CommandLineSection} message CommandLineSection
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CommandLineSection.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.sectionLabel = "";
            if (message.sectionLabel != null && message.hasOwnProperty("sectionLabel"))
                object.sectionLabel = message.sectionLabel;
            if (message.chunkList != null && message.hasOwnProperty("chunkList")) {
                object.chunkList = $root.command_line.ChunkList.toObject(message.chunkList, options);
                if (options.oneofs)
                    object.sectionType = "chunkList";
            }
            if (message.optionList != null && message.hasOwnProperty("optionList")) {
                object.optionList = $root.command_line.OptionList.toObject(message.optionList, options);
                if (options.oneofs)
                    object.sectionType = "optionList";
            }
            return object;
        };

        /**
         * Converts this CommandLineSection to JSON.
         * @function toJSON
         * @memberof command_line.CommandLineSection
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CommandLineSection.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CommandLineSection;
    })();

    command_line.ChunkList = (function() {

        /**
         * Properties of a ChunkList.
         * @memberof command_line
         * @interface IChunkList
         * @property {Array.<string>|null} [chunk] ChunkList chunk
         */

        /**
         * Constructs a new ChunkList.
         * @memberof command_line
         * @classdesc Represents a ChunkList.
         * @implements IChunkList
         * @constructor
         * @param {command_line.IChunkList=} [properties] Properties to set
         */
        function ChunkList(properties) {
            this.chunk = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * ChunkList chunk.
         * @member {Array.<string>} chunk
         * @memberof command_line.ChunkList
         * @instance
         */
        ChunkList.prototype.chunk = $util.emptyArray;

        /**
         * Creates a new ChunkList instance using the specified properties.
         * @function create
         * @memberof command_line.ChunkList
         * @static
         * @param {command_line.IChunkList=} [properties] Properties to set
         * @returns {command_line.ChunkList} ChunkList instance
         */
        ChunkList.create = function create(properties) {
            return new ChunkList(properties);
        };

        /**
         * Encodes the specified ChunkList message. Does not implicitly {@link command_line.ChunkList.verify|verify} messages.
         * @function encode
         * @memberof command_line.ChunkList
         * @static
         * @param {command_line.IChunkList} message ChunkList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChunkList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.chunk != null && message.chunk.length)
                for (let i = 0; i < message.chunk.length; ++i)
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.chunk[i]);
            return writer;
        };

        /**
         * Encodes the specified ChunkList message, length delimited. Does not implicitly {@link command_line.ChunkList.verify|verify} messages.
         * @function encodeDelimited
         * @memberof command_line.ChunkList
         * @static
         * @param {command_line.IChunkList} message ChunkList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ChunkList.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ChunkList message from the specified reader or buffer.
         * @function decode
         * @memberof command_line.ChunkList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {command_line.ChunkList} ChunkList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChunkList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.command_line.ChunkList();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.chunk && message.chunk.length))
                        message.chunk = [];
                    message.chunk.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ChunkList message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof command_line.ChunkList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {command_line.ChunkList} ChunkList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ChunkList.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ChunkList message.
         * @function verify
         * @memberof command_line.ChunkList
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ChunkList.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.chunk != null && message.hasOwnProperty("chunk")) {
                if (!Array.isArray(message.chunk))
                    return "chunk: array expected";
                for (let i = 0; i < message.chunk.length; ++i)
                    if (!$util.isString(message.chunk[i]))
                        return "chunk: string[] expected";
            }
            return null;
        };

        /**
         * Creates a ChunkList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof command_line.ChunkList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {command_line.ChunkList} ChunkList
         */
        ChunkList.fromObject = function fromObject(object) {
            if (object instanceof $root.command_line.ChunkList)
                return object;
            let message = new $root.command_line.ChunkList();
            if (object.chunk) {
                if (!Array.isArray(object.chunk))
                    throw TypeError(".command_line.ChunkList.chunk: array expected");
                message.chunk = [];
                for (let i = 0; i < object.chunk.length; ++i)
                    message.chunk[i] = String(object.chunk[i]);
            }
            return message;
        };

        /**
         * Creates a plain object from a ChunkList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof command_line.ChunkList
         * @static
         * @param {command_line.ChunkList} message ChunkList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ChunkList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.chunk = [];
            if (message.chunk && message.chunk.length) {
                object.chunk = [];
                for (let j = 0; j < message.chunk.length; ++j)
                    object.chunk[j] = message.chunk[j];
            }
            return object;
        };

        /**
         * Converts this ChunkList to JSON.
         * @function toJSON
         * @memberof command_line.ChunkList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ChunkList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ChunkList;
    })();

    command_line.OptionList = (function() {

        /**
         * Properties of an OptionList.
         * @memberof command_line
         * @interface IOptionList
         * @property {Array.<command_line.IOption>|null} [option] OptionList option
         */

        /**
         * Constructs a new OptionList.
         * @memberof command_line
         * @classdesc Represents an OptionList.
         * @implements IOptionList
         * @constructor
         * @param {command_line.IOptionList=} [properties] Properties to set
         */
        function OptionList(properties) {
            this.option = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * OptionList option.
         * @member {Array.<command_line.IOption>} option
         * @memberof command_line.OptionList
         * @instance
         */
        OptionList.prototype.option = $util.emptyArray;

        /**
         * Creates a new OptionList instance using the specified properties.
         * @function create
         * @memberof command_line.OptionList
         * @static
         * @param {command_line.IOptionList=} [properties] Properties to set
         * @returns {command_line.OptionList} OptionList instance
         */
        OptionList.create = function create(properties) {
            return new OptionList(properties);
        };

        /**
         * Encodes the specified OptionList message. Does not implicitly {@link command_line.OptionList.verify|verify} messages.
         * @function encode
         * @memberof command_line.OptionList
         * @static
         * @param {command_line.IOptionList} message OptionList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OptionList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.option != null && message.option.length)
                for (let i = 0; i < message.option.length; ++i)
                    $root.command_line.Option.encode(message.option[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified OptionList message, length delimited. Does not implicitly {@link command_line.OptionList.verify|verify} messages.
         * @function encodeDelimited
         * @memberof command_line.OptionList
         * @static
         * @param {command_line.IOptionList} message OptionList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        OptionList.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an OptionList message from the specified reader or buffer.
         * @function decode
         * @memberof command_line.OptionList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {command_line.OptionList} OptionList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OptionList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.command_line.OptionList();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.option && message.option.length))
                        message.option = [];
                    message.option.push($root.command_line.Option.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an OptionList message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof command_line.OptionList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {command_line.OptionList} OptionList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        OptionList.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an OptionList message.
         * @function verify
         * @memberof command_line.OptionList
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        OptionList.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.option != null && message.hasOwnProperty("option")) {
                if (!Array.isArray(message.option))
                    return "option: array expected";
                for (let i = 0; i < message.option.length; ++i) {
                    let error = $root.command_line.Option.verify(message.option[i]);
                    if (error)
                        return "option." + error;
                }
            }
            return null;
        };

        /**
         * Creates an OptionList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof command_line.OptionList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {command_line.OptionList} OptionList
         */
        OptionList.fromObject = function fromObject(object) {
            if (object instanceof $root.command_line.OptionList)
                return object;
            let message = new $root.command_line.OptionList();
            if (object.option) {
                if (!Array.isArray(object.option))
                    throw TypeError(".command_line.OptionList.option: array expected");
                message.option = [];
                for (let i = 0; i < object.option.length; ++i) {
                    if (typeof object.option[i] !== "object")
                        throw TypeError(".command_line.OptionList.option: object expected");
                    message.option[i] = $root.command_line.Option.fromObject(object.option[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from an OptionList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof command_line.OptionList
         * @static
         * @param {command_line.OptionList} message OptionList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        OptionList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.option = [];
            if (message.option && message.option.length) {
                object.option = [];
                for (let j = 0; j < message.option.length; ++j)
                    object.option[j] = $root.command_line.Option.toObject(message.option[j], options);
            }
            return object;
        };

        /**
         * Converts this OptionList to JSON.
         * @function toJSON
         * @memberof command_line.OptionList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        OptionList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OptionList;
    })();

    command_line.Option = (function() {

        /**
         * Properties of an Option.
         * @memberof command_line
         * @interface IOption
         * @property {string|null} [combinedForm] Option combinedForm
         * @property {string|null} [optionName] Option optionName
         * @property {string|null} [optionValue] Option optionValue
         * @property {Array.<options.OptionEffectTag>|null} [effectTags] Option effectTags
         * @property {Array.<options.OptionMetadataTag>|null} [metadataTags] Option metadataTags
         */

        /**
         * Constructs a new Option.
         * @memberof command_line
         * @classdesc Represents an Option.
         * @implements IOption
         * @constructor
         * @param {command_line.IOption=} [properties] Properties to set
         */
        function Option(properties) {
            this.effectTags = [];
            this.metadataTags = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Option combinedForm.
         * @member {string} combinedForm
         * @memberof command_line.Option
         * @instance
         */
        Option.prototype.combinedForm = "";

        /**
         * Option optionName.
         * @member {string} optionName
         * @memberof command_line.Option
         * @instance
         */
        Option.prototype.optionName = "";

        /**
         * Option optionValue.
         * @member {string} optionValue
         * @memberof command_line.Option
         * @instance
         */
        Option.prototype.optionValue = "";

        /**
         * Option effectTags.
         * @member {Array.<options.OptionEffectTag>} effectTags
         * @memberof command_line.Option
         * @instance
         */
        Option.prototype.effectTags = $util.emptyArray;

        /**
         * Option metadataTags.
         * @member {Array.<options.OptionMetadataTag>} metadataTags
         * @memberof command_line.Option
         * @instance
         */
        Option.prototype.metadataTags = $util.emptyArray;

        /**
         * Creates a new Option instance using the specified properties.
         * @function create
         * @memberof command_line.Option
         * @static
         * @param {command_line.IOption=} [properties] Properties to set
         * @returns {command_line.Option} Option instance
         */
        Option.create = function create(properties) {
            return new Option(properties);
        };

        /**
         * Encodes the specified Option message. Does not implicitly {@link command_line.Option.verify|verify} messages.
         * @function encode
         * @memberof command_line.Option
         * @static
         * @param {command_line.IOption} message Option message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Option.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.combinedForm != null && message.hasOwnProperty("combinedForm"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.combinedForm);
            if (message.optionName != null && message.hasOwnProperty("optionName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.optionName);
            if (message.optionValue != null && message.hasOwnProperty("optionValue"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.optionValue);
            if (message.effectTags != null && message.effectTags.length) {
                writer.uint32(/* id 4, wireType 2 =*/34).fork();
                for (let i = 0; i < message.effectTags.length; ++i)
                    writer.int32(message.effectTags[i]);
                writer.ldelim();
            }
            if (message.metadataTags != null && message.metadataTags.length) {
                writer.uint32(/* id 5, wireType 2 =*/42).fork();
                for (let i = 0; i < message.metadataTags.length; ++i)
                    writer.int32(message.metadataTags[i]);
                writer.ldelim();
            }
            return writer;
        };

        /**
         * Encodes the specified Option message, length delimited. Does not implicitly {@link command_line.Option.verify|verify} messages.
         * @function encodeDelimited
         * @memberof command_line.Option
         * @static
         * @param {command_line.IOption} message Option message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Option.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an Option message from the specified reader or buffer.
         * @function decode
         * @memberof command_line.Option
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {command_line.Option} Option
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Option.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.command_line.Option();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.combinedForm = reader.string();
                    break;
                case 2:
                    message.optionName = reader.string();
                    break;
                case 3:
                    message.optionValue = reader.string();
                    break;
                case 4:
                    if (!(message.effectTags && message.effectTags.length))
                        message.effectTags = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.effectTags.push(reader.int32());
                    } else
                        message.effectTags.push(reader.int32());
                    break;
                case 5:
                    if (!(message.metadataTags && message.metadataTags.length))
                        message.metadataTags = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.metadataTags.push(reader.int32());
                    } else
                        message.metadataTags.push(reader.int32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes an Option message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof command_line.Option
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {command_line.Option} Option
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Option.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies an Option message.
         * @function verify
         * @memberof command_line.Option
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Option.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.combinedForm != null && message.hasOwnProperty("combinedForm"))
                if (!$util.isString(message.combinedForm))
                    return "combinedForm: string expected";
            if (message.optionName != null && message.hasOwnProperty("optionName"))
                if (!$util.isString(message.optionName))
                    return "optionName: string expected";
            if (message.optionValue != null && message.hasOwnProperty("optionValue"))
                if (!$util.isString(message.optionValue))
                    return "optionValue: string expected";
            if (message.effectTags != null && message.hasOwnProperty("effectTags")) {
                if (!Array.isArray(message.effectTags))
                    return "effectTags: array expected";
                for (let i = 0; i < message.effectTags.length; ++i)
                    switch (message.effectTags[i]) {
                    default:
                        return "effectTags: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                        break;
                    }
            }
            if (message.metadataTags != null && message.hasOwnProperty("metadataTags")) {
                if (!Array.isArray(message.metadataTags))
                    return "metadataTags: array expected";
                for (let i = 0; i < message.metadataTags.length; ++i)
                    switch (message.metadataTags[i]) {
                    default:
                        return "metadataTags: enum value[] expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
            }
            return null;
        };

        /**
         * Creates an Option message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof command_line.Option
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {command_line.Option} Option
         */
        Option.fromObject = function fromObject(object) {
            if (object instanceof $root.command_line.Option)
                return object;
            let message = new $root.command_line.Option();
            if (object.combinedForm != null)
                message.combinedForm = String(object.combinedForm);
            if (object.optionName != null)
                message.optionName = String(object.optionName);
            if (object.optionValue != null)
                message.optionValue = String(object.optionValue);
            if (object.effectTags) {
                if (!Array.isArray(object.effectTags))
                    throw TypeError(".command_line.Option.effectTags: array expected");
                message.effectTags = [];
                for (let i = 0; i < object.effectTags.length; ++i)
                    switch (object.effectTags[i]) {
                    default:
                    case "UNKNOWN":
                    case 0:
                        message.effectTags[i] = 0;
                        break;
                    case "NO_OP":
                    case 1:
                        message.effectTags[i] = 1;
                        break;
                    case "LOSES_INCREMENTAL_STATE":
                    case 2:
                        message.effectTags[i] = 2;
                        break;
                    case "CHANGES_INPUTS":
                    case 3:
                        message.effectTags[i] = 3;
                        break;
                    case "AFFECTS_OUTPUTS":
                    case 4:
                        message.effectTags[i] = 4;
                        break;
                    case "BUILD_FILE_SEMANTICS":
                    case 5:
                        message.effectTags[i] = 5;
                        break;
                    case "BAZEL_INTERNAL_CONFIGURATION":
                    case 6:
                        message.effectTags[i] = 6;
                        break;
                    case "LOADING_AND_ANALYSIS":
                    case 7:
                        message.effectTags[i] = 7;
                        break;
                    case "EXECUTION":
                    case 8:
                        message.effectTags[i] = 8;
                        break;
                    case "HOST_MACHINE_RESOURCE_OPTIMIZATIONS":
                    case 9:
                        message.effectTags[i] = 9;
                        break;
                    case "EAGERNESS_TO_EXIT":
                    case 10:
                        message.effectTags[i] = 10;
                        break;
                    case "BAZEL_MONITORING":
                    case 11:
                        message.effectTags[i] = 11;
                        break;
                    case "TERMINAL_OUTPUT":
                    case 12:
                        message.effectTags[i] = 12;
                        break;
                    case "ACTION_COMMAND_LINES":
                    case 13:
                        message.effectTags[i] = 13;
                        break;
                    case "TEST_RUNNER":
                    case 14:
                        message.effectTags[i] = 14;
                        break;
                    }
            }
            if (object.metadataTags) {
                if (!Array.isArray(object.metadataTags))
                    throw TypeError(".command_line.Option.metadataTags: array expected");
                message.metadataTags = [];
                for (let i = 0; i < object.metadataTags.length; ++i)
                    switch (object.metadataTags[i]) {
                    default:
                    case "EXPERIMENTAL":
                    case 0:
                        message.metadataTags[i] = 0;
                        break;
                    case "INCOMPATIBLE_CHANGE":
                    case 1:
                        message.metadataTags[i] = 1;
                        break;
                    case "DEPRECATED":
                    case 2:
                        message.metadataTags[i] = 2;
                        break;
                    case "HIDDEN":
                    case 3:
                        message.metadataTags[i] = 3;
                        break;
                    case "INTERNAL":
                    case 4:
                        message.metadataTags[i] = 4;
                        break;
                    case "TRIGGERED_BY_ALL_INCOMPATIBLE_CHANGES":
                    case 5:
                        message.metadataTags[i] = 5;
                        break;
                    }
            }
            return message;
        };

        /**
         * Creates a plain object from an Option message. Also converts values to other types if specified.
         * @function toObject
         * @memberof command_line.Option
         * @static
         * @param {command_line.Option} message Option
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Option.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults) {
                object.effectTags = [];
                object.metadataTags = [];
            }
            if (options.defaults) {
                object.combinedForm = "";
                object.optionName = "";
                object.optionValue = "";
            }
            if (message.combinedForm != null && message.hasOwnProperty("combinedForm"))
                object.combinedForm = message.combinedForm;
            if (message.optionName != null && message.hasOwnProperty("optionName"))
                object.optionName = message.optionName;
            if (message.optionValue != null && message.hasOwnProperty("optionValue"))
                object.optionValue = message.optionValue;
            if (message.effectTags && message.effectTags.length) {
                object.effectTags = [];
                for (let j = 0; j < message.effectTags.length; ++j)
                    object.effectTags[j] = options.enums === String ? $root.options.OptionEffectTag[message.effectTags[j]] : message.effectTags[j];
            }
            if (message.metadataTags && message.metadataTags.length) {
                object.metadataTags = [];
                for (let j = 0; j < message.metadataTags.length; ++j)
                    object.metadataTags[j] = options.enums === String ? $root.options.OptionMetadataTag[message.metadataTags[j]] : message.metadataTags[j];
            }
            return object;
        };

        /**
         * Converts this Option to JSON.
         * @function toJSON
         * @memberof command_line.Option
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Option.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Option;
    })();

    return command_line;
})();

export const options = $root.options = (() => {

    /**
     * Namespace options.
     * @exports options
     * @namespace
     */
    const options = {};

    /**
     * OptionEffectTag enum.
     * @name options.OptionEffectTag
     * @enum {string}
     * @property {number} UNKNOWN=0 UNKNOWN value
     * @property {number} NO_OP=1 NO_OP value
     * @property {number} LOSES_INCREMENTAL_STATE=2 LOSES_INCREMENTAL_STATE value
     * @property {number} CHANGES_INPUTS=3 CHANGES_INPUTS value
     * @property {number} AFFECTS_OUTPUTS=4 AFFECTS_OUTPUTS value
     * @property {number} BUILD_FILE_SEMANTICS=5 BUILD_FILE_SEMANTICS value
     * @property {number} BAZEL_INTERNAL_CONFIGURATION=6 BAZEL_INTERNAL_CONFIGURATION value
     * @property {number} LOADING_AND_ANALYSIS=7 LOADING_AND_ANALYSIS value
     * @property {number} EXECUTION=8 EXECUTION value
     * @property {number} HOST_MACHINE_RESOURCE_OPTIMIZATIONS=9 HOST_MACHINE_RESOURCE_OPTIMIZATIONS value
     * @property {number} EAGERNESS_TO_EXIT=10 EAGERNESS_TO_EXIT value
     * @property {number} BAZEL_MONITORING=11 BAZEL_MONITORING value
     * @property {number} TERMINAL_OUTPUT=12 TERMINAL_OUTPUT value
     * @property {number} ACTION_COMMAND_LINES=13 ACTION_COMMAND_LINES value
     * @property {number} TEST_RUNNER=14 TEST_RUNNER value
     */
    options.OptionEffectTag = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "UNKNOWN"] = 0;
        values[valuesById[1] = "NO_OP"] = 1;
        values[valuesById[2] = "LOSES_INCREMENTAL_STATE"] = 2;
        values[valuesById[3] = "CHANGES_INPUTS"] = 3;
        values[valuesById[4] = "AFFECTS_OUTPUTS"] = 4;
        values[valuesById[5] = "BUILD_FILE_SEMANTICS"] = 5;
        values[valuesById[6] = "BAZEL_INTERNAL_CONFIGURATION"] = 6;
        values[valuesById[7] = "LOADING_AND_ANALYSIS"] = 7;
        values[valuesById[8] = "EXECUTION"] = 8;
        values[valuesById[9] = "HOST_MACHINE_RESOURCE_OPTIMIZATIONS"] = 9;
        values[valuesById[10] = "EAGERNESS_TO_EXIT"] = 10;
        values[valuesById[11] = "BAZEL_MONITORING"] = 11;
        values[valuesById[12] = "TERMINAL_OUTPUT"] = 12;
        values[valuesById[13] = "ACTION_COMMAND_LINES"] = 13;
        values[valuesById[14] = "TEST_RUNNER"] = 14;
        return values;
    })();

    /**
     * OptionMetadataTag enum.
     * @name options.OptionMetadataTag
     * @enum {string}
     * @property {number} EXPERIMENTAL=0 EXPERIMENTAL value
     * @property {number} INCOMPATIBLE_CHANGE=1 INCOMPATIBLE_CHANGE value
     * @property {number} DEPRECATED=2 DEPRECATED value
     * @property {number} HIDDEN=3 HIDDEN value
     * @property {number} INTERNAL=4 INTERNAL value
     * @property {number} TRIGGERED_BY_ALL_INCOMPATIBLE_CHANGES=5 TRIGGERED_BY_ALL_INCOMPATIBLE_CHANGES value
     */
    options.OptionMetadataTag = (function() {
        const valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "EXPERIMENTAL"] = 0;
        values[valuesById[1] = "INCOMPATIBLE_CHANGE"] = 1;
        values[valuesById[2] = "DEPRECATED"] = 2;
        values[valuesById[3] = "HIDDEN"] = 3;
        values[valuesById[4] = "INTERNAL"] = 4;
        values[valuesById[5] = "TRIGGERED_BY_ALL_INCOMPATIBLE_CHANGES"] = 5;
        return values;
    })();

    return options;
})();

export const blaze = $root.blaze = (() => {

    /**
     * Namespace blaze.
     * @exports blaze
     * @namespace
     */
    const blaze = {};

    blaze.invocation_policy = (function() {

        /**
         * Namespace invocation_policy.
         * @memberof blaze
         * @namespace
         */
        const invocation_policy = {};

        invocation_policy.InvocationPolicy = (function() {

            /**
             * Properties of an InvocationPolicy.
             * @memberof blaze.invocation_policy
             * @interface IInvocationPolicy
             * @property {Array.<blaze.invocation_policy.IFlagPolicy>|null} [flagPolicies] InvocationPolicy flagPolicies
             */

            /**
             * Constructs a new InvocationPolicy.
             * @memberof blaze.invocation_policy
             * @classdesc Represents an InvocationPolicy.
             * @implements IInvocationPolicy
             * @constructor
             * @param {blaze.invocation_policy.IInvocationPolicy=} [properties] Properties to set
             */
            function InvocationPolicy(properties) {
                this.flagPolicies = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * InvocationPolicy flagPolicies.
             * @member {Array.<blaze.invocation_policy.IFlagPolicy>} flagPolicies
             * @memberof blaze.invocation_policy.InvocationPolicy
             * @instance
             */
            InvocationPolicy.prototype.flagPolicies = $util.emptyArray;

            /**
             * Creates a new InvocationPolicy instance using the specified properties.
             * @function create
             * @memberof blaze.invocation_policy.InvocationPolicy
             * @static
             * @param {blaze.invocation_policy.IInvocationPolicy=} [properties] Properties to set
             * @returns {blaze.invocation_policy.InvocationPolicy} InvocationPolicy instance
             */
            InvocationPolicy.create = function create(properties) {
                return new InvocationPolicy(properties);
            };

            /**
             * Encodes the specified InvocationPolicy message. Does not implicitly {@link blaze.invocation_policy.InvocationPolicy.verify|verify} messages.
             * @function encode
             * @memberof blaze.invocation_policy.InvocationPolicy
             * @static
             * @param {blaze.invocation_policy.IInvocationPolicy} message InvocationPolicy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InvocationPolicy.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.flagPolicies != null && message.flagPolicies.length)
                    for (let i = 0; i < message.flagPolicies.length; ++i)
                        $root.blaze.invocation_policy.FlagPolicy.encode(message.flagPolicies[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified InvocationPolicy message, length delimited. Does not implicitly {@link blaze.invocation_policy.InvocationPolicy.verify|verify} messages.
             * @function encodeDelimited
             * @memberof blaze.invocation_policy.InvocationPolicy
             * @static
             * @param {blaze.invocation_policy.IInvocationPolicy} message InvocationPolicy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            InvocationPolicy.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an InvocationPolicy message from the specified reader or buffer.
             * @function decode
             * @memberof blaze.invocation_policy.InvocationPolicy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {blaze.invocation_policy.InvocationPolicy} InvocationPolicy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InvocationPolicy.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.blaze.invocation_policy.InvocationPolicy();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.flagPolicies && message.flagPolicies.length))
                            message.flagPolicies = [];
                        message.flagPolicies.push($root.blaze.invocation_policy.FlagPolicy.decode(reader, reader.uint32()));
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an InvocationPolicy message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof blaze.invocation_policy.InvocationPolicy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {blaze.invocation_policy.InvocationPolicy} InvocationPolicy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            InvocationPolicy.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an InvocationPolicy message.
             * @function verify
             * @memberof blaze.invocation_policy.InvocationPolicy
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            InvocationPolicy.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.flagPolicies != null && message.hasOwnProperty("flagPolicies")) {
                    if (!Array.isArray(message.flagPolicies))
                        return "flagPolicies: array expected";
                    for (let i = 0; i < message.flagPolicies.length; ++i) {
                        let error = $root.blaze.invocation_policy.FlagPolicy.verify(message.flagPolicies[i]);
                        if (error)
                            return "flagPolicies." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an InvocationPolicy message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof blaze.invocation_policy.InvocationPolicy
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {blaze.invocation_policy.InvocationPolicy} InvocationPolicy
             */
            InvocationPolicy.fromObject = function fromObject(object) {
                if (object instanceof $root.blaze.invocation_policy.InvocationPolicy)
                    return object;
                let message = new $root.blaze.invocation_policy.InvocationPolicy();
                if (object.flagPolicies) {
                    if (!Array.isArray(object.flagPolicies))
                        throw TypeError(".blaze.invocation_policy.InvocationPolicy.flagPolicies: array expected");
                    message.flagPolicies = [];
                    for (let i = 0; i < object.flagPolicies.length; ++i) {
                        if (typeof object.flagPolicies[i] !== "object")
                            throw TypeError(".blaze.invocation_policy.InvocationPolicy.flagPolicies: object expected");
                        message.flagPolicies[i] = $root.blaze.invocation_policy.FlagPolicy.fromObject(object.flagPolicies[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an InvocationPolicy message. Also converts values to other types if specified.
             * @function toObject
             * @memberof blaze.invocation_policy.InvocationPolicy
             * @static
             * @param {blaze.invocation_policy.InvocationPolicy} message InvocationPolicy
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            InvocationPolicy.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.flagPolicies = [];
                if (message.flagPolicies && message.flagPolicies.length) {
                    object.flagPolicies = [];
                    for (let j = 0; j < message.flagPolicies.length; ++j)
                        object.flagPolicies[j] = $root.blaze.invocation_policy.FlagPolicy.toObject(message.flagPolicies[j], options);
                }
                return object;
            };

            /**
             * Converts this InvocationPolicy to JSON.
             * @function toJSON
             * @memberof blaze.invocation_policy.InvocationPolicy
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            InvocationPolicy.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return InvocationPolicy;
        })();

        invocation_policy.FlagPolicy = (function() {

            /**
             * Properties of a FlagPolicy.
             * @memberof blaze.invocation_policy
             * @interface IFlagPolicy
             * @property {string|null} [flagName] FlagPolicy flagName
             * @property {Array.<string>|null} [commands] FlagPolicy commands
             * @property {blaze.invocation_policy.ISetValue|null} [setValue] FlagPolicy setValue
             * @property {blaze.invocation_policy.IUseDefault|null} [useDefault] FlagPolicy useDefault
             * @property {blaze.invocation_policy.IDisallowValues|null} [disallowValues] FlagPolicy disallowValues
             * @property {blaze.invocation_policy.IAllowValues|null} [allowValues] FlagPolicy allowValues
             */

            /**
             * Constructs a new FlagPolicy.
             * @memberof blaze.invocation_policy
             * @classdesc Represents a FlagPolicy.
             * @implements IFlagPolicy
             * @constructor
             * @param {blaze.invocation_policy.IFlagPolicy=} [properties] Properties to set
             */
            function FlagPolicy(properties) {
                this.commands = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FlagPolicy flagName.
             * @member {string} flagName
             * @memberof blaze.invocation_policy.FlagPolicy
             * @instance
             */
            FlagPolicy.prototype.flagName = "";

            /**
             * FlagPolicy commands.
             * @member {Array.<string>} commands
             * @memberof blaze.invocation_policy.FlagPolicy
             * @instance
             */
            FlagPolicy.prototype.commands = $util.emptyArray;

            /**
             * FlagPolicy setValue.
             * @member {blaze.invocation_policy.ISetValue|null|undefined} setValue
             * @memberof blaze.invocation_policy.FlagPolicy
             * @instance
             */
            FlagPolicy.prototype.setValue = null;

            /**
             * FlagPolicy useDefault.
             * @member {blaze.invocation_policy.IUseDefault|null|undefined} useDefault
             * @memberof blaze.invocation_policy.FlagPolicy
             * @instance
             */
            FlagPolicy.prototype.useDefault = null;

            /**
             * FlagPolicy disallowValues.
             * @member {blaze.invocation_policy.IDisallowValues|null|undefined} disallowValues
             * @memberof blaze.invocation_policy.FlagPolicy
             * @instance
             */
            FlagPolicy.prototype.disallowValues = null;

            /**
             * FlagPolicy allowValues.
             * @member {blaze.invocation_policy.IAllowValues|null|undefined} allowValues
             * @memberof blaze.invocation_policy.FlagPolicy
             * @instance
             */
            FlagPolicy.prototype.allowValues = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * FlagPolicy operation.
             * @member {"setValue"|"useDefault"|"disallowValues"|"allowValues"|undefined} operation
             * @memberof blaze.invocation_policy.FlagPolicy
             * @instance
             */
            Object.defineProperty(FlagPolicy.prototype, "operation", {
                get: $util.oneOfGetter($oneOfFields = ["setValue", "useDefault", "disallowValues", "allowValues"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new FlagPolicy instance using the specified properties.
             * @function create
             * @memberof blaze.invocation_policy.FlagPolicy
             * @static
             * @param {blaze.invocation_policy.IFlagPolicy=} [properties] Properties to set
             * @returns {blaze.invocation_policy.FlagPolicy} FlagPolicy instance
             */
            FlagPolicy.create = function create(properties) {
                return new FlagPolicy(properties);
            };

            /**
             * Encodes the specified FlagPolicy message. Does not implicitly {@link blaze.invocation_policy.FlagPolicy.verify|verify} messages.
             * @function encode
             * @memberof blaze.invocation_policy.FlagPolicy
             * @static
             * @param {blaze.invocation_policy.IFlagPolicy} message FlagPolicy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FlagPolicy.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.flagName != null && message.hasOwnProperty("flagName"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.flagName);
                if (message.commands != null && message.commands.length)
                    for (let i = 0; i < message.commands.length; ++i)
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.commands[i]);
                if (message.setValue != null && message.hasOwnProperty("setValue"))
                    $root.blaze.invocation_policy.SetValue.encode(message.setValue, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.useDefault != null && message.hasOwnProperty("useDefault"))
                    $root.blaze.invocation_policy.UseDefault.encode(message.useDefault, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.disallowValues != null && message.hasOwnProperty("disallowValues"))
                    $root.blaze.invocation_policy.DisallowValues.encode(message.disallowValues, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.allowValues != null && message.hasOwnProperty("allowValues"))
                    $root.blaze.invocation_policy.AllowValues.encode(message.allowValues, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified FlagPolicy message, length delimited. Does not implicitly {@link blaze.invocation_policy.FlagPolicy.verify|verify} messages.
             * @function encodeDelimited
             * @memberof blaze.invocation_policy.FlagPolicy
             * @static
             * @param {blaze.invocation_policy.IFlagPolicy} message FlagPolicy message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FlagPolicy.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FlagPolicy message from the specified reader or buffer.
             * @function decode
             * @memberof blaze.invocation_policy.FlagPolicy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {blaze.invocation_policy.FlagPolicy} FlagPolicy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FlagPolicy.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.blaze.invocation_policy.FlagPolicy();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        message.flagName = reader.string();
                        break;
                    case 2:
                        if (!(message.commands && message.commands.length))
                            message.commands = [];
                        message.commands.push(reader.string());
                        break;
                    case 3:
                        message.setValue = $root.blaze.invocation_policy.SetValue.decode(reader, reader.uint32());
                        break;
                    case 4:
                        message.useDefault = $root.blaze.invocation_policy.UseDefault.decode(reader, reader.uint32());
                        break;
                    case 5:
                        message.disallowValues = $root.blaze.invocation_policy.DisallowValues.decode(reader, reader.uint32());
                        break;
                    case 6:
                        message.allowValues = $root.blaze.invocation_policy.AllowValues.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FlagPolicy message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof blaze.invocation_policy.FlagPolicy
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {blaze.invocation_policy.FlagPolicy} FlagPolicy
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FlagPolicy.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FlagPolicy message.
             * @function verify
             * @memberof blaze.invocation_policy.FlagPolicy
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FlagPolicy.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.flagName != null && message.hasOwnProperty("flagName"))
                    if (!$util.isString(message.flagName))
                        return "flagName: string expected";
                if (message.commands != null && message.hasOwnProperty("commands")) {
                    if (!Array.isArray(message.commands))
                        return "commands: array expected";
                    for (let i = 0; i < message.commands.length; ++i)
                        if (!$util.isString(message.commands[i]))
                            return "commands: string[] expected";
                }
                if (message.setValue != null && message.hasOwnProperty("setValue")) {
                    properties.operation = 1;
                    {
                        let error = $root.blaze.invocation_policy.SetValue.verify(message.setValue);
                        if (error)
                            return "setValue." + error;
                    }
                }
                if (message.useDefault != null && message.hasOwnProperty("useDefault")) {
                    if (properties.operation === 1)
                        return "operation: multiple values";
                    properties.operation = 1;
                    {
                        let error = $root.blaze.invocation_policy.UseDefault.verify(message.useDefault);
                        if (error)
                            return "useDefault." + error;
                    }
                }
                if (message.disallowValues != null && message.hasOwnProperty("disallowValues")) {
                    if (properties.operation === 1)
                        return "operation: multiple values";
                    properties.operation = 1;
                    {
                        let error = $root.blaze.invocation_policy.DisallowValues.verify(message.disallowValues);
                        if (error)
                            return "disallowValues." + error;
                    }
                }
                if (message.allowValues != null && message.hasOwnProperty("allowValues")) {
                    if (properties.operation === 1)
                        return "operation: multiple values";
                    properties.operation = 1;
                    {
                        let error = $root.blaze.invocation_policy.AllowValues.verify(message.allowValues);
                        if (error)
                            return "allowValues." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FlagPolicy message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof blaze.invocation_policy.FlagPolicy
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {blaze.invocation_policy.FlagPolicy} FlagPolicy
             */
            FlagPolicy.fromObject = function fromObject(object) {
                if (object instanceof $root.blaze.invocation_policy.FlagPolicy)
                    return object;
                let message = new $root.blaze.invocation_policy.FlagPolicy();
                if (object.flagName != null)
                    message.flagName = String(object.flagName);
                if (object.commands) {
                    if (!Array.isArray(object.commands))
                        throw TypeError(".blaze.invocation_policy.FlagPolicy.commands: array expected");
                    message.commands = [];
                    for (let i = 0; i < object.commands.length; ++i)
                        message.commands[i] = String(object.commands[i]);
                }
                if (object.setValue != null) {
                    if (typeof object.setValue !== "object")
                        throw TypeError(".blaze.invocation_policy.FlagPolicy.setValue: object expected");
                    message.setValue = $root.blaze.invocation_policy.SetValue.fromObject(object.setValue);
                }
                if (object.useDefault != null) {
                    if (typeof object.useDefault !== "object")
                        throw TypeError(".blaze.invocation_policy.FlagPolicy.useDefault: object expected");
                    message.useDefault = $root.blaze.invocation_policy.UseDefault.fromObject(object.useDefault);
                }
                if (object.disallowValues != null) {
                    if (typeof object.disallowValues !== "object")
                        throw TypeError(".blaze.invocation_policy.FlagPolicy.disallowValues: object expected");
                    message.disallowValues = $root.blaze.invocation_policy.DisallowValues.fromObject(object.disallowValues);
                }
                if (object.allowValues != null) {
                    if (typeof object.allowValues !== "object")
                        throw TypeError(".blaze.invocation_policy.FlagPolicy.allowValues: object expected");
                    message.allowValues = $root.blaze.invocation_policy.AllowValues.fromObject(object.allowValues);
                }
                return message;
            };

            /**
             * Creates a plain object from a FlagPolicy message. Also converts values to other types if specified.
             * @function toObject
             * @memberof blaze.invocation_policy.FlagPolicy
             * @static
             * @param {blaze.invocation_policy.FlagPolicy} message FlagPolicy
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FlagPolicy.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.commands = [];
                if (options.defaults)
                    object.flagName = "";
                if (message.flagName != null && message.hasOwnProperty("flagName"))
                    object.flagName = message.flagName;
                if (message.commands && message.commands.length) {
                    object.commands = [];
                    for (let j = 0; j < message.commands.length; ++j)
                        object.commands[j] = message.commands[j];
                }
                if (message.setValue != null && message.hasOwnProperty("setValue")) {
                    object.setValue = $root.blaze.invocation_policy.SetValue.toObject(message.setValue, options);
                    if (options.oneofs)
                        object.operation = "setValue";
                }
                if (message.useDefault != null && message.hasOwnProperty("useDefault")) {
                    object.useDefault = $root.blaze.invocation_policy.UseDefault.toObject(message.useDefault, options);
                    if (options.oneofs)
                        object.operation = "useDefault";
                }
                if (message.disallowValues != null && message.hasOwnProperty("disallowValues")) {
                    object.disallowValues = $root.blaze.invocation_policy.DisallowValues.toObject(message.disallowValues, options);
                    if (options.oneofs)
                        object.operation = "disallowValues";
                }
                if (message.allowValues != null && message.hasOwnProperty("allowValues")) {
                    object.allowValues = $root.blaze.invocation_policy.AllowValues.toObject(message.allowValues, options);
                    if (options.oneofs)
                        object.operation = "allowValues";
                }
                return object;
            };

            /**
             * Converts this FlagPolicy to JSON.
             * @function toJSON
             * @memberof blaze.invocation_policy.FlagPolicy
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FlagPolicy.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return FlagPolicy;
        })();

        invocation_policy.SetValue = (function() {

            /**
             * Properties of a SetValue.
             * @memberof blaze.invocation_policy
             * @interface ISetValue
             * @property {Array.<string>|null} [flagValue] SetValue flagValue
             * @property {boolean|null} [overridable] SetValue overridable
             * @property {boolean|null} [append] SetValue append
             */

            /**
             * Constructs a new SetValue.
             * @memberof blaze.invocation_policy
             * @classdesc Represents a SetValue.
             * @implements ISetValue
             * @constructor
             * @param {blaze.invocation_policy.ISetValue=} [properties] Properties to set
             */
            function SetValue(properties) {
                this.flagValue = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SetValue flagValue.
             * @member {Array.<string>} flagValue
             * @memberof blaze.invocation_policy.SetValue
             * @instance
             */
            SetValue.prototype.flagValue = $util.emptyArray;

            /**
             * SetValue overridable.
             * @member {boolean} overridable
             * @memberof blaze.invocation_policy.SetValue
             * @instance
             */
            SetValue.prototype.overridable = false;

            /**
             * SetValue append.
             * @member {boolean} append
             * @memberof blaze.invocation_policy.SetValue
             * @instance
             */
            SetValue.prototype.append = false;

            /**
             * Creates a new SetValue instance using the specified properties.
             * @function create
             * @memberof blaze.invocation_policy.SetValue
             * @static
             * @param {blaze.invocation_policy.ISetValue=} [properties] Properties to set
             * @returns {blaze.invocation_policy.SetValue} SetValue instance
             */
            SetValue.create = function create(properties) {
                return new SetValue(properties);
            };

            /**
             * Encodes the specified SetValue message. Does not implicitly {@link blaze.invocation_policy.SetValue.verify|verify} messages.
             * @function encode
             * @memberof blaze.invocation_policy.SetValue
             * @static
             * @param {blaze.invocation_policy.ISetValue} message SetValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetValue.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.flagValue != null && message.flagValue.length)
                    for (let i = 0; i < message.flagValue.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.flagValue[i]);
                if (message.overridable != null && message.hasOwnProperty("overridable"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.overridable);
                if (message.append != null && message.hasOwnProperty("append"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.append);
                return writer;
            };

            /**
             * Encodes the specified SetValue message, length delimited. Does not implicitly {@link blaze.invocation_policy.SetValue.verify|verify} messages.
             * @function encodeDelimited
             * @memberof blaze.invocation_policy.SetValue
             * @static
             * @param {blaze.invocation_policy.ISetValue} message SetValue message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SetValue.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SetValue message from the specified reader or buffer.
             * @function decode
             * @memberof blaze.invocation_policy.SetValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {blaze.invocation_policy.SetValue} SetValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetValue.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.blaze.invocation_policy.SetValue();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.flagValue && message.flagValue.length))
                            message.flagValue = [];
                        message.flagValue.push(reader.string());
                        break;
                    case 2:
                        message.overridable = reader.bool();
                        break;
                    case 3:
                        message.append = reader.bool();
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SetValue message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof blaze.invocation_policy.SetValue
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {blaze.invocation_policy.SetValue} SetValue
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SetValue.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SetValue message.
             * @function verify
             * @memberof blaze.invocation_policy.SetValue
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SetValue.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.flagValue != null && message.hasOwnProperty("flagValue")) {
                    if (!Array.isArray(message.flagValue))
                        return "flagValue: array expected";
                    for (let i = 0; i < message.flagValue.length; ++i)
                        if (!$util.isString(message.flagValue[i]))
                            return "flagValue: string[] expected";
                }
                if (message.overridable != null && message.hasOwnProperty("overridable"))
                    if (typeof message.overridable !== "boolean")
                        return "overridable: boolean expected";
                if (message.append != null && message.hasOwnProperty("append"))
                    if (typeof message.append !== "boolean")
                        return "append: boolean expected";
                return null;
            };

            /**
             * Creates a SetValue message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof blaze.invocation_policy.SetValue
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {blaze.invocation_policy.SetValue} SetValue
             */
            SetValue.fromObject = function fromObject(object) {
                if (object instanceof $root.blaze.invocation_policy.SetValue)
                    return object;
                let message = new $root.blaze.invocation_policy.SetValue();
                if (object.flagValue) {
                    if (!Array.isArray(object.flagValue))
                        throw TypeError(".blaze.invocation_policy.SetValue.flagValue: array expected");
                    message.flagValue = [];
                    for (let i = 0; i < object.flagValue.length; ++i)
                        message.flagValue[i] = String(object.flagValue[i]);
                }
                if (object.overridable != null)
                    message.overridable = Boolean(object.overridable);
                if (object.append != null)
                    message.append = Boolean(object.append);
                return message;
            };

            /**
             * Creates a plain object from a SetValue message. Also converts values to other types if specified.
             * @function toObject
             * @memberof blaze.invocation_policy.SetValue
             * @static
             * @param {blaze.invocation_policy.SetValue} message SetValue
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SetValue.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.flagValue = [];
                if (options.defaults) {
                    object.overridable = false;
                    object.append = false;
                }
                if (message.flagValue && message.flagValue.length) {
                    object.flagValue = [];
                    for (let j = 0; j < message.flagValue.length; ++j)
                        object.flagValue[j] = message.flagValue[j];
                }
                if (message.overridable != null && message.hasOwnProperty("overridable"))
                    object.overridable = message.overridable;
                if (message.append != null && message.hasOwnProperty("append"))
                    object.append = message.append;
                return object;
            };

            /**
             * Converts this SetValue to JSON.
             * @function toJSON
             * @memberof blaze.invocation_policy.SetValue
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SetValue.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return SetValue;
        })();

        invocation_policy.UseDefault = (function() {

            /**
             * Properties of a UseDefault.
             * @memberof blaze.invocation_policy
             * @interface IUseDefault
             */

            /**
             * Constructs a new UseDefault.
             * @memberof blaze.invocation_policy
             * @classdesc Represents a UseDefault.
             * @implements IUseDefault
             * @constructor
             * @param {blaze.invocation_policy.IUseDefault=} [properties] Properties to set
             */
            function UseDefault(properties) {
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UseDefault instance using the specified properties.
             * @function create
             * @memberof blaze.invocation_policy.UseDefault
             * @static
             * @param {blaze.invocation_policy.IUseDefault=} [properties] Properties to set
             * @returns {blaze.invocation_policy.UseDefault} UseDefault instance
             */
            UseDefault.create = function create(properties) {
                return new UseDefault(properties);
            };

            /**
             * Encodes the specified UseDefault message. Does not implicitly {@link blaze.invocation_policy.UseDefault.verify|verify} messages.
             * @function encode
             * @memberof blaze.invocation_policy.UseDefault
             * @static
             * @param {blaze.invocation_policy.IUseDefault} message UseDefault message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UseDefault.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UseDefault message, length delimited. Does not implicitly {@link blaze.invocation_policy.UseDefault.verify|verify} messages.
             * @function encodeDelimited
             * @memberof blaze.invocation_policy.UseDefault
             * @static
             * @param {blaze.invocation_policy.IUseDefault} message UseDefault message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UseDefault.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UseDefault message from the specified reader or buffer.
             * @function decode
             * @memberof blaze.invocation_policy.UseDefault
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {blaze.invocation_policy.UseDefault} UseDefault
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UseDefault.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.blaze.invocation_policy.UseDefault();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UseDefault message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof blaze.invocation_policy.UseDefault
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {blaze.invocation_policy.UseDefault} UseDefault
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UseDefault.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UseDefault message.
             * @function verify
             * @memberof blaze.invocation_policy.UseDefault
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UseDefault.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a UseDefault message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof blaze.invocation_policy.UseDefault
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {blaze.invocation_policy.UseDefault} UseDefault
             */
            UseDefault.fromObject = function fromObject(object) {
                if (object instanceof $root.blaze.invocation_policy.UseDefault)
                    return object;
                return new $root.blaze.invocation_policy.UseDefault();
            };

            /**
             * Creates a plain object from a UseDefault message. Also converts values to other types if specified.
             * @function toObject
             * @memberof blaze.invocation_policy.UseDefault
             * @static
             * @param {blaze.invocation_policy.UseDefault} message UseDefault
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UseDefault.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UseDefault to JSON.
             * @function toJSON
             * @memberof blaze.invocation_policy.UseDefault
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UseDefault.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return UseDefault;
        })();

        invocation_policy.DisallowValues = (function() {

            /**
             * Properties of a DisallowValues.
             * @memberof blaze.invocation_policy
             * @interface IDisallowValues
             * @property {Array.<string>|null} [disallowedValues] DisallowValues disallowedValues
             * @property {string|null} [newValue] DisallowValues newValue
             * @property {blaze.invocation_policy.IUseDefault|null} [useDefault] DisallowValues useDefault
             */

            /**
             * Constructs a new DisallowValues.
             * @memberof blaze.invocation_policy
             * @classdesc Represents a DisallowValues.
             * @implements IDisallowValues
             * @constructor
             * @param {blaze.invocation_policy.IDisallowValues=} [properties] Properties to set
             */
            function DisallowValues(properties) {
                this.disallowedValues = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DisallowValues disallowedValues.
             * @member {Array.<string>} disallowedValues
             * @memberof blaze.invocation_policy.DisallowValues
             * @instance
             */
            DisallowValues.prototype.disallowedValues = $util.emptyArray;

            /**
             * DisallowValues newValue.
             * @member {string} newValue
             * @memberof blaze.invocation_policy.DisallowValues
             * @instance
             */
            DisallowValues.prototype.newValue = "";

            /**
             * DisallowValues useDefault.
             * @member {blaze.invocation_policy.IUseDefault|null|undefined} useDefault
             * @memberof blaze.invocation_policy.DisallowValues
             * @instance
             */
            DisallowValues.prototype.useDefault = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * DisallowValues replacementValue.
             * @member {"newValue"|"useDefault"|undefined} replacementValue
             * @memberof blaze.invocation_policy.DisallowValues
             * @instance
             */
            Object.defineProperty(DisallowValues.prototype, "replacementValue", {
                get: $util.oneOfGetter($oneOfFields = ["newValue", "useDefault"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new DisallowValues instance using the specified properties.
             * @function create
             * @memberof blaze.invocation_policy.DisallowValues
             * @static
             * @param {blaze.invocation_policy.IDisallowValues=} [properties] Properties to set
             * @returns {blaze.invocation_policy.DisallowValues} DisallowValues instance
             */
            DisallowValues.create = function create(properties) {
                return new DisallowValues(properties);
            };

            /**
             * Encodes the specified DisallowValues message. Does not implicitly {@link blaze.invocation_policy.DisallowValues.verify|verify} messages.
             * @function encode
             * @memberof blaze.invocation_policy.DisallowValues
             * @static
             * @param {blaze.invocation_policy.IDisallowValues} message DisallowValues message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DisallowValues.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.disallowedValues != null && message.disallowedValues.length)
                    for (let i = 0; i < message.disallowedValues.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.disallowedValues[i]);
                if (message.newValue != null && message.hasOwnProperty("newValue"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.newValue);
                if (message.useDefault != null && message.hasOwnProperty("useDefault"))
                    $root.blaze.invocation_policy.UseDefault.encode(message.useDefault, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified DisallowValues message, length delimited. Does not implicitly {@link blaze.invocation_policy.DisallowValues.verify|verify} messages.
             * @function encodeDelimited
             * @memberof blaze.invocation_policy.DisallowValues
             * @static
             * @param {blaze.invocation_policy.IDisallowValues} message DisallowValues message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DisallowValues.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DisallowValues message from the specified reader or buffer.
             * @function decode
             * @memberof blaze.invocation_policy.DisallowValues
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {blaze.invocation_policy.DisallowValues} DisallowValues
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DisallowValues.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.blaze.invocation_policy.DisallowValues();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.disallowedValues && message.disallowedValues.length))
                            message.disallowedValues = [];
                        message.disallowedValues.push(reader.string());
                        break;
                    case 3:
                        message.newValue = reader.string();
                        break;
                    case 4:
                        message.useDefault = $root.blaze.invocation_policy.UseDefault.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DisallowValues message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof blaze.invocation_policy.DisallowValues
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {blaze.invocation_policy.DisallowValues} DisallowValues
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DisallowValues.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DisallowValues message.
             * @function verify
             * @memberof blaze.invocation_policy.DisallowValues
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DisallowValues.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.disallowedValues != null && message.hasOwnProperty("disallowedValues")) {
                    if (!Array.isArray(message.disallowedValues))
                        return "disallowedValues: array expected";
                    for (let i = 0; i < message.disallowedValues.length; ++i)
                        if (!$util.isString(message.disallowedValues[i]))
                            return "disallowedValues: string[] expected";
                }
                if (message.newValue != null && message.hasOwnProperty("newValue")) {
                    properties.replacementValue = 1;
                    if (!$util.isString(message.newValue))
                        return "newValue: string expected";
                }
                if (message.useDefault != null && message.hasOwnProperty("useDefault")) {
                    if (properties.replacementValue === 1)
                        return "replacementValue: multiple values";
                    properties.replacementValue = 1;
                    {
                        let error = $root.blaze.invocation_policy.UseDefault.verify(message.useDefault);
                        if (error)
                            return "useDefault." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a DisallowValues message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof blaze.invocation_policy.DisallowValues
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {blaze.invocation_policy.DisallowValues} DisallowValues
             */
            DisallowValues.fromObject = function fromObject(object) {
                if (object instanceof $root.blaze.invocation_policy.DisallowValues)
                    return object;
                let message = new $root.blaze.invocation_policy.DisallowValues();
                if (object.disallowedValues) {
                    if (!Array.isArray(object.disallowedValues))
                        throw TypeError(".blaze.invocation_policy.DisallowValues.disallowedValues: array expected");
                    message.disallowedValues = [];
                    for (let i = 0; i < object.disallowedValues.length; ++i)
                        message.disallowedValues[i] = String(object.disallowedValues[i]);
                }
                if (object.newValue != null)
                    message.newValue = String(object.newValue);
                if (object.useDefault != null) {
                    if (typeof object.useDefault !== "object")
                        throw TypeError(".blaze.invocation_policy.DisallowValues.useDefault: object expected");
                    message.useDefault = $root.blaze.invocation_policy.UseDefault.fromObject(object.useDefault);
                }
                return message;
            };

            /**
             * Creates a plain object from a DisallowValues message. Also converts values to other types if specified.
             * @function toObject
             * @memberof blaze.invocation_policy.DisallowValues
             * @static
             * @param {blaze.invocation_policy.DisallowValues} message DisallowValues
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DisallowValues.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.disallowedValues = [];
                if (message.disallowedValues && message.disallowedValues.length) {
                    object.disallowedValues = [];
                    for (let j = 0; j < message.disallowedValues.length; ++j)
                        object.disallowedValues[j] = message.disallowedValues[j];
                }
                if (message.newValue != null && message.hasOwnProperty("newValue")) {
                    object.newValue = message.newValue;
                    if (options.oneofs)
                        object.replacementValue = "newValue";
                }
                if (message.useDefault != null && message.hasOwnProperty("useDefault")) {
                    object.useDefault = $root.blaze.invocation_policy.UseDefault.toObject(message.useDefault, options);
                    if (options.oneofs)
                        object.replacementValue = "useDefault";
                }
                return object;
            };

            /**
             * Converts this DisallowValues to JSON.
             * @function toJSON
             * @memberof blaze.invocation_policy.DisallowValues
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DisallowValues.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DisallowValues;
        })();

        invocation_policy.AllowValues = (function() {

            /**
             * Properties of an AllowValues.
             * @memberof blaze.invocation_policy
             * @interface IAllowValues
             * @property {Array.<string>|null} [allowedValues] AllowValues allowedValues
             * @property {string|null} [newValue] AllowValues newValue
             * @property {blaze.invocation_policy.IUseDefault|null} [useDefault] AllowValues useDefault
             */

            /**
             * Constructs a new AllowValues.
             * @memberof blaze.invocation_policy
             * @classdesc Represents an AllowValues.
             * @implements IAllowValues
             * @constructor
             * @param {blaze.invocation_policy.IAllowValues=} [properties] Properties to set
             */
            function AllowValues(properties) {
                this.allowedValues = [];
                if (properties)
                    for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AllowValues allowedValues.
             * @member {Array.<string>} allowedValues
             * @memberof blaze.invocation_policy.AllowValues
             * @instance
             */
            AllowValues.prototype.allowedValues = $util.emptyArray;

            /**
             * AllowValues newValue.
             * @member {string} newValue
             * @memberof blaze.invocation_policy.AllowValues
             * @instance
             */
            AllowValues.prototype.newValue = "";

            /**
             * AllowValues useDefault.
             * @member {blaze.invocation_policy.IUseDefault|null|undefined} useDefault
             * @memberof blaze.invocation_policy.AllowValues
             * @instance
             */
            AllowValues.prototype.useDefault = null;

            // OneOf field names bound to virtual getters and setters
            let $oneOfFields;

            /**
             * AllowValues replacementValue.
             * @member {"newValue"|"useDefault"|undefined} replacementValue
             * @memberof blaze.invocation_policy.AllowValues
             * @instance
             */
            Object.defineProperty(AllowValues.prototype, "replacementValue", {
                get: $util.oneOfGetter($oneOfFields = ["newValue", "useDefault"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new AllowValues instance using the specified properties.
             * @function create
             * @memberof blaze.invocation_policy.AllowValues
             * @static
             * @param {blaze.invocation_policy.IAllowValues=} [properties] Properties to set
             * @returns {blaze.invocation_policy.AllowValues} AllowValues instance
             */
            AllowValues.create = function create(properties) {
                return new AllowValues(properties);
            };

            /**
             * Encodes the specified AllowValues message. Does not implicitly {@link blaze.invocation_policy.AllowValues.verify|verify} messages.
             * @function encode
             * @memberof blaze.invocation_policy.AllowValues
             * @static
             * @param {blaze.invocation_policy.IAllowValues} message AllowValues message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AllowValues.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.allowedValues != null && message.allowedValues.length)
                    for (let i = 0; i < message.allowedValues.length; ++i)
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.allowedValues[i]);
                if (message.newValue != null && message.hasOwnProperty("newValue"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.newValue);
                if (message.useDefault != null && message.hasOwnProperty("useDefault"))
                    $root.blaze.invocation_policy.UseDefault.encode(message.useDefault, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AllowValues message, length delimited. Does not implicitly {@link blaze.invocation_policy.AllowValues.verify|verify} messages.
             * @function encodeDelimited
             * @memberof blaze.invocation_policy.AllowValues
             * @static
             * @param {blaze.invocation_policy.IAllowValues} message AllowValues message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AllowValues.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AllowValues message from the specified reader or buffer.
             * @function decode
             * @memberof blaze.invocation_policy.AllowValues
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {blaze.invocation_policy.AllowValues} AllowValues
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AllowValues.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                let end = length === undefined ? reader.len : reader.pos + length, message = new $root.blaze.invocation_policy.AllowValues();
                while (reader.pos < end) {
                    let tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1:
                        if (!(message.allowedValues && message.allowedValues.length))
                            message.allowedValues = [];
                        message.allowedValues.push(reader.string());
                        break;
                    case 3:
                        message.newValue = reader.string();
                        break;
                    case 4:
                        message.useDefault = $root.blaze.invocation_policy.UseDefault.decode(reader, reader.uint32());
                        break;
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AllowValues message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof blaze.invocation_policy.AllowValues
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {blaze.invocation_policy.AllowValues} AllowValues
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AllowValues.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AllowValues message.
             * @function verify
             * @memberof blaze.invocation_policy.AllowValues
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AllowValues.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                let properties = {};
                if (message.allowedValues != null && message.hasOwnProperty("allowedValues")) {
                    if (!Array.isArray(message.allowedValues))
                        return "allowedValues: array expected";
                    for (let i = 0; i < message.allowedValues.length; ++i)
                        if (!$util.isString(message.allowedValues[i]))
                            return "allowedValues: string[] expected";
                }
                if (message.newValue != null && message.hasOwnProperty("newValue")) {
                    properties.replacementValue = 1;
                    if (!$util.isString(message.newValue))
                        return "newValue: string expected";
                }
                if (message.useDefault != null && message.hasOwnProperty("useDefault")) {
                    if (properties.replacementValue === 1)
                        return "replacementValue: multiple values";
                    properties.replacementValue = 1;
                    {
                        let error = $root.blaze.invocation_policy.UseDefault.verify(message.useDefault);
                        if (error)
                            return "useDefault." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an AllowValues message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof blaze.invocation_policy.AllowValues
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {blaze.invocation_policy.AllowValues} AllowValues
             */
            AllowValues.fromObject = function fromObject(object) {
                if (object instanceof $root.blaze.invocation_policy.AllowValues)
                    return object;
                let message = new $root.blaze.invocation_policy.AllowValues();
                if (object.allowedValues) {
                    if (!Array.isArray(object.allowedValues))
                        throw TypeError(".blaze.invocation_policy.AllowValues.allowedValues: array expected");
                    message.allowedValues = [];
                    for (let i = 0; i < object.allowedValues.length; ++i)
                        message.allowedValues[i] = String(object.allowedValues[i]);
                }
                if (object.newValue != null)
                    message.newValue = String(object.newValue);
                if (object.useDefault != null) {
                    if (typeof object.useDefault !== "object")
                        throw TypeError(".blaze.invocation_policy.AllowValues.useDefault: object expected");
                    message.useDefault = $root.blaze.invocation_policy.UseDefault.fromObject(object.useDefault);
                }
                return message;
            };

            /**
             * Creates a plain object from an AllowValues message. Also converts values to other types if specified.
             * @function toObject
             * @memberof blaze.invocation_policy.AllowValues
             * @static
             * @param {blaze.invocation_policy.AllowValues} message AllowValues
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AllowValues.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                let object = {};
                if (options.arrays || options.defaults)
                    object.allowedValues = [];
                if (message.allowedValues && message.allowedValues.length) {
                    object.allowedValues = [];
                    for (let j = 0; j < message.allowedValues.length; ++j)
                        object.allowedValues[j] = message.allowedValues[j];
                }
                if (message.newValue != null && message.hasOwnProperty("newValue")) {
                    object.newValue = message.newValue;
                    if (options.oneofs)
                        object.replacementValue = "newValue";
                }
                if (message.useDefault != null && message.hasOwnProperty("useDefault")) {
                    object.useDefault = $root.blaze.invocation_policy.UseDefault.toObject(message.useDefault, options);
                    if (options.oneofs)
                        object.replacementValue = "useDefault";
                }
                return object;
            };

            /**
             * Converts this AllowValues to JSON.
             * @function toJSON
             * @memberof blaze.invocation_policy.AllowValues
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AllowValues.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AllowValues;
        })();

        return invocation_policy;
    })();

    return blaze;
})();

export { $root as default };

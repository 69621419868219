/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const context = $root.context = (() => {

    /**
     * Namespace context.
     * @exports context
     * @namespace
     */
    const context = {};

    context.RequestContext = (function() {

        /**
         * Properties of a RequestContext.
         * @memberof context
         * @interface IRequestContext
         * @property {user.IUserId|null} [userId] RequestContext userId
         */

        /**
         * Constructs a new RequestContext.
         * @memberof context
         * @classdesc Represents a RequestContext.
         * @implements IRequestContext
         * @constructor
         * @param {context.IRequestContext=} [properties] Properties to set
         */
        function RequestContext(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * RequestContext userId.
         * @member {user.IUserId|null|undefined} userId
         * @memberof context.RequestContext
         * @instance
         */
        RequestContext.prototype.userId = null;

        /**
         * Creates a new RequestContext instance using the specified properties.
         * @function create
         * @memberof context.RequestContext
         * @static
         * @param {context.IRequestContext=} [properties] Properties to set
         * @returns {context.RequestContext} RequestContext instance
         */
        RequestContext.create = function create(properties) {
            return new RequestContext(properties);
        };

        /**
         * Encodes the specified RequestContext message. Does not implicitly {@link context.RequestContext.verify|verify} messages.
         * @function encode
         * @memberof context.RequestContext
         * @static
         * @param {context.IRequestContext} message RequestContext message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestContext.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && message.hasOwnProperty("userId"))
                $root.user.UserId.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified RequestContext message, length delimited. Does not implicitly {@link context.RequestContext.verify|verify} messages.
         * @function encodeDelimited
         * @memberof context.RequestContext
         * @static
         * @param {context.IRequestContext} message RequestContext message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        RequestContext.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a RequestContext message from the specified reader or buffer.
         * @function decode
         * @memberof context.RequestContext
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {context.RequestContext} RequestContext
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestContext.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.context.RequestContext();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.user.UserId.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a RequestContext message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof context.RequestContext
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {context.RequestContext} RequestContext
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        RequestContext.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a RequestContext message.
         * @function verify
         * @memberof context.RequestContext
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        RequestContext.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId")) {
                let error = $root.user.UserId.verify(message.userId);
                if (error)
                    return "userId." + error;
            }
            return null;
        };

        /**
         * Creates a RequestContext message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof context.RequestContext
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {context.RequestContext} RequestContext
         */
        RequestContext.fromObject = function fromObject(object) {
            if (object instanceof $root.context.RequestContext)
                return object;
            let message = new $root.context.RequestContext();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".context.RequestContext.userId: object expected");
                message.userId = $root.user.UserId.fromObject(object.userId);
            }
            return message;
        };

        /**
         * Creates a plain object from a RequestContext message. Also converts values to other types if specified.
         * @function toObject
         * @memberof context.RequestContext
         * @static
         * @param {context.RequestContext} message RequestContext
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        RequestContext.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.userId = null;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.user.UserId.toObject(message.userId, options);
            return object;
        };

        /**
         * Converts this RequestContext to JSON.
         * @function toJSON
         * @memberof context.RequestContext
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        RequestContext.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RequestContext;
    })();

    context.ResponseContext = (function() {

        /**
         * Properties of a ResponseContext.
         * @memberof context
         * @interface IResponseContext
         */

        /**
         * Constructs a new ResponseContext.
         * @memberof context
         * @classdesc Represents a ResponseContext.
         * @implements IResponseContext
         * @constructor
         * @param {context.IResponseContext=} [properties] Properties to set
         */
        function ResponseContext(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new ResponseContext instance using the specified properties.
         * @function create
         * @memberof context.ResponseContext
         * @static
         * @param {context.IResponseContext=} [properties] Properties to set
         * @returns {context.ResponseContext} ResponseContext instance
         */
        ResponseContext.create = function create(properties) {
            return new ResponseContext(properties);
        };

        /**
         * Encodes the specified ResponseContext message. Does not implicitly {@link context.ResponseContext.verify|verify} messages.
         * @function encode
         * @memberof context.ResponseContext
         * @static
         * @param {context.IResponseContext} message ResponseContext message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseContext.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified ResponseContext message, length delimited. Does not implicitly {@link context.ResponseContext.verify|verify} messages.
         * @function encodeDelimited
         * @memberof context.ResponseContext
         * @static
         * @param {context.IResponseContext} message ResponseContext message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ResponseContext.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a ResponseContext message from the specified reader or buffer.
         * @function decode
         * @memberof context.ResponseContext
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {context.ResponseContext} ResponseContext
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseContext.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.context.ResponseContext();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a ResponseContext message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof context.ResponseContext
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {context.ResponseContext} ResponseContext
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ResponseContext.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a ResponseContext message.
         * @function verify
         * @memberof context.ResponseContext
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ResponseContext.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a ResponseContext message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof context.ResponseContext
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {context.ResponseContext} ResponseContext
         */
        ResponseContext.fromObject = function fromObject(object) {
            if (object instanceof $root.context.ResponseContext)
                return object;
            return new $root.context.ResponseContext();
        };

        /**
         * Creates a plain object from a ResponseContext message. Also converts values to other types if specified.
         * @function toObject
         * @memberof context.ResponseContext
         * @static
         * @param {context.ResponseContext} message ResponseContext
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ResponseContext.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this ResponseContext to JSON.
         * @function toJSON
         * @memberof context.ResponseContext
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ResponseContext.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ResponseContext;
    })();

    return context;
})();

export const user = $root.user = (() => {

    /**
     * Namespace user.
     * @exports user
     * @namespace
     */
    const user = {};

    user.GetUserRequest = (function() {

        /**
         * Properties of a GetUserRequest.
         * @memberof user
         * @interface IGetUserRequest
         * @property {user.IUserId|null} [userId] GetUserRequest userId
         */

        /**
         * Constructs a new GetUserRequest.
         * @memberof user
         * @classdesc Represents a GetUserRequest.
         * @implements IGetUserRequest
         * @constructor
         * @param {user.IGetUserRequest=} [properties] Properties to set
         */
        function GetUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetUserRequest userId.
         * @member {user.IUserId|null|undefined} userId
         * @memberof user.GetUserRequest
         * @instance
         */
        GetUserRequest.prototype.userId = null;

        /**
         * Creates a new GetUserRequest instance using the specified properties.
         * @function create
         * @memberof user.GetUserRequest
         * @static
         * @param {user.IGetUserRequest=} [properties] Properties to set
         * @returns {user.GetUserRequest} GetUserRequest instance
         */
        GetUserRequest.create = function create(properties) {
            return new GetUserRequest(properties);
        };

        /**
         * Encodes the specified GetUserRequest message. Does not implicitly {@link user.GetUserRequest.verify|verify} messages.
         * @function encode
         * @memberof user.GetUserRequest
         * @static
         * @param {user.IGetUserRequest} message GetUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && message.hasOwnProperty("userId"))
                $root.user.UserId.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetUserRequest message, length delimited. Does not implicitly {@link user.GetUserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user.GetUserRequest
         * @static
         * @param {user.IGetUserRequest} message GetUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof user.GetUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.GetUserRequest} GetUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.GetUserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.user.UserId.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetUserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user.GetUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user.GetUserRequest} GetUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetUserRequest message.
         * @function verify
         * @memberof user.GetUserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetUserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId")) {
                let error = $root.user.UserId.verify(message.userId);
                if (error)
                    return "userId." + error;
            }
            return null;
        };

        /**
         * Creates a GetUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.GetUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.GetUserRequest} GetUserRequest
         */
        GetUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.user.GetUserRequest)
                return object;
            let message = new $root.user.GetUserRequest();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".user.GetUserRequest.userId: object expected");
                message.userId = $root.user.UserId.fromObject(object.userId);
            }
            return message;
        };

        /**
         * Creates a plain object from a GetUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.GetUserRequest
         * @static
         * @param {user.GetUserRequest} message GetUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetUserRequest.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.userId = null;
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.user.UserId.toObject(message.userId, options);
            return object;
        };

        /**
         * Converts this GetUserRequest to JSON.
         * @function toJSON
         * @memberof user.GetUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetUserRequest;
    })();

    user.GetUserResponse = (function() {

        /**
         * Properties of a GetUserResponse.
         * @memberof user
         * @interface IGetUserResponse
         * @property {user.IDisplayUser|null} [displayUser] GetUserResponse displayUser
         * @property {Array.<grp.IGroup>|null} [userGroup] GetUserResponse userGroup
         */

        /**
         * Constructs a new GetUserResponse.
         * @memberof user
         * @classdesc Represents a GetUserResponse.
         * @implements IGetUserResponse
         * @constructor
         * @param {user.IGetUserResponse=} [properties] Properties to set
         */
        function GetUserResponse(properties) {
            this.userGroup = [];
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * GetUserResponse displayUser.
         * @member {user.IDisplayUser|null|undefined} displayUser
         * @memberof user.GetUserResponse
         * @instance
         */
        GetUserResponse.prototype.displayUser = null;

        /**
         * GetUserResponse userGroup.
         * @member {Array.<grp.IGroup>} userGroup
         * @memberof user.GetUserResponse
         * @instance
         */
        GetUserResponse.prototype.userGroup = $util.emptyArray;

        /**
         * Creates a new GetUserResponse instance using the specified properties.
         * @function create
         * @memberof user.GetUserResponse
         * @static
         * @param {user.IGetUserResponse=} [properties] Properties to set
         * @returns {user.GetUserResponse} GetUserResponse instance
         */
        GetUserResponse.create = function create(properties) {
            return new GetUserResponse(properties);
        };

        /**
         * Encodes the specified GetUserResponse message. Does not implicitly {@link user.GetUserResponse.verify|verify} messages.
         * @function encode
         * @memberof user.GetUserResponse
         * @static
         * @param {user.IGetUserResponse} message GetUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.displayUser != null && message.hasOwnProperty("displayUser"))
                $root.user.DisplayUser.encode(message.displayUser, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.userGroup != null && message.userGroup.length)
                for (let i = 0; i < message.userGroup.length; ++i)
                    $root.grp.Group.encode(message.userGroup[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified GetUserResponse message, length delimited. Does not implicitly {@link user.GetUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user.GetUserResponse
         * @static
         * @param {user.IGetUserResponse} message GetUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        GetUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a GetUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof user.GetUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.GetUserResponse} GetUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.GetUserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.displayUser = $root.user.DisplayUser.decode(reader, reader.uint32());
                    break;
                case 2:
                    if (!(message.userGroup && message.userGroup.length))
                        message.userGroup = [];
                    message.userGroup.push($root.grp.Group.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a GetUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user.GetUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user.GetUserResponse} GetUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        GetUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a GetUserResponse message.
         * @function verify
         * @memberof user.GetUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        GetUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.displayUser != null && message.hasOwnProperty("displayUser")) {
                let error = $root.user.DisplayUser.verify(message.displayUser);
                if (error)
                    return "displayUser." + error;
            }
            if (message.userGroup != null && message.hasOwnProperty("userGroup")) {
                if (!Array.isArray(message.userGroup))
                    return "userGroup: array expected";
                for (let i = 0; i < message.userGroup.length; ++i) {
                    let error = $root.grp.Group.verify(message.userGroup[i]);
                    if (error)
                        return "userGroup." + error;
                }
            }
            return null;
        };

        /**
         * Creates a GetUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.GetUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.GetUserResponse} GetUserResponse
         */
        GetUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.user.GetUserResponse)
                return object;
            let message = new $root.user.GetUserResponse();
            if (object.displayUser != null) {
                if (typeof object.displayUser !== "object")
                    throw TypeError(".user.GetUserResponse.displayUser: object expected");
                message.displayUser = $root.user.DisplayUser.fromObject(object.displayUser);
            }
            if (object.userGroup) {
                if (!Array.isArray(object.userGroup))
                    throw TypeError(".user.GetUserResponse.userGroup: array expected");
                message.userGroup = [];
                for (let i = 0; i < object.userGroup.length; ++i) {
                    if (typeof object.userGroup[i] !== "object")
                        throw TypeError(".user.GetUserResponse.userGroup: object expected");
                    message.userGroup[i] = $root.grp.Group.fromObject(object.userGroup[i]);
                }
            }
            return message;
        };

        /**
         * Creates a plain object from a GetUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.GetUserResponse
         * @static
         * @param {user.GetUserResponse} message GetUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        GetUserResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.arrays || options.defaults)
                object.userGroup = [];
            if (options.defaults)
                object.displayUser = null;
            if (message.displayUser != null && message.hasOwnProperty("displayUser"))
                object.displayUser = $root.user.DisplayUser.toObject(message.displayUser, options);
            if (message.userGroup && message.userGroup.length) {
                object.userGroup = [];
                for (let j = 0; j < message.userGroup.length; ++j)
                    object.userGroup[j] = $root.grp.Group.toObject(message.userGroup[j], options);
            }
            return object;
        };

        /**
         * Converts this GetUserResponse to JSON.
         * @function toJSON
         * @memberof user.GetUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        GetUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return GetUserResponse;
    })();

    user.CreateUserRequest = (function() {

        /**
         * Properties of a CreateUserRequest.
         * @memberof user
         * @interface ICreateUserRequest
         */

        /**
         * Constructs a new CreateUserRequest.
         * @memberof user
         * @classdesc Represents a CreateUserRequest.
         * @implements ICreateUserRequest
         * @constructor
         * @param {user.ICreateUserRequest=} [properties] Properties to set
         */
        function CreateUserRequest(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Creates a new CreateUserRequest instance using the specified properties.
         * @function create
         * @memberof user.CreateUserRequest
         * @static
         * @param {user.ICreateUserRequest=} [properties] Properties to set
         * @returns {user.CreateUserRequest} CreateUserRequest instance
         */
        CreateUserRequest.create = function create(properties) {
            return new CreateUserRequest(properties);
        };

        /**
         * Encodes the specified CreateUserRequest message. Does not implicitly {@link user.CreateUserRequest.verify|verify} messages.
         * @function encode
         * @memberof user.CreateUserRequest
         * @static
         * @param {user.ICreateUserRequest} message CreateUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateUserRequest.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            return writer;
        };

        /**
         * Encodes the specified CreateUserRequest message, length delimited. Does not implicitly {@link user.CreateUserRequest.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user.CreateUserRequest
         * @static
         * @param {user.ICreateUserRequest} message CreateUserRequest message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateUserRequest.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateUserRequest message from the specified reader or buffer.
         * @function decode
         * @memberof user.CreateUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.CreateUserRequest} CreateUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateUserRequest.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.CreateUserRequest();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateUserRequest message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user.CreateUserRequest
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user.CreateUserRequest} CreateUserRequest
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateUserRequest.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateUserRequest message.
         * @function verify
         * @memberof user.CreateUserRequest
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateUserRequest.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            return null;
        };

        /**
         * Creates a CreateUserRequest message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.CreateUserRequest
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.CreateUserRequest} CreateUserRequest
         */
        CreateUserRequest.fromObject = function fromObject(object) {
            if (object instanceof $root.user.CreateUserRequest)
                return object;
            return new $root.user.CreateUserRequest();
        };

        /**
         * Creates a plain object from a CreateUserRequest message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.CreateUserRequest
         * @static
         * @param {user.CreateUserRequest} message CreateUserRequest
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateUserRequest.toObject = function toObject() {
            return {};
        };

        /**
         * Converts this CreateUserRequest to JSON.
         * @function toJSON
         * @memberof user.CreateUserRequest
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateUserRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateUserRequest;
    })();

    user.CreateUserResponse = (function() {

        /**
         * Properties of a CreateUserResponse.
         * @memberof user
         * @interface ICreateUserResponse
         * @property {user.IDisplayUser|null} [displayUser] CreateUserResponse displayUser
         */

        /**
         * Constructs a new CreateUserResponse.
         * @memberof user
         * @classdesc Represents a CreateUserResponse.
         * @implements ICreateUserResponse
         * @constructor
         * @param {user.ICreateUserResponse=} [properties] Properties to set
         */
        function CreateUserResponse(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * CreateUserResponse displayUser.
         * @member {user.IDisplayUser|null|undefined} displayUser
         * @memberof user.CreateUserResponse
         * @instance
         */
        CreateUserResponse.prototype.displayUser = null;

        /**
         * Creates a new CreateUserResponse instance using the specified properties.
         * @function create
         * @memberof user.CreateUserResponse
         * @static
         * @param {user.ICreateUserResponse=} [properties] Properties to set
         * @returns {user.CreateUserResponse} CreateUserResponse instance
         */
        CreateUserResponse.create = function create(properties) {
            return new CreateUserResponse(properties);
        };

        /**
         * Encodes the specified CreateUserResponse message. Does not implicitly {@link user.CreateUserResponse.verify|verify} messages.
         * @function encode
         * @memberof user.CreateUserResponse
         * @static
         * @param {user.ICreateUserResponse} message CreateUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateUserResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.displayUser != null && message.hasOwnProperty("displayUser"))
                $root.user.DisplayUser.encode(message.displayUser, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };

        /**
         * Encodes the specified CreateUserResponse message, length delimited. Does not implicitly {@link user.CreateUserResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user.CreateUserResponse
         * @static
         * @param {user.ICreateUserResponse} message CreateUserResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CreateUserResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a CreateUserResponse message from the specified reader or buffer.
         * @function decode
         * @memberof user.CreateUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.CreateUserResponse} CreateUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateUserResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.CreateUserResponse();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.displayUser = $root.user.DisplayUser.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a CreateUserResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user.CreateUserResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user.CreateUserResponse} CreateUserResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CreateUserResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a CreateUserResponse message.
         * @function verify
         * @memberof user.CreateUserResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CreateUserResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.displayUser != null && message.hasOwnProperty("displayUser")) {
                let error = $root.user.DisplayUser.verify(message.displayUser);
                if (error)
                    return "displayUser." + error;
            }
            return null;
        };

        /**
         * Creates a CreateUserResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.CreateUserResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.CreateUserResponse} CreateUserResponse
         */
        CreateUserResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.user.CreateUserResponse)
                return object;
            let message = new $root.user.CreateUserResponse();
            if (object.displayUser != null) {
                if (typeof object.displayUser !== "object")
                    throw TypeError(".user.CreateUserResponse.displayUser: object expected");
                message.displayUser = $root.user.DisplayUser.fromObject(object.displayUser);
            }
            return message;
        };

        /**
         * Creates a plain object from a CreateUserResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.CreateUserResponse
         * @static
         * @param {user.CreateUserResponse} message CreateUserResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CreateUserResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.displayUser = null;
            if (message.displayUser != null && message.hasOwnProperty("displayUser"))
                object.displayUser = $root.user.DisplayUser.toObject(message.displayUser, options);
            return object;
        };

        /**
         * Converts this CreateUserResponse to JSON.
         * @function toJSON
         * @memberof user.CreateUserResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CreateUserResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CreateUserResponse;
    })();

    user.UserId = (function() {

        /**
         * Properties of a UserId.
         * @memberof user
         * @interface IUserId
         * @property {string|null} [id] UserId id
         */

        /**
         * Constructs a new UserId.
         * @memberof user
         * @classdesc Represents a UserId.
         * @implements IUserId
         * @constructor
         * @param {user.IUserId=} [properties] Properties to set
         */
        function UserId(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * UserId id.
         * @member {string} id
         * @memberof user.UserId
         * @instance
         */
        UserId.prototype.id = "";

        /**
         * Creates a new UserId instance using the specified properties.
         * @function create
         * @memberof user.UserId
         * @static
         * @param {user.IUserId=} [properties] Properties to set
         * @returns {user.UserId} UserId instance
         */
        UserId.create = function create(properties) {
            return new UserId(properties);
        };

        /**
         * Encodes the specified UserId message. Does not implicitly {@link user.UserId.verify|verify} messages.
         * @function encode
         * @memberof user.UserId
         * @static
         * @param {user.IUserId} message UserId message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserId.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && message.hasOwnProperty("id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            return writer;
        };

        /**
         * Encodes the specified UserId message, length delimited. Does not implicitly {@link user.UserId.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user.UserId
         * @static
         * @param {user.IUserId} message UserId message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        UserId.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a UserId message from the specified reader or buffer.
         * @function decode
         * @memberof user.UserId
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.UserId} UserId
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserId.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.UserId();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a UserId message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user.UserId
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user.UserId} UserId
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        UserId.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a UserId message.
         * @function verify
         * @memberof user.UserId
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        UserId.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            return null;
        };

        /**
         * Creates a UserId message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.UserId
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.UserId} UserId
         */
        UserId.fromObject = function fromObject(object) {
            if (object instanceof $root.user.UserId)
                return object;
            let message = new $root.user.UserId();
            if (object.id != null)
                message.id = String(object.id);
            return message;
        };

        /**
         * Creates a plain object from a UserId message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.UserId
         * @static
         * @param {user.UserId} message UserId
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        UserId.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults)
                object.id = "";
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            return object;
        };

        /**
         * Converts this UserId to JSON.
         * @function toJSON
         * @memberof user.UserId
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        UserId.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UserId;
    })();

    user.Name = (function() {

        /**
         * Properties of a Name.
         * @memberof user
         * @interface IName
         * @property {string|null} [full] Name full
         * @property {string|null} [first] Name first
         * @property {string|null} [last] Name last
         */

        /**
         * Constructs a new Name.
         * @memberof user
         * @classdesc Represents a Name.
         * @implements IName
         * @constructor
         * @param {user.IName=} [properties] Properties to set
         */
        function Name(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Name full.
         * @member {string} full
         * @memberof user.Name
         * @instance
         */
        Name.prototype.full = "";

        /**
         * Name first.
         * @member {string} first
         * @memberof user.Name
         * @instance
         */
        Name.prototype.first = "";

        /**
         * Name last.
         * @member {string} last
         * @memberof user.Name
         * @instance
         */
        Name.prototype.last = "";

        /**
         * Creates a new Name instance using the specified properties.
         * @function create
         * @memberof user.Name
         * @static
         * @param {user.IName=} [properties] Properties to set
         * @returns {user.Name} Name instance
         */
        Name.create = function create(properties) {
            return new Name(properties);
        };

        /**
         * Encodes the specified Name message. Does not implicitly {@link user.Name.verify|verify} messages.
         * @function encode
         * @memberof user.Name
         * @static
         * @param {user.IName} message Name message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Name.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.full != null && message.hasOwnProperty("full"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.full);
            if (message.first != null && message.hasOwnProperty("first"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.first);
            if (message.last != null && message.hasOwnProperty("last"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.last);
            return writer;
        };

        /**
         * Encodes the specified Name message, length delimited. Does not implicitly {@link user.Name.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user.Name
         * @static
         * @param {user.IName} message Name message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Name.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Name message from the specified reader or buffer.
         * @function decode
         * @memberof user.Name
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.Name} Name
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Name.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.Name();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.full = reader.string();
                    break;
                case 2:
                    message.first = reader.string();
                    break;
                case 3:
                    message.last = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Name message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user.Name
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user.Name} Name
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Name.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Name message.
         * @function verify
         * @memberof user.Name
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Name.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.full != null && message.hasOwnProperty("full"))
                if (!$util.isString(message.full))
                    return "full: string expected";
            if (message.first != null && message.hasOwnProperty("first"))
                if (!$util.isString(message.first))
                    return "first: string expected";
            if (message.last != null && message.hasOwnProperty("last"))
                if (!$util.isString(message.last))
                    return "last: string expected";
            return null;
        };

        /**
         * Creates a Name message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.Name
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.Name} Name
         */
        Name.fromObject = function fromObject(object) {
            if (object instanceof $root.user.Name)
                return object;
            let message = new $root.user.Name();
            if (object.full != null)
                message.full = String(object.full);
            if (object.first != null)
                message.first = String(object.first);
            if (object.last != null)
                message.last = String(object.last);
            return message;
        };

        /**
         * Creates a plain object from a Name message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.Name
         * @static
         * @param {user.Name} message Name
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Name.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.full = "";
                object.first = "";
                object.last = "";
            }
            if (message.full != null && message.hasOwnProperty("full"))
                object.full = message.full;
            if (message.first != null && message.hasOwnProperty("first"))
                object.first = message.first;
            if (message.last != null && message.hasOwnProperty("last"))
                object.last = message.last;
            return object;
        };

        /**
         * Converts this Name to JSON.
         * @function toJSON
         * @memberof user.Name
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Name.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Name;
    })();

    user.DisplayUser = (function() {

        /**
         * Properties of a DisplayUser.
         * @memberof user
         * @interface IDisplayUser
         * @property {user.IUserId|null} [userId] DisplayUser userId
         * @property {user.IName|null} [name] DisplayUser name
         * @property {string|null} [profileImageUrl] DisplayUser profileImageUrl
         */

        /**
         * Constructs a new DisplayUser.
         * @memberof user
         * @classdesc Represents a DisplayUser.
         * @implements IDisplayUser
         * @constructor
         * @param {user.IDisplayUser=} [properties] Properties to set
         */
        function DisplayUser(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * DisplayUser userId.
         * @member {user.IUserId|null|undefined} userId
         * @memberof user.DisplayUser
         * @instance
         */
        DisplayUser.prototype.userId = null;

        /**
         * DisplayUser name.
         * @member {user.IName|null|undefined} name
         * @memberof user.DisplayUser
         * @instance
         */
        DisplayUser.prototype.name = null;

        /**
         * DisplayUser profileImageUrl.
         * @member {string} profileImageUrl
         * @memberof user.DisplayUser
         * @instance
         */
        DisplayUser.prototype.profileImageUrl = "";

        /**
         * Creates a new DisplayUser instance using the specified properties.
         * @function create
         * @memberof user.DisplayUser
         * @static
         * @param {user.IDisplayUser=} [properties] Properties to set
         * @returns {user.DisplayUser} DisplayUser instance
         */
        DisplayUser.create = function create(properties) {
            return new DisplayUser(properties);
        };

        /**
         * Encodes the specified DisplayUser message. Does not implicitly {@link user.DisplayUser.verify|verify} messages.
         * @function encode
         * @memberof user.DisplayUser
         * @static
         * @param {user.IDisplayUser} message DisplayUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DisplayUser.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.userId != null && message.hasOwnProperty("userId"))
                $root.user.UserId.encode(message.userId, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.name != null && message.hasOwnProperty("name"))
                $root.user.Name.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.profileImageUrl != null && message.hasOwnProperty("profileImageUrl"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.profileImageUrl);
            return writer;
        };

        /**
         * Encodes the specified DisplayUser message, length delimited. Does not implicitly {@link user.DisplayUser.verify|verify} messages.
         * @function encodeDelimited
         * @memberof user.DisplayUser
         * @static
         * @param {user.IDisplayUser} message DisplayUser message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DisplayUser.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a DisplayUser message from the specified reader or buffer.
         * @function decode
         * @memberof user.DisplayUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {user.DisplayUser} DisplayUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DisplayUser.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.user.DisplayUser();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.userId = $root.user.UserId.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.name = $root.user.Name.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.profileImageUrl = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a DisplayUser message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof user.DisplayUser
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {user.DisplayUser} DisplayUser
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DisplayUser.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a DisplayUser message.
         * @function verify
         * @memberof user.DisplayUser
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DisplayUser.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.userId != null && message.hasOwnProperty("userId")) {
                let error = $root.user.UserId.verify(message.userId);
                if (error)
                    return "userId." + error;
            }
            if (message.name != null && message.hasOwnProperty("name")) {
                let error = $root.user.Name.verify(message.name);
                if (error)
                    return "name." + error;
            }
            if (message.profileImageUrl != null && message.hasOwnProperty("profileImageUrl"))
                if (!$util.isString(message.profileImageUrl))
                    return "profileImageUrl: string expected";
            return null;
        };

        /**
         * Creates a DisplayUser message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof user.DisplayUser
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {user.DisplayUser} DisplayUser
         */
        DisplayUser.fromObject = function fromObject(object) {
            if (object instanceof $root.user.DisplayUser)
                return object;
            let message = new $root.user.DisplayUser();
            if (object.userId != null) {
                if (typeof object.userId !== "object")
                    throw TypeError(".user.DisplayUser.userId: object expected");
                message.userId = $root.user.UserId.fromObject(object.userId);
            }
            if (object.name != null) {
                if (typeof object.name !== "object")
                    throw TypeError(".user.DisplayUser.name: object expected");
                message.name = $root.user.Name.fromObject(object.name);
            }
            if (object.profileImageUrl != null)
                message.profileImageUrl = String(object.profileImageUrl);
            return message;
        };

        /**
         * Creates a plain object from a DisplayUser message. Also converts values to other types if specified.
         * @function toObject
         * @memberof user.DisplayUser
         * @static
         * @param {user.DisplayUser} message DisplayUser
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DisplayUser.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.userId = null;
                object.name = null;
                object.profileImageUrl = "";
            }
            if (message.userId != null && message.hasOwnProperty("userId"))
                object.userId = $root.user.UserId.toObject(message.userId, options);
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = $root.user.Name.toObject(message.name, options);
            if (message.profileImageUrl != null && message.hasOwnProperty("profileImageUrl"))
                object.profileImageUrl = message.profileImageUrl;
            return object;
        };

        /**
         * Converts this DisplayUser to JSON.
         * @function toJSON
         * @memberof user.DisplayUser
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DisplayUser.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return DisplayUser;
    })();

    return user;
})();

export const grp = $root.grp = (() => {

    /**
     * Namespace grp.
     * @exports grp
     * @namespace
     */
    const grp = {};

    grp.Group = (function() {

        /**
         * Properties of a Group.
         * @memberof grp
         * @interface IGroup
         * @property {string|null} [id] Group id
         * @property {string|null} [name] Group name
         * @property {string|null} [ownedDomain] Group ownedDomain
         */

        /**
         * Constructs a new Group.
         * @memberof grp
         * @classdesc Represents a Group.
         * @implements IGroup
         * @constructor
         * @param {grp.IGroup=} [properties] Properties to set
         */
        function Group(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Group id.
         * @member {string} id
         * @memberof grp.Group
         * @instance
         */
        Group.prototype.id = "";

        /**
         * Group name.
         * @member {string} name
         * @memberof grp.Group
         * @instance
         */
        Group.prototype.name = "";

        /**
         * Group ownedDomain.
         * @member {string} ownedDomain
         * @memberof grp.Group
         * @instance
         */
        Group.prototype.ownedDomain = "";

        /**
         * Creates a new Group instance using the specified properties.
         * @function create
         * @memberof grp.Group
         * @static
         * @param {grp.IGroup=} [properties] Properties to set
         * @returns {grp.Group} Group instance
         */
        Group.create = function create(properties) {
            return new Group(properties);
        };

        /**
         * Encodes the specified Group message. Does not implicitly {@link grp.Group.verify|verify} messages.
         * @function encode
         * @memberof grp.Group
         * @static
         * @param {grp.IGroup} message Group message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Group.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && message.hasOwnProperty("id"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
            if (message.name != null && message.hasOwnProperty("name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.ownedDomain != null && message.hasOwnProperty("ownedDomain"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.ownedDomain);
            return writer;
        };

        /**
         * Encodes the specified Group message, length delimited. Does not implicitly {@link grp.Group.verify|verify} messages.
         * @function encodeDelimited
         * @memberof grp.Group
         * @static
         * @param {grp.IGroup} message Group message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Group.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a Group message from the specified reader or buffer.
         * @function decode
         * @memberof grp.Group
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {grp.Group} Group
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Group.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.grp.Group();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.string();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.ownedDomain = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        /**
         * Decodes a Group message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof grp.Group
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {grp.Group} Group
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Group.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        /**
         * Verifies a Group message.
         * @function verify
         * @memberof grp.Group
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Group.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!$util.isString(message.id))
                    return "id: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.ownedDomain != null && message.hasOwnProperty("ownedDomain"))
                if (!$util.isString(message.ownedDomain))
                    return "ownedDomain: string expected";
            return null;
        };

        /**
         * Creates a Group message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof grp.Group
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {grp.Group} Group
         */
        Group.fromObject = function fromObject(object) {
            if (object instanceof $root.grp.Group)
                return object;
            let message = new $root.grp.Group();
            if (object.id != null)
                message.id = String(object.id);
            if (object.name != null)
                message.name = String(object.name);
            if (object.ownedDomain != null)
                message.ownedDomain = String(object.ownedDomain);
            return message;
        };

        /**
         * Creates a plain object from a Group message. Also converts values to other types if specified.
         * @function toObject
         * @memberof grp.Group
         * @static
         * @param {grp.Group} message Group
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Group.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            let object = {};
            if (options.defaults) {
                object.id = "";
                object.name = "";
                object.ownedDomain = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.ownedDomain != null && message.hasOwnProperty("ownedDomain"))
                object.ownedDomain = message.ownedDomain;
            return object;
        };

        /**
         * Converts this Group to JSON.
         * @function toJSON
         * @memberof grp.Group
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Group.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Group;
    })();

    return grp;
})();

export { $root as default };
